
import React, { createContext, useContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";

import { ICampaign } from "../types/ICampaign";
import { FETCH_LATEST_CAMPAIGNS_DB } from "config";


export const LatestCampaignsContext = createContext<ILatestCampaignsContext>(null!);
export interface ILatestCampaignsContext extends IDataContext<ICampaign> { }


interface ILatestCampaignsContextProvider {
    children: React.ReactNode;
}
const LatestCampaignsContextProvider = ({ children }: ILatestCampaignsContextProvider) => {
    const { clients } = useContext(UserContext);

    let params = { 
        clients: clients
    };

    return (
        <DataContextProvider<ICampaign>
            context={LatestCampaignsContext}
            endpoint={FETCH_LATEST_CAMPAIGNS_DB}
            params={params}
        >
            {children}
        </DataContextProvider>
    );
};

export default LatestCampaignsContextProvider;