const GetDaysFromConfig = (daysStr: string) => {
    let days = daysStr
            ?.split(",")
            .map((x: string) => x.trim())
            .join(", ") ?? "";
    if (days === "Monday, Tuesday, Wednesday, Thursday, Friday") days = "on weekdays";
    if (days === "Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday") days = "every day";
    return days;
};

export default GetDaysFromConfig;