import React from "react";

import { Fade } from "react-awesome-reveal";

import { IEvent } from "../EventDashboard";

interface IEventInfoProps {
  event: IEvent;
  onClose: () => void;
}

const EventInfo = ({ event, onClose }: IEventInfoProps) => {
  return (
    <>
      <Fade duration={1500} delay={50}>
        <div>
          <h2>{event.title}</h2>
        </div>
      </Fade>
      <Fade duration={1500} delay={100}>
        <>
          <label htmlFor="description">Description</label>
          <input
            type="text"
            placeholder="Description"
            name="description"
            value={event.description}
            readOnly={true}
          ></input>
        </>
      </Fade>
      {event.resource && (
        <Fade duration={1500} delay={100}>
          <>
            <label htmlFor="resource">Resource</label>
            <input
              type="text"
              placeholder="Resource"
              name="resource"
              value={event.resource}
              readOnly={true}
            ></input>
          </>
        </Fade>
      )}
      {event.meta && event.meta.tags && (
        <Fade duration={1500} delay={100}>
          <>
            <label htmlFor="tags">Tags</label>
            <input
              type="text"
              placeholder="Tags"
              name="tags"
              value={event.meta.tags.join(", ")}
              readOnly={true}
            ></input>
          </>
        </Fade>
      )}
      <Fade duration={1500} delay={150}>
        <>
          <button
            className={`calendar--button cancel`}
            onClick={onClose}
          ></button>
        </>
      </Fade>
    </>
  );
};

export default EventInfo;
