import React from "react";

import GetStatusIcon from "core/helpers/GetStatusIcon";
import { IssueEnum } from "../tabs/enums/IssueEnum";

const IssuesSubTitle = (issues: number) => {
    const icon = issues > 0 ? GetStatusIcon(IssueEnum.ERROR) : GetStatusIcon(IssueEnum.OK);
    const spacer = <span style={{'width': '1rem', 'display': 'inline-block'}}></span>
    return (
        <h5>{icon}{spacer}{`${issues > 0 ? issues : 'No'} issues`}</h5>
    );
};

export default IssuesSubTitle;