import { Feeds } from "./feeds";

export interface IFrequency {
    expected: number;
    warning: number;
    alert: number;
    ignoredDays?: number[];
}

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;

export const TEN_MIN_FREQ = {
    expected: 10 * MINUTE,
    warning: HOUR,
    alert: 4 * HOUR,
};
export const DAILY_FREQ = {
    expected: 24 * HOUR,
    warning: 36 * HOUR,
    alert: 48 * HOUR,
};
export const WEEKDAY_FREQ = {
    expected: 24 * HOUR,
    warning: 25 * HOUR,
    alert: 36 * HOUR,
    ignoredDays: [0, 6],
};
export const WEEKLY_FREQ = {
    expected: 7 * 24 * HOUR,
    warning: 7 * 24 * HOUR + 8 * HOUR,
    alert: 8 * 24 * HOUR,
};
export const MONTHLY_FREQ = {
    expected: 30 * 24 * HOUR,
    warning: 30 * 24 * HOUR + 8 * HOUR,
    alert: 31 * 24 * HOUR,
};
export const QUARTERLY_FREQ = {
    expected: 90 * 24 * HOUR,
    warning: 90 * 24 * HOUR + 8 * HOUR,
    alert: 91 * 24 * HOUR,
};

export const Frequencies: Record<string, IFrequency> = {
    [Feeds.AccountCounter]: TEN_MIN_FREQ,
    [Feeds.AccountCounterRecord]: TEN_MIN_FREQ,
    [Feeds.AccountHistory]: TEN_MIN_FREQ,
    [Feeds.ActivationTermination]: DAILY_FREQ,
    [Feeds.Adjustment]: DAILY_FREQ,
    [Feeds.AdjustmentRecord]: TEN_MIN_FREQ,
    [Feeds.ANIInventory]: DAILY_FREQ,
    [Feeds.CallRecord]: TEN_MIN_FREQ,
    [Feeds.CallsInbound]: DAILY_FREQ,
    [Feeds.CallsOutbound]: DAILY_FREQ,
    [Feeds.CustomerHistory]: TEN_MIN_FREQ,
    [Feeds.DataSessions]: DAILY_FREQ,
    [Feeds.DataSessionsTBUsed]: DAILY_FREQ,
    [Feeds.DataSessionsUserCount]: DAILY_FREQ,
    [Feeds.Inventory]: DAILY_FREQ,
    [Feeds.PadsRecord]: TEN_MIN_FREQ,
    [Feeds.PhoneNumberHistory]: TEN_MIN_FREQ,
    [Feeds.SIMHistory]: TEN_MIN_FREQ,
    [Feeds.SMSOutbound]: DAILY_FREQ,
    [Feeds.SMSRecord]: TEN_MIN_FREQ,
    [Feeds.SubscriptionHistory]: TEN_MIN_FREQ,
    [Feeds.Topup]: DAILY_FREQ,
    [Feeds.MessageHistoryReport]: DAILY_FREQ,
    [Feeds.PDAAccountBalance]: DAILY_FREQ,
    [Feeds.PDAAccountLifecycle]: DAILY_FREQ,
    [Feeds.PDAAccountStatus]: DAILY_FREQ,
    [Feeds.PDABalanceManagement]: DAILY_FREQ,
    [Feeds.PDACustomerDeactivation]: DAILY_FREQ,
    [Feeds.PDAFreeUsage]: DAILY_FREQ,
    [Feeds.PDAIMEIRef]: DAILY_FREQ,
    [Feeds.PDAInterconnectVoice]: DAILY_FREQ,
    [Feeds.PDAMemberExpiry]: DAILY_FREQ,
    [Feeds.PDAMOPrepaidMMS]: DAILY_FREQ,
    [Feeds.PDAMOPrepaidSMS]: DAILY_FREQ,
    [Feeds.PDAMOPrepaidVoice]: DAILY_FREQ,
    [Feeds.PDAMOWholesaleVoice]: DAILY_FREQ,
    [Feeds.PDAMTPrepaidSMS]: DAILY_FREQ,
    [Feeds.PDAMTPrepaidVoice]: DAILY_FREQ,
    [Feeds.PDAMTWholesaleVoice]: DAILY_FREQ,
    [Feeds.PDAPortIn]: DAILY_FREQ,
    [Feeds.PDAPortOut]: DAILY_FREQ,
    [Feeds.PDAPortOutBalance]: DAILY_FREQ,
    [Feeds.PDAPrepaidGPRS]: DAILY_FREQ,
    [Feeds.PDAPrepaidTopups]: DAILY_FREQ,
    [Feeds.PDASocialMedia]: DAILY_FREQ,
    [Feeds.PDASubscrAdj]: DAILY_FREQ,
    [Feeds.PDATimeRecTopups]: DAILY_FREQ,
    [Feeds.PDATRTRecurringMem]: DAILY_FREQ,
    [Feeds.PDAEEARoamingSummary]: QUARTERLY_FREQ,
    [Feeds.PDAIntraEEASummary]: QUARTERLY_FREQ,
    [Feeds.PDARoamingSummary]: QUARTERLY_FREQ,
    [Feeds.SHIPNOT]: DAILY_FREQ,
    [Feeds.FreemiumParqueReport]: DAILY_FREQ,
    [Feeds.ReportTableroDiario]: DAILY_FREQ,
    [Feeds.CalidadDeAltasWeekly]: WEEKLY_FREQ,
    [Feeds.MatrizA]: WEEKLY_FREQ,
    [Feeds.ReporteVentas]: DAILY_FREQ,
    [Feeds.WeeklyParqueReport]: WEEKLY_FREQ,
    [Feeds.ActivatedCustomers]: DAILY_FREQ,
    [Feeds.ActivationRate]: DAILY_FREQ,
    [Feeds.BaseManagementThreeReport]: DAILY_FREQ,
    [Feeds.BaseManagement]: DAILY_FREQ,
    [Feeds.BaseManagementMonthly]: MONTHLY_FREQ,
    [Feeds.BaseManagement1]: DAILY_FREQ,
    [Feeds.BaseManagement2]: DAILY_FREQ,
    [Feeds.BundlesPerDaySummary]: DAILY_FREQ,
    [Feeds.CallTypeSummary]: DAILY_FREQ,
    [Feeds.CampaignPIR]: DAILY_FREQ,
    [Feeds.ChurnFunnel]: WEEKLY_FREQ,
    [Feeds.CreditApplication]: WEEKLY_FREQ,
    [Feeds.CreditApplicationReport]: WEEKLY_FREQ,
    [Feeds.DailyFlash]: DAILY_FREQ,
    [Feeds.Daily]: WEEKLY_FREQ,
    [Feeds.DecayRate]: WEEKLY_FREQ,
    [Feeds.Donations]: WEEKDAY_FREQ,
    [Feeds.FailedPayments]: DAILY_FREQ,
    [Feeds.FlexiData]: WEEKDAY_FREQ,
    [Feeds.FreeUsageTest]: WEEKDAY_FREQ,
    [Feeds.FreeUsage]: WEEKDAY_FREQ,
    [Feeds.FUPDailyReportDistinctDial]: WEEKLY_FREQ,
    [Feeds.KickbackPortalOrders]: DAILY_FREQ,
    [Feeds.KickbackPortalOrdersCSV]: DAILY_FREQ,
    [Feeds.KickbackPortalOrdersExcel]: DAILY_FREQ,
    [Feeds.PortInOutSummary]: DAILY_FREQ,
    [Feeds.PortInOutSummaryCSV]: DAILY_FREQ,
    [Feeds.PortInOutSummaryExcel]: DAILY_FREQ,
    [Feeds.Migration]: WEEKDAY_FREQ,
    [Feeds.RecruiteesReport]: DAILY_FREQ,
    [Feeds.SMSAndVoiceAbuseWeeklyReport]: WEEKLY_FREQ,
    [Feeds.TheSafe]: WEEKDAY_FREQ,
    [Feeds.WeeklyTradingReport]: WEEKLY_FREQ,
    [Feeds.Tester48]: DAILY_FREQ,
    [Feeds.TotalNetworkVoiceMonthly]: MONTHLY_FREQ,
    [Feeds.TotalNetworkSMSMonthly]: MONTHLY_FREQ,
    [Feeds.DataUsageTotalNetworkMonthly]: MONTHLY_FREQ,
    [Feeds.ComReg]: MONTHLY_FREQ,
    [Feeds.BaseManagementThreeReportMonthly]: MONTHLY_FREQ,
    [Feeds.PortingAndRoamingQuarterly]: QUARTERLY_FREQ,
    [Feeds.Segmentation]: DAILY_FREQ,
}