import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

import { LambdaContext } from "core/data/contexts/LambdaContextProvider";
import Debug from "core/helpers/Debug";

import { User } from "core/data/auth/types/User";

import FetchFromLambda from "../aws/lambda/FetchFromLambda";

import { FETCH_USERS_LAMBDA } from "config";

// Define default value to avoid destructuring errors
export const UsersContext = createContext<IUsersContext>({
    users: [],
    fetchUser: (id: number) => ({}) as User,
    fetchUserName: (id: number) => "",
    fetchUserIdByEmail: (email: string) => -1,
    // overrideUser: (id: number) => { },
});

export interface IUsersContext {
    users: User[];
    fetchUser: (id: number) => User;
    fetchUserName: (id: number) => string;
    fetchUserIdByEmail: (email: string) => number;
    // overrideUser: (id: number) => void;
}

interface UsersContextProviderProps {
    children: ReactNode;
}
const UsersContextProvider = (props: UsersContextProviderProps) => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);

    const { lambda } = useContext(LambdaContext);

    // Fetch the DK User from the API
    const getUsers = useCallback(async () => {
        if (!lambda) return;

        try {
            const [error, data] = await FetchFromLambda<User[]>(lambda, FETCH_USERS_LAMBDA);
            Debug(FETCH_USERS_LAMBDA, error, data);

            if (error) {
                console.error(error);
                setUsers([]);
                setLoading(false);
            } else {
                setUsers(data);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setUsers([]);
            setLoading(false);
        }
    }, [lambda]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);



    const fetchUser = useCallback((id: number) => {
        return users.find(x => x.id === id) ?? {} as User;
    }, [users]);

    const fetchUserByEmail = useCallback((email: string) => {
        return users.find(x => x.email_address === email) ?? {} as User;
    }, [users]);

    const fetchUserName = useCallback((id: number) => {
        return fetchUser(id)?.first_name ?? "Missing";
    }, [fetchUser]);

    const fetchUserIdByEmail = useCallback((email: string) => {
        return fetchUserByEmail(email)?.id ?? -1;
    }, [fetchUserByEmail]);

    // As pretty much all of the app depends on the user being logged in
    // wait until we have all the data before rendering the app

    const value: IUsersContext = {
        users,
        fetchUser,
        fetchUserName,
        fetchUserIdByEmail,
    };

    return (
        <UsersContext.Provider value={value}>
            {loading && <div>Loading...</div>}
            {!loading && props.children}
        </UsersContext.Provider>
    )
};

export default UsersContextProvider;