import React from 'react';

interface ISimpleListItemProps {
    title: string;
    text?: string;
}

const SimpleListItem = ({ title, text = '' }: ISimpleListItemProps) => {
    return (
        <div className={'listitem simple'}>
            <div className={'listitem__header'}>
                <div className={'listitem__title'}>
                    {title}
                </div>
                
                <div className={`listitem__value`}>
                    {text}
                </div>
            </div>
        </div>
    );
};

export default SimpleListItem;