import React from "react";


const LoadingSpinner = () => {
    return (
        <div className="lds-ring-container">
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <style>{`
                .lds-ring-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }
                .lds-ring {
                    display: inline-block;
                    position: relative;
                    width: 80px;
                    height: 80px;
                }
                .lds-ring div {
                    box-sizing: border-box;
                    display: block;
                    position: absolute;
                    width: 64px;
                    height: 64px;
                    margin: 8px;
                    border: 8px solid #000;
                    border-radius: 50%;
                    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    border-color: #fff transparent transparent transparent;
                }
                .lds-ring div:nth-child(1) {
                    animation-delay: -0.45s;
                    border-color: #c0162e;
                    border-width: 6px;
                    width: 62px;
                    height: 62px;
                    left: 1px;
                    top: 1px;
                }
                .lds-ring div:nth-child(2) {
                    animation-delay: -0.3s;
                }
                .lds-ring div:nth-child(3) {
                    animation-delay: -0.15s;
                }
                @keyframes lds-ring {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
        </div>
        </div>
    );
};

export default LoadingSpinner;