import React, { useEffect, useState, useContext } from "react";

import { Auth } from 'aws-amplify';

import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

import { IAWSAuthContext, AWSAuthContext } from 'core/data/aws/AWSAuthContextProvider';

import IsEmptyObj from 'core/helpers/IsEmptyObj';

import LoginForm from './components/LoginForm';
import OTPForm from './components/OTPForm';
import AddOTPForm from "./components/AddOTPForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
import NewPasswordForm from "./components/NewPasswordForm";

import "./_login.scss";

const FORM_STATE_LOGIN = 'login';
const FORM_STATE_OTP = 'otp';
const FORM_STATE_RESET_PASSWORD = 'reset_password';
const FORM_STATE_NEW_PASSWORD = 'new_password';
const FORM_STATE_ADD_OTP = 'add_otp';

const Login = () => {
    const [loginState, setLoginState] = useState(FORM_STATE_LOGIN);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const [email, setEmail] = useState('');
    const [tempUser, setTempUser] = useState({});

    const navigate = useNavigate();

    const { user, logIn } = useContext(AWSAuthContext);

    useEffect(() => {
        if (!IsEmptyObj(user)) {
            navigate("/");
            return;
        }
    }, [user, navigate]);

    const doLogin = async (email: string, password: string) => {
        setError('');
        setMessage('');

        try {
            const user = await Auth.signIn(email, password);

            if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setupForOTPCheck(user);
            } else if (user.challengeName === 'MFA_SETUP') {
                setupForAddingOTPToUser(user, email);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log(user.challengeParam);
                setupForNewPassword(user);
            } else {
                actionLogin();
            }
        } catch (error: any) {
            console.log(error, email);

            if (error.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
                setError('User is not confirmed. Please check your email for the confirmation code.');
            } else if (error.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
                setMessage('Password reset required. Please check your email for the reset code.');
                await setupForPasswordReset(email);
            } else if (error.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                setError('Incorrect username or password.');
            } else if (error.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                setError('Incorrect username or password.');
            } else {
                setError(error.message);
            }
        }
    }

    // Set the form state for the MFA input
    const setupForOTPCheck = (user: object) => {
        setTempUser(user);
        setLoginState(FORM_STATE_OTP);
    };

    // Set the form state to add MFA to user's account
    const setupForAddingOTPToUser = (user: object, email: string) => {
        setTempUser(user);
        setEmail(email);
        setLoginState(FORM_STATE_ADD_OTP);
    };

    // Set the form state to resetting a user's password
    const setupForPasswordReset = async (email: string) => {
        setEmail(email);
        setLoginState(FORM_STATE_RESET_PASSWORD);
        
        if (email) {
            await doSendVerificationEmail(email);
        }
    };

    // Set the form state to resetting a user's password
    const setupForNewPassword = (user: object) => {
        setTempUser(user);
        setLoginState(FORM_STATE_NEW_PASSWORD);
    };


    // Set the form state to resetting a user's password
    const doSendVerificationEmail = async (email: string) => {
        setError('');
        setMessage('Please check your email for the verification code.');

        try {
            const data = await Auth.forgotPassword(email);
            console.log(data);
        } catch (error: any) {
            // If the user doesn't exist, don't show an error as it allows easy enumeration of emails
            console.error(error);
            if (error.message === "User password cannot be reset in the current state.") {
                setError(error.message);
            }
            setMessage('');
            setLoginState(FORM_STATE_LOGIN);
        }
    };

    // Validate the MFA code
    const doOTPCheck = async (otpCode: string) => {
        setError('');
        setMessage('');

        try {
            await Auth.confirmSignIn(tempUser, otpCode, 'SOFTWARE_TOKEN_MFA');

            actionLogin();
        } catch (error) {
            console.error(error);
            setError('Failed to login. Please try again.');
            setLoginState(FORM_STATE_LOGIN);
        }
    };

    // Verify the token and add the MFA to the user's account
    const doAddOTP = async (user: object, otpCode: string) => {
        setError('');
        setMessage('');

        try {
            await Auth.verifyTotpToken(user, otpCode);
            Auth.setPreferredMFA(user, 'TOTP');

            actionLogin();
        } catch (error: any) {
            console.error(error);

            if (error.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
                setError('User is not confirmed. Please check your email for the confirmation code.');
                setLoginState(FORM_STATE_LOGIN);
            } else if (error.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
                setLoginState(FORM_STATE_RESET_PASSWORD);
            } else if (error.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                setError('Incorrect username or password.');
                setLoginState(FORM_STATE_LOGIN);
            } else if (error.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                setError('Incorrect username or password.');
                setLoginState(FORM_STATE_LOGIN);
            } else if (error.code === 'EnableSoftwareTokenMFAException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                setError('Code mismatch');
            } else {
                setError(error.message);
            }
            // Throw the error so the UI can reset the button
            throw error;
        }
    };

    // Verify the token and add the MFA to the user's account
    const doPasswordReset = async (email: string, verificationCode: string, newPassword: string) => {
        setError('');
        setMessage('');

        try {
            await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);

            clearInputs();
            setLoginState(FORM_STATE_LOGIN);
            setMessage('Password reset successfully. Please log in.');
        } catch (error: any) {
            console.error(error);

            if (error.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
                setError('User is not confirmed. Please check your email for the confirmation code.');
                setLoginState(FORM_STATE_LOGIN);
            } else if (error.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
                setLoginState(FORM_STATE_RESET_PASSWORD);
            } else if (error.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                setError('Incorrect username or password.');
                setLoginState(FORM_STATE_LOGIN);
            } else if (error.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                setError('Incorrect username or password.');
                setLoginState(FORM_STATE_LOGIN);
            } else {
                setError(error.message);
            }
        }
    };

    // Verify the token and add the MFA to the user's account
    const doNewPassword = async (tempUser: object, newPassword: string, name: string) => {
        setError('');
        setMessage('');

        try {
            const user = await Auth.completeNewPassword(tempUser, newPassword, { name });

            if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setupForOTPCheck(user);
            } else if (user.challengeName === 'MFA_SETUP') {
                setupForAddingOTPToUser(user, email);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log(user.challengeParam);
                setupForNewPassword(user);
            } else {
                actionLogin();
            }
        } catch (error: any) {
            console.error(error);

            if (error.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
                setError('User is not confirmed. Please check your email for the confirmation code.');
                setLoginState(FORM_STATE_LOGIN);
            } else if (error.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
                setLoginState(FORM_STATE_RESET_PASSWORD);
            } else if (error.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                setError('Incorrect username or password.');
                setLoginState(FORM_STATE_LOGIN);
            } else if (error.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                setError('Incorrect username or password.');
                setLoginState(FORM_STATE_LOGIN);
            } else {
                setError(error.message);
            }
        }
    };


    const actionLogin = () => {
        logIn();
        // Navigation will be handled by useEffect once the user object has updated
    };

    const clearInputs = () => {
        setEmail('');
        setTempUser({});
        setError('');
    };

    return (
        <section className="login">
            <section className="login--hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-12">
                            <Fade duration={1500} delay={0}>
                                <div className="hero__heading">
                                    <h1>
                                        Welcome
                                    </h1>
                                </div>
                            </Fade>
                            <Fade duration={1500} delay={200}>
                                <div className="hero__subheading">
                                    <h6>
                                        Log in and view your data with the Iris platform
                                    </h6>
                                </div>
                            </Fade>
                        </div>

                        <div className="col-12 col-lg-5">
                            {error &&
                                <p className="error">{error}</p>
                            }
                            {message &&
                                <p className="message">{message}</p>
                            }
                            {loginState === FORM_STATE_LOGIN &&
                                <LoginForm doLogin={doLogin} setupForPasswordReset={setupForPasswordReset} />
                            }
                            {loginState === FORM_STATE_OTP &&
                                <OTPForm doOTPCheck={doOTPCheck} />
                            }
                            {loginState === FORM_STATE_ADD_OTP &&
                                <AddOTPForm user={tempUser} email={email} doAddOTP={doAddOTP} />
                            }
                            {loginState === FORM_STATE_RESET_PASSWORD &&
                                <ResetPasswordForm inital_email={email} doSendVerificationEmail={doSendVerificationEmail} doPasswordReset={doPasswordReset} />
                            }
                            {loginState === FORM_STATE_NEW_PASSWORD &&
                                <NewPasswordForm user={tempUser} doNewPassword={doNewPassword} />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Login;
