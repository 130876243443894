import React, { ReactNode, useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "core/data/auth/UserContextProvider";

import { Permission } from "core/enums/Permissions";

import Debug from "../Debug";

interface AdminLinkProps {
    permission: Permission;
    to: string;
    children: ReactNode;
}
const AdminLink = ({permission, to, children}: AdminLinkProps) => {
    const { permissions } = useContext(UserContext);

    Debug("AdminLink", permission, permissions);

    return (
        <>
            {permissions.includes(permission) ? (
                <Link to={to}>
                    {children}
                </Link>
            ) : (
                <>
                    {children}
                </>
            )}
        </>
    );
}

export default AdminLink;