import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import Box from "@mui/material/Box";

import CustomTable, { SelectColumnFilter } from "core/components/status-table/CustomTable";
import FormatDate from "core/helpers/datetime/FormatDate";

import { Ticket } from "../types/Ticket";
import { ServiceDeskContext } from "../contexts/ServiceDeskContextProvider";
import ListTile from "core/components/tiles/ListTile";

const ServiceDeskTicketsList = () => {
    const { tickets } = useContext(ServiceDeskContext);
    console.log('tickets', tickets);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }: CellProps<Ticket>) => {
                    return (
                        <Link to={`/service-desk/${row.original.id}`}>
                            {row.original.name}
                        </Link>
                    )
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                filter: 'fuzzyText'
            },
            {
                Header: 'Status',
                accessor: 'status',
                Filter: SelectColumnFilter,
                filter: 'includes'
            },
            {
                Header: 'Priority',
                accessor: 'priority',
                Filter: SelectColumnFilter
            },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: ({ value }: { value: Date }) => FormatDate(value),
            },

            {
                Header: 'Updated',
                accessor: 'updated',
                Cell: ({ value }: { value: Date }) => FormatDate(value),
            },
            {
                Header: 'Type',
                accessor: 'type',
                Filter: SelectColumnFilter
            },
        ], []
    );

    tickets.sort((a, b) => a.id - b.id);
    const data = useMemo(() => tickets, [tickets]);

    console.log(tickets.map(x => x.client));

    return (
        <div className="service-desk__tickets-list">
            <ListTile>
                <h1 className={`list__title`}>
                    All Tickets
                </h1>

                <Box className={'tabs-container'}>
                    <CustomTable columns={columns} data={data} />
                </Box>
            </ListTile>
        </div >
    );
};
export default ServiceDeskTicketsList;
