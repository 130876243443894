import React, { ReactNode, createContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";

import { IFeedConfig } from "../types/IFeedConfig";
import { FETCH_FEEDS_CONFIG_DB } from "config";

export const FeedsConfigContext = createContext<IFeedsConfigContext>(null!);
export interface IFeedsConfigContext extends IDataContext<IFeedConfig> { }


interface IFeedsConfigContextProvider {
    children: ReactNode;
}
const FeedsConfigContextProvider = ({ children }: IFeedsConfigContextProvider) => {
    return (
        <DataContextProvider<IFeedConfig>
            context={FeedsConfigContext}
            endpoint={FETCH_FEEDS_CONFIG_DB}
        >
            {children}
        </DataContextProvider>
    );
};

export default FeedsConfigContextProvider;