import React from "react";
import TiledDashboard from "./TiledDashboard";


const GraphDashboard = () => {
    return (
        <section className="homepage">
            <TiledDashboard />
        </section>
    )
}
export default GraphDashboard;