import React from "react";

import IssuesList from "features/dashboards/issues/IssuesList";
import StatusSummary from "./components/StatusSummary";

const Dashboard = () => {
    return (
        <>
            <IssuesList />
            <StatusSummary />
        </>
    );
};

export default Dashboard;