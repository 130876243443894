import React, { useContext } from "react";

import HalfWidthTileContainer from "core/components/tiles/HalfWidthTileContainer";
import WideTile from "core/components/tiles/WideTile";
import SimpleTable from "core/components/status-table/SimpleTable";
import SimpleListItem from "core/components/status-table/SimpleListItem";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";

import FormatDay from "core/helpers/datetime/FormatDay";
import Debug from "core/helpers/Debug";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";

import { MetricsHistoryContext } from "../contexts/MetricsHistoryContextProvider";

import { IMetricHistory } from "../types/IMetricHistory";

interface IMetricHistoryTableProps {
    client: number;
    metric: number;
}
const MetricHistoryTable = ({ client, metric }: IMetricHistoryTableProps) => {
    const { items: metricsHistory, error: metricsHistoryError } = useContext(MetricsHistoryContext);

    const metricHistory = metricsHistory
        ?.filter((x) => x.client_id === client && x.metric_id === metric && x.metric_date !== null)
        ?.map((x) => ({
            ...x,
            name: x.metric_name,
            last_date: ParseDBDate(x.metric_date),
            next_date: 0,
            issue: IssueEnum.OK,
        }))
        ?.sort((a, b) => b.last_date - a.last_date);
    Debug("History", metricHistory);

    return (
        <HalfWidthTileContainer grow={true}>
            {metricHistory && <SimpleTable<IMetricHistory> title={"History"} items={metricHistory.slice(0, 7)} error={metricsHistoryError} RenderRow={(item) => <SimpleListItem key={item.name} title={FormatDay(item.last_date)} text={item.metric_value.toString()} />} />}
            {!metricHistory && <LoadingSpinner />}
            {metricHistory && metricHistory.length === 0 && (
                <WideTile>
                    <h3>History</h3>
                    <p>No history found</p>
                </WideTile>
            )}
        </HalfWidthTileContainer>
    );
};

export default MetricHistoryTable;