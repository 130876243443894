import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { LatestReportsContext } from "../contexts/LatestReportsContextProvider";

const ReportTitle = ({ client, report }: { client: number; report: number }) => {
    const { items: latestReports, setVisible: setLatestVisible } = useContext(LatestReportsContext);

    useEffect(() => {
        setLatestVisible(true);

        return () => {
            setLatestVisible(false);
        };
    }, [setLatestVisible]);

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const report_data = latestReports?.find((x) => x.client_id === client && x.report_id === report);
    const report_name = report_data?.report_name ?? "";
    const client_name = report_data?.client_name ?? "";

    return (
        <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
            <Button style={{ marginRight: "auto", alignSelf: "center" }} color="secondary" onClick={goBack}>
                Back
            </Button>
            <h1 style={{ marginRight: "2rem", marginBottom: "0" }}>{report_name}</h1>
            <h3 style={{ marginRight: "auto", marginBottom: ".2rem" }}>{client_name}</h3>
        </div>
    );
};

export default ReportTitle;