import React, { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";

import { ClientNames } from "core/data/clients/client_names";
import { FeedNames } from "core/data/feeds/feed_names";
import { Feeds } from "core/data/feeds/feeds";

import CampaignTitle from "./CampaignTitle";
import CampaignConfig from "./CampaignConfig";
import CampaignHistoryTable from "./CampaignHistoryTable";

import "../_campaigns.scss";


const CampaignPage = () => {
    let { client, campaign } = useParams();

    console.log(client, campaign);

    const valid = useMemo(() => Object.values(Feeds).includes(campaign as Feeds), [campaign]);

    const campaignName = FeedNames.get(campaign!) ?? campaign ?? "";
    const clientName = ClientNames.get(client!) ?? client ?? "";

    return (
        <>
            {!valid && <Navigate to="/campaigns" />}

            {valid &&
                <>
                    <CampaignTitle client={clientName} campaign={campaignName} />

                    <div className="campaign-config">
                        <CampaignConfig client={client!} campaign={campaignName} />
                        <CampaignHistoryTable client={client!} campaign={campaignName} />
                    </div>
                </>
            }
        </>
    );
};
export default CampaignPage;
