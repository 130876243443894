import React from "react";

import ReactEcharts from "echarts-for-react";

const Line = () => {
    const option = {
        xAxis: {
            type: 'category',
            data: ['Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#EEEEEE'],
                    width: 1,
                    type: 'solid'
                }

            },
            axisLine: { show: false },
            boundaryGap: false
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 100,
            splitNumber: 6,
            tooltip: { show: true },
            splitLine: {
                show: false
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#EEEEEE;',
                    width: 1,
                    type: 'dashed'
                }
            },
            axisPointer: [{ show: true }]
        },
        series: [
            {
                data: [26, 50, 25, 25, 50, 22, 10, 25],
                type: 'line',
                smooth: true
            },
            {
                data: [10, 25, 23, 25, 5, 27, 40, 30, 50],
                type: 'line',
                smooth: true
            },
        ]
    };

    return (
        <ReactEcharts
            option={option}
            style={{ height: "100%", width: "100%" }}
        />
    )
}

export default Line;