import React, { ReactNode, createContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";

import { IMetric } from "../types/IMetric";
import { FETCH_LATEST_METRICS_DB } from "config";


export const LatestMetricsContext = createContext<ILatestMetricsContext>(null!);
export interface ILatestMetricsContext extends IDataContext<IMetric> { }


interface ILatestMetricsContextProvider {
    children: ReactNode;
}
const LatestMetricsContextProvider = ({ children }: ILatestMetricsContextProvider) => {
    return (
        <DataContextProvider<IMetric>
            context={LatestMetricsContext}
            endpoint={FETCH_LATEST_METRICS_DB}
        >
            {children}
        </DataContextProvider>
    );
};

export default LatestMetricsContextProvider;