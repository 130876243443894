import React from "react";
import { Fade } from "react-awesome-reveal";

import Box from "@mui/material/Box";

interface ISimpleTableProps<T> {
    title: string,
    RenderRow: (item: T) => JSX.Element
    items?: T[] | null,
    error: string
}

const SimpleTable = <T extends unknown>({ title, RenderRow, items = null, error = '' }: ISimpleTableProps<T>) => {
    // Display the items in a single long list
    return (
        <div className={`tile wide list simple`}>
            <h3 className={`list__title`}>{title}</h3>

            <Box className={'tabs-container'}>
                {error &&
                    <p>{error}</p>
                }
                {(!items || items.length === 0) &&
                    <p>No items found</p>
                }
                {items && items.length > 0 &&
                    <>
                        {items.map((row, ii) => (
                            <Fade key={ii} duration={500} delay={50}>
                                {RenderRow(row)}
                            </Fade>
                        ))}
                    </>
                }
            </Box>
        </div>
    );
};

export default SimpleTable;