import React from "react";
import { useParams } from "react-router-dom";

import { fetchFeedHistoryContext } from "../contexts/FeedsHistoryContextProviders";

import FeedTitle from "./FeedTitle";
import FeedConfig from "./FeedConfig";
import FeedHistoryTable from "./FeedHistoryTable";

import "../_feeds.scss";

const FeedPage = () => {
    let { client, feed } = useParams();

    const client_id = parseInt(client ?? "0");
    const feed_id = parseInt(feed ?? "0");

    console.log(client, feed, client_id, feed_id);

    const historyCtx = fetchFeedHistoryContext(feed_id);
    console.log('historyCtx', historyCtx);

    return (
        <>
            <FeedTitle client={client_id} feed={feed_id} />

            {historyCtx && historyCtx.context && (
                <div className="feed-config">
                    <FeedConfig client={client_id} feed={feed_id} historyCtx={historyCtx!.context} />
                    <FeedHistoryTable client={client_id} feed={feed_id} historyCtx={historyCtx!.context} />
                </div>
            )}
        </>
    );
};
export default FeedPage;