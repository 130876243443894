import React, { FormEvent, useCallback, useContext, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import Select, { MultiValue } from 'react-select';
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "@mui/material";

import { EmailType, MetricType, TargetingType } from "../types/ReportTypes";
import { Priority } from "../types/Priority";
import { ItemType } from "../types/ItemType";
import { Ticket } from "../types/Ticket";
import { IContractQuota, ServiceDeskContext } from "../contexts/ServiceDeskContextProvider";
import { TicketType } from "../types/TicketType";
import { TicketStatus } from "../types/TicketStatus";
import { UserContext } from "core/data/auth/UserContextProvider";
import CustomTable from "core/components/status-table/CustomTable";
import { ContractPriority } from "../types/ContractPriorities";


enum CreateTicketViews {
    ChooseType = "choose-type",
    ChooseSubType = "choose-sub-type",
    AddDetails = "add-details",
    ChooseItem = "choose-item",
}

const ServiceDeskCreateNew = () => {
    const { items, quotas, contract, fetchItemName, newTicket } = useContext(ServiceDeskContext);
    const { user, clients } = useContext(UserContext);
    const navigate = useNavigate();

    const [client, setClient] = useState<number>(clients.length > 0 ? clients[0] : -1);
    const [errors, setErrors] = useState<string[]>([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState(Priority.Low);
    const [type, setType] = useState(TicketType.NewFeature);
    const [itemIds, setItemIds] = useState<number[]>([]);
    const [itemType, setItemType] = useState(ItemType.None);
    const [emailType, setEmailType] = useState(EmailType.None);
    const [metricType, setMetricType] = useState(MetricType.None);
    const [targetingTypes, setTargetingTypes] = useState([TargetingType.None]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [view, setView] = useState(CreateTicketViews.ChooseType);
    const [viewHistory, setViewHistory] = useState<CreateTicketViews[]>([]);

    const createNewFeature = useCallback(() => {
        setType(TicketType.NewFeature);
        setViewHistory([...viewHistory, CreateTicketViews.ChooseType]);
        setView(CreateTicketViews.ChooseSubType);
    }, [viewHistory]);

    const createChangeRequest = useCallback(() => {
        setType(TicketType.ChangeRequest);
        setViewHistory([...viewHistory, CreateTicketViews.ChooseType]);
        setView(CreateTicketViews.ChooseItem);
    }, [viewHistory]);

    const createIssue = useCallback(() => {
        setType(TicketType.Issue);
        setViewHistory([...viewHistory, CreateTicketViews.ChooseType]);
        setView(CreateTicketViews.ChooseItem);
    }, [viewHistory]);

    const createOther = useCallback(() => {
        setType(TicketType.Other);
        setViewHistory([...viewHistory, CreateTicketViews.ChooseType]);
        setView(CreateTicketViews.AddDetails);
    }, [viewHistory]);

    const chooseSubType = useCallback((st: ItemType) => {
        setItemType(st);
        setViewHistory([...viewHistory, CreateTicketViews.ChooseSubType]);
        setView(CreateTicketViews.AddDetails);
    }, [viewHistory]);

    // const chooseItem = useCallback((item: ContractItem) => {
    //     setItemId(item.id);
    //     setViewHistory([...viewHistory, CreateTicketViews.ChooseItem]);
    //     setView(CreateTicketViews.AddDetails);
    // }, [viewHistory]);

    const chooseItems = useCallback(() => {
        setViewHistory([...viewHistory, CreateTicketViews.ChooseItem]);
        setView(CreateTicketViews.AddDetails);
    }, [viewHistory]);

    const goBack = () => {
        if (viewHistory.length <= 0) return;

        let vh = viewHistory;
        const lastView = vh.pop();

        setView(lastView!);
        setViewHistory(vh);
    };

    const updateSelectedItems = (e: MultiValue<{ value: number; label: string }>) => {
        setItemIds(e.map((x) => x.value));
    };

    const validateTicket = () => {
        let valid = true;
        setErrors(e => []);

        if (name.length <= 0) {
            setErrors(e => [...e, "Name is required"]);
            valid = false;
        }
        if (description.length <= 0) {
            setErrors(e => [...e, "Description is required"]);
            valid = false;
        }
        if ((type === TicketType.ChangeRequest || type === TicketType.Issue) && itemIds.length <= 0) {
            setErrors(e => [...e, "Items are required"]);
            valid = false;
        }
        if (type === TicketType.NewFeature && itemType === ItemType.None) {
            setErrors(e => [...e, "Item Type is required"]);
            valid = false;
        }
        if (type === TicketType.NewFeature && itemType === ItemType.Report && emailType === EmailType.None) {
            setErrors(e => [...e, "Email Type is required"]);
            valid = false;
        }   
        if (type === TicketType.NewFeature && itemType === ItemType.Report && metricType === MetricType.None) {
            setErrors(e => [...e, "Metric Type is required"]);
            valid = false;
        }
        if (type === TicketType.NewFeature && itemType === ItemType.Report && targetingTypes.length <= 0) {
            setErrors(e => [...e, "Targeting Types are required"]);
            valid = false;
        }
        if (type === TicketType.NewFeature && itemType === ItemType.Report && startDate === null) {
            setErrors(e => [...e, "Start Date is required"]);
            valid = false;
        }
        if (type === TicketType.NewFeature && itemType === ItemType.Report && endDate === null) {
            setErrors(e => [...e, "End Date is required"]);
            valid = false;
        }

        return valid;
    };


    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const valid = validateTicket();
        if (!valid) return;

        const parent: Ticket = {
            id: -1,
            client: client,
            creator_id: user.id,
            assignee_id: user.id,
            name: name,
            description: description,
            status: TicketStatus.Submitted,
            priority: priority,
            created: new Date(),
            updated: new Date(),
            type: type,
            item_ids: itemIds,
            email_type: emailType,
            metric_type: metricType,
            targeting_types: targetingTypes,
            start_date: startDate,
            end_date: endDate,
            contract_priority: ContractPriority.Low,
            internal: false
        };
        newTicket(parent);

        if (itemIds.length > 1) {
            itemIds.forEach((itemId) => {
                const child: Ticket = {
                    id: -1,
                    client: client,
                    creator_id: user.id,
                    assignee_id: user.id,
                    name: name + " - " + fetchItemName(itemId),
                    description: description,
                    status: TicketStatus.Submitted,
                    priority: priority,
                    created: new Date(),
                    updated: new Date(),
                    type: type,
                    item_ids: [itemId],
                    email_type: emailType,
                    metric_type: metricType,
                    targeting_types: targetingTypes,
                    start_date: startDate,
                    end_date: endDate,
                    contract_priority: ContractPriority.Low,
                    internal: true
                };
                newTicket(child);
            });
        }
        navigate("/service-desk/list");
    };

    const ticketTypeCols = useMemo(() => [
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Description',
            accessor: 'description',
        }
    ], []);

    const ticketTypeData = useMemo(() => [
        {
            description: 'A new feature is a new functionality that is not currently available in the product.',
            type: <Button variant="contained" color="primary" onClick={createNewFeature}>
                New Feature
            </Button>
        },
        {
            description: 'A change request is a change to an existing feature.',
            type: <Button variant="contained" color="primary" onClick={createChangeRequest}>
                Change Feature
            </Button>
        },
        {
            description: 'An issue is a problem with the product that needs to be fixed.',
            type: <Button variant="contained" color="primary" onClick={createIssue}>
                Issue
            </Button>
        },
        {
            description: 'Other is for any other type of ticket that does not fit into the other categories.',
            type: <Button variant="contained" color="primary" onClick={createOther}>
                Other
            </Button>
        }
    ], [createNewFeature, createChangeRequest, createIssue, createOther]);


    const reportQuota = quotas.find(x => x.contract_id === contract.id && x.item_type === ItemType.Report);
    const campaignQuota = quotas.find(x => x.contract_id === contract.id && x.item_type === ItemType.Campaign);
    const analysisQuota = quotas.find(x => x.contract_id === contract.id && x.item_type === ItemType.Analysis);
    const feedQuota = quotas.find(x => x.contract_id === contract.id && x.item_type === ItemType.Feed);

    const newFeatureTypeCols = useMemo(() => [
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Cost',
            accessor: 'cost',
        },
        {
            Header: 'Quota',
            accessor: 'quota',
        }
    ], []);

    const getQuota = useCallback((quota: IContractQuota | undefined) => {
        if (!quota) return 0;

        const usedQuota = items.filter(x => x.item_type === quota.item_type).length;
        return Math.max(quota.quota - usedQuota, 0);
    }, [items]);
    const getCost = useCallback((quota: IContractQuota | undefined) => {
        if (!quota) return 0;
        return getQuota(quota) > 0 ? 0 : quota.cost;
    }, [getQuota]);
    const newFeatureTypeData = useMemo(() => [
        {
            description: 'A new report is a new report that is not currently available in the product.',
            type: <Button variant="contained" color="primary" onClick={() => chooseSubType(ItemType.Report)}>
                Report
            </Button>,
            cost: getCost(reportQuota),
            quota: getQuota(reportQuota),
        },
        {
            description: 'A new campaign is a new campaign that is not currently available in the product.',
            type: <Button variant="contained" color="primary" onClick={() => chooseSubType(ItemType.Campaign)}>
                Campaign
            </Button>,
            cost: getCost(campaignQuota),
            quota: getQuota(campaignQuota),
        },
        {
            description: 'A new analysis is a new analysis that is not currently available in the product.',
            type: <Button variant="contained" color="primary" onClick={() => chooseSubType(ItemType.Analysis)}>
                Analysis
            </Button>,
            cost: getCost(analysisQuota),
            quota: getQuota(analysisQuota),
        },
        {
            description: 'A new feed is a new feed that is not currently available in the product.',
            type: <Button variant="contained" color="primary" onClick={() => chooseSubType(ItemType.Feed)}>
                Feed
            </Button>,
            cost: getCost(feedQuota),
            quota: getQuota(feedQuota),
        }
    ], [chooseSubType, reportQuota, campaignQuota, analysisQuota, feedQuota, getCost, getQuota]);


    const selectedItems = items.filter(x => itemIds.includes(x.id));

    return (
        <div className="service-desk__create-new">

            {view === CreateTicketViews.ChooseType && (
                <>
                    <h2>Create new ticket</h2>
                    <div className="service-desk__create-new__choose-type">
                        <CustomTable columns={ticketTypeCols} data={ticketTypeData} />
                    </div>
                </>
            )}

            {view === CreateTicketViews.ChooseSubType && (
                <>
                    <h2>{type}</h2>
                    <div className="service-desk__create-new__choose-item">
                        <h3>Choose type</h3>
                        <CustomTable columns={newFeatureTypeCols} data={newFeatureTypeData} />
                    </div>
                </>
            )}

            {view === CreateTicketViews.ChooseItem && (
                <>
                    <h2>{type}</h2>
                    <div className="service-desk__create-new__choose-item">
                        <h3>Choose item</h3>
                        <Select
                            options={items.map(x => ({ value: x.id, label: fetchItemName(x.id) }))}
                            defaultValue={selectedItems.map(x => ({ value: x.id, label: fetchItemName(x.id) }))}
                            onChange={updateSelectedItems}
                            isMulti={true}
                        />
                        <Button onClick={chooseItems}>Continue</Button>
                    </div>
                </>
            )}

            {view === CreateTicketViews.AddDetails && (
                <>
                    <div>
                        <label htmlFor="name"><b>Name</b></label>
                        <input
                            type="text"
                            name="name"
                            placeholder="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="description"><b>Description</b></label>
                        <input
                            type="text"
                            name="description"
                            placeholder="description"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>

                    {type && (
                        <div>
                            <label htmlFor="type"><b>Type</b></label>
                            <input
                                type="text"
                                name="type"
                                placeholder="type"
                                id="type"
                                value={type}
                                disabled
                            />
                        </div>
                    )}

                    {itemType && itemType !== ItemType.None && (
                        <div>
                            <label htmlFor="itemType"><b>Item Type</b></label>
                            <input
                                type="text"
                                name="itemType"
                                placeholder="itemType"
                                id="itemType"
                                value={itemType}
                                disabled
                            />
                        </div>
                    )}

                    {itemIds.length > 0 && selectedItems.length > 0 && (
                        <div>
                            <label htmlFor="item"><b>Item</b></label>
                            <input
                                type="text"
                                name="item"
                                placeholder="item"
                                id="item"
                                value={selectedItems.map(x => fetchItemName(x.id)).join(', ')}
                                disabled
                            />
                        </div>
                    )}

                    <div>
                        <label htmlFor="priority"><b>Priority</b></label>
                        <select
                            name="priority"
                            id="priority"
                            value={priority}
                            onChange={(e) => setPriority(Priority[e.target.value as Priority])}
                        >
                            {Object.values(Priority).map((key, ii) => (
                                <option key={ii} value={key}>
                                    {key}
                                </option>
                            ))}
                        </select>
                    </div>

                    {itemType && itemType === ItemType.Report && (
                        <>
                            <div>
                                <label htmlFor="emailType"><b>Email Type</b></label>
                                <select
                                    name="emailType"
                                    id="emailType"
                                    value={emailType}
                                    onChange={(e) => setEmailType(EmailType[e.target.value as EmailType])}
                                >
                                    {Object.values(EmailType).map((key, ii) => (
                                        <option key={ii} value={key}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label htmlFor="metricType"><b>Metric Type</b></label>
                                <select
                                    name="metricType"
                                    id="metricType"
                                    value={metricType}
                                    onChange={(e) => setMetricType(MetricType[e.target.value as MetricType])}
                                >
                                    {Object.values(MetricType).map((key, ii) => (
                                        <option key={ii} value={key}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label htmlFor="targetingType"><b>Targeting Type</b></label>
                                <Select
                                    options={Object.values(TargetingType).map(x => ({ value: x, label: x }))}
                                    isMulti={true}
                                    onChange={(e) => {
                                        console.log(e);
                                        setTargetingTypes(e.map(x => x.value as TargetingType));
                                    }}
                                    defaultValue={targetingTypes.map(x => ({ value: x, label: x }))}
                                />
                            </div>

                            <div>
                                <label htmlFor="startDate"><b>Start Date</b></label>
                                <DatePicker selected={startDate} onChange={(date) => !!date ? setStartDate(date) : null} />
                            </div>

                            <div>
                                <label htmlFor="endDate"><b>End Date</b></label>
                                <DatePicker selected={endDate} onChange={(date) => !!date ? setEndDate(date) : null} />
                            </div>
                        </>
                    )}

                    {errors.length > 0 && (
                        errors.map(x => <Alert severity="error">{x}</Alert>)
                    )}
                    <Button type="submit" onClick={onSubmit}>Submit</Button>
                </>
            )}
            {viewHistory.length > 0 &&
                <Button color="secondary" onClick={goBack}>Back</Button>
            }
        </div>
    );
};
export default ServiceDeskCreateNew;