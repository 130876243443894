import React, { FormEvent, useEffect, useState } from "react";
import { Auth } from 'aws-amplify';
import QRCode from 'qrcode.react';
import { Fade } from "react-awesome-reveal";

const BUTTON_STATE_DEFAULT = '';
const BUTTON_STATE_LOADING = 'loading';
const BUTTON_STATE_SENT = 'sent';

const ISSUER = "Data Kraken - Iris";

interface IAddOTPFormProps {
    user: object;
    email: string;
    doAddOTP: (user: object, otpCode: string) => Promise<void>;
}

const AddOTPForm = ({ user, email, doAddOTP }: IAddOTPFormProps) => {
    const [btnState, setBtnState] = useState(BUTTON_STATE_DEFAULT);
    const [otpCode, setOTPCode] = useState('');
    const [otpQR, setOTPQR] = useState('');

    useEffect(() => {
        const setupOTPCode = async () => {
            const code = await Auth.setupTOTP(user);
            setOTPQR("otpauth://totp/AWSCognito:" + email + "?secret=" + code + "&issuer=" + ISSUER);
        }
        setupOTPCode();
    }, [email, user]);

    const handleAddOTP = async (e: FormEvent) => {
        e.preventDefault();
        setBtnState(BUTTON_STATE_LOADING);

        try {
            console.log(user, otpCode);
            await doAddOTP(user, otpCode);
            // Don't set the button state here as the form has been replaced
        } catch (err) {
            setBtnState(BUTTON_STATE_DEFAULT);
        }
    }

    return (
        <form className="login--hero__form" onSubmit={handleAddOTP} autoComplete="off">
            <Fade duration={1500} delay={50}>
                <>
                    <h3>Scan this QR code with your MFA app</h3>
                    <QRCode value={otpQR} />
                </>
            </Fade>
            <Fade duration={1500} delay={100}>
                <>
                    <label htmlFor="code">Verify your MFA by entering the code from your app</label>
                    <input
                        type="text"
                        placeholder="Code"
                        name="code"
                        value={otpCode}
                        onChange={(e) => setOTPCode(e.target.value)}
                        required
                        autoFocus
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={150}>
                <button
                    disabled={btnState === BUTTON_STATE_LOADING || btnState === BUTTON_STATE_SENT}
                    className={`login--button otp ${btnState}`}
                ></button>
            </Fade>
        </form>
    );
}

export default AddOTPForm;