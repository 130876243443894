import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

import { LambdaContext } from "core/data/contexts/LambdaContextProvider";
import Debug from "core/helpers/Debug";

import { UserGroup } from "core/data/auth/types/UserGroup";
import { User } from "core/data/auth/types/User";
import { Permission } from "core/enums/Permissions";

import { AWSAuthContext } from "../aws/AWSAuthContextProvider";
import SendToLambda from "../aws/lambda/SendToLambda";

import { FETCH_DK_USER_GROUPS_LAMBDA, FETCH_DK_USER_LAMBDA } from "config";
import { DKUserResponse } from "./types/DKUserResponse";
import Parsed from "core/helpers/Parsed";
import Button from "@mui/material/Button";
import { DKUserGroupResponse } from "./types/DKUserGroupResponse";
import PermissionsCheckComponent from "core/helpers/auth/PermissionsCheckComponent";


// Define default value to avoid destructuring errors
export const UserContext = createContext<IUserContext>({
    user: {} as User,
    userGroups: [],
    permissions: [],
    clients: [],
    overrideUser: (id: number) => { },
});

export interface IUserContext {
    user: User;
    userGroups: UserGroup[];
    permissions: Permission[];
    clients: number[];
    overrideUser: (id: number) => void;
}


interface UserContextProviderProps {
    children: ReactNode;
}
const UserContextProvider = (props: UserContextProviderProps) => {
    const [user, setUser] = useState<string>(null!);
    const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [clients, setClients] = useState<number[]>([]);
    const [loading, setLoading] = useState(true);

    const { lambda } = useContext(LambdaContext);
    const { user: AWSUser } = useContext(AWSAuthContext);

    const updateUser = (u: object) => setUser(JSON.stringify(u));
    const clearUser = useCallback(() => updateUser({}), []);

    // Fetch the DK User from the API
    const getDKUser = useCallback(async () => {
        if (!lambda) return;
        if (!AWSUser) return;

        try {
            const payload = JSON.stringify({
                aws_id: AWSUser?.username ?? "",
                email: AWSUser?.attributes?.email ?? "",
                name: AWSUser?.attributes?.name ?? "",
            });

            const [error, data] = await SendToLambda<DKUserResponse>(lambda, FETCH_DK_USER_LAMBDA, payload);
            Debug(FETCH_DK_USER_LAMBDA, error, data);

            let dk_user = data?.data ?? null;

            if (error) {
                console.error(error);
                clearUser();
                setUserGroups([]);
                setPermissions([]);
                setClients([]);
            } else {
                if (dk_user) {
                    console.log(dk_user);
                    updateUser(dk_user);
                    
                    setUserGroups(dk_user.user_groups);

                    let p = dk_user.user_groups
                        .map((g: any) => g.permissions_group.permissions ?? [])
                        .flat();
                    setPermissions(p);

                    setClients(dk_user.user_groups.map((x: any) => x.client_id));
                } else {
                    clearUser();
                    setUserGroups([]);
                    setPermissions([]);
                    setClients([]);
                }
            }
        } catch (error) {
            console.error(error);
            clearUser();
        }
    }, [clearUser, lambda, AWSUser]);

    useEffect(() => {
        getDKUser();
    }, [getDKUser]);


    const overrideUser = useCallback(async (id: number) => {
        if (!lambda) return;
        console.log("Overriding as user: ", id);

        try {
            const payload = JSON.stringify({
                user_id: id
            });

            const [error, data] = await SendToLambda<DKUserGroupResponse>(lambda, FETCH_DK_USER_GROUPS_LAMBDA, payload);
            Debug(FETCH_DK_USER_GROUPS_LAMBDA, error, data);

            let user_groups = data?.data ?? null;

            if (error) {
                console.error(error);
            } else {
                if (!!user_groups && user_groups.length > 0) {
                    console.log(user_groups);
                    updateUser({
                        user_id: id,
                        user_groups
                    });
                    
                    setUserGroups(user_groups);

                    let p = user_groups
                        .map((g: any) => g.permissions_group.permissions ?? [])
                        .flat();
                    setPermissions(p);

                    setClients(user_groups.map((x: any) => x.client_id));
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [lambda]);

    // As pretty much all of the app depends on the user being logged in
    // wait until we have all the data before rendering the app
    useEffect(() => {
        if (user !== null) {
            setLoading(false);
        }
    }, [user]);

    const value: IUserContext = {
        user: Parsed(user),
        userGroups,
        permissions,
        clients,
        overrideUser
    };

    const assumeAdmin = () => overrideUser(6);
    const assumeClient1 = () => overrideUser(8);
    // const assumeClient2 = () => overrideUser(4);
    const assumeDK = () => overrideUser(7);

    return (
        <UserContext.Provider value={value}>
            <>
                {loading && <div>Loading...</div>}
                {!loading && props.children}

                <PermissionsCheckComponent permission={Permission.OverrideUsers}>
                    <div style={{display: "flex"}}>
                        <p>Viewing as: {Parsed(user).first_name}</p>
                        <Button onClick={assumeAdmin}>View as Admin</Button>
                        <Button onClick={assumeClient1}>View as 48</Button>
                        {/* <Button onClick={assumeClient2}>View as Client 2</Button> */}
                        <Button onClick={assumeDK}>View as DK</Button>
                    </div>
                </PermissionsCheckComponent>
            </>
        </UserContext.Provider>
    )
};

export default UserContextProvider;