import React, { FormEvent, useState } from "react";

import { Fade } from "react-awesome-reveal";

import { IEvent } from "../EventDashboard";

interface IAddEventProps {
    newEvent: IEvent,
    onComplete: (event?: IEvent) => void
}

const AddEvent = ({ newEvent, onComplete }: IAddEventProps) => {
    const [event, setEvent] = useState(newEvent);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        onComplete(event);
    };

    const onCancel = (e: FormEvent) => {
        e.preventDefault();
        onComplete();
    };

    return (
        <form className="calendar--hero__form" onSubmit={onSubmit}>
            <Fade duration={1500} delay={50}>
                <>
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        placeholder="Title"
                        name="title"
                        value={event.title}
                        onChange={(e) => setEvent({ ...event, title: e.target.value })}
                        required
                        autoFocus
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={100}>
                <>
                    <label htmlFor="description">Description</label>
                    <input
                        type="text"
                        placeholder="Description"
                        name="description"
                        value={event.description}
                        onChange={(e) => setEvent({ ...event, description: e.target.value })}
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={150}>
                <>
                    <button
                        className={`calendar--button`}
                    ></button>
                    <button
                        className={`calendar--button cancel`}
                        onClick={onCancel}
                    ></button>
                </>
            </Fade>
        </form>
    );
};

export default AddEvent;