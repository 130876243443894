import React, { ReactNode, createContext, useContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";

import { IReport } from "../types/IReport";

import { FETCH_LATEST_REPORTS_DB } from "config";

export const LatestReportsContext = createContext<ILatestReportsContext>(null!);
export interface ILatestReportsContext extends IDataContext<IReport> { }


interface ILatestReportsContextProvider {
    children: ReactNode;
}
const LatestReportsContextProvider = ({ children }: ILatestReportsContextProvider) => {
    const { clients } = useContext(UserContext);

    let params = { 
        clients: clients
    };

    return (
        <DataContextProvider<IReport>
            context={LatestReportsContext}
            endpoint={FETCH_LATEST_REPORTS_DB}
            params={params}
        >
            {children}
        </DataContextProvider>
    );
};

export default LatestReportsContextProvider;