import React, { Context, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";

import { AWSAuthContext } from "core/data/aws/AWSAuthContextProvider";
import FetchFromLambda from "core/data/aws/lambda/FetchFromLambda";

import Debug from "core/helpers/Debug";
import IsEmptyObj from "core/helpers/IsEmptyObj";

import { LambdaContext } from "./LambdaContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";


export interface IDataContext<T> {
    items: T[] | null;
    error: string;
    updateItem: (item: T) => void;
    setVisible: (v: boolean) => void;
}

interface IAPIResponse<T> {
    data: T[]
}

interface IDataContextProviderProps<T> {
    endpoint: string,
    context: Context<IDataContext<T>>,
    children: ReactNode;
    updateFrequency?: number;
    params?: {[key: string]: string | number | number[]};
}

const DataContextProvider = <T extends unknown>({ endpoint, children, context, updateFrequency = 1000 * 60, params = {} }: IDataContextProviderProps<T>) => {
    const [items, setItems] = useState<T[] | null>(null!);
    const [error, setError] = useState('');

    const { credentials, refreshSession } = useContext(AWSAuthContext);
    const { lambda } = useContext(LambdaContext);
    const { clients } = useContext(UserContext);

    const isVisible = useRef(false);
    const isLoading = useRef(false);
    const curClients = useRef(clients);


    // Fetch data and refresh session if needed
    const doListItems = useCallback(async () => {
        if (!isVisible.current) return;
        if (!lambda || IsEmptyObj(credentials)) return;
        if (isLoading.current) return;
        
        let p = params;
        p = { 
            ...p,
            clients: clients,
        };

        try {
            isLoading.current = true;
            const [err, response] = await FetchFromLambda<IAPIResponse<T>>(lambda, endpoint, p);
            Debug(endpoint, err, response);

            // Don't trigger a state update if the data hasn't changed
            if (JSON.stringify(response.data) !== JSON.stringify(items)) {
                setItems(response.data);
            }
            setError(err);
            isLoading.current = false;
        } catch (err: any) {
            if (err[0] === 'The security token included in the request is expired') {
                await refreshSession();
                // Don't immediately retry, as this will be before the state updates
            } else {
                console.error(err);
                setItems([]);
                setError(err);
            }
            isLoading.current = false;
        }
    }, [lambda, endpoint, refreshSession, credentials, params, clients, items]);

    // Fetch data from the APIs, and update every minute
    useEffect(() => {
        const i = setInterval(() => {
            if (isVisible.current) {
                doListItems();
            }
        }, updateFrequency);

        return () => {
            clearInterval(i);
        };
    }, [doListItems, updateFrequency]);

    //  This is for the 'override user' hack
    useEffect(() => {
        if (clients !== curClients.current) {
            setItems(null);
            curClients.current = clients;

            if (isVisible.current) {
                doListItems();
            }
        }
    }, [doListItems, clients]);

    // General version of a helper method to resolve an issue
    const updateItem = useCallback((item: T) => {
        if (!items) {
            return;
        }

        setItems([
            ...(items.filter(i => i !== item)),
            item
        ]);
    }, [items]);

    const setVisible = useCallback((v: boolean) => {
        if (v === isVisible.current) return;

        isVisible.current = v;

        if (isVisible.current && items === null) {
            doListItems();
        }
    }, [doListItems, items]);


    const value: IDataContext<T> = {
        items,
        error,
        updateItem,
        setVisible
    };

    return (
        <context.Provider value={value}>
            {children}
        </context.Provider>
    )
};

export default DataContextProvider;