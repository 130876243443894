import React, { useContext } from "react";

import HalfWidthTileContainer from "core/components/tiles/HalfWidthTileContainer";
import WideTile from "core/components/tiles/WideTile";
import SimpleTable from "core/components/status-table/SimpleTable";
import SimpleListItem from "core/components/status-table/SimpleListItem";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";

import FormatTime from "core/helpers/datetime/FormatTime";
import FormatDay from "core/helpers/datetime/FormatDay";
import Log from "core/helpers/Log";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";

import { ReportsExpectedContext } from "../contexts/ReportsExpectedContextProvider";

import { IReportExpected } from "../types/IReportExpected";

const ReportsExpectedTable = ({ client, report }: { client: number; report: number }) => {
    const { items: reportsExpected, error: reportsExpectedError } = useContext(ReportsExpectedContext);

    let reportExpected = reportsExpected
        ?.filter((x) => x.client_id === client && x.report_id === report && x.next_report_datetime !== null)
        ?.map((x) => ({
            ...x,
            name: x.report_name,
            last_date: 0,
            next_date: ParseDBDate(x.next_report_datetime),
            issue: IssueEnum.OK,
        }))
        ?.filter((x) => x.next_date > Date.now())
        ?.sort((a, b) => a.last_date - b.last_date)
        ?.slice(0, 10);
    Log("reportExpected", reportExpected);

    return (
        <HalfWidthTileContainer grow={true}>
            {reportExpected && (
                <SimpleTable<IReportExpected>
                    title={"Expected"}
                    items={reportExpected.slice(0, 7)}
                    error={reportsExpectedError}
                    RenderRow={(item) => <SimpleListItem key={item.name} title={FormatDay(item.next_date)} text={FormatTime(item.next_date)} />}
                />
            )}
            {reportExpected === null && <LoadingSpinner />}
            {reportExpected && reportExpected.length === 0 && (
                <WideTile>
                    <h3>Expected</h3>
                    <p>No expected dates found</p>
                </WideTile>
            )}
        </HalfWidthTileContainer>
    );
};

export default ReportsExpectedTable;