import React from "react";

import ReactEcharts from "echarts-for-react";

import { IPopulationDataYear, populationDataFemale } from "../data/DataFemale";
import { populationDataMale } from "../data/DataMale";

interface ChartData {
    series: {
        stack: string;
        data: IPopulationDataYear[];
    }[];
    title: {
        text: string;
    };
}

const AnimatedBarChart = () => {
    const getOption = () => {
        let districts: string[] = [];
        let years: string[] = [];

        Object.entries(populationDataFemale).forEach((entry) => {
            years = [...years, entry[0]];
            entry[1].forEach(e => {
                districts = [...new Set([...districts, e.name])];
            });
        });

        const options = years.map(year => {
            const obj: ChartData = {
                series: [],
                title: {
                    text: ""
                }
            };

            obj["series"] = [
                {
                    stack: "group",
                    data: populationDataFemale[year]
                },
                {
                    stack: "group",
                    data: populationDataMale[year]
                }
            ];

            obj["title"] = {
                text: `Population of Singapore by District, ${year}`
            };

            return obj;
        });

        return {
            baseOption: {
                timeline: {
                    autoPlay: true,
                    axisType: "category",
                    bottom: 20,
                    data: years,
                    height: null,
                    inverse: true,
                    left: null,
                    orient: "vertical",
                    playInterval: 1000,
                    right: 0,
                    top: 20,
                    width: 55,
                    label: {
                        normal: {
                            textStyle: {
                                color: "#aaa"
                            }
                        },
                        emphasis: {
                            textStyle: {
                                color: "#333"
                            }
                        }
                    },
                    symbol: "none",
                    lineStyle: {
                        color: "#aaa"
                    },
                    checkpointStyle: {
                        color: "#354EF6",
                        borderColor: "transparent",
                        borderWidth: 2
                    },
                    controlStyle: {
                        showNextBtn: false,
                        showPrevBtn: false,
                        normal: {
                            color: "#354EF6",
                            borderColor: "#354EF6"
                        },
                        emphasis: {
                            color: "#5d71f7",
                            borderColor: "#5d71f7"
                        }
                    }
                },
                color: ["#e91e63", "#354EF6"],
                title: {
                    subtext: "Data from the Singapore Department of Statistics",
                    textAlign: "left",
                    left: "5%"
                },
                tooltip: { backgroundColor: "#555", borderWidth: 0, padding: 10 },
                legend: {
                    data: ["Female", "Male"],
                    itemGap: 35,
                    itemHeight: 18,
                    right: "11%",
                    top: 20
                },
                calculable: true,
                grid: {
                    top: 100,
                    bottom: 150,
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                            label: {
                                show: true,
                                formatter: function (params: { value: string; }) {
                                    return params.value.replace("\n", "");
                                }
                            }
                        }
                    }
                },
                xAxis: [
                    {
                        axisLabel: {
                            interval: 0,
                            rotate: 55,
                            textStyle: {
                                baseline: "top",
                                color: "#333",
                                fontSize: 10,
                                fontWeight: "bold"
                            }
                        },
                        axisLine: { lineStyle: { color: "#aaa" }, show: true },
                        axisTick: { show: false },
                        data: districts,
                        splitLine: { show: false },
                        type: "category"
                    }
                ],
                yAxis: [
                    {
                        axisLabel: {
                            textStyle: { fontSize: 10 }
                        },
                        axisLine: { show: false },
                        axisTick: { show: false },
                        name: "Population",
                        splitLine: {
                            lineStyle: {
                                type: "dotted"
                            }
                        },
                        type: "value"
                    }
                ],
                series: [{ name: "Female", type: "bar" }, { name: "Male", type: "bar" }]
            },
            options: options
        };
    };

    return (
        <ReactEcharts
            option={getOption()}
            style={{ height: "100%", width: "100%" }}
        />
    );
}

export default AnimatedBarChart;