import React from 'react';
import { Link } from 'react-router-dom';

import { Frequencies, TEN_MIN_FREQ } from 'core/data/feeds/frequencies';
import { FeedNames } from 'core/data/feeds/feed_names';
import FormatDate from 'core/helpers/datetime/FormatDate';
import Countdown from 'core/helpers/Countdown';
import GetStatusIcon from 'core/helpers/GetStatusIcon';

import { IReport } from '../types/IReport';
import { IReportHistory } from '../types/IReportHistory';

interface IReportsListItemProps {
    item: IReport | IReportHistory
}

const ReportsListItem = ({ item }: IReportsListItemProps) => {
    const freq = Frequencies[item.name] ?? TEN_MIN_FREQ;

    return (
        <div className={`listitem issue-${item.issue}`}>
            <Link to={`/reports/${item.client_id}/${item.id}`}>

                <div className={'listitem__header'}>
                    <div className={'listitem__toggle-details'}>
                        <div className={'listitem__toggle-details__container'}>
                            {GetStatusIcon(item.issue)}
                        </div>
                    </div>

                    <div className={'listitem__title'}>
                        {FeedNames.get(item.name) ?? item.name}
                    </div>

                    <div className={`listitem__value`}>
                        Received: {item.last_date === 0 ? 'Never' : FormatDate(item.last_date)}
                    </div>

                    {item.next_date !== 0 &&
                        <>
                            <div className={`listitem__value issue-${item.issue}`}>
                                Expected: {FormatDate(item.next_date)}
                            </div>
                            <div className={'listitem__icon'}>
                                <Countdown time={item.next_date - Date.now()} freq={freq} />
                            </div>
                        </>
                    }
                    {item.next_date === 0 &&
                        <>
                            <div className={`listitem__value issue-${item.issue}`}>
                                Expected: Unknown
                            </div>
                            <div className={'listitem__icon'}>
                            </div>
                        </>
                    }
                </div>
            </Link>
        </div>
    );
};

export default ReportsListItem;