import React, { FormEvent, useState } from "react";

import { Fade } from "react-awesome-reveal";

const BUTTON_STATE_DEFAULT = '';
const BUTTON_STATE_LOADING = 'loading';
const BUTTON_STATE_SENT = 'sent';

interface IResetPasswordFormProps {
    inital_email: string;
    doSendVerificationEmail: (email: string) => Promise<void>;
    doPasswordReset: (email: string, verificationCode: string, newPassword: string) => Promise<void>;
}

const ResetPasswordForm = ({ inital_email, doSendVerificationEmail, doPasswordReset }: IResetPasswordFormProps) => {
    const [btnState, setBtnState] = useState(BUTTON_STATE_DEFAULT);
    const [email, setEmail] = useState(inital_email);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [hasSentVerificationEmail, setHasSentVerificationEmail] = useState(!!email);

    const handlePasswordReset = async (e: FormEvent) => {
        e.preventDefault();
        setBtnState(BUTTON_STATE_LOADING);

        if (!hasSentVerificationEmail) {
            try {
                await doSendVerificationEmail(email);
                setBtnState(BUTTON_STATE_DEFAULT);
                setHasSentVerificationEmail(true);
            } catch (err) {
                setHasSentVerificationEmail(false);
                setBtnState(BUTTON_STATE_DEFAULT);
            }
        } else {
            try {
                await doPasswordReset(email, verificationCode, newPassword);
                // Don't set the button state here as the form has been replaced
            } catch (err) {
                setBtnState(BUTTON_STATE_DEFAULT);
            }
        }
    };

    return (
        <form className="login--hero__form" onSubmit={handlePasswordReset} autoComplete="off">
            <Fade duration={1500} delay={50}>
                <h3>Reset your password</h3>
            </Fade>
            <Fade duration={1500} delay={100}>
                <>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoFocus
                    ></input>
                </>
            </Fade>
            {hasSentVerificationEmail &&
                <>
                    <Fade duration={1500} delay={150}>
                        <>
                            <label htmlFor="password">New Password</label>
                            <input
                                type="password"
                                placeholder="New Password"
                                name="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            ></input>
                        </>
                    </Fade>
                    <Fade duration={1500} delay={200}>
                        <>
                            <label htmlFor="confirm_password">Confirm Password</label>
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                name="confirm_password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            ></input>
                        </>
                    </Fade>
                    <Fade duration={1500} delay={250}>
                        <>
                            <label htmlFor="verification_code">Verification Code</label>
                            <input
                                type="text"
                                placeholder="Verification Code"
                                name="verification_code"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                required
                            ></input>
                        </>
                    </Fade>
                </>
            }
            <Fade duration={1500} delay={hasSentVerificationEmail ? 300 : 150}>
                <button
                    disabled={
                        btnState === BUTTON_STATE_LOADING ||
                        btnState === BUTTON_STATE_SENT ||
                        newPassword !== confirmPassword ||
                        !email ||
                        (hasSentVerificationEmail && !verificationCode)
                    }
                    className={`login--button ${hasSentVerificationEmail ? 'reset' : 'pre-verify'} ${btnState}`}
                ></button>
            </Fade>
        </form>
    );
}

export default ResetPasswordForm;