import React, { useCallback, useContext, useEffect } from "react";

import StatusTable, { IStatusItemBase } from "core/components/status-table/StatusTable";
import LoadingSpinner from "core/components/loading/Loading";
import WideTile from "core/components/tiles/WideTile";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";

import { IssuesContext } from "./IssuesContextProvider";
import Issue from "./components/Issue";

import "./_issues-list.scss";
import ListTile from "core/components/tiles/ListTile";

export interface IIssue extends IStatusItemBase {
  client_name: string,
  feed_name: string,
  error: string,
  status: string,
  assignee: string,
  time_raised: string,
  time_resolved: string,
  notes: string
}

const IssuesList = () => {
  const { items, error, updateItem, setVisible } = useContext(IssuesContext);

  useEffect(() => {
    setVisible(true);

    return () => {
      setVisible(false);
    };
  }, [setVisible]);

  const GetIssue = useCallback((item: IIssue) => {
    return IssueEnum.ERROR;
  }, []);

  return (
    <>
      {!items && <LoadingSpinner />}
      {items && items.length === 0 && (
        <ListTile>
            <h3>Issues</h3>
            <p>No issues found</p>
        </ListTile>
      )}
      {items && items.length > 0 && 
        <StatusTable<IIssue> 
          unique={true}
          title={'Issues'}
          items={items}
          error={error}
          clientKeyIndex={1}
          RenderRow={(item) => <Issue key={item.Key} item={item} action={updateItem} />}
          GetIssue={GetIssue}
          />
      }
    </>
  );
};

export default IssuesList;