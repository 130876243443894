import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import LoadingSpinner from "core/components/loading/Loading";
import { FeedNames } from "core/data/feeds/feed_names";
import { StatusEnum } from "core/enums/StatusEnum";
import FormatTime from "core/helpers/datetime/FormatTime";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";
import FormatDate from "core/helpers/datetime/FormatDate";
import GetStartOfWeek from "core/helpers/datetime/GetStartOfWeek";
import GetStartOfDay from "core/helpers/datetime/GetStartOfDay";

import { ReportsExpectedContext } from "features/dashboards/reports/contexts/ReportsExpectedContextProvider";
import { ReportsHistoryContext } from "features/dashboards/reports/contexts/ReportsHistoryContextProvider";

import EventAgenda from "./EventAgenda";
import Toolbar from "./Toolbar";



const ReportsCalendar = () => {
  const { items: expectedItems, setVisible: setExpectedVisible } =
    useContext(ReportsExpectedContext);
  const { items: historyItems, setVisible: setHistoryVisible } =
    useContext(ReportsHistoryContext);

  const [currentDate, setCurrentDate] = useState(GetStartOfDay());
  const daysVisible = useRef(1);
  const navAction = useRef("");

  const getAgendaStartDate = (date: Date) => {
    // Hacky fix - I've decreased length by one to stop extra days showing
    // So here we need to compensate
    if (navAction.current === 'PREV') {
      date.setDate(date.getDate() - 1);
    } else if (navAction.current === 'NEXT') {
      date.setDate(date.getDate() + 1);
    }

    if (daysVisible.current === 1) {
      setCurrentDate(GetStartOfDay(date));
    } else {
      setCurrentDate(GetStartOfWeek(date));
    }
  }

  useEffect(() => {
    setHistoryVisible(true);
    setExpectedVisible(true);

    return () => {
      setHistoryVisible(false);
      setExpectedVisible(false);
    };
  }, [setHistoryVisible, setExpectedVisible]);

  const localizer = momentLocalizer(moment);

  let events = expectedItems
    ?.filter((x) => !!x.next_report_datetime)
    ?.map((x) => ({
      title: "* " + x.client_name + " - " + (FeedNames.get(x.report_name) ?? x.report_name),
      description: "Expected: " + FormatDate(x.next_report_datetime),
      start: ParseDBDate(x.next_report_datetime),
      end: ParseDBDate(x.next_report_datetime),
      allDay: false,
      meta: {
        tags: [x.client_name, x.report_name],
        status: StatusEnum.Expected,
      },
    }));

  if (historyItems) {
    events = events?.concat(
      historyItems.map((x) => ({
        title: x.client_name + " - " + (FeedNames.get(x.report_name) ?? x.report_name),
        description: "Received: " + FormatDate(x.report_timestamp),
        start: ParseDBDate(x.report_timestamp),
        end: ParseDBDate(x.report_timestamp),
        allDay: false,
        meta: {
          tags: [x.client_name, x.report_name],
          status: StatusEnum.Received,
        },
      }))
    );
  }

  events?.sort((a, b) => FormatTime(a.start).localeCompare(FormatTime(b.start)) || a.title.localeCompare(b.title))


  const components = useMemo(() => ({
    agenda: {
      event: EventAgenda,
    },
    toolbar: (props: any) => 
      <Toolbar 
        days={daysVisible.current}
        setDays={(v) => daysVisible.current = v}
        setNav={(a) => navAction.current = a}
        currentDate={currentDate}
        {...props} 
      />,
  }), [currentDate]);

  return (
    <>
      {events === null && <LoadingSpinner />}
      {events && 
        <Calendar
          components={components}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="agenda"
          views={{
            agenda: true,
            month: false,
            week: false,
            day: false,
          }}
          date={currentDate}
          length={daysVisible.current - 1}
          onNavigate={getAgendaStartDate}
          formats={{
            agendaDateFormat: 'ddd Do MMM',
            agendaTimeFormat: 'HH:mm',
          }}
        />
      }
    </>
  );
};


export default ReportsCalendar;