import React, { Context, useContext, useEffect, useMemo } from "react";

import HalfWidthTileContainer from "core/components/tiles/HalfWidthTileContainer";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import WideTile from "core/components/tiles/WideTile";
import SimpleTable from "core/components/status-table/SimpleTable";
import SimpleListItem from "core/components/status-table/SimpleListItem";
import FormatTime from "core/helpers/datetime/FormatTime";
import FormatDay from "core/helpers/datetime/FormatDay";
import Log from "core/helpers/Log";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";

import { LatestFeedsContext } from "../contexts/LatestFeedsContextProvider";
import { IFeedsHistoryContext } from "../contexts/FeedsHistoryContextProvider";

import { IFeedHistory } from "../types/IFeedHistory";

import "../_feeds.scss";


const FeedHistoryTable = ({ client, feed, historyCtx }: { client: number; feed: number, historyCtx: Context<IFeedsHistoryContext> }) => {
    const { items: feedsHistory, error: feedsHistoryError, setVisible: setHistoryVisible } = useContext(historyCtx);
    const { items: latestFeeds, setVisible: setLatestVisible } = useContext(LatestFeedsContext);

    useEffect(() => {
        setHistoryVisible(true);
        setLatestVisible(true);

        return () => {
            setHistoryVisible(false);
            setLatestVisible(false);
        };
    }, [setHistoryVisible, setLatestVisible]);

    const latest = latestFeeds?.find((x) => x.client_id === client && x.feed_id === feed);
    const latestFeed = useMemo(() => {
        if (!latest) return null;
        return {
            ...latest,
            id: latest.feed_id,
            name: latest.feed_name,
            last_date: ParseDBDate(latest.modified_timestamp),
            next_date: 0,
            issue: IssueEnum.OK,
        };
    }, [latest]);
    Log("LatestFeed", latestFeed);

    const feedHistoryCounts = {} as { [key: number]: number };
    const feedHistory = feedsHistory
        ?.filter((x) => x.client_id === client && x.feed_name === latestFeed?.feed_name)
        ?.filter((x, ii, arr) => {
            // Sum record counts for duplicate dates, then filter out duplicates
            const key = ParseDBDate(x.modified_timestamp);
            feedHistoryCounts[key] = (feedHistoryCounts[key] ?? 0) + x.record_count;
            return ii === arr.findIndex((y) => y.modified_timestamp === x.modified_timestamp);
        })
        ?.map((x) => ({
            ...x,
            id: x.feed_id,
            name: x.feed_name,
            last_date: ParseDBDate(x.modified_timestamp),
            next_date: 0,
            issue: IssueEnum.OK,
        }))
        ?.filter((x) => x.last_date < Date.now())
        ?.sort((a, b) => b.last_date - a.last_date);
    Log("History", feedHistory);
    Log("History Counts", feedHistoryCounts);

    return (
        <HalfWidthTileContainer grow={true}>
            {feedHistory && (
                <SimpleTable<IFeedHistory>
                    title={"History"}
                    items={feedHistory}
                    error={feedsHistoryError}
                    RenderRow={(item) => <SimpleListItem key={item.name} title={FormatDay(item.last_date)} text={FormatTime(item.last_date)} />}
                />
            )}
            {feedHistory && feedHistory.length === 0 && (
                <WideTile>
                    <h3>History</h3>
                    <p>No history found</p>
                </WideTile>
            )}
            {!feedHistory && (
                <WideTile>
                    <LoadingSpinner />
                </WideTile>
            )}
        </HalfWidthTileContainer>
    );
};

export default FeedHistoryTable;