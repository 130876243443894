import React from "react";
import { Route, Routes } from "react-router-dom";

import { Permission } from "core/enums/Permissions";
import PermissionsCheck from "core/helpers/auth/PermissionsCheck";

import ReportsListTable from "./components/ReportsListTable";
import ReportPage from "./components/ReportPage";


import "./_reports.scss";

const ReportsPage = () => {
  return (
    <section className="reports-db">
      <Routes>
        <Route path="/" element={
          <PermissionsCheck permission={Permission.ViewFeeds}>
            <>
              <ReportsListTable />
            </>
          </PermissionsCheck>
        } />
        <Route path="/:client/:report" element={
          <PermissionsCheck permission={Permission.ViewFeedDetails}>
            <ReportPage />
          </PermissionsCheck>
        } />
      </Routes>
    </section>
  );
};
export default ReportsPage;