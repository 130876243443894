import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Box from "@mui/material/Box";

import SimpleTable from "core/components/status-table/SimpleTable";
import SimpleListItem from "core/components/status-table/SimpleListItem";
import { IStatusItemBaseDb } from "core/components/status-table/DbStatusTable";
import Tabs from "core/components/tabs/Tabs";
import FormatCounter from "core/helpers/FormatCounter";
import FormatDay from "core/helpers/datetime/FormatDay";
import FormatDate from "core/helpers/datetime/FormatDate";
import { IEvent } from "features/events/EventDashboard";

import { ServiceDeskContext } from "../contexts/ServiceDeskContextProvider";
import { Priority } from "../types/Priority";
import { Ticket } from "../types/Ticket";
import { ICalendar } from "../types/Calendar";
import { UsersContext } from "core/data/auth/UsersContextProvider";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import { ITabStatuses } from "core/components/tabs/types/ITabStatuses";
import ListTile from "core/components/tiles/ListTile";


interface IUserSprintCapacity {
    user_id: number;
    leave: number;
}

const ServiceDeskSprintPlan = () => {
    const { tickets, sprints, calendars, fetchSprintName } = useContext(ServiceDeskContext);
    const { fetchUserName } = useContext(UsersContext);

    const localizer = momentLocalizer(moment);

    tickets.sort((a, b) =>
        (!!b.flagged ? 1 : 0) - (!!a.flagged ? 1 : 0) ||
        Object.keys(Priority).indexOf(a.priority) - Object.keys(Priority).indexOf(b.priority) ||
        a.id - b.id
    );

    const ticketsBySprint = useMemo(() => {
        return tickets.reduce((acc, x) => {
            if (x.sprint === undefined || x.sprint === null) {
                return acc;
            }
            const sprintName = fetchSprintName(x.sprint);
            if (acc[sprintName] === undefined) {
                acc[sprintName] = [];
            }
            acc[sprintName].push(x as ISprintTicket);
            return acc;
        }, {} as { [key: string]: ISprintTicket[] });
    }, [tickets, fetchSprintName]);

    const sprintStatuses = useMemo(() => {
        return sprints.reduce((acc, x) => {
            const issue = x.end_date < new Date() 
                ? IssueEnum.OK
                : (
                    x.start_date > new Date() 
                        ? IssueEnum.ERROR
                        : IssueEnum.WARNING
                );
            return { 
                ...acc,
                [x.name]: issue }
        }, {} as ITabStatuses);
    }, [sprints]);


    const calendarToEvent = (calendar: ICalendar): IEvent => {
        const start = new Date(calendar.start);
        const end = new Date(calendar.end);
        const duration = (end.getTime() - start.getTime()) / 1000 / 60;

        return {
            start,
            end,
            title: fetchUserName(calendar.user_id) + ' - ' + duration.toString() + ' mins',
            allDay: false,
        };
    };

    const showSprintDatesAndCapacity = (tab: string) => {
        const sprint = sprints.find(x => x.name === tab);
        if (sprint) {
            return <>
                <b>{FormatDay(sprint.start_date)} - {FormatDay(sprint.end_date)}</b>
                <br />
                <b>Scope: {ticketsBySprint[tab]?.reduce((acc, cur) => acc += cur.scope ?? 0, 0) ?? 0}</b>
            </>
        }
        return null;
    }

    const userSprintCapacities = useMemo(() => {
        const userSprintCapacities: IUserSprintCapacity[] = [];
        for (const calendar of calendars) {
            const userSprintCapacity = userSprintCapacities.find(x => x.user_id === calendar.user_id);
            const duration = (calendar.end.getTime() - calendar.start.getTime()) / 1000 / 60;

            if (userSprintCapacity) {
                userSprintCapacity.leave += duration;
            } else {
                userSprintCapacities.push({
                    user_id: calendar.user_id,
                    leave: duration,
                });
            }
        }
        return userSprintCapacities;
    }, [calendars]);

    return (
        <div className="service-desk__sprint-plan">
            <ListTile>
                <h1 className={`list__title`}>{'Sprint Plan'}</h1>

                <Box className={'tabs-container'}>
                    {sprints.length === 0 &&
                        <p>No sprints found</p>
                    }
                    {sprints.length > 0 &&
                        <>
                            <Tabs<ISprintTicket>
                                tabs={sprints.map(x => x.name)}
                                tabStatuses={sprintStatuses}
                                tabData={ticketsBySprint}
                                renderItem={(t) => <SprintItem ticket={t} />}
                                startTab={sprints.findIndex(x => x.start_date < new Date() && x.end_date > new Date())}
                                header={showSprintDatesAndCapacity}
                            />
                        </>
                    }
                </Box>
            </ListTile>

            <SimpleTable<IUserSprintCapacity>
                title={'Sprint Capacity'}
                items={userSprintCapacities}
                error={''}
                RenderRow={(item) => (
                    <SimpleListItem
                        key={item.user_id}
                        title={fetchUserName(item.user_id)}
                        text={'Capacity: ' + FormatCounter((5 * 7.5 * 60 - item.leave) * 60 * 1000, 'hours')}
                    />
                )}
            />
            <div className={`tile-container wide grow`}>
                <div className={`tile wide`}>
                    <h1 className={`list__title`}>
                        Sprint Leave
                    </h1>

                    <Box sx={{ width: '100%' }}>
                        <Calendar
                            localizer={localizer}
                            events={calendars.map(calendarToEvent)}
                            // onSelectEvent={handleSelectEvent}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            selectable
                            // onSelectSlot={handleSelectSlot}
                        />
                    </Box>
                </div>
            </div>
        </div >
    );
};
export default ServiceDeskSprintPlan;

interface ISprintTicket extends IStatusItemBaseDb, Ticket {
}

const SprintItem = ({ ticket }: { ticket: ISprintTicket }) => {
    const { fetchUserName } = useContext(UsersContext);
    return (
        <div className={`listitem issue-${ticket.issue}`}>
            <Link to={`/service-desk/${ticket.id}`}>

                <div className={'listitem__header'}>
                    <div className={'listitem__toggle-details'}>
                        <div className={'listitem__toggle-details__container'}>
                            {/* {getIcon(item)} */}
                        </div>
                    </div>

                    <div className={'listitem__title'}>
                        {ticket.name}
                    </div>

                    <div className={`listitem__value`}>
                        Status: {ticket.status}
                    </div>

                    <div className={`listitem__value`}>
                        Priority: {ticket.priority}
                    </div>

                    <div className={`listitem__value`}>
                        Deadline: {ticket.deadline ? FormatDate(ticket.deadline) : 'Never'}
                    </div>

                    <div className={`listitem__value`}>
                        Assignee: {ticket.assignee_id ? fetchUserName(ticket.assignee_id) : "Unassigned"}
                    </div>

                    <div className={`listitem__value`}>
                        Scope: {ticket.scope ?? -1}
                    </div>
                </div>
            </Link>
        </div>
    );
};
