import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

import MainLogo from "core/images/dk_logo_no_text.svg";
import UpArrowRound from "core/images/icons/up_arrow_round.svg";

import "./_footer.scss";

// TODO: Low - componentise this
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 order-2 order-lg-1">
            <Fade duration={1500} delay={0}>
              <ul className="footer--sitemap">
                <li>
                  <h5>
                    <Link to="/" className="footer--sitemap__link">
                      Home
                    </Link>
                  </h5>
                </li>
                {/* <li>
                  <h5>
                    <a href="/privacy-policy" className="footer--sitemap__link">
                      Privacy Policy
                    </a>
                  </h5>
                </li> */}
              </ul>
            </Fade>
          </div>
          <div className="col-12 col-lg-4 order-3 order-lg-2 footer--logo">
            <Fade duration={1500} delay={200}>
              <div className="row footer--logo__icon">
                <img src={MainLogo} alt="logo"></img>
              </div>
            </Fade>
          </div>
          <div className="col-12 col-lg-4 order-1  order-lg-3 footer--logo">
            <Fade duration={1500} delay={300}>
              <div className="row">
                <p
                  className="footer--backtotop"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Back to top
                  <img
                    src={UpArrowRound}
                    className="footer--backtotop__icon"
                    alt="btp"
                  ></img>
                </p>
              </div>
              <div className="row footer--email">
                <h3>
                  <a href="mailto:hello@datakraken.net" className="text-white">
                    hello@datakraken.net
                  </a>
                </h3>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
