import React from "react";
import { Route, Routes } from "react-router-dom";

import { Permission } from "core/enums/Permissions";
import PermissionsCheck from "core/helpers/auth/PermissionsCheck";

import MetricsListTable from "./components/MetricsListTable";
import MetricPage from "./components/MetricPage";


import "./_metrics.scss";

const MetricsPage = () => {
  return (
    <section className="metrics-db">
      <Routes>
        <Route path="/" element={
          <PermissionsCheck permission={Permission.ViewMetrics}>
            <>
              <MetricsListTable />
            </>
          </PermissionsCheck>
        } />
        <Route path="/:client/:metric" element={
          <PermissionsCheck permission={Permission.ViewMetricDetails}>
            <MetricPage />
          </PermissionsCheck>
        } />
      </Routes>
    </section>
  );
};

export default MetricsPage;