import React from "react";
import { Route, Routes } from "react-router-dom";

import { Permission } from "core/enums/Permissions";
import PermissionsCheck from "core/helpers/auth/PermissionsCheck";

import CampaignsListTable from "./components/CampaignsListTable";
import CampaignPage from "./components/CampaignPage";


import "./_campaigns.scss";

const CampaignsPage = () => {
  return (
    <section className="campaigns-db">
      <Routes>
        <Route path="/" element={
          <PermissionsCheck permission={Permission.ViewCampaigns}>
            <>
              <CampaignsListTable />
            </>
          </PermissionsCheck>
        } />
        <Route path="/:client/:campaign" element={
          <PermissionsCheck permission={Permission.ViewCampaignDetails}>
            <CampaignPage />
          </PermissionsCheck>
        } />
      </Routes>
    </section>
  );
};

export default CampaignsPage;