import React, { ReactNode, createContext, useContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";

import { IReportConfig } from "../types/IReportConfig";
import { FETCH_REPORTS_CONFIG_DB } from "config";

export const ReportsConfigContext = createContext<IReportsConfigContext>(null!);
export interface IReportsConfigContext extends IDataContext<IReportConfig> { }


interface IReportsConfigContextProvider {
    children: ReactNode;
}
const ReportsConfigContextProvider = ({ children }: IReportsConfigContextProvider) => {
    const { clients } = useContext(UserContext);

    let params = { 
        clients: clients
    };
    return (
        <DataContextProvider<IReportConfig>
            context={ReportsConfigContext}
            endpoint={FETCH_REPORTS_CONFIG_DB}
            params={params}
        >
            {children}
        </DataContextProvider>
    );
};

export default ReportsConfigContextProvider;