import React from "react";
import { Route, Routes } from "react-router-dom";

import PermissionsCheck from "core/helpers/auth/PermissionsCheck";
import Dashboard from "features/dashboards/home/Dashboard";
import { Permission } from "core/enums/Permissions";

import "./_homepage.scss";

const Homepage = () => {
  return (
    <section className="homepage">
      <Routes>
        <Route path="/" element={
          <PermissionsCheck permission={Permission.ViewHomePage}>
            <Dashboard />
          </PermissionsCheck>
        } />
      </Routes>
    </section>
  );
};

export default Homepage;
