import React, { Context, useContext, useEffect, useMemo } from "react";

import SquareTile from "core/components/tiles/SquareTile";
import WideTile from "core/components/tiles/WideTile";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import HalfSizeTileContainer from "core/components/tiles/HalfSizeTileContainer";
import SimpleTimeGraph from "core/components/graphs/SimpleTimeGraph";
import { DAILY_FREQ, Frequencies, MONTHLY_FREQ, QUARTERLY_FREQ, WEEKLY_FREQ } from "core/data/feeds/frequencies";
import Countdown from "core/helpers/Countdown";
import FormatTime from "core/helpers/datetime/FormatTime";
import FormatDay from "core/helpers/datetime/FormatDay";
import Log from "core/helpers/Log";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";
import { ReportFrequency } from "core/enums/ReportFrequency";

import { LatestFeedsContext } from "../contexts/LatestFeedsContextProvider";
import { IFeedsHistoryContext } from "../contexts/FeedsHistoryContextProvider";
import { FeedsConfigContext } from "../contexts/FeedsConfigContextProvider";

import "../_feeds.scss";



const FeedConfig = ({ client, feed, historyCtx }: { client: number; feed: number; historyCtx: Context<IFeedsHistoryContext> }) => {
    const { items: feedsConfig, setVisible: setConfigVisible } = useContext(FeedsConfigContext);
    const { items: feedsHistory, setVisible: setHistoryVisible } = useContext(historyCtx);
    const { items: latestFeeds, setVisible: setLatestVisible } = useContext(LatestFeedsContext);

    useEffect(() => {
        setHistoryVisible(true);
        setConfigVisible(true);
        setLatestVisible(true);

        return () => {
            setHistoryVisible(false);
            setConfigVisible(false);
            setLatestVisible(false);
        };
    }, [setHistoryVisible, setConfigVisible, setLatestVisible]);

    const latest = latestFeeds?.find((x) => x.client_id === client && x.feed_id === feed);
    const latestFeed = useMemo(() => {
        if (!latest) return null;
        return {
            ...latest,
            id: latest.feed_id,
            name: latest.feed_name,
            last_date: ParseDBDate(latest.modified_timestamp),
            next_date: 0,
            issue: IssueEnum.OK,
        };
    }, [latest]);
    Log("LatestFeed", latestFeed);

    const feedHistoryCounts = {} as { [key: number]: number };
    Log("feedsHistory", feedsHistory);
    const feedHistory = feedsHistory
        ?.filter((x) => x.client_id === client && x.feed_name === latestFeed?.feed_name)
        ?.filter((x, ii, arr) => {
            // Sum record counts for duplicate dates, then filter out duplicates
            const key = ParseDBDate(x.modified_timestamp);
            feedHistoryCounts[key] = (feedHistoryCounts[key] ?? 0) + x.record_count;
            return ii === arr.findIndex((y) => y.modified_timestamp === x.modified_timestamp);
        })
        ?.map((x) => ({
            ...x,
            id: x.feed_id,
            name: x.feed_name,
            last_date: ParseDBDate(x.modified_timestamp),
            next_date: 0,
            issue: IssueEnum.OK,
        }))
        ?.filter((x) => x.last_date < Date.now())
        ?.sort((a, b) => b.last_date - a.last_date)
        ?.slice(0, 30);
    Log("History", feedHistory);
    Log("History Counts", feedHistoryCounts);

    const feedConfig = feedsConfig?.filter((x) => x.feed_id === feed)[0];
    // const feedConfig = feedsConfig?.filter(x => x.feed_id === feed.feed_id)[0];
    Log("Config", feedConfig);

    let freq = useMemo(() => {
        let freq = Frequencies[latestFeed?.name ?? ""] ?? DAILY_FREQ;
        if (feedConfig?.feed_frequency) {
            switch (feedConfig.feed_frequency) {
                case ReportFrequency.Daily:
                default:
                    freq = DAILY_FREQ;
                    break;
                case ReportFrequency.Weekly:
                    freq = WEEKLY_FREQ;
                    break;
                case ReportFrequency.Monthly:
                    freq = MONTHLY_FREQ;
                    break;
                case ReportFrequency.Quarterly:
                    freq = QUARTERLY_FREQ;
                    break;
            }
        }
        return freq;
    }, [feedConfig, latestFeed]);

    let expectedTime = useMemo(() => {
        return (latestFeed?.last_date ?? 0) + freq.expected - Date.now();
    }, [latestFeed, freq]);

    return (
        <>
            {!latestFeed && <LoadingSpinner />}
            {latestFeed && (
                <HalfSizeTileContainer>
                    <SquareTile>
                        <h3>Last Received</h3>
                        <p style={{ fontSize: "2rem", textAlign: "center" }}>
                            {latestFeed.last_date === 0 ? "Never" : FormatTime(latestFeed.last_date)}
                            <br />
                            {latestFeed.last_date === 0 ? "" : FormatDay(latestFeed.last_date)}
                        </p>
                        <p></p>
                    </SquareTile>
                    <SquareTile>
                        <h3>Next Expected</h3>
                        <p style={{ fontSize: "2rem", textAlign: "center" }}>
                            {FormatTime(latestFeed.last_date + freq.expected)}
                            <br />
                            {FormatDay(latestFeed.last_date + freq.expected)}
                        </p>
                        <Countdown style={{ margin: "-3.2rem auto 1rem auto" }} time={expectedTime} freq={freq} reportFreq={feedConfig?.feed_frequency} />
                    </SquareTile>
                    <WideTile>
                        <h3>Change over time</h3>
                        {feedHistoryCounts && Object.entries(feedHistoryCounts).length > 0 && <SimpleTimeGraph items={Object.entries(feedHistoryCounts).map((x) => ({ name: parseInt(x[0]), value: x[1] }))} />}
                        {!feedHistoryCounts && <LoadingSpinner />}
                        {Object.entries(feedHistoryCounts).length === 0 && <p>No history</p>}
                    </WideTile>
                </HalfSizeTileContainer>
            )}
        </>
    );
};

export default FeedConfig;