import React from "react";

import ReactEcharts from "echarts-for-react";

const Radar = () => {
    const options = {
        textStyle: {
            fontWeight: "400",
            color: "black"
        },
        legend: {
            data: [
                {
                    name: "A felhasználók átlaga",
                    itemStyle: { color: "rgba(247, 100, 90, 1)" }
                },
                {
                    name: "A te tanulási analízised",
                    itemStyle: { color: "rgba(190, 245, 225, 1)" }
                }
            ],
            orient: "vertical",
            icon: "circle",
            bottom: 0,

            left: 0,
            textStyle: {
                fontWeight: "700",
                color: "black"
            }
        },
        radar: {
            splitNumber: 7,
            splitLine: {
                lineStyle: {
                    color: "#B46372"
                }
            },
            center: ["50%", "50%"],
            splitArea: false,
            indicator: [
                { name: "Egyedül tanulok szívesebben", max: 7 },
                { name: "Hangosan kimondom, \n amit meg akarok\n jegyezni", max: 7 },
                { name: "Elméleti módok \nérdekelnek inkább", max: 7 },
                { name: "Látás után rögzül\n az információ", max: 7 },
                { name: "Az analitikus\n gondolkodást \nrészesítem előnyben", max: 7 },
                { name: "Közösségben tanulok szívesebben", max: 7 },
                { name: "Térben vizualizálom, \namit meg akarok tanulni", max: 7 },
                { name: "Gyakorlati alkalmazás \nérdekel inkább", max: 7 },
                { name: "Hallás alapján \nrögzül az információ", max: 7 },
                { name: "A kreatív gondolkodást\n tartom fontosabbnak", max: 7 }
            ],
            axisLine: {
                lineStyle: {
                    color: "#B46372"
                }
            }
        },
        series: [
            {
                name: "Vinyl vs Streaming",
                type: "radar",
                symbolSize: 0,

                data: [
                    {
                        value: [3, 4, 5, 7, 4, 2, 4, 5, 4, 4],
                        name: "A felhasználók átlaga",
                        lineStyle: {
                            width: 3,
                            color: "rgba(247, 100, 90, 1)",
                            shadowColor: "rgba(247, 100, 90, 1)",
                            shadowOffsetY: 2,
                            shadowOffsetX: 2
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: "rgba(247, 100, 90, 1)",
                            shadowColor: "rgba(0, 0, 0, .4)",
                            shadowBlur: 12,
                            shadowOffsetY: 10,
                            shadowOffsetX: 4
                        }
                    },
                    {
                        value: [2, 4, 3, 5, 6, 3, 4, 5, 3, 6],
                        name: "A te tanulási analízised",
                        lineStyle: {
                            width: 3,
                            color: "rgba(190, 245, 225, 1)",
                            shadowColor: "rgba(190, 245, 225, 1)",
                            shadowOffsetY: 2,
                            shadowOffsetX: 2
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: "rgba(190, 245, 225, 1)",
                            shadowColor: "rgba(0, 0, 0, .4)",
                            shadowBlur: 12,
                            shadowOffsetY: 10,
                            shadowOffsetX: 4
                        }
                    }
                ]
            }
        ]
    };

    return (
        <ReactEcharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: "100%", width: "100%" }}
        />
    );
};

export default Radar;