import React, { Context, ReactNode, createContext, useContext } from "react";

import FeedsHistoryContextProvider, { IFeedsHistoryContext } from "./FeedsHistoryContextProvider";
import { LatestFeedsContext } from "./LatestFeedsContextProvider";

interface FeedsHistoryContextList {
    feed_name: string;
    feed_id: number;
    context: Context<IFeedsHistoryContext>;
}
let contexts: FeedsHistoryContextList[] = [];

export const fetchFeedHistoryContext = (id: number) => {
    return contexts.find(x => x.feed_id === id);
}

interface IFeedsHistoryContextProvidersProps {
    children: ReactNode;
}
const FeedsHistoryContextProviders = ({ children }: IFeedsHistoryContextProvidersProps) => {
    const { items } = useContext(LatestFeedsContext);

    contexts = items?.map(feed => {
        const ctx = createContext<IFeedsHistoryContext>(null!);
        
        return {
            feed_name: feed.feed_name,
            feed_id: feed.feed_id,
            context: ctx
        }
    }) ?? [];


    return (
        <>
            <FeedsHistoryContextProvider>
                {contexts.reduce((acc, feed) => (
                    <FeedsHistoryContextProvider 
                        params={{ feed_name: feed.feed_name }} 
                        context={feed.context}>
                        {acc}
                    </FeedsHistoryContextProvider>
                ), children)}
            </FeedsHistoryContextProvider>
        </>
    )
}

export default FeedsHistoryContextProviders;