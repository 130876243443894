import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import NavBar from "core/components/navbar/NavBar";
import Footer from "core/components/footer/Footer";
import LambdaContextProvider from "core/data/contexts/LambdaContextProvider";
import DataContextProviders from "core/data/contexts/DataContextProviders";
import UserContextProvider from "core/data/auth/UserContextProvider";
import AWSAuthContextProvider from "core/data/aws/AWSAuthContextProvider";
import UsersContextProvider from "core/data/auth/UsersContextProvider";

import ScrollToTop from "core/helpers/ScrollToTop";
import PrivateRoute from "core/helpers/auth/PrivateRoute";
import Homepage from "core/pages/home/Homepage";
import Error404 from "core/pages/errors/404/Error404";

import Login from "features/login/Login";
import EventDashboard from "features/events/EventDashboard";
import FeedsPage from "features/dashboards/feeds/FeedsPage";
import ReportsPage from "features/dashboards/reports/ReportsPage";
import GraphDashboard from "features/graphs/GraphDashboard";
import MetricsPage from "features/dashboards/metrics/MetricsPage";
import ServiceDeskPage from "features/service-desk/ServiceDeskPage";
import CampaignsPage from "features/dashboards/campaigns/CampaignsPage";
import DataPage from "features/graphs/DataPage";
import DeliverablesPage from "features/dashboards/deliverables/DeliverablesPage";

import "./app.scss";
import PipelinesPage from "features/pipelines/PipelinesPage";

const App = () => {
    return (
        <BrowserRouter>
            <AWSAuthContextProvider>
                <LambdaContextProvider>
                    <UserContextProvider>
                        <UsersContextProvider>
                            <DataContextProviders>
                                <NavBar />
                                <ScrollToTop />

                                <Routes>
                                    <Route
                                        path="/*"
                                        element={
                                            <PrivateRoute>
                                                <Homepage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/feeds/*"
                                        element={
                                            <PrivateRoute>
                                                <FeedsPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/reports/*"
                                        element={
                                            <PrivateRoute>
                                                <ReportsPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/metrics/*"
                                        element={
                                            <PrivateRoute>
                                                <MetricsPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/campaigns/*"
                                        element={
                                            <PrivateRoute>
                                                <CampaignsPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/deliverables/*"
                                        element={
                                            <PrivateRoute>
                                                <DeliverablesPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/graphs"
                                        element={
                                            <PrivateRoute>
                                                <GraphDashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/events"
                                        element={
                                            <PrivateRoute>
                                                <EventDashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/data"
                                        element={<DataPage />}
                                    />
                                    <Route
                                        path="/pipelines"
                                        element={
                                            <PrivateRoute>
                                                <PipelinesPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/service-desk/*"
                                        element={
                                            <PrivateRoute>
                                                <ServiceDeskPage />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="*" element={<Error404 />} />
                                </Routes>

                                <Footer />
                            </DataContextProviders>
                        </UsersContextProvider>
                    </UserContextProvider>
                </LambdaContextProvider>
            </AWSAuthContextProvider>
        </BrowserRouter>
    );
};

export default App;
