import React, { createContext, useContext, useMemo } from "react";

import { Lambda } from "@aws-sdk/client-lambda";
import { Auth } from "aws-amplify";

import { AWSAuthContext } from "core/data/aws/AWSAuthContextProvider";

import { Region } from "config";

export const LambdaContext = createContext<ILambdaContext>({
    lambda: null
});

export interface ILambdaContext {
    lambda: Lambda | null;
}

interface ILambdaContextProviderProps {
    children: React.ReactNode;
}
const LambdaContextProvider = ({ children }: ILambdaContextProviderProps) => {
    const { credentials } = useContext(AWSAuthContext);

    // Use one lambda across all subsequent contexts
    const lambda = useMemo(() => {
        return credentials
            ? new Lambda({
                credentials: Auth.essentialCredentials(credentials),
                region: Region
            })
            : null
    }, [credentials]);


    const value: ILambdaContext = {
        lambda
    };

    return (
        <LambdaContext.Provider value={value}>
            {!lambda && <div>Loading...</div>}
            {!!lambda && children}
        </LambdaContext.Provider>
    )
};

export default LambdaContextProvider;