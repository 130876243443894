interface IPopulationData {
    [key: string]: IPopulationDataYear[];
}
interface IPopulationDataYear {
    name: string;
    value: number;
}
export const populationDataMale: IPopulationData = {
    "2000": [
        { "name": "Ang Mo Kio", "value": 89800 },
        { "name": "Bedok", "value": 141350 },
        { "name": "Bishan", "value": 44460 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 63800 },
        { "name": "Bukit Merah", "value": 74400 },
        { "name": "Bukit Panjang", "value": 48700 },
        { "name": "Bukit Timah", "value": 30570 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 560 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 69090 },
        { "name": "Clementi", "value": 44920 },
        { "name": "Downtown Core", "value": 2400 },
        { "name": "Geylang", "value": 59780 },
        { "name": "Hougang", "value": 101610 },
        { "name": "Jurong East", "value": 44960 },
        { "name": "Jurong West", "value": 104720 },
        { "name": "Kallang", "value": 47670 },
        { "name": "Lim Chu Kang", "value": 170 },
        { "name": "Mandai", "value": 490 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 21930 },
        { "name": "Museum", "value": 150 },
        { "name": "Newton", "value": 2860 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 20800 },
        { "name": "Orchard", "value": 270 },
        { "name": "Outram", "value": 11660 },
        { "name": "Pasir Ris", "value": 58080 },
        { "name": "Paya Lebar", "value": 80 },
        { "name": "Punggol", "value": 120 },
        { "name": "Queenstown", "value": 48000 },
        { "name": "River Valley", "value": 4380 },
        { "name": "Rochor", "value": 9640 },
        { "name": "Seletar", "value": 220 },
        { "name": "Sembawang", "value": 15840 },
        { "name": "Sengkang", "value": 30810 },
        { "name": "Serangoon", "value": 60130 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 420 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 480 },
        { "name": "Tampines", "value": 125850 },
        { "name": "Tanglin", "value": 6310 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 59490 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 200 },
        { "name": "Woodlands", "value": 95510 },
        { "name": "Yishun", "value": 89130 }
    ],
    "2001": [
        { "name": "Ang Mo Kio", "value": 87460 },
        { "name": "Bedok", "value": 141040 },
        { "name": "Bishan", "value": 43990 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 63830 },
        { "name": "Bukit Merah", "value": 72610 },
        { "name": "Bukit Panjang", "value": 53990 },
        { "name": "Bukit Timah", "value": 31250 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 570 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 73370 },
        { "name": "Clementi", "value": 43030 },
        { "name": "Downtown Core", "value": 1820 },
        { "name": "Geylang", "value": 58460 },
        { "name": "Hougang", "value": 102130 },
        { "name": "Jurong East", "value": 44320 },
        { "name": "Jurong West", "value": 110930 },
        { "name": "Kallang", "value": 46480 },
        { "name": "Lim Chu Kang", "value": 60 },
        { "name": "Mandai", "value": 460 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 21660 },
        { "name": "Museum", "value": 70 },
        { "name": "Newton", "value": 2960 },
        { "name": "North-Eastern Islands", "value": 70 },
        { "name": "Novena", "value": 20340 },
        { "name": "Orchard", "value": 280 },
        { "name": "Outram", "value": 10320 },
        { "name": "Pasir Ris", "value": 59600 },
        { "name": "Paya Lebar", "value": 80 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 360 },
        { "name": "Queenstown", "value": 46890 },
        { "name": "River Valley", "value": 4350 },
        { "name": "Rochor", "value": 9530 },
        { "name": "Seletar", "value": 220 },
        { "name": "Sembawang", "value": 22440 },
        { "name": "Sengkang", "value": 43930 },
        { "name": "Serangoon", "value": 59400 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 530 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 460 },
        { "name": "Tampines", "value": 125340 },
        { "name": "Tanglin", "value": 6710 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 58390 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 250 },
        { "name": "Woodlands", "value": 100800 },
        { "name": "Yishun", "value": 87660 }
    ],
    "2002": [
        { "name": "Ang Mo Kio", "value": 85850 },
        { "name": "Bedok", "value": 141310 },
        { "name": "Bishan", "value": 43590 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 64920 },
        { "name": "Bukit Merah", "value": 72170 },
        { "name": "Bukit Panjang", "value": 56440 },
        { "name": "Bukit Timah", "value": 31620 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 560 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 76580 },
        { "name": "Clementi", "value": 43020 },
        { "name": "Downtown Core", "value": 1750 },
        { "name": "Geylang", "value": 57710 },
        { "name": "Hougang", "value": 102470 },
        { "name": "Jurong East", "value": 44460 },
        { "name": "Jurong West", "value": 114260 },
        { "name": "Kallang", "value": 45730 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 610 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 21390 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3130 },
        { "name": "North-Eastern Islands", "value": 60 },
        { "name": "Novena", "value": 20100 },
        { "name": "Orchard", "value": 290 },
        { "name": "Outram", "value": 9220 },
        { "name": "Pasir Ris", "value": 60530 },
        { "name": "Paya Lebar", "value": 70 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 6370 },
        { "name": "Queenstown", "value": 46260 },
        { "name": "River Valley", "value": 4400 },
        { "name": "Rochor", "value": 9100 },
        { "name": "Seletar", "value": 220 },
        { "name": "Sembawang", "value": 25330 },
        { "name": "Sengkang", "value": 51320 },
        { "name": "Serangoon", "value": 59310 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 500 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 470 },
        { "name": "Tampines", "value": 125190 },
        { "name": "Tanglin", "value": 6960 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 57510 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 300 },
        { "name": "Woodlands", "value": 105270 },
        { "name": "Yishun", "value": 87710 }
    ],
    "2003": [
        { "name": "Ang Mo Kio", "value": 83800 },
        { "name": "Bedok", "value": 137970 },
        { "name": "Bishan", "value": 42170 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 65160 },
        { "name": "Bukit Merah", "value": 69990 },
        { "name": "Bukit Panjang", "value": 57520 },
        { "name": "Bukit Timah", "value": 30470 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 740 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 77950 },
        { "name": "Clementi", "value": 41960 },
        { "name": "Downtown Core", "value": 1660 },
        { "name": "Geylang", "value": 56040 },
        { "name": "Hougang", "value": 101590 },
        { "name": "Jurong East", "value": 43380 },
        { "name": "Jurong West", "value": 114860 },
        { "name": "Kallang", "value": 44020 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 710 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 20480 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 2970 },
        { "name": "North-Eastern Islands", "value": 60 },
        { "name": "Novena", "value": 19580 },
        { "name": "Orchard", "value": 250 },
        { "name": "Outram", "value": 8390 },
        { "name": "Pasir Ris", "value": 60180 },
        { "name": "Paya Lebar", "value": 80 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 12120 },
        { "name": "Queenstown", "value": 44920 },
        { "name": "River Valley", "value": 4190 },
        { "name": "Rochor", "value": 7620 },
        { "name": "Seletar", "value": 200 },
        { "name": "Sembawang", "value": 27140 },
        { "name": "Sengkang", "value": 56250 },
        { "name": "Serangoon", "value": 57850 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 460 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 420 },
        { "name": "Tampines", "value": 123460 },
        { "name": "Tanglin", "value": 6730 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 55760 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 310 },
        { "name": "Woodlands", "value": 107390 },
        { "name": "Yishun", "value": 86410 }
    ],
    "2004": [
        { "name": "Ang Mo Kio", "value": 83150 },
        { "name": "Bedok", "value": 137760 },
        { "name": "Bishan", "value": 42570 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 66400 },
        { "name": "Bukit Merah", "value": 70080 },
        { "name": "Bukit Panjang", "value": 59190 },
        { "name": "Bukit Timah", "value": 31200 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 780 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 79310 },
        { "name": "Clementi", "value": 41810 },
        { "name": "Downtown Core", "value": 1640 },
        { "name": "Geylang", "value": 55800 },
        { "name": "Hougang", "value": 102150 },
        { "name": "Jurong East", "value": 43150 },
        { "name": "Jurong West", "value": 116580 },
        { "name": "Kallang", "value": 44040 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 740 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 20300 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3030 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 19950 },
        { "name": "Orchard", "value": 270 },
        { "name": "Outram", "value": 8200 },
        { "name": "Pasir Ris", "value": 60320 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 18050 },
        { "name": "Queenstown", "value": 44910 },
        { "name": "River Valley", "value": 4330 },
        { "name": "Rochor", "value": 7520 },
        { "name": "Seletar", "value": 200 },
        { "name": "Sembawang", "value": 28850 },
        { "name": "Sengkang", "value": 60840 },
        { "name": "Serangoon", "value": 57840 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 510 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 420 },
        { "name": "Tampines", "value": 123440 },
        { "name": "Tanglin", "value": 7060 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 55730 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 320 },
        { "name": "Woodlands", "value": 109740 },
        { "name": "Yishun", "value": 86590 }
    ],
    "2005": [
        { "name": "Ang Mo Kio", "value": 83280 },
        { "name": "Bedok", "value": 138230 },
        { "name": "Bishan", "value": 43070 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 67610 },
        { "name": "Bukit Merah", "value": 70640 },
        { "name": "Bukit Panjang", "value": 60100 },
        { "name": "Bukit Timah", "value": 32040 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 790 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 80990 },
        { "name": "Clementi", "value": 42010 },
        { "name": "Downtown Core", "value": 1650 },
        { "name": "Geylang", "value": 56390 },
        { "name": "Hougang", "value": 102890 },
        { "name": "Jurong East", "value": 43270 },
        { "name": "Jurong West", "value": 118850 },
        { "name": "Kallang", "value": 44780 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 750 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 20610 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3180 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 20810 },
        { "name": "Orchard", "value": 280 },
        { "name": "Outram", "value": 8160 },
        { "name": "Pasir Ris", "value": 60930 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 21030 },
        { "name": "Queenstown", "value": 44890 },
        { "name": "River Valley", "value": 4380 },
        { "name": "Rochor", "value": 7660 },
        { "name": "Seletar", "value": 220 },
        { "name": "Sembawang", "value": 30350 },
        { "name": "Sengkang", "value": 64730 },
        { "name": "Serangoon", "value": 58690 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 590 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 410 },
        { "name": "Tampines", "value": 123980 },
        { "name": "Tanglin", "value": 7430 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 55710 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 360 },
        { "name": "Woodlands", "value": 111900 },
        { "name": "Yishun", "value": 87260 }
    ],
    "2006": [
        { "name": "Ang Mo Kio", "value": 83780 },
        { "name": "Bedok", "value": 138810 },
        { "name": "Bishan", "value": 43590 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 68980 },
        { "name": "Bukit Merah", "value": 72110 },
        { "name": "Bukit Panjang", "value": 60700 },
        { "name": "Bukit Timah", "value": 33080 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 810 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 82150 },
        { "name": "Clementi", "value": 42400 },
        { "name": "Downtown Core", "value": 1680 },
        { "name": "Geylang", "value": 57190 },
        { "name": "Hougang", "value": 103680 },
        { "name": "Jurong East", "value": 43360 },
        { "name": "Jurong West", "value": 121190 },
        { "name": "Kallang", "value": 45710 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 760 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 20910 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3280 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 21580 },
        { "name": "Orchard", "value": 310 },
        { "name": "Outram", "value": 8260 },
        { "name": "Pasir Ris", "value": 62130 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 23130 },
        { "name": "Queenstown", "value": 45350 },
        { "name": "River Valley", "value": 4410 },
        { "name": "Rochor", "value": 7830 },
        { "name": "Seletar", "value": 230 },
        { "name": "Sembawang", "value": 31500 },
        { "name": "Sengkang", "value": 68550 },
        { "name": "Serangoon", "value": 59260 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 620 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 400 },
        { "name": "Tampines", "value": 124850 },
        { "name": "Tanglin", "value": 7770 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 55800 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 390 },
        { "name": "Woodlands", "value": 113770 },
        { "name": "Yishun", "value": 87660 }
    ],
    "2007": [
        { "name": "Ang Mo Kio", "value": 84550 },
        { "name": "Bedok", "value": 140020 },
        { "name": "Bishan", "value": 43900 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 69850 },
        { "name": "Bukit Merah", "value": 73660 },
        { "name": "Bukit Panjang", "value": 61450 },
        { "name": "Bukit Timah", "value": 33680 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 820 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 83490 },
        { "name": "Clementi", "value": 42670 },
        { "name": "Downtown Core", "value": 1630 },
        { "name": "Geylang", "value": 58240 },
        { "name": "Hougang", "value": 104530 },
        { "name": "Jurong East", "value": 43590 },
        { "name": "Jurong West", "value": 123950 },
        { "name": "Kallang", "value": 46570 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 680 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 21140 },
        { "name": "Museum", "value": 110 },
        { "name": "Newton", "value": 3100 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 21880 },
        { "name": "Orchard", "value": 330 },
        { "name": "Outram", "value": 8810 },
        { "name": "Pasir Ris", "value": 63400 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 25290 },
        { "name": "Queenstown", "value": 45670 },
        { "name": "River Valley", "value": 4150 },
        { "name": "Rochor", "value": 7880 },
        { "name": "Seletar", "value": 240 },
        { "name": "Sembawang", "value": 32540 },
        { "name": "Sengkang", "value": 71800 },
        { "name": "Serangoon", "value": 59640 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 660 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 125560 },
        { "name": "Tanglin", "value": 7870 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 56270 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 410 },
        { "name": "Woodlands", "value": 115820 },
        { "name": "Yishun", "value": 88920 }
    ],
    "2008": [
        { "name": "Ang Mo Kio", "value": 85500 },
        { "name": "Bedok", "value": 140630 },
        { "name": "Bishan", "value": 44150 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70230 },
        { "name": "Bukit Merah", "value": 74990 },
        { "name": "Bukit Panjang", "value": 62410 },
        { "name": "Bukit Timah", "value": 32780 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 860 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 84690 },
        { "name": "Clementi", "value": 43010 },
        { "name": "Downtown Core", "value": 1710 },
        { "name": "Geylang", "value": 58860 },
        { "name": "Hougang", "value": 106000 },
        { "name": "Jurong East", "value": 43710 },
        { "name": "Jurong West", "value": 128830 },
        { "name": "Kallang", "value": 47790 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 700 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 21080 },
        { "name": "Museum", "value": 140 },
        { "name": "Newton", "value": 2800 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 21720 },
        { "name": "Orchard", "value": 340 },
        { "name": "Outram", "value": 8810 },
        { "name": "Pasir Ris", "value": 64540 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 26940 },
        { "name": "Queenstown", "value": 45930 },
        { "name": "River Valley", "value": 3860 },
        { "name": "Rochor", "value": 7910 },
        { "name": "Seletar", "value": 250 },
        { "name": "Sembawang", "value": 33740 },
        { "name": "Sengkang", "value": 75810 },
        { "name": "Serangoon", "value": 59940 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 670 },
        { "name": "Southern Islands", "value": 120 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 410 },
        { "name": "Tampines", "value": 126610 },
        { "name": "Tanglin", "value": 7610 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 57440 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 440 },
        { "name": "Woodlands", "value": 118660 },
        { "name": "Yishun", "value": 90130 }
    ],
    "2009": [
        { "name": "Ang Mo Kio", "value": 87130 },
        { "name": "Bedok", "value": 143400 },
        { "name": "Bishan", "value": 44360 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 71510 },
        { "name": "Bukit Merah", "value": 76630 },
        { "name": "Bukit Panjang", "value": 63690 },
        { "name": "Bukit Timah", "value": 32570 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 990 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 86190 },
        { "name": "Clementi", "value": 44210 },
        { "name": "Downtown Core", "value": 1850 },
        { "name": "Geylang", "value": 59670 },
        { "name": "Hougang", "value": 107360 },
        { "name": "Jurong East", "value": 44490 },
        { "name": "Jurong West", "value": 134350 },
        { "name": "Kallang", "value": 49450 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 710 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 21790 },
        { "name": "Museum", "value": 140 },
        { "name": "Newton", "value": 2760 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 22180 },
        { "name": "Orchard", "value": 350 },
        { "name": "Outram", "value": 8890 },
        { "name": "Pasir Ris", "value": 65700 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 70 },
        { "name": "Punggol", "value": 28130 },
        { "name": "Queenstown", "value": 46900 },
        { "name": "River Valley", "value": 3630 },
        { "name": "Rochor", "value": 8090 },
        { "name": "Seletar", "value": 150 },
        { "name": "Sembawang", "value": 34920 },
        { "name": "Sengkang", "value": 79710 },
        { "name": "Serangoon", "value": 60710 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 810 },
        { "name": "Southern Islands", "value": 190 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 128690 },
        { "name": "Tanglin", "value": 7770 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 59090 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 480 },
        { "name": "Woodlands", "value": 122590 },
        { "name": "Yishun", "value": 91790 }
    ],
    "2010": [
        { "name": "Ang Mo Kio", "value": 87110 },
        { "name": "Bedok", "value": 144400 },
        { "name": "Bishan", "value": 44530 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 71360 },
        { "name": "Bukit Merah", "value": 76750 },
        { "name": "Bukit Panjang", "value": 64230 },
        { "name": "Bukit Timah", "value": 32980 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1130 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 86890 },
        { "name": "Clementi", "value": 44470 },
        { "name": "Downtown Core", "value": 1860 },
        { "name": "Geylang", "value": 59930 },
        { "name": "Hougang", "value": 107190 },
        { "name": "Jurong East", "value": 44220 },
        { "name": "Jurong West", "value": 135940 },
        { "name": "Kallang", "value": 49980 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 890 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22200 },
        { "name": "Museum", "value": 170 },
        { "name": "Newton", "value": 2870 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 22400 },
        { "name": "Orchard", "value": 390 },
        { "name": "Outram", "value": 10140 },
        { "name": "Pasir Ris", "value": 66490 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 29270 },
        { "name": "Queenstown", "value": 46820 },
        { "name": "River Valley", "value": 3730 },
        { "name": "Rochor", "value": 7910 },
        { "name": "Seletar", "value": 150 },
        { "name": "Sembawang", "value": 36200 },
        { "name": "Sengkang", "value": 82710 },
        { "name": "Serangoon", "value": 60710 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 970 },
        { "name": "Southern Islands", "value": 320 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 128870 },
        { "name": "Tanglin", "value": 8030 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 59920 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 480 },
        { "name": "Woodlands", "value": 123660 },
        { "name": "Yishun", "value": 92160 }
    ],
    "2011": [
        { "name": "Ang Mo Kio", "value": 86650 },
        { "name": "Bedok", "value": 144720 },
        { "name": "Bishan", "value": 44160 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70840 },
        { "name": "Bukit Merah", "value": 76220 },
        { "name": "Bukit Panjang", "value": 64540 },
        { "name": "Bukit Timah", "value": 33020 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1250 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 86990 },
        { "name": "Clementi", "value": 44150 },
        { "name": "Downtown Core", "value": 1850 },
        { "name": "Geylang", "value": 59780 },
        { "name": "Hougang", "value": 107370 },
        { "name": "Jurong East", "value": 43610 },
        { "name": "Jurong West", "value": 137180 },
        { "name": "Kallang", "value": 51000 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 970 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22670 },
        { "name": "Museum", "value": 170 },
        { "name": "Newton", "value": 2910 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 22520 },
        { "name": "Orchard", "value": 390 },
        { "name": "Outram", "value": 11040 },
        { "name": "Pasir Ris", "value": 66360 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 31780 },
        { "name": "Queenstown", "value": 46500 },
        { "name": "River Valley", "value": 3790 },
        { "name": "Rochor", "value": 7810 },
        { "name": "Seletar", "value": 140 },
        { "name": "Sembawang", "value": 36420 },
        { "name": "Sengkang", "value": 84720 },
        { "name": "Serangoon", "value": 60100 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1050 },
        { "name": "Southern Islands", "value": 420 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 420 },
        { "name": "Tampines", "value": 128400 },
        { "name": "Tanglin", "value": 8080 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 60440 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 460 },
        { "name": "Woodlands", "value": 124810 },
        { "name": "Yishun", "value": 92280 }
    ],
    "2012": [
        { "name": "Ang Mo Kio", "value": 86610 },
        { "name": "Bedok", "value": 144420 },
        { "name": "Bishan", "value": 45110 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70330 },
        { "name": "Bukit Merah", "value": 76560 },
        { "name": "Bukit Panjang", "value": 65280 },
        { "name": "Bukit Timah", "value": 33560 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1280 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 87040 },
        { "name": "Clementi", "value": 43860 },
        { "name": "Downtown Core", "value": 1840 },
        { "name": "Geylang", "value": 59270 },
        { "name": "Hougang", "value": 107240 },
        { "name": "Jurong East", "value": 43290 },
        { "name": "Jurong West", "value": 137910 },
        { "name": "Kallang", "value": 51370 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 980 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22800 },
        { "name": "Museum", "value": 170 },
        { "name": "Newton", "value": 2970 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 22660 },
        { "name": "Orchard", "value": 410 },
        { "name": "Outram", "value": 11160 },
        { "name": "Pasir Ris", "value": 67410 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 36840 },
        { "name": "Queenstown", "value": 46360 },
        { "name": "River Valley", "value": 3890 },
        { "name": "Rochor", "value": 7700 },
        { "name": "Seletar", "value": 140 },
        { "name": "Sembawang", "value": 36490 },
        { "name": "Sengkang", "value": 87780 },
        { "name": "Serangoon", "value": 59860 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1140 },
        { "name": "Southern Islands", "value": 520 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 127810 },
        { "name": "Tanglin", "value": 8210 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 60650 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 460 },
        { "name": "Woodlands", "value": 124980 },
        { "name": "Yishun", "value": 93060 }
    ],
    "2013": [
        { "name": "Ang Mo Kio", "value": 86480 },
        { "name": "Bedok", "value": 143670 },
        { "name": "Bishan", "value": 45210 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70240 },
        { "name": "Bukit Merah", "value": 75780 },
        { "name": "Bukit Panjang", "value": 65500 },
        { "name": "Bukit Timah", "value": 34060 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1320 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 87250 },
        { "name": "Clementi", "value": 44000 },
        { "name": "Downtown Core", "value": 1860 },
        { "name": "Geylang", "value": 59410 },
        { "name": "Hougang", "value": 107480 },
        { "name": "Jurong East", "value": 43440 },
        { "name": "Jurong West", "value": 137370 },
        { "name": "Kallang", "value": 51300 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1000 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22990 },
        { "name": "Museum", "value": 180 },
        { "name": "Newton", "value": 3000 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 22990 },
        { "name": "Orchard", "value": 430 },
        { "name": "Outram", "value": 11300 },
        { "name": "Pasir Ris", "value": 67770 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 43400 },
        { "name": "Queenstown", "value": 46730 },
        { "name": "River Valley", "value": 4080 },
        { "name": "Rochor", "value": 7660 },
        { "name": "Seletar", "value": 150 },
        { "name": "Sembawang", "value": 36550 },
        { "name": "Sengkang", "value": 90240 },
        { "name": "Serangoon", "value": 59380 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1230 },
        { "name": "Southern Islands", "value": 630 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 127530 },
        { "name": "Tanglin", "value": 8320 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 61280 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 420 },
        { "name": "Woodlands", "value": 125780 },
        { "name": "Yishun", "value": 93430 }
    ],
    "2014": [
        { "name": "Ang Mo Kio", "value": 86080 },
        { "name": "Bedok", "value": 143120 },
        { "name": "Bishan", "value": 44730 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 69760 },
        { "name": "Bukit Merah", "value": 75940 },
        { "name": "Bukit Panjang", "value": 66250 },
        { "name": "Bukit Timah", "value": 34330 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1300 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 87450 },
        { "name": "Clementi", "value": 43800 },
        { "name": "Downtown Core", "value": 1870 },
        { "name": "Geylang", "value": 59140 },
        { "name": "Hougang", "value": 108180 },
        { "name": "Jurong East", "value": 43210 },
        { "name": "Jurong West", "value": 137410 },
        { "name": "Kallang", "value": 51040 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 990 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 23120 },
        { "name": "Museum", "value": 180 },
        { "name": "Newton", "value": 3100 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23100 },
        { "name": "Orchard", "value": 420 },
        { "name": "Outram", "value": 11250 },
        { "name": "Pasir Ris", "value": 68050 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 48380 },
        { "name": "Queenstown", "value": 46530 },
        { "name": "River Valley", "value": 4210 },
        { "name": "Rochor", "value": 7480 },
        { "name": "Seletar", "value": 140 },
        { "name": "Sembawang", "value": 37450 },
        { "name": "Sengkang", "value": 94600 },
        { "name": "Serangoon", "value": 59140 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1240 },
        { "name": "Southern Islands", "value": 680 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 400 },
        { "name": "Tampines", "value": 127010 },
        { "name": "Tanglin", "value": 8490 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 60770 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 410 },
        { "name": "Woodlands", "value": 125920 },
        { "name": "Yishun", "value": 95540 }
    ],
    "2015": [
        { "name": "Ang Mo Kio", "value": 84220 },
        { "name": "Bedok", "value": 141220 },
        { "name": "Bishan", "value": 44110 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 68650 },
        { "name": "Bukit Merah", "value": 75450 },
        { "name": "Bukit Panjang", "value": 69160 },
        { "name": "Bukit Timah", "value": 34660 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1300 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 86990 },
        { "name": "Clementi", "value": 43870 },
        { "name": "Downtown Core", "value": 1860 },
        { "name": "Geylang", "value": 58090 },
        { "name": "Hougang", "value": 109560 },
        { "name": "Jurong East", "value": 42270 },
        { "name": "Jurong West", "value": 137560 },
        { "name": "Kallang", "value": 50410 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1000 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22970 },
        { "name": "Museum", "value": 190 },
        { "name": "Newton", "value": 3240 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23160 },
        { "name": "Orchard", "value": 430 },
        { "name": "Outram", "value": 11100 },
        { "name": "Pasir Ris", "value": 69150 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 54100 },
        { "name": "Queenstown", "value": 46230 },
        { "name": "River Valley", "value": 4260 },
        { "name": "Rochor", "value": 7410 },
        { "name": "Seletar", "value": 140 },
        { "name": "Sembawang", "value": 37910 },
        { "name": "Sengkang", "value": 101790 },
        { "name": "Serangoon", "value": 58380 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1310 },
        { "name": "Southern Islands", "value": 720 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 128200 },
        { "name": "Tanglin", "value": 8770 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 59840 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 400 },
        { "name": "Woodlands", "value": 125900 },
        { "name": "Yishun", "value": 99990 }
    ],
    "2016": [
        { "name": "Ang Mo Kio", "value": 82140 },
        { "name": "Bedok", "value": 139730 },
        { "name": "Bishan", "value": 43770 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 67960 },
        { "name": "Bukit Merah", "value": 74620 },
        { "name": "Bukit Panjang", "value": 70160 },
        { "name": "Bukit Timah", "value": 35140 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1260 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 89320 },
        { "name": "Clementi", "value": 43710 },
        { "name": "Downtown Core", "value": 1840 },
        { "name": "Geylang", "value": 57450 },
        { "name": "Hougang", "value": 109530 },
        { "name": "Jurong East", "value": 42080 },
        { "name": "Jurong West", "value": 136170 },
        { "name": "Kallang", "value": 49860 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1010 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22720 },
        { "name": "Museum", "value": 200 },
        { "name": "Newton", "value": 3320 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23030 },
        { "name": "Orchard", "value": 420 },
        { "name": "Outram", "value": 10790 },
        { "name": "Pasir Ris", "value": 70610 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 64420 },
        { "name": "Queenstown", "value": 46940 },
        { "name": "River Valley", "value": 4340 },
        { "name": "Rochor", "value": 7200 },
        { "name": "Seletar", "value": 120 },
        { "name": "Sembawang", "value": 38320 },
        { "name": "Sengkang", "value": 107920 },
        { "name": "Serangoon", "value": 57970 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1360 },
        { "name": "Southern Islands", "value": 780 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 127120 },
        { "name": "Tanglin", "value": 9090 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 58690 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 370 },
        { "name": "Woodlands", "value": 125510 },
        { "name": "Yishun", "value": 101860 }
    ],
    "2017": [
        { "name": "Ang Mo Kio", "value": 80040 },
        { "name": "Bedok", "value": 138460 },
        { "name": "Bishan", "value": 43850 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 67930 },
        { "name": "Bukit Merah", "value": 73630 },
        { "name": "Bukit Panjang", "value": 70340 },
        { "name": "Bukit Timah", "value": 35470 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1150 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 93060 },
        { "name": "Clementi", "value": 44100 },
        { "name": "Downtown Core", "value": 1260 },
        { "name": "Geylang", "value": 56210 },
        { "name": "Hougang", "value": 110240 },
        { "name": "Jurong East", "value": 41530 },
        { "name": "Jurong West", "value": 134800 },
        { "name": "Kallang", "value": 50310 },
        { "name": "Lim Chu Kang", "value": 60 },
        { "name": "Mandai", "value": 1010 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22500 },
        { "name": "Museum", "value": 200 },
        { "name": "Newton", "value": 3410 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23520 },
        { "name": "Orchard", "value": 440 },
        { "name": "Outram", "value": 10480 },
        { "name": "Pasir Ris", "value": 71600 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 72440 },
        { "name": "Queenstown", "value": 46030 },
        { "name": "River Valley", "value": 4490 },
        { "name": "Rochor", "value": 6980 },
        { "name": "Seletar", "value": 100 },
        { "name": "Sembawang", "value": 39490 },
        { "name": "Sengkang", "value": 114200 },
        { "name": "Serangoon", "value": 57590 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1380 },
        { "name": "Southern Islands", "value": 800 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 400 },
        { "name": "Tampines", "value": 126390 },
        { "name": "Tanglin", "value": 9450 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 58120 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 340 },
        { "name": "Woodlands", "value": 125560 },
        { "name": "Yishun", "value": 104000 }
    ],
    "2018": [
        { "name": "Ang Mo Kio", "value": 79360 },
        { "name": "Bedok", "value": 136520 },
        { "name": "Bishan", "value": 42900 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70860 },
        { "name": "Bukit Merah", "value": 73120 },
        { "name": "Bukit Panjang", "value": 69540 },
        { "name": "Bukit Timah", "value": 35830 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1050 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 93440 },
        { "name": "Clementi", "value": 44300 },
        { "name": "Downtown Core", "value": 1250 },
        { "name": "Geylang", "value": 55250 },
        { "name": "Hougang", "value": 109540 },
        { "name": "Jurong East", "value": 40270 },
        { "name": "Jurong West", "value": 134080 },
        { "name": "Kallang", "value": 50250 },
        { "name": "Lim Chu Kang", "value": 60 },
        { "name": "Mandai", "value": 1000 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22200 },
        { "name": "Museum", "value": 210 },
        { "name": "Newton", "value": 3610 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23700 },
        { "name": "Orchard", "value": 470 },
        { "name": "Outram", "value": 10080 },
        { "name": "Pasir Ris", "value": 72480 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 79780 },
        { "name": "Queenstown", "value": 46050 },
        { "name": "River Valley", "value": 4600 },
        { "name": "Rochor", "value": 6690 },
        { "name": "Seletar", "value": 110 },
        { "name": "Sembawang", "value": 43240 },
        { "name": "Sengkang", "value": 118370 },
        { "name": "Serangoon", "value": 56710 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1420 },
        { "name": "Southern Islands", "value": 840 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 390 },
        { "name": "Tampines", "value": 125780 },
        { "name": "Tanglin", "value": 9690 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 57420 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 340 },
        { "name": "Woodlands", "value": 126710 },
        { "name": "Yishun", "value": 106170 }
    ]
};