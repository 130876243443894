import React, { ReactNode, createContext, useContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";

import { IReportHistory } from "../types/IReportHistory";

import { FETCH_REPORTS_HISTORY_DB } from "config";

export const ReportsHistoryContext = createContext<IReportsHistoryContext>(null!);
export interface IReportsHistoryContext extends IDataContext<IReportHistory> { }


interface IReportsHistoryContextProvider {
    children: ReactNode;
}
const ReportsHistoryContextProvider = ({ children }: IReportsHistoryContextProvider) => {
    const { clients } = useContext(UserContext);

    let params = { 
        clients: clients
    };
    return (
        <DataContextProvider<IReportHistory>
            context={ReportsHistoryContext}
            endpoint={FETCH_REPORTS_HISTORY_DB}
            params={params}
        >
            {children}
        </DataContextProvider>
    );
};

export default ReportsHistoryContextProvider;