import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { AWSAuthContext } from 'core/data/aws/AWSAuthContextProvider';
import { UserContext } from "core/data/auth/UserContextProvider";
import logo from "core/images/dk_logo_primary.svg";

import { Permission } from "core/enums/Permissions";

import { Pages } from "./enums/Pages";
import NavLink from "./components/NavLink";

import "./styles/_navbar.scss";


const NavBar = () => {
  const [showLoginButtons, setShowLoginButtons] = useState(true);
  const [currentPage, setCurrentPage] = useState(Pages.Home);
  const {permissions} = useContext(UserContext);

  const location = useLocation();

  const { user, logOut } = useContext(AWSAuthContext);

  const doLogout = async () => {
    await logOut();
  }

  useEffect(() => {
    const currentPath = location.pathname.split("/")[1];

    if (currentPath === Pages.Login) {
      setShowLoginButtons(false);
    } else {
      setShowLoginButtons(true);
    }

    if (currentPath === Pages.Home) {
      setCurrentPage(Pages.Home);
    } else if (currentPath === Pages.Feeds) {
      setCurrentPage(Pages.Feeds);
    } else if (currentPath === Pages.Reports) {
      setCurrentPage(Pages.Reports);
    } else if (currentPath === Pages.Metrics) {
      setCurrentPage(Pages.Metrics);
    } else if (currentPath === Pages.Campaigns) {
      setCurrentPage(Pages.Campaigns);
    } else if (currentPath === Pages.ServiceDesk) {
      setCurrentPage(Pages.ServiceDesk);
    } else if (currentPath === Pages.Deliverables) {
      setCurrentPage(Pages.Deliverables);
    }
  }, [location]);

  return (
    <section className="nav">
      <nav className="navbar navbar-expand-lg">
        <div className="justify-content-start">
          <Link to={Pages.Home}>
            <img src={logo} className="nav__logo" alt="dk_logo" />
          </Link>
        </div>

        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <div id="nav-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        {showLoginButtons &&
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarTogglerDemo01"
          >
            <ul className="pt-4">
              {permissions.includes(Permission.ViewHomePage) && <NavLink to={"/" + Pages.Home} active={currentPage === Pages.Home} label="Home" />}
              {(
                permissions.includes(Permission.ViewFeeds) || permissions.includes(Permission.ViewReports) || permissions.includes(Permission.ViewCampaigns) || permissions.includes(Permission.ViewCampaigns)
              ) && <NavLink to={"/" + Pages.Deliverables} active={currentPage === Pages.Deliverables} label="Deliverables" />}
              {permissions.includes(Permission.ListTickets) && <NavLink to={"/" + Pages.ServiceDesk} active={currentPage === Pages.ServiceDesk} label="Service Desk" />}
              {user &&
                <li className="nav__list  nav__cta">
                  <button onClick={doLogout} className="btn btn--red nav__link">
                    Sign Out
                  </button>
                </li>
              }
              {!user &&
                <li className="nav__list  nav__cta">
                  <Link to={"/" + Pages.Login} className="btn btn--red nav__link">
                    Log in
                  </Link>
                </li>
              }
            </ul>
          </div>
        }
      </nav>
    </section>
  );
};

export default NavBar;
