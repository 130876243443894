import React, { useContext, useEffect } from "react";

import SquareTile from "core/components/tiles/SquareTile";
import WideTile from "core/components/tiles/WideTile";
import HalfSizeTileContainer from "core/components/tiles/HalfSizeTileContainer";
import SimpleTimeGraph from "core/components/graphs/SimpleTimeGraph";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";

import FormatDay from "core/helpers/datetime/FormatDay";
import Debug from "core/helpers/Debug";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";

import { LatestMetricsContext } from "../contexts/LatestMetricsContextProvider";
import { MetricsHistoryContext } from "../contexts/MetricsHistoryContextProvider";


interface IMetricConfigProps {
    client: number;
    metric: number;
}
const MetricConfig = ({ client, metric }: IMetricConfigProps) => {
    const { items: latestMetrics, setVisible: setLatestVisible } = useContext(LatestMetricsContext);
    const { items: metricsHistory, setVisible: setHistoryVisible } = useContext(MetricsHistoryContext);

    useEffect(() => {
        setHistoryVisible(true);
        setLatestVisible(true);

        return () => {
            setHistoryVisible(false);
            setLatestVisible(false);
        };
    }, [setHistoryVisible, setLatestVisible]);

    const latestMetric = latestMetrics
        ?.filter((x) => x.client_id === client && x.metric_id === metric)
        ?.map((x) => ({
            ...x,
            name: x.metric_name,
            last_date: ParseDBDate(x.metric_date),
            next_date: 0,
            issue: IssueEnum.OK,
        }))[0];
    Debug("Latest", latestMetrics, client, metric);

    // const metricConfig = metricsConfig?.filter(x => x.metric_id === metric)[0];

    const metricHistory = metricsHistory
        ?.filter((x) => x.client_id === client && x.metric_id === metric && x.metric_date !== null)
        ?.map((x) => ({
            ...x,
            name: x.metric_name,
            last_date: ParseDBDate(x.metric_date),
            next_date: 0,
            issue: IssueEnum.OK,
        }))
        ?.sort((a, b) => b.last_date - a.last_date)
        ?.slice(0, 30);
    Debug("History", metricHistory);

    return (
        <>
            {!latestMetric && <LoadingSpinner />}
            {latestMetric && (
                <HalfSizeTileContainer>
                    <SquareTile>
                        <h3>Last Received</h3>
                        <p style={{ fontSize: "3rem" }}>{latestMetric.last_date === 0 ? "Never" : FormatDay(latestMetric.last_date)}</p>
                        <p></p>
                    </SquareTile>
                    <SquareTile>
                        <h3>Latest Value</h3>
                        <p style={{ fontSize: "3rem" }}>{latestMetric.metric_value}</p>
                        <p></p>
                    </SquareTile>
                    {metricHistory && (
                        <WideTile>
                            <h3>Change over time</h3>
                            <SimpleTimeGraph items={metricHistory.map((x) => ({ name: x.last_date, value: x.metric_value }))} />
                        </WideTile>
                    )}
                    {!metricHistory && <LoadingSpinner />}
                    {metricHistory && metricHistory.length === 0 && (
                        <WideTile>
                            <h3>Change over time</h3>
                            <p>No history found</p>
                        </WideTile>
                    )}
                </HalfSizeTileContainer>
            )}
        </>
    );
};

export default MetricConfig;