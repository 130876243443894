import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import FormatDate from "core/helpers/datetime/FormatDate";

import {
  ReportsExpectedContext,
} from "features/dashboards/reports/contexts/ReportsExpectedContextProvider";
import {
  ReportsHistoryContext,
} from "features/dashboards/reports/contexts/ReportsHistoryContextProvider";

import EventInfo from "./components/EventInfo";
import AddEvent from "./components/AddEvent";

import "./_events.scss";

export interface IEvent {
  start: Date;
  end: Date;
  title: string;
  description?: string;
  resource?: string;
  allDay: boolean;
  meta?: {
    tags: string[];
  };
}

interface IDateRange {
  start: Date;
  end: Date;
}

const EventDashboard = () => {
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
  const [addEvent, setAddEvent] = useState(false);
  const [newEvent, setNewEvent] = useState<IEvent | null>(null);
  const [events, setEvents] = useState<IEvent[]>([]);

  const tomorrow = moment().add(1, "days").toDate();
  const defaultEvent: IEvent = useMemo(
    () => ({
      start: new Date(),
      end: tomorrow,
      title: "Title",
      description: "Description",
      resource: "Flowery",
      allDay: false,
      meta: {
        tags: ["tag1", "tag2"],
      },
    }),
    [tomorrow]
  );

  const handleSelectEvent = useCallback((event: IEvent) => {
    setSelectedEvent(event);
    setAddEvent(false);
  }, []);

  const deselectEvent = useCallback(() => {
    setSelectedEvent(null);
    setAddEvent(false);
  }, []);

  const handleSelectSlot = useCallback(
    ({ start, end }: IDateRange) => {
      setNewEvent({ ...defaultEvent, start, end });
      setSelectedEvent(null);
      setAddEvent(true);
    },
    [defaultEvent]
  );

  const doAddEvent = useCallback((event?: IEvent) => {
    if (event) {
      setEvents((prev: IEvent[]) => [...prev, event]);
      setSelectedEvent(event);
    }
    setAddEvent(false);
  }, []);

  return (
    <section className="homepage">
      <div className="row">
        <div className={"col-md-" + ((selectedEvent || addEvent) ? 12 : 12).toString()}>
          <ReportsCalendar
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
          />
        </div>
        {(selectedEvent || addEvent) && (
          <div className="col-md-5 offset-md-1">
            {selectedEvent && <EventInfo event={selectedEvent} onClose={deselectEvent} />}
            {addEvent && (
              <AddEvent
                newEvent={newEvent!}
                onComplete={(event?: IEvent) => doAddEvent(event)}
              />
            )}
          </div>
        )}
      </div>
    </section>
  );
};
export default EventDashboard;

interface ReportsCalendarProps {
  onSelectEvent: (e: any) => void;
  onSelectSlot: (e: any) => void;
}
const ReportsCalendar = ({
  onSelectEvent,
  onSelectSlot,
}: ReportsCalendarProps) => {
  const { items: expectedItems, setVisible: setExpectedVisible } =
    useContext(ReportsExpectedContext);
  const { items: historyItems, setVisible: setHistoryVisible } =
    useContext(ReportsHistoryContext);

  useEffect(() => {
    setHistoryVisible(true);
    setExpectedVisible(true);

    return () => {
      setHistoryVisible(false);
      setExpectedVisible(false);
    };
  }, [setHistoryVisible, setExpectedVisible]);

  const localizer = momentLocalizer(moment);

  let events = expectedItems
    ?.filter((x) => !!x.next_report_datetime)
    ?.map((x) => ({
      title: x.client_name + " - " + x.report_name + ' - Expected',
      description: "Expected: " + FormatDate(x.next_report_datetime),
      start: new Date(x.next_report_datetime),
      end: new Date(
        new Date(x.next_report_datetime).getTime() + 60 * 1000
      ),
      allDay: false,
      meta: {
        tags: [x.client_name, x.report_name],
      },
    }));

  if (historyItems) {
    events = events?.concat(
      historyItems.map((x) => ({
        title: x.client_name + " - " + x.report_name + ' - Received',
        description: "Received: " + FormatDate(x.report_timestamp),
        start: new Date(x.report_timestamp),
        end: new Date(new Date(x.report_timestamp).getTime() + 60 * 1000),
        allDay: false,
        meta: {
          tags: [x.client_name, x.report_name],
        },
      }))
    );
  }

  return (
    <Calendar
      localizer={localizer}
      events={events}
      onSelectEvent={onSelectEvent}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      selectable
      onSelectSlot={onSelectSlot}
    />
  );
};
