import React, { ReactNode, createContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";

import { IMetricHistory } from "../types/IMetricHistory";
import { FETCH_METRICS_HISTORY_DB } from "config";


export const MetricsHistoryContext = createContext<IMetricsHistoryContext>(null!);
export interface IMetricsHistoryContext extends IDataContext<IMetricHistory> { }


interface IMetricsHistoryContextProvider {
    children: ReactNode;
}
const MetricsHistoryContextProvider = ({ children }: IMetricsHistoryContextProvider) => {

    return (
        <DataContextProvider<IMetricHistory>
            context={MetricsHistoryContext}
            endpoint={FETCH_METRICS_HISTORY_DB}
        >
            {children}
        </DataContextProvider>
    );
};

export default MetricsHistoryContextProvider;