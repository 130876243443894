export enum SLA {
    Default = "Default",
    Emergency = "Emergency",
}

export interface ISLA {
    id: number;
    sla_name: string;
    sla_description: string;
    time_in_hours: number;
}