import React, { ReactNode } from "react";
import { Fade } from "react-awesome-reveal";
import { Row } from "react-table";

import TabPanel from './TabPanel';

import { IStatusItemBase } from "../../status-table/StatusTable";
import { IStatusItemBaseDb } from "../../status-table/DbStatusTable";
import CustomTable from "../../status-table/CustomTable";

import { ITabDataDict } from "../types/ITabDataDict";
import { IRenderItem } from "../types/IRenderItem";

interface ITabPanelsProps<T extends object> {
    tabs: string[];
    tabData: ITabDataDict<T>;
    value: number;
    renderItem: IRenderItem<T>;
    header?: (tab: string) => ReactNode;
    columns?: any[];
    getRowProps?: (row: Row<T>) => any;
}

const TabPanels = <T extends IStatusItemBase | IStatusItemBaseDb>({ tabs, tabData, value, renderItem, header, columns, getRowProps = () => {} }: ITabPanelsProps<T>) => {
    return (
        <>
            {tabs?.map((tab, index) =>
                <TabPanel key={index} value={value} index={index}>
                    {header && header(tab)}
                    {!columns && tabData[tab]?.map((row, ii) => (
                        <Fade key={ii} duration={500} delay={50}>
                            {renderItem(tabData[tab][ii])}
                        </Fade>
                    ))}
                    {columns && (
                        <CustomTable 
                            columns={columns}
                            data={tabData[tab]}
                            filterable={columns.some(x => x.canFilter)}
                            sortBy={columns.filter(x => !!x.isSorted).map(x => ({ id: x.accessor, desc: x.isSortedDesc }))}
                            filters={columns.filter(x => !!x.getFilterValue).map(x => ({ id: x.accessor, value: x.getFilterValue(tab) }))}
                            getRowProps={getRowProps}
                        />
                )}
                </TabPanel>
            )}
        </>
    );
};

export default TabPanels;