import React from "react";
import { useParams } from "react-router-dom";

import ReportTitle from "./ReportTitle";
import ReportConfig from "./ReportConfig";
import ReportsHistoryTable from "./ReportsHistoryTable";
import ReportsExpectedTable from "./ReportsExpectedTable";

import "../_reports.scss";

const ReportPage = () => {
    let { client, report } = useParams();

    const client_id = parseInt(client ?? "0");
    const report_id = parseInt(report ?? "0");

    console.log(client, report, client_id, report_id);
    return (
        <>
            <ReportTitle client={client_id} report={report_id} />

            <div className="report-config">
                <ReportConfig client={client_id} report={report_id} />
                <ReportsHistoryTable client={client_id} report={report_id} />
                <ReportsExpectedTable client={client_id} report={report_id} />
            </div>
        </>
    );
};
export default ReportPage;

