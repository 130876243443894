import React, { SyntheticEvent } from "react";

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ITabStatuses } from "../types/ITabStatuses";
import GetStatusIcon from "../../../helpers/GetStatusIcon";


interface ITabHeadersProps {
    tabs: string[],
    tabStatuses: ITabStatuses,
    value: number,
    handleChange: (event: SyntheticEvent, newValue: number) => void
}

const TabHeaders = ({ tabs, tabStatuses, value, handleChange }: ITabHeadersProps) => {
    if (tabs.length <= 1) return null;
    return (
        <Box className={'tabs-header'}>
            <Tabs value={value} onChange={handleChange} >
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab} icon={GetStatusIcon(tabStatuses[tab])} iconPosition={'end'} />
                ))}
            </Tabs>
        </Box >
    )
};

export default TabHeaders;