import React from 'react';
import { Link } from 'react-router-dom';

import GetStatusIcon from 'core/helpers/GetStatusIcon';
import { FeedNames } from 'core/data/feeds/feed_names';
import FormatDate from 'core/helpers/datetime/FormatDate';

import { IFeed } from '../types/IFeed';

interface IFeedsListItemProps {
    item: IFeed
}

const FeedsListItem = ({ item }: IFeedsListItemProps) => {
    return (
        <div className={`listitem issue-${item.issue}`}>
            <Link to={`/feeds/${item.client_id}/${item.feed_id}`}>

                <div className={'listitem__header'}>
                    <div className={'listitem__toggle-details'}>
                        <div className={'listitem__toggle-details__container'}>
                            {GetStatusIcon(item.issue)}
                        </div>
                    </div>

                    <div className={'listitem__title'}>
                        {FeedNames.get(item.feed_name) ?? item.feed_name}
                    </div>

                    <div className={`listitem__value`}>
                        Received: {item.last_date === 0 ? 'Never' : FormatDate(item.last_date)}
                    </div>

                    <div className={`listitem__value issue-${item.issue}`}>
                        Record Count: {item.record_count}
                    </div>
                    <div className={'listitem__icon'}>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default FeedsListItem;