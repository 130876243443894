import React, { createContext, useContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";

import { ICampaignConfig } from "../types/ICampaignConfig";
import { FETCH_METRICS_CONFIG_DB } from "config";


export const CampaignsConfigContext = createContext<ICampaignsConfigContext>(null!);
export interface ICampaignsConfigContext extends IDataContext<ICampaignConfig> { }


interface ICampaignsConfigContextProvider {
    children: React.ReactNode;
}
const CampaignsConfigContextProvider = ({ children }: ICampaignsConfigContextProvider) => {
    const { clients } = useContext(UserContext);

    let params = { 
        clients: clients
    };

    return (
        <DataContextProvider<ICampaignConfig>
            context={CampaignsConfigContext}
            endpoint={FETCH_METRICS_CONFIG_DB}
            params={params}
        >
            {children}
        </DataContextProvider>
    );
};

export default CampaignsConfigContextProvider;