import React, { createContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";

import { IIssue } from "./IssuesList";

import { LIST_S3_ISSUES_LAMBDA } from "config";

export const IssuesContext = createContext<IIssuesContext>(null!);
export interface IIssuesContext extends IDataContext<IIssue> { }


interface IIssuesContextProvider {
    children: React.ReactNode;
}
const IssuesContextProvider = ({ children }: IIssuesContextProvider) => {
    return (
        <DataContextProvider<IIssue>
            context={IssuesContext}
            endpoint={LIST_S3_ISSUES_LAMBDA}
        >
            {children}
        </DataContextProvider>
    );
};

export default IssuesContextProvider;