import React, { ReactNode } from "react";

import Box from '@mui/material/Box';

interface ITabPanelProps {
    children: ReactNode;
    value: number;
    index: number;
}

const TabPanel = ({ children, value, index }: ITabPanelProps) => {
    return (
        <>
            {value === index && (
                <Box className={'tab-panel'}
                    role="tabpanel"
                    hidden={value !== index}
                >
                    {children}
                </Box>
            )}
        </>
    );
};

export default TabPanel;