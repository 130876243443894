import React from "react";
import { Link } from "react-router-dom";


const NavLink = ({active, to, label}: {active: boolean, to: string, label: string}) => (
  <li className="nav__list">
    <Link
      to={to}
      className={`nav__link ${active ? "active" : ""}`}
    >
      {label}
    </Link>
  </li>
);
export default NavLink;
