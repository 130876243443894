import React, { useContext, useCallback, useEffect, useMemo } from "react";
import { CellProps, Row } from "react-table";

import { ToggleFilter } from "core/components/status-table/CustomTable";
import DbStatusTable from "core/components/status-table/DbStatusTable";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import IssuesSubTitle from "core/components/status-table/IssuesSubTitle";
import { UserContext } from "core/data/auth/UserContextProvider";
import { DAILY_FREQ, Frequencies } from "core/data/feeds/frequencies";
import { FeedNames } from "core/data/feeds/feed_names";
import { Permission } from "core/enums/Permissions";
import FormatDate from "core/helpers/datetime/FormatDate";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";
import AdminLink from "core/helpers/auth/AdminLink";
import GetContractSupportIcon from "core/helpers/GetContractSupportIcon";
import GetStatusIcon from "core/helpers/GetStatusIcon";


import { LatestFeedsContext } from "../contexts/LatestFeedsContextProvider";
import { IFeed } from "../types/IFeed";
import FeedsListItem from "./FeedsListItem";

export const getFeedsIssue = (item: IFeed) => {
    try {
        // If we've not received it yet, lets assume it's just pending
        if (item.last_date === 0) return IssueEnum.OK;

        const freq = Frequencies[item.feed_name] ?? DAILY_FREQ;

        const now = Date.now();

        const issue = item.last_date < now - freq.alert;
        const warning = item.last_date < now - freq.warning;

        return issue ? IssueEnum.ERROR : (warning ? IssueEnum.WARNING : IssueEnum.OK);
    } catch (e) {
        console.error(e, item);
        return IssueEnum.ERROR;
    }
};
export const transformFeedsData =  (latestFeeds: IFeed[] | null) => {
    if (!latestFeeds) return null;

    return latestFeeds
        ?.filter(x => !!x.transform_timestamp)
        ?.map(x => ({
            ...x,
            name: x.feed_name,
            last_date: ParseDBDate(x.transform_timestamp) ?? 0,
            next_date: 0
        }))
        ?.map(x => ({
            ...x,
            issue: getFeedsIssue(x)
        }));
};

const FeedsListTable = ({collapsible = false}: {collapsible?: boolean}) => {
    const { items, error, setVisible } = useContext(LatestFeedsContext);
    const {permissions} = useContext(UserContext);

    useEffect(() => {
        setVisible(true);

        return () => {
            setVisible(false);
        };
    }, [setVisible]);


    const GetIssue = useCallback((item: IFeed) => getFeedsIssue(item), []);

    const data = useMemo(() => transformFeedsData(items), [items]);


    const getContractFilter = useCallback(() => {
        return -1;
    }, []);


    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ value, row }: CellProps<IFeed>) => (
                    <AdminLink
                        permission={Permission.ViewFeedDetails}
                        to={`/feeds/${row.original.client_id}/${row.original.feed_id}`}
                    >
                        <b>{FeedNames.get(value) ?? value}</b>
                    </AdminLink>
                ),
                headerStyle: { width: "30%" }
            },
            {
                Header: 'Received',
                accessor: 'last_date',
                Cell: ({ value }: CellProps<IFeed>) => FormatDate(value),
                isSorted: true,
                isSortedDesc: false,
            },
            {
                Header: 'Supported',
                accessor: 'in_contract',
                Cell: ({ value }: CellProps<IFeed>) => GetContractSupportIcon(value),
                style: { textAlign: 'center' },
                Filter: ToggleFilter,
                canFilter: true,
                filter: 'toggle',
                getFilterValue: getContractFilter
            },
            {
                Header: 'Status',
                accessor: 'issue',
                Cell: ({ value }: CellProps<IFeed>) => GetStatusIcon(value),
                style: { textAlign: 'center' },
            },
        ], []
    );

    const issues = data?.filter(x => x.issue !== IssueEnum.OK).length ?? 0;
    
    if (!permissions.includes(Permission.ViewFeeds)) {
        return null;
    }

    return (
        <>
            {items === null && <LoadingSpinner />}
            
            {items  && (
                <DbStatusTable<IFeed>
                    unique={true}
                    title={'Feeds'}
                    subtitle={IssuesSubTitle(issues)}
                    items={data}
                    error={error}
                    RenderRow={(item) => <FeedsListItem key={item.id} item={item} />}
                    GetIssue={GetIssue}
                    columns={columns}
                    collapsible={collapsible}
                    getRowProps={(row: Row<IFeed>) => ({
                            style: {
                                backgroundColor: row.original.issue === IssueEnum.ERROR 
                                    ? '#ffcccc' : 
                                    (row.original.issue === IssueEnum.WARNING ? '#ffffcc' : 'white')
                            }
                        })
                    }
                />
            )}
        </>
    );
};

export default FeedsListTable;