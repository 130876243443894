import React, { useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import Select from 'react-select';

import FormatDate from "core/helpers/datetime/FormatDate";
import { Ticket } from "../types/Ticket";
import CustomTable from "core/components/status-table/CustomTable";
import { ServiceDeskContext } from "../contexts/ServiceDeskContextProvider";
import { Priority } from "../types/Priority";
import { TicketStatus } from "../types/TicketStatus";
import { ContractPriority } from "../types/ContractPriorities";
import { UsersContext } from "core/data/auth/UsersContextProvider";
import ListTile from "core/components/tiles/ListTile";

const ServiceDeskPrioritiseTickets = () => {
    const { tickets, updateTicket, sprints, fetchSprint, currentSprint } = useContext(ServiceDeskContext);
    const { users, fetchUserName } = useContext(UsersContext);

    const sortByPrio = (a: Ticket, b: Ticket) =>
        (b.sprint ?? -1) - (a.sprint ?? -1) ||
        Object.keys(ContractPriority).indexOf(a.contract_priority) - Object.keys(ContractPriority).indexOf(b.contract_priority) ||
        Object.keys(Priority).indexOf(a.priority) - Object.keys(Priority).indexOf(b.priority) ||
        a.id - b.id;

    // Only:
    //  - Accepted tickets
    //  - Not in sprint
    //  - With scope
    let readyTickets = tickets.filter(x =>
        x.status === TicketStatus.Accepted &&
        (x.sprint === null || x.sprint === undefined || x.sprint < 0) &&
        (x.scope !== null && x.scope !== undefined && x.scope >= 0)
    );
    // Include leftover tickets from last sprint
    readyTickets = readyTickets.concat(
        tickets.filter(x =>
            (
                x.status === TicketStatus.InDevelopment ||
                x.status === TicketStatus.Paused ||
                x.status === TicketStatus.InfoRequested ||
                x.status === TicketStatus.ReviewRequested ||
                x.status === TicketStatus.FeedbackRequested
            ) && (
                x.sprint === null || x.sprint === undefined || 
                x.sprint < 0 || x.sprint < currentSprint.id
            )
        )
    );
    readyTickets.sort(sortByPrio);
    console.log(readyTickets);

    // Only:
    // - Not completed tickets
    // - Not in sprint
    // - Not with scope
    const readyIds = readyTickets.map(x => x.id);
    const unreadyTickets = tickets.filter(x =>
        !readyIds.includes(x.id) &&
        x.status !== TicketStatus.Complete &&
        x.status !== TicketStatus.Draft &&
        x.status !== TicketStatus.Submitted &&
        x.status !== TicketStatus.Cancelled &&
        x.status !== TicketStatus.Deleted &&
        x.status !== TicketStatus.Scoped &&
        (x.sprint === null || x.sprint === undefined || x.sprint < 0) &&
        (x.scope === null || x.scope === undefined || x.scope < 0)
    );
    unreadyTickets.sort(sortByPrio);

    const flagTicket = useCallback((ticket: Ticket) => {
        updateTicket({
            ...ticket,
            flagged: !ticket.flagged
        });
    }, [updateTicket]);

    const updateAssignee = useCallback((ticket: Ticket, assignee: number) => {
        updateTicket({
            ...ticket,
            assignee_id: assignee
        });
    }, [updateTicket]);

    const updateScope = useCallback((ticket: Ticket, scope: number) => {
        if (scope < 0) scope = 0;
        if (scope > 1000) scope = 1000;

        updateTicket({
            ...ticket,
            scope: scope
        });
    }, [updateTicket]);

    const updateSprint = useCallback((ticket: Ticket, sprint: number) => {
        updateTicket({
            ...ticket,
            sprint: sprint
        });
    }, [updateTicket]);

    const updatePriority = useCallback((ticket: Ticket, prio: Priority) => {
        updateTicket({
            ...ticket,
            priority: prio
        });
    }, [updateTicket]);


    const assigneeOptions = useMemo(() => {
        let opts = users.map((x: any) => ({ value: x.id, label: x.first_name }));
        opts.push({ value: -1, label: 'Unassigned' });
        return opts;
    }, [users]);

    const GetAssignee = useMemo(() => {
        return (id: number) => ({ value: id, label: id === -1 ? "Unassigned" : fetchUserName(id) })
    }, [fetchUserName]);


    const sprintOptions = useMemo(() => {
        let opts = sprints.map(x => ({ value: x.id, label: x.name }));
        opts.push({ value: -1, label: 'No Sprint' });
        return opts;
    }, [sprints]);

    const GetSprint = useMemo(() => {
        return (id: number) => ({ value: id, label: (id === undefined || id === null || id === -1) ? "No Sprint" : fetchSprint(id)?.name ?? "Missing Sprint" })
    }, [fetchSprint]);

    
    const priorityOptions = useMemo(() => {
        let opts = Object.values(Priority)
            .map(x => ({ value: x, label: x }));
        return opts;
    }, [sprints]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }: CellProps<Ticket>) => {
                    return (
                        <Link to={`/service-desk/${row.original.id}`}>
                            {row.original.name}
                        </Link>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Priority',
                accessor: 'priority',
                Cell: ({ row, value }: CellProps<Ticket>) => {
                    return (
                        <Select
                            options={priorityOptions}
                            defaultValue={{ value: row.original.priority, label: row.original.priority }}
                            onChange={(x) => updatePriority(row.original, x?.value ?? row.original.priority)}
                        />
                    )
                },
            },
            {
                Header: 'Deadline',
                accessor: 'deadline',
                Cell: ({ value }: { value: Date }) => FormatDate(value),
            },
            {
                Header: 'Contract',
                accessor: 'contract_priority',
            },
            {
                Header: 'Scope',
                accessor: 'scope',
                Cell: ({ row, value }: CellProps<Ticket>) => (
                    <input type={'number'}
                        defaultValue={value ?? -1}
                        min={0}
                        max={1000}
                        onBlur={(x) => updateScope(row.original, parseInt(x?.target.value) ?? -1)}
                    />
                )
            },
            {
                Header: 'Sprint',
                accessor: 'sprint',
                Cell: ({ row, value }: CellProps<Ticket>) => {
                    return (
                        <>
                            <Select
                                options={sprintOptions}
                                defaultValue={GetSprint(value)}
                                onChange={(x) => updateSprint(row.original, x?.value ?? -1)}
                            />
                        </>
                    );
                }
            },
            {
                Header: 'Assignee',
                accessor: 'assignee_id',
                Cell: ({ row, value }: CellProps<Ticket>) => {
                    return (
                        <>
                            <Select
                                options={assigneeOptions}
                                defaultValue={GetAssignee(value)}
                                onChange={(x) => updateAssignee(row.original, x?.value ?? -1)}
                            />
                        </>
                    );
                }
            }
        ], [flagTicket, updateAssignee, GetAssignee, GetSprint, assigneeOptions, sprintOptions, updateScope, updateSprint]
    )


    return (
        <div className="service-desk__tickets-list">
            <ListTile>
                <h1 className={`list__title`}>
                    Ready for Sprint
                </h1>
                <CustomTable columns={columns} data={readyTickets} />
            </ListTile>
            <ListTile>
                <h1 className={`list__title`}>
                    Not Ready for Sprint
                </h1>
                <CustomTable columns={columns} data={unreadyTickets} />
            </ListTile>
        </div >
    );
};
export default ServiceDeskPrioritiseTickets;