import React, { useEffect, useMemo, useState } from "react";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    Row,
    HeaderProps,
    useSortBy,
    TableInstance,
    FilterValue,
    usePagination,
} from "react-table";
import { matchSorter } from 'match-sorter';
import Select from 'react-select';


import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import "./_custom-table.scss"

interface ITableProps<T extends object> {
    columns: any;
    data: T[];
    filterable?: boolean;
    globalFilter?: boolean;
    pageSize?: number;
    sortBy?: any[];
    filters?: any[];
    getRowProps?: (row: Row<T>) => any;
}
const CustomTable = <T extends object>({ columns, data, filterable = false, globalFilter = false, pageSize = 100, sortBy = [], filters = [], getRowProps = () => {} }: ITableProps<T>) => {
    const textFilter = <T extends object>(rows: Row<T>[], columnIds: string[], filterValue: FilterValue) => {
        return rows.filter(row => {
            const matches = columnIds.map(id => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            });
            return matches.some(x => !!x);
        })
    }
    const multiSelectFilter = <T extends object>(rows: Row<T>[], columnIds: string[], filterValue: FilterValue) => {
        return rows.filter(row => (
            columnIds
                .map(id => filterValue?.includes(row.values[id]) ?? true)
                .some(x => !!x)
        ))
    }
    const toggleFilter = <T extends object>(rows: Row<T>[], columnIds: string[], filterValue: FilterValue) => {
        return rows.filter(row => (
            columnIds
                .map(id => filterValue === -1 || row.values[id] === filterValue)
                .some(x => !!x)
        ))
    }

    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            text: textFilter,
            includes: multiSelectFilter,
            toggle: toggleFilter,
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            Filter: NullColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        page,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            globalFilter: fuzzyTextFilterFn,
            initialState: {
                sortBy: sortBy ?? [],
                filters: filters ?? [],
                pageIndex: 0,
                pageSize,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // const firstPageRows = rows.slice(0, pageSize);

    return (
        <>
            <Table {...getTableProps()}>
                <TableHead>
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <TableCell {...column.getHeaderProps([
                                    { style: column.style },
                                    { style: column.headerStyle },
                                    { style: { 
                                        verticalAlign: "top",
                                        overflowWrap: "anywhere"
                                    } }
                                ])}>
                                    <div {...column.getHeaderProps([
                                        column.getSortByToggleProps()
                                    ])}>
                                        <b>{column.render("Header")}</b>

                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <ArrowDropDownIcon />
                                                : <ArrowDropUpIcon />
                                            : ''}
                                    </div>
                                    <div>{(column.canFilter && filterable) ? column.render('Filter') : null}</div>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                    {filterable && globalFilter && (
                        <TableRow>
                            <TableCell
                                colSpan={visibleColumns.length}
                                style={{
                                    textAlign: 'left',
                                    overflowWrap: "anywhere"
                                }}
                            >
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow {...row.getRowProps(getRowProps(row))}>
                                {row.cells.map((cell) => {
                                    return (
                                        <TableCell {...cell.getCellProps([
                                            { style: cell.column.style },
                                            { style: {
                                                overflowWrap: "anywhere"
                                            } }
                                        ])}>
                                            {cell.render("Cell")}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <br />
            <div>Showing the first {Math.min(state.pageSize, page.length)} results of {data.length} rows</div>

            <div className="pagination" style={{justifyContent: "center"}}>
                {pageOptions.length > 1 && (
                    <div style={{ marginRight: "1rem", alignSelf: "center" }}>
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </button>{' '}
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </button>
                    </div>
                )}
                <div style={{ marginRight: "1rem", alignSelf: "center" }}>
                    <span>Page{' '}</span>
                    <strong>
                        {state.pageIndex + 1} of {pageOptions.length}
                    </strong>
                </div>
                {pageOptions.length > 1 && (
                    <div>
                        | 
                        <span style={{marginLeft: "1rem"}}>Go to page:</span>
                        <input
                            type="number"
                            defaultValue={state.pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px',  marginLeft: "1rem" }}
                        />
                    </div>
                )}
                <select
                    style={{marginLeft: "1rem"}}
                    value={state.pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {Array.from(new Set([20, 50, 100, 1e6, state.pageSize])).sort((a, b) => a - b).map(pSize => (
                        <option key={pSize} value={pSize}>
                            Show {pSize === 1e6 ? 'All' : pSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};
export default CustomTable;

interface IGlobalFilterProps<T extends object> {
    preGlobalFilteredRows: Row<T>[];
    globalFilter: any;
    setGlobalFilter: (filterValue: FilterValue) => void;
}
// https://react-table-v7.tanstack.com/docs/examples/filtering
function GlobalFilter<T extends object>({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}: IGlobalFilterProps<T>) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    console.log('global filter', value)

    return (
        <span style={{ display: 'flex' }}>
            Search:{" "}
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Search ${count} records...`}
                style={{
                    border: "0",
                    padding: '0 1rem',
                    margin: '0'
                }}
            />
        </span>
    );
}

// Define a default UI for filtering
function NullColumnFilter<T extends object>({
    column: { filterValue, preFilteredRows, setFilter },
}: HeaderProps<T>) {
    return null;
}

// Define a default UI for filtering
function DefaultColumnFilter<T extends object>({
    column: { filterValue, preFilteredRows, setFilter },
}: HeaderProps<T>) {
    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}

// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = <T extends object>({
    column: { filterValue, setFilter, preFilteredRows, id },
}: HeaderProps<T>) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
        const options = new Set<string>();
        preFilteredRows.forEach((row: any) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <div style={{ width: '80%', marginLeft: "20%" }}>
            <Select
                options={options.map(x => ({ value: x, label: x }))}
                isMulti={true}
                onChange={(e) => {
                    console.log(e);
                    setFilter(e.map(x => x.value).join(',') || undefined);
                }}
                defaultValue={filterValue?.split(',')?.map((x: string) => ({ value: x, label: x }))}
            />
        </div>
    );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter<T extends object>({
    column: { filterValue, setFilter, preFilteredRows, id },
}: HeaderProps<T>) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={(e) => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter<T extends object>({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}: HeaderProps<T>) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <input
                value={filterValue[0] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: "70px",
                    marginRight: "0.5rem",
                }}
            />
            to
            <input
                value={filterValue[1] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: "70px",
                    marginLeft: "0.5rem",
                }}
            />
        </div>
    );
}

function fuzzyTextFilterFn(rows: Row<any>[], columnIds: string[], filterValue: string) {
    console.log('fuzzy', columnIds, filterValue)

    const terms = filterValue.split(' ').filter(x => x);
    return terms.reduceRight((acc, term) => matchSorter(acc, term, { keys: [row => columnIds.map(id => row.values[id])] }), rows);
    // return matchSorter(rows, filterValue, { keys: [row => columnIds.map(id => row.values[id])] })
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: string) => !val


// Define a custom filter filter function!
function filterGreaterThan(rows: Row<any>[], columnIds: string[], filterValue: number) {
    return rows.filter(row => {
        const matches = columnIds.map(id => {
            const rowValue = row.values[id]
            return rowValue >= filterValue;
        });
        return matches.some(x => !!x);
    })
}
// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number'


export const ToggleFilter = <T extends object>({
    column: { filterValue, setFilter, preFilteredRows, id },
}: HeaderProps<T>) => {
    const selectYes = () => {
        setFilter(1);
    }
    const selectNo = () => {
        setFilter(0);
    }
    const selectAll = () => {
        setFilter(-1);
    }
    const ToggleInput = ({ value, onChange, checked, label }: { value: string, onChange: (e: any) => void, checked: boolean, label: string }) => {
        return (
            <label className={checked ? 'selected' : ''}>
                <input onChange={onChange} defaultChecked={checked} type="radio" name="toggle" value={value} />
                {label}
            </label>
        )
    }

    return (
        <div>
            <div className="tw-toggle">
                <ToggleInput value={"1"} onChange={selectYes} checked={filterValue == 1} label={"Y"} />
                <ToggleInput value={"-1"} onChange={selectAll} checked={filterValue == -1} label={"-"} />
                <ToggleInput value={"0"} onChange={selectNo} checked={filterValue == 0} label={"N"} />
                <span className={filterValue === 1 ? 'yes' : (filterValue === 0 ? 'no' : 'null')}></span>
            </div>
        </div>
    );
}