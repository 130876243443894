import React from "react";

import { StatusEnum } from "core/enums/StatusEnum";

import { IEvent } from "../types/IEvent";


const EventAgenda = ({ event }: { event: IEvent }) => {
  let received = event.meta?.status === StatusEnum.Received;

  return (
    <span>
      {received && <span className="received">{event.title}</span>}
      {!received && <em className="expected">{event.title}</em>}
    </span>
  )
}
export default EventAgenda;