export enum EmailType {
    None = "None",
    Default = "Default",
}

export enum MetricType {
    None = "None",
    Default = "Default",
}

export enum TargetingType {
    None = "None",
    Default = "Default",
}