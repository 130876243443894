import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import Box from "@mui/material/Box";

import CustomTable from "core/components/status-table/CustomTable";
import { UserContext } from "core/data/auth/UserContextProvider";
import { Role } from "core/enums/Role";
import { Permission } from "core/enums/Permissions";
import FormatDay from "core/helpers/datetime/FormatDay";
import FormatDate from "core/helpers/datetime/FormatDate";

import { ServiceDeskContext } from "../contexts/ServiceDeskContextProvider";
import { Ticket } from "../types/Ticket";
import { TicketStatus } from "../types/TicketStatus";
import ListTile from "core/components/tiles/ListTile";

const ServiceDeskDashboard = () => {
    const { tickets, sprints, currentSprint } = useContext(ServiceDeskContext);
    const { permissions, user, userGroups } = useContext(UserContext);

    const currentSprintTickets = useMemo(() => {
        return tickets.filter((ticket) => ticket.sprint === currentSprint.id);
    }, [tickets, currentSprint.id]);

    const assignedTickets = useMemo(() => {
        if (!permissions.includes(Permission.StartWork)) return [];

        const dkAssignedStatuses = [TicketStatus.Scheduled, TicketStatus.InDevelopment, TicketStatus.ReviewRequested];
        return currentSprintTickets.filter(
            (ticket) => dkAssignedStatuses.includes(ticket.status) && ticket.assignee_id === user.id
        );
    }, [user, currentSprintTickets, permissions]);

    const awaitingTickets = useMemo(() => {
        let t: Ticket[] = [];

        const adminAwaitingStatuses = [TicketStatus.Submitted];

        const clientAwaitingStatuses = [
            TicketStatus.Draft,
            TicketStatus.Scoped,
            TicketStatus.InfoRequested,
            TicketStatus.FeedbackRequested,
        ];

        const dkAwaitingStatuses = [TicketStatus.Scheduled, TicketStatus.ReviewRequested];

        console.log("Awaiting", userGroups);

        if (userGroups.some(x => x.permissions_group.name === Role.Admin)) {
            t = t.concat(tickets.filter((ticket) => adminAwaitingStatuses.includes(ticket.status)));
        }
        if (userGroups.some(x => x.permissions_group.name === Role.Client)) {
            t = t.concat(tickets.filter((ticket) => clientAwaitingStatuses.includes(ticket.status)));
        }
        if (userGroups.some(x => x.permissions_group.name === Role.DK)) {
            t = t.concat(currentSprintTickets.filter((ticket) => dkAwaitingStatuses.includes(ticket.status)));
        }
        return t;
    }, [tickets, currentSprintTickets, userGroups]);

    const draftTickets = useMemo(() => {
        if (!userGroups.some(x => x.permissions_group.name === Role.Client)) return [];

        return tickets.filter((ticket) => ticket.status === TicketStatus.Draft);
    }, [tickets, userGroups]);

    const nextSprint = useMemo(() => {
        return sprints.find((x) => x.start_date.getTime() === currentSprint.end_date.getTime());
    }, [sprints, currentSprint]);

    const nextSprintTickets = useMemo(() => {
        if (!nextSprint) return [];
        return tickets.filter((x) => x.sprint === nextSprint.id);
    }, [nextSprint, tickets]);

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ row }: CellProps<Ticket>) => {
                    return <Link to={`/service-desk/${row.original.id}`}>{row.original.name}</Link>;
                },
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "Priority",
                accessor: "priority",
            },
            {
                Header: "Created",
                accessor: "created",
                Cell: ({ value }: { value: Date }) => FormatDate(value),
            },

            {
                Header: "Updated",
                accessor: "updated",
                Cell: ({ value }: { value: Date }) => FormatDate(value),
            },
            {
                Header: "Type",
                accessor: "type",
            },
        ],
        []
    );

    const seeCurrentSprintTickets =
        userGroups.some(x => x.permissions_group.name === Role.Admin) || userGroups.some(x => x.permissions_group.name === Role.Client);

    return (
        <div className="service-desk__dashboard">
            <ListTile>
                <h1 className={`list__title`}>{"Current Sprint"}</h1>

                <Box className={"tabs-container"}>
                    {currentSprint && (
                        <>
                            <h6 style={{width: "100%", textAlign:"center", marginBottom:"1.5rem"}}>
                                {FormatDay(currentSprint.start_date)} - {FormatDay(currentSprint.end_date)}
                            </h6>
                            {seeCurrentSprintTickets && <CustomTable columns={columns} data={currentSprintTickets} />}
                        </>
                    )}
                </Box>
            </ListTile>

            {permissions.includes(Permission.StartWork) && (
                <ListTile>
                    <h1 className={`list__title`}>{"Assigned tickets"}</h1>

                    <Box className={"tabs-container"}>
                        {(!assignedTickets || assignedTickets.length === 0) && <p>No tickets assigned to you</p>}
                        {assignedTickets && assignedTickets.length > 0 && (
                            <>
                                <p>Tickets assigned to you</p>
                                <CustomTable columns={columns} data={assignedTickets} />
                            </>
                        )}
                    </Box>
                </ListTile>
            )}

            {awaitingTickets && awaitingTickets.length > 0 && (
                <ListTile>
                    <h1 className={`list__title`}>{"Awaiting tickets"}</h1>

                    <Box className={"tabs-container"}>
                        <p>Tickets awaiting your input</p>
                        <CustomTable columns={columns} data={awaitingTickets} />
                    </Box>
                </ListTile>
            )}

            {userGroups.some(x => x.permissions_group.name === Role.Admin) && nextSprint && (
                <ListTile>
                    <h1 className={`list__title`}>{"Next Sprint"}</h1>

                    <Box className={"tabs-container"}>
                        <>
                            <p>
                                {FormatDay(nextSprint.start_date)} - {FormatDay(nextSprint.end_date)}
                            </p>
                            <CustomTable columns={columns} data={nextSprintTickets} />
                        </>
                    </Box>
                </ListTile>
            )}

            {draftTickets && draftTickets.length > 0 && (
                <ListTile>
                    <h1 className={`list__title`}>{"Draft tickets"}</h1>

                    <Box className={"tabs-container"}>
                        {draftTickets.length === 0 && <p>No drafts</p>}
                        {draftTickets.length > 0 && (
                            <>
                                <CustomTable columns={columns} data={draftTickets} />
                            </>
                        )}
                    </Box>
                </ListTile>
            )}
        </div>
    );
};
export default ServiceDeskDashboard;
