import React, { useState, SyntheticEvent, ReactNode } from "react";
import { Row } from "react-table";

import TabHeaders from "./components/TabHeaders";
import TabPanels from "./components/TabPanels";

import { ITabStatuses } from "./types/ITabStatuses";
import { ITabDataDict } from "./types/ITabDataDict";
import { IRenderItem } from "./types/IRenderItem";

import { IStatusItemBase } from "../status-table/StatusTable";
import { IStatusItemBaseDb } from "../status-table/DbStatusTable";

import "./styles/_tabs.scss"


interface ITabsProps<T extends object> {
    tabs: string[],
    tabStatuses: ITabStatuses,
    tabData: ITabDataDict<T>,
    renderItem: IRenderItem<T>;
    startTab?: number;
    header?: (tab: string) => ReactNode;
    columns?: any[];
    getRowProps?: (row: Row<T>) => any;
}

const Tabs = <T extends IStatusItemBase | IStatusItemBaseDb>({ tabs, tabStatuses, tabData, renderItem, startTab = 0, header, columns, getRowProps = () => {} }: ITabsProps<T>) => {
    const [value, setValue] = useState(startTab);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <TabHeaders 
                tabs={tabs}
                tabStatuses={tabStatuses}
                value={value}
                handleChange={handleChange}
            />
            <TabPanels 
                tabs={tabs}
                tabData={tabData}
                value={value}
                renderItem={renderItem}
                header={header}
                columns={columns}
                getRowProps={getRowProps}
            />
        </>
    );
}

export default Tabs;