import { SubPages } from "core/components/navbar/enums/SubPages";
import Debug from "core/helpers/Debug";
import { Permission } from "core/enums/Permissions";


const GetViewablePages = (permissions: Permission[], possiblePages: SubPages[]): SubPages[] => {
    const viewablePages = [];
    Debug("GetViewablePages", permissions, possiblePages);

    for (const page of possiblePages) {
        switch (page) {
            case SubPages.ListTickets:
                if (permissions.includes(Permission.ListTickets)) viewablePages.push(page);
                break;
            case SubPages.NewTicket:
                if (permissions.includes(Permission.CreateTicket)) viewablePages.push(page);
                break;
            case SubPages.PrioritiseTickets:
                if (permissions.includes(Permission.PrioritiseTickets)) viewablePages.push(page);
                break;
            case SubPages.SprintPlan:
                if (permissions.includes(Permission.AllocateTickets)) viewablePages.push(page);
                break;
            case SubPages.AllocatedWork:
                if (permissions.includes(Permission.StartWork)) viewablePages.push(page);
                break;
            case SubPages.ServiceDeskDashboard:
                if (permissions.includes(Permission.ListTickets)) viewablePages.push(page);
                break;
        }
    }

    Debug("GetViewablePages", viewablePages);
    return viewablePages;
};

export default GetViewablePages;