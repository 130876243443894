import React from "react";
import { useParams } from "react-router-dom";

import MetricTitle from "./MetricTitle";
import MetricConfig from "./MetricConfig";
import MetricHistoryTable from "./MetricHistoryTable";

import "../_metrics.scss";

const MetricPage = () => {
    let { client, metric } = useParams();

    const client_id = parseInt(client ?? "0");
    const metric_id = parseInt(metric ?? "0");

    console.log(client, metric, client_id, metric_id);

    return (
        <>
            <MetricTitle client={client_id} metric={metric_id} />

            <div className="metric-config">
                <MetricConfig client={client_id} metric={metric_id} />
                <MetricHistoryTable client={client_id} metric={metric_id} />
            </div>
        </>
    );
};
export default MetricPage;