import React, { useCallback, useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Loading from "core/components/loading/Loading";

import { Frequencies, TEN_MIN_FREQ } from "core/data/feeds/frequencies";

import Tabs from "core/components/tabs/Tabs";
import { ClientNames } from "core/data/clients/client_names";
import { IssueEnum } from "../tabs/enums/IssueEnum";
import { ITabStatuses } from "../tabs/types/ITabStatuses";
import { ITabDataDict } from "../tabs/types/ITabDataDict";
import ListTile from "../tiles/ListTile";


export interface IStatusItemBase {
    Key: string,
    LastModified: string,
    Size: number,
    issue: IssueEnum,
    name: string
}

interface IStatusTableProps<T> {
    title: string,
    unique?: boolean,
    clientKeyIndex?: number,
    RenderRow: (item: T) => JSX.Element
    GetIssue: (item: T) => IssueEnum
    items?: T[] | null,
    error: string
}

const StatusTable = <T extends IStatusItemBase>({ title, unique = true, clientKeyIndex = 0, RenderRow, GetIssue, items = null, error = '' }: IStatusTableProps<T>) => {
    const [clients, setClients] = useState<string[]>([]);
    const [clientIssues, setClientIssues] = useState<ITabStatuses>({});
    const [itemsByClient, setItemsByClient] = useState<ITabDataDict<T>>({});
    const [loading, setLoading] = useState(true);

    const GetIssueCB = useCallback(GetIssue, [GetIssue]);
    const SortByIssueAndName = useCallback((a: T, b: T) => {
        if (a.issue === b.issue) {
            let freqA = Frequencies[a.name] ?? TEN_MIN_FREQ;
            let freqB = Frequencies[b.name] ?? TEN_MIN_FREQ;

            let expA = new Date(a.LastModified).getTime() - freqA.expected;
            let expB = new Date(b.LastModified).getTime() - freqB.expected;

            if (expA === expB) {
                return a.Key.localeCompare(b.Key);
            } else {
                return expB - expA;
            }
        }
        return Object.keys(IssueEnum).indexOf(b.issue) - Object.keys(IssueEnum).indexOf(a.issue);
    }, []);

    useEffect(() => {
        if (items === null) return;

        // Create a list of clients from the items returned by the api (de-duped)
        const c: string[] = items.map(o => o.Key.split("/")[clientKeyIndex]).filter((v, i, a) => a.indexOf(v) === i).map(c => ClientNames.get(c) ?? c);
        const ci: ITabStatuses = c.reduce((acc, client) => ({ ...acc, [client]: IssueEnum.OK }), {});
        // Group the items by client - optionally de-dupe the items
        const i = items.reduce((acc, obj) => {
            const params = obj.Key.split("/");
            const client = ClientNames.get(params[clientKeyIndex]) ?? params[clientKeyIndex];

            const issue = GetIssueCB(obj);
            obj.issue = issue;
            if (ci[client] !== IssueEnum.ERROR && issue === IssueEnum.ERROR) {
                ci[client] = IssueEnum.ERROR;
            } else if (ci[client] === IssueEnum.OK && issue === IssueEnum.WARNING) {
                ci[client] = IssueEnum.WARNING;
            }

            if (!acc[client]) {
                acc[client] = [];
            }
            // Append and then potentially de-dupe the feeds
            if (unique) {
                acc[client] = [obj, ...acc[client]]
                    .filter((v, i, a) => a.findIndex(t => (t.Key === v.Key)) === i);
            } else {
                acc[client].push(obj);
            }

            // Show the errors at the top
            acc[client].sort(SortByIssueAndName);
            return acc;
        }, {} as ITabDataDict<T>)

        setClients(c);
        setClientIssues(ci);
        setItemsByClient(i);
        setLoading(false);
    }, [items, GetIssueCB, unique, SortByIssueAndName, clientKeyIndex]);

    if (loading || items === null) {
        return <Loading />;
    }

    // Display the items by client
    return (
        <ListTile>
            <h1 className={`list__title`}>{title}</h1>

            <Box className={'tabs-container'}>
                {error &&
                    <p>{error}</p>
                }
                {items.length === 0 &&
                    <p>No items found</p>
                }
                {items.length > 0 &&
                    <>
                        <Tabs<T>
                            tabs={clients}
                            tabStatuses={clientIssues}
                            tabData={itemsByClient}
                            renderItem={RenderRow}
                        />
                    </>
                }
            </Box>
        </ListTile>
    );
};

export default StatusTable;