import React, { useContext, useCallback, useEffect, useMemo } from "react";
import { CellProps } from "react-table";

import DbStatusTable from "core/components/status-table/DbStatusTable";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import { DAILY_FREQ, Frequencies } from "core/data/feeds/frequencies";
import { FeedNames } from "core/data/feeds/feed_names";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";
import FormatDay from "core/helpers/datetime/FormatDay";
import AdminLink from "core/helpers/auth/AdminLink";

import { Permission } from "core/enums/Permissions";

import { LatestMetricsContext } from "../contexts/LatestMetricsContextProvider";
import { IMetric } from "../types/IMetric";
import MetricsListItem from "./MetricsListItem";
import { UserContext } from "core/data/auth/UserContextProvider";
import IssuesSubTitle from "core/components/status-table/IssuesSubTitle";


export const getMetricsIssue = (item: IMetric) => {
    try {
        // For now, just do this
        return IssueEnum.OK;

        // If we've not received it yet, lets assume it's just pending
        if (item.last_date === 0) return IssueEnum.OK;

        const freq = Frequencies[item.metric_name] ?? DAILY_FREQ;

        const now = Date.now();
        const date = item.last_date; // YYYY-MM-DDTHH:MM:SS.SSSZ

        const issue = date < now - freq.alert;
        const warning = date < now - freq.warning;

        return issue ? IssueEnum.ERROR : (warning ? IssueEnum.WARNING : IssueEnum.OK);
    } catch (e) {
        console.error(e, item);
        return IssueEnum.ERROR;
    }
};
export const transformMetricsData = (latestMetrics: IMetric[] | null) => {
    if (!latestMetrics) return null;

    return latestMetrics
    ?.filter(x => !!x.metric_date)
    ?.map(x => ({
        ...x,
        id: x.metric_id,
        name: x.metric_name,
        last_date: ParseDBDate(x.metric_date) ?? 0,
        next_date: 0
    }))
    ?.map(x => ({
        ...x,
        issue: getMetricsIssue(x)
    }));
};

const MetricsListTable = ({collapsible = false}: {collapsible?: boolean}) => {
    const { items, error, setVisible } = useContext(LatestMetricsContext);
    const {permissions} = useContext(UserContext);
    
    useEffect(() => {
        setVisible(true);

        return () => {
            setVisible(false);
        };
    }, [setVisible]);

    const GetIssue = useCallback((item: IMetric) => getMetricsIssue(item), []);

    const data = useMemo(() => transformMetricsData(items), [items]);
    
    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ value, row }: CellProps<IMetric>) => (
                    <AdminLink
                        permission={Permission.ViewMetricDetails}
                        to={`/metrics/${row.original.client_id}/${row.original.id}`}
                    >
                        <b>{FeedNames.get(value) ?? value}</b>
                    </AdminLink>
                ),
                headerStyle: { width: "40%" }
            },
            {
                Header: 'Received',
                accessor: 'last_date',
                Cell: ({ value }: CellProps<IMetric>) => FormatDay(value),
                style: { textAlign: 'right' },
            }
        ], []
    );

    if (!permissions.includes(Permission.ViewMetrics)) {
        return null;
    }
    return (
        <>
            {items === null && <LoadingSpinner />}

            {items && (
                <DbStatusTable<IMetric>
                    unique={true}
                    title={'Metrics'}
                    items={data}
                    error={error}
                    RenderRow={(item) => <MetricsListItem key={item.id} item={item} />}
                    GetIssue={GetIssue}
                    columns={columns}
                    collapsible={collapsible}
                />
            )}
        </>
    );
};

export default MetricsListTable;