export interface IContractPriority {
    id: number;
    contract_id: number;
    priority_name: string;
    priority_value: number;
    priority_description: string;
    response_time_in_hours: number;
    resolution_time_in_hours: number;
}
export enum ContractPriority {
    HighReporting = "High - Reporting",
    HighDataLoads = "High - Data Loads",
    Medium = "Medium",
    Low = "Low",
}