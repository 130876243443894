import React, { ReactNode, createContext, useContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";

import { IReportExpected } from "../types/IReportExpected";
import { FETCH_REPORTS_EXPECTED_DB } from "config";

export const ReportsExpectedContext = createContext<IReportsExpectedContext>(null!);
export interface IReportsExpectedContext extends IDataContext<IReportExpected> { }


interface IReportsExpectedContextProvider {
    children: ReactNode;
}
const ReportsExpectedContextProvider = ({ children }: IReportsExpectedContextProvider) => {
    const { clients } = useContext(UserContext);

    let params = { 
        clients: clients
    };
    return (
        <DataContextProvider<IReportExpected>
            context={ReportsExpectedContext}
            endpoint={FETCH_REPORTS_EXPECTED_DB}
            params={params}
        >
            {children}
        </DataContextProvider>
    );
};

export default ReportsExpectedContextProvider;