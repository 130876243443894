import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { ClientNames } from "core/data/clients/client_names";
import { FeedNames } from "core/data/feeds/feed_names";
import { ClientIds } from "core/data/clients/client_ids";

import { LatestFeedsContext } from "../contexts/LatestFeedsContextProvider";

import "../_feeds.scss";


const FeedTitle = ({ client, feed }: { client: number; feed: number }) => {
    const { items: latestFeeds, setVisible: setLatestVisible } = useContext(LatestFeedsContext);

    useEffect(() => {
        setLatestVisible(true);

        return () => {
            setLatestVisible(false);
        };
    }, [setLatestVisible]);


    const latestFeed = latestFeeds?.find((x) => x.client_id === client && x.feed_id === feed);

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
            <Button style={{ marginRight: "auto", alignSelf: "center" }} color="secondary" onClick={goBack}>
                Back
            </Button>
            <h1 style={{ marginRight: "2rem", marginBottom: "0" }}>{FeedNames.get(latestFeed?.feed_name ?? "") ?? latestFeed?.feed_name ?? ""}</h1>
            <h3 style={{ marginRight: "auto", marginBottom: ".2rem" }}>{ClientNames.get(ClientIds.get(client) ?? "0") ?? ""}</h3>
        </div>
    );
};

export default FeedTitle;