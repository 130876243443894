import React, { ReactNode, useContext } from "react";

import { UserContext } from "core/data/auth/UserContextProvider";

import { Permission } from "core/enums/Permissions";

import Debug from "../Debug";

interface IPermissionsCheckComponentProps {
    children: ReactNode;
    permission: Permission;
}

const PermissionsCheckComponent = ({ children, permission }: IPermissionsCheckComponentProps): JSX.Element => {
    const { user, permissions } = useContext(UserContext);

    Debug("PermissionsCheckComponent", user, permission, permissions);

    return (
        <>
            {permissions.includes(permission) && children}
        </>
    );
};

export default PermissionsCheckComponent;