import React, { useContext, useEffect } from "react";

import SquareTile from "core/components/tiles/SquareTile";
import WideTile from "core/components/tiles/WideTile";
import HalfSizeTileContainer from "core/components/tiles/HalfSizeTileContainer";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import { DAILY_FREQ, MONTHLY_FREQ, QUARTERLY_FREQ, WEEKLY_FREQ } from "core/data/feeds/frequencies";
import { ReportFrequency } from "core/enums/ReportFrequency";

import Countdown from "core/helpers/Countdown";
import FormatTime from "core/helpers/datetime/FormatTime";
import FormatDay from "core/helpers/datetime/FormatDay";
import Log from "core/helpers/Log";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";
import Cardinalise from "core/helpers/Cardinalise";
import DaysOfWeek from "core/helpers/DaysOfWeek";
import GetDaysFromConfig from "core/helpers/GetDaysFromConfig";

import { ReportsExpectedContext } from "../contexts/ReportsExpectedContextProvider";
import { LatestReportsContext } from "../contexts/LatestReportsContextProvider";
import { ReportsConfigContext } from "../contexts/ReportsConfigContextProvider";
import { ReportsHistoryContext } from "../contexts/ReportsHistoryContextProvider";

import "../_reports.scss";

const ReportConfig = ({ client, report }: { client: number; report: number }) => {
    const { items: reportsConfig, setVisible: setConfigVisible } = useContext(ReportsConfigContext);
    const { items: latestReports, setVisible: setLatestVisible } = useContext(LatestReportsContext);
    const { items: reportsHistory, setVisible: setHistoryVisible } = useContext(ReportsHistoryContext);
    const { items: reportsExpected, setVisible: setExpectedVisible } = useContext(ReportsExpectedContext);

    useEffect(() => {
        setHistoryVisible(true);
        setConfigVisible(true);
        setLatestVisible(true);
        setExpectedVisible(true);

        return () => {
            setHistoryVisible(false);
            setConfigVisible(false);
            setLatestVisible(false);
            setExpectedVisible(false);
        };
    }, [setHistoryVisible, setConfigVisible, setLatestVisible, setExpectedVisible]);

    const latestReport = latestReports
        ?.filter((x) => x.client_id === client && x.report_id === report)
        ?.map((x) => ({
            ...x,
            id: x.report_id,
            name: x.report_name,
            last_date: ParseDBDate(x.last_report_datetime),
            next_date: ParseDBDate(x.next_report_datetime),
            issue: IssueEnum.OK,
        }))[0];
    Log("latestReport", latestReport);

    const reportConfig = reportsConfig
        ?.filter((x) => x.client_id === client && x.report_id === report)
        ?.map((x) => ({
            ...x,
            name: x.report_name,
            last_date: 0,
            next_date: 0,
            issue: IssueEnum.OK,
        }))[0];
    Log("reportConfig", reportConfig);

    const reportHistory = reportsHistory
        ?.filter((x) => x.client_id === client && x.report_id === report && x.report_timestamp !== null)
        ?.map((x) => ({
            ...x,
            name: x.report_name,
            last_date: ParseDBDate(x.report_timestamp),
            next_date: 0,
            issue: IssueEnum.OK,
        }))
        ?.sort((a, b) => b.last_date - a.last_date)
        ?.slice(0, 30);
    Log("History", reportHistory);

    let reportExpected = reportsExpected
        ?.filter((x) => x.client_id === client && x.report_id === report && x.next_report_datetime !== null)
        ?.map((x) => ({
            ...x,
            name: x.report_name,
            last_date: 0,
            next_date: ParseDBDate(x.next_report_datetime),
            issue: IssueEnum.OK,
        }))
        ?.filter((x) => x.next_date > Date.now())
        ?.sort((a, b) => a.last_date - b.last_date)
        ?.slice(0, 10);
    Log("reportExpected", reportExpected);

    let freq = DAILY_FREQ;
    if (reportConfig?.report_frequency) {
        switch (reportConfig.report_frequency) {
            case ReportFrequency.Daily:
            default:
                freq = DAILY_FREQ;
                break;
            case ReportFrequency.Weekly:
                freq = WEEKLY_FREQ;
                break;
            case ReportFrequency.Monthly:
                freq = MONTHLY_FREQ;
                break;
            case ReportFrequency.Quarterly:
                freq = QUARTERLY_FREQ;
                break;
        }
    }

    const next_date = !!latestReport?.next_date ? latestReport?.next_date : reportExpected && reportExpected.length > 0 ? reportExpected[0].next_date : !!latestReport?.last_date ? latestReport?.last_date + freq.expected : reportHistory && reportHistory.length > 0 ? reportHistory[0].last_date + freq.expected : 0;

    return (
        <>
            {(!reportConfig || !latestReport) && (
                <LoadingSpinner />
            )}
            {reportConfig && latestReport && (
                <HalfSizeTileContainer>
                    <SquareTile>
                        <h3>Last Received</h3>
                        <p style={{ fontSize: "2rem", textAlign: "center" }}>
                            {latestReport.last_date === 0 ? "Never" : FormatTime(latestReport.last_date)}
                            <br />
                            {latestReport.last_date === 0 ? "" : FormatDay(latestReport.last_date)}
                        </p>
                        <p></p>
                    </SquareTile>
                    <SquareTile>
                        <h3>Next Expected</h3>
                        <p style={{ fontSize: "2rem", textAlign: "center" }}>
                            {latestReport.last_date === 0 ? "Never" : FormatTime(next_date)}
                            <br />
                            {latestReport.last_date === 0 ? "" : FormatDay(next_date)}
                        </p>
                        <Countdown style={{ margin: "-3.2rem auto 1rem auto" }} time={next_date - Date.now()} reportFreq={latestReport.report_frequency} />
                    </SquareTile>
                    <WideTile>
                        <h3>Reports sent</h3>
                        <DaysOfWeek days={reportConfig.days_of_week} freq={reportConfig.report_frequency} />
                        <div>
                            {reportConfig.report_frequency === ReportFrequency.Daily && (
                                <p style={{ fontSize: "2rem" }}>
                                    {reportConfig.report_frequency} at {reportConfig.report_time}
                                </p>
                            )}
                            {reportConfig.report_frequency === ReportFrequency.Weekly && (
                                <p style={{ fontSize: "2rem" }}>
                                    {reportConfig.report_frequency} ({GetDaysFromConfig(reportConfig.days_of_week)}) at {reportConfig.report_time}
                                </p>
                            )}
                            {reportConfig.report_frequency === ReportFrequency.Monthly && (
                                <p style={{ fontSize: "2rem" }}>
                                    On the {Cardinalise(reportConfig.day_of_month)} of each month at {reportConfig.report_time}
                                </p>
                            )}
                            {reportConfig.report_frequency === ReportFrequency.Quarterly && (
                                <p style={{ fontSize: "2rem" }}>
                                    {reportConfig.report_frequency} from {reportConfig.quarterly_start_date} at {reportConfig.report_time}
                                </p>
                            )}
                        </div>
                    </WideTile>
                </HalfSizeTileContainer>
            )}
        </>
    );
};

export default ReportConfig;




