import React, { Context, ReactNode, createContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";
import { IFeedHistory } from "../types/IFeedHistory";

import { FETCH_FEEDS_HISTORY_DB } from "config";

export const FeedsHistoryContext = createContext<IFeedsHistoryContext>(null!);
export interface IFeedsHistoryContext extends IDataContext<IFeedHistory> { }


interface IFeedsHistoryContextProvider {
    children: ReactNode;
    params?: { [key: string]: string | number };
    context?: Context<IFeedsHistoryContext>;
}
const FeedsHistoryContextProvider = ({ children, params = {}, context = undefined }: IFeedsHistoryContextProvider) => {
    return (
        <DataContextProvider<IFeedHistory>
            context={context ?? FeedsHistoryContext}
            endpoint={FETCH_FEEDS_HISTORY_DB}
            params={params}
        >
            {children}
        </DataContextProvider>
    );
};

export default FeedsHistoryContextProvider;