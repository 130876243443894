import React, { ReactNode } from "react";

import Tile from './Tile';

interface IWideTileProps {
    children: ReactNode;
}

const WideTile = ({ children }: IWideTileProps) => <Tile type={'wide'}>{children}</Tile>;

export default WideTile;