import React from "react";

import ReactEcharts from "echarts-for-react";

const Bar = () => {
    const getOption = () => ({
        title: {
            text: "折线图堆叠"
        },
        tooltip: {
            trigger: "axis"
        },
        legend: {
            data: ["邮件营销", "联盟广告", "视频广告", "直接访问", "搜索引擎"]
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: "category",
            data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },
        yAxis: {
            type: "value"
        },

        series: [
            {
                name: "邮件营销",
                type: "bar",
                data: [120, 132, 101, 134, 90, 230, 210],
                animationDuration: 1000,
                color: ["yellow"]
            },
            {
                name: "联盟广告",
                type: "line",
                data: [220, 182, 191, 234, 290, 330, 310],
                animationDuration: 5000
            }
        ]
    });

    return (
        <ReactEcharts
            option={getOption()}
            style={{ height: "100%", width: "100%" }}
        />
    );
}

export default Bar;