import React, { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";

import { UserContext } from "core/data/auth/UserContextProvider";
import { Permission } from "core/enums/Permissions";

import Debug from "../Debug";

interface IPermissionsCheckProps {
    children: ReactNode;
    permission: Permission;
}

const PermissionsCheck = ({ children, permission }: IPermissionsCheckProps): JSX.Element => {
    const { user, permissions } = useContext(UserContext);

    Debug("PermissionsCheck", user, permission, permissions);
    if (!permissions.includes(permission)) {
        // Clients can't view the 'Home' page, but they can view the 'Deliverables' page
        if (permission !== Permission.ViewHomePage) {
            return <Navigate to="/" />;
        } else {
            return <Navigate to="/deliverables" />;
        }
    }

    return (
        <>
            {children}
        </>
    );
};

export default PermissionsCheck;