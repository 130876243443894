import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";


const CampaignTitle = ({ client, campaign }: { client: string, campaign: string }) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    // TODO: Styles
    return (
        <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
            <Button style={{ marginRight: "auto", alignSelf: "center" }} color="secondary" onClick={goBack}>Back</Button>
            <h1 style={{ marginRight: "2rem", marginBottom: "0" }}>{campaign}</h1>
            <h3 style={{ marginRight: "auto", marginBottom: ".2rem" }}>{client}</h3>
        </div>
    );
};

export default CampaignTitle;