import React from "react";
import ReactEcharts from "echarts-for-react";

import FormatDate from "core/helpers/datetime/FormatDate";
import Debug from "core/helpers/Debug";

import { ISimpleChartProps } from "./types/ISimpleChartProps";

const SimpleTimeGraph = ({ items, title = '' }: ISimpleChartProps) => {
    Debug(items);

    const option = {
        title: {
            text: title,
        },
        legend: {
            show: false
        },
        tooltip: {
            trigger: 'item',
            formatter: ({value}: any) => `<b>Value:</b> ${value[1]}<br/> <b>Date:</b> ${FormatDate(value[0])}`,
        },
        xAxis: {
            type: 'time',
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                type: "line",
                data: items.map(item => ({
                    label: item.name,
                    value: [item.name, item.value]
                })),
                label: {
                    show: true,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                colorBy: 'series'
            }
        ]
    };

    return (
        <ReactEcharts
            option={option}
            style={{ height: "100%", width: "100%" }}
        />
    );
};

export default SimpleTimeGraph;