export const Region = "eu-west-2";
export const ENV = process.env.REACT_APP_ENV;

export const LIST_S3_DATA_FEEDS_LAMBDA = "irisApiFetchFeedStatus-" + ENV;
export const LIST_S3_REPORTS_LAMBDA = "irisApiFetchReportStatus-" + ENV;
export const LIST_S3_REPORTS_HISTORY_LAMBDA = "irisApiFetchReportHistory-" + ENV;
export const LIST_S3_METRICS_LAMBDA = "irisApiFetchMetricStatus-" + ENV;
export const LIST_S3_METRICS_HISTORY_LAMBDA = "irisApiFetchMetricHistory-" + ENV;
export const LIST_S3_ISSUES_LAMBDA = "irisApiFetchIssues-" + ENV;
export const RESOLVE_ISSUE_LAMBDA = "irisApiResolveIssue-" + ENV;

export const FEED_MODIFIED_DATES = "irisCheckForExpectedFeeds-" + ENV;
export const REPORT_MODIFIED_DATES = "irisCheckForExpectedReports-" + ENV;
export const METRICS_VALUES_HISTORY = "irisFetchMetricValues-" + ENV;

export const FETCH_DK_USER_LAMBDA = "irisFetchUser-" + ENV;
export const FETCH_USERS_LAMBDA = "irisFetchUsers-" + ENV;
export const FETCH_DK_USER_GROUPS_LAMBDA = "irisFetchUserGroups-" + ENV;


export const FETCH_LATEST_REPORTS_DB = "irisApiFetchReports-" + ENV;
export const FETCH_REPORTS_HISTORY_DB = "irisApiFetchReportsHistory-" + ENV;
export const FETCH_REPORTS_CONFIG_DB = "irisApiFetchReportConfig-" + ENV;
export const FETCH_REPORTS_EXPECTED_DB = "irisApiFetchReportsExpected-" + ENV;

export const FETCH_LATEST_METRICS_DB = "irisApiFetchMetrics-" + ENV;
export const FETCH_METRICS_HISTORY_DB = "irisApiFetchMetricsHistory-" + ENV;
export const FETCH_METRICS_CONFIG_DB = "irisApiFetchMetricConfig-" + ENV;

export const FETCH_LATEST_FEEDS_DB = "irisApiFetchFeeds-" + ENV;
export const FETCH_FEEDS_HISTORY_DB = "irisApiFetchFeedsHistory-" + ENV;
export const FETCH_FEEDS_CONFIG_DB = "irisApiFetchFeedConfig-" + ENV;

export const FETCH_LATEST_CAMPAIGNS_DB = "irisApiFetchCampaigns-" + ENV;

export const FETCH_CALENDARS_LAMBDA = "irisApiFetchCalendars-" + ENV;