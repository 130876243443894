import React from 'react';
import { Link } from 'react-router-dom';

import { FeedNames } from 'core/data/feeds/feed_names';
import FormatDay from 'core/helpers/datetime/FormatDay';
import GetStatusIcon from 'core/helpers/GetStatusIcon';

import { IMetric } from '../types/IMetric';
import { IMetricHistory } from '../types/IMetricHistory';

interface IMetricsListItemProps {
    item: IMetric | IMetricHistory
}

const MetricsListItem = ({ item }: IMetricsListItemProps) => {
    return (
        <div className={`listitem issue-${item.issue}`}>
            <Link to={`/metrics/${item.client_id}/${item.metric_id}`}>

                <div className={'listitem__header'}>
                    <div className={'listitem__toggle-details'}>
                        <div className={'listitem__toggle-details__container'}>
                            {GetStatusIcon(item.issue)}
                        </div>
                    </div>

                    <div className={'listitem__title'}>
                        {FeedNames.get(item.metric_name) ?? item.metric_name}
                    </div>

                    <div className={`listitem__value`}>
                        Received: {item.last_date === 0 ? 'Never' : FormatDay(item.last_date)}
                    </div>

                    <div className={`listitem__value issue-${item.issue}`}>
                        Value: {item.metric_value}
                    </div>
                    <div className={'listitem__icon'}>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default MetricsListItem;