import React, { useContext, useEffect} from "react";

import SquareTile from "core/components/tiles/SquareTile";
import HalfSizeTileContainer from "core/components/tiles/HalfSizeTileContainer";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";

import FormatDay from "core/helpers/datetime/FormatDay";
import Debug from "core/helpers/Debug";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";

import { LatestCampaignsContext, ILatestCampaignsContext } from "../contexts/LatestCampaignsContextProvider";
import { CampaignsHistoryContext, ICampaignsHistoryContext } from "../contexts/CampaignsHistoryContextProvider";


interface ICampaignConfigProps {
    client: string;
    campaign: string;
}
const CampaignConfig = ({ client, campaign }: ICampaignConfigProps) => {
    const { items: latestCampaigns, setVisible: setLatestVisible } = useContext(LatestCampaignsContext);
    const { items: campaignsHistory, setVisible: setHistoryVisible } = useContext(CampaignsHistoryContext);

    useEffect(() => {
        setHistoryVisible(true);
        setLatestVisible(true);

        return () => {
            setHistoryVisible(false);
            setLatestVisible(false);
        };
    }, [setHistoryVisible, setLatestVisible]);

    const latestCampaign = latestCampaigns
        ?.filter(x => x.client_name === client && x.campaign_name === campaign)
        ?.map(x => ({
            ...x,
            name: x.campaign_name,
            last_date: ParseDBDate(x.campaign_timestamp),
            next_date: 0,
            issue: IssueEnum.OK
        }))[0];
    Debug('Latest', latestCampaigns, client, campaign);

    // const campaignConfig = campaignsConfig?.filter(x => x.campaign_name === campaign)[0];

    const campaignHistory = campaignsHistory
        ?.filter(x => x.client_name === client && x.campaign_name === campaign && x.campaign_date !== null)
        ?.map(x => ({
            ...x,
            name: x.campaign_name,
            last_date: ParseDBDate(x.campaign_date),
            next_date: 0,
            issue: IssueEnum.OK
        }))
        ?.sort((a, b) => b.last_date - a.last_date)
        ?.slice(0, 30);
    Debug('History', campaignHistory);

    return (
        <>
            {latestCampaign &&
                <HalfSizeTileContainer>
                    <SquareTile>
                        <h3>Date</h3>
                        <p style={{ fontSize: "3rem" }}>{latestCampaign.last_date === 0 ? 'Never' : FormatDay(latestCampaign.last_date)}</p>
                        <p></p>
                    </SquareTile>
                    <SquareTile>
                        <h3>Sent</h3>
                        <p style={{ fontSize: "3rem" }}>{latestCampaign.sent}</p>
                        <p></p>
                    </SquareTile>
                    <SquareTile>
                        <h3>Opens</h3>
                        <p style={{ fontSize: "3rem" }}>{latestCampaign.opens}</p>
                        <p></p>
                    </SquareTile>
                    <SquareTile>
                        <h3>Clicks</h3>
                        <p style={{ fontSize: "3rem" }}>{latestCampaign.clicks}</p>
                        <p></p>
                    </SquareTile>
                </HalfSizeTileContainer>
            }
        </>
    );
};

export default CampaignConfig;