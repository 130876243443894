export interface ISprint {
    id: number;
    name: string;
    start_date: Date;
    end_date: Date;
    status: SprintStatus;
}

export enum SprintStatus {
    InProgress = "In Progress",
    Complete = "Complete",
    NotStarted = "Not Started",
}