import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import SubNav from "core/components/navbar/components/SubNav";
import { SubPages } from "core/components/navbar/enums/SubPages";
import PermissionsCheck from "core/helpers/auth/PermissionsCheck";
import { UserContext } from "core/data/auth/UserContextProvider";

import ServiceDeskTicketPage from "./components/ServiceDeskTicketPage";
import ServiceDeskTicketsList from "./components/ServiceDeskTicketsList";
import ServiceDeskCreateNew from "./components/ServiceDeskCreateNew";
import ServiceDeskPrioritiseTickets from "./components/ServiceDeskPrioritiseTickets";
import ServiceDeskSprintPlan from "./components/ServiceDeskSprintPlan";
import ServiceDeskAllocatedWork from "./components/ServiceDeskAllocatedWork";
import ServiceDeskDashboard from "./components/ServiceDeskDashboard";

import { Permission } from "../../core/enums/Permissions";
import GetViewablePages from "./helpers/GetViewablePages";

import "./_service-desk.scss";

const ServiceDeskPage = () => {
    const { permissions } = useContext(UserContext);

    const viewablePages = GetViewablePages(
        permissions,
        [
            SubPages.ServiceDeskDashboard,
            SubPages.ListTickets,
            SubPages.NewTicket,
            SubPages.PrioritiseTickets,
            SubPages.SprintPlan,
            SubPages.AllocatedWork,
        ],
    );


    return (
            <section className="service-desk">
                <SubNav pages={viewablePages} prefix={"service-desk"} />

                <Routes>
                    <Route path="/dashboard" element={
                        <PermissionsCheck permission={Permission.ListTickets}>
                            <ServiceDeskDashboard />
                        </PermissionsCheck>
                    } />
                    <Route path="/list" element={
                        <PermissionsCheck permission={Permission.ListTickets}>
                            <ServiceDeskTicketsList />
                        </PermissionsCheck>
                    } />
                    <Route path="/new" element={
                        <PermissionsCheck permission={Permission.CreateTicket}>
                            <ServiceDeskCreateNew />
                        </PermissionsCheck>
                    } />
                    <Route path="/prioritise" element={
                        <PermissionsCheck permission={Permission.PrioritiseTickets}>
                            <ServiceDeskPrioritiseTickets />
                        </PermissionsCheck>
                    } />
                    <Route path="/plan" element={
                        <PermissionsCheck permission={Permission.AllocateTickets}>
                            <ServiceDeskSprintPlan />
                        </PermissionsCheck>
                    } />
                    <Route path="/allocated" element={
                        <PermissionsCheck permission={Permission.StartWork}>
                            <ServiceDeskAllocatedWork />
                        </PermissionsCheck>
                    } />
                    <Route path="/:id" element={<ServiceDeskTicketPage />} />
                    <Route path="/" element={<Navigate to={"/service-desk/dashboard"} />} />
                </Routes>
            </section >
    );
};
export default ServiceDeskPage;
