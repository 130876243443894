import React, { useContext, useCallback, useEffect, useMemo } from "react";
import { CellProps } from "react-table";

import DbStatusTable from "core/components/status-table/DbStatusTable";
import LoadingSpinner from "core/components/loading/Loading";
import { ToggleFilter } from "core/components/status-table/CustomTable";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import { DAILY_FREQ, Frequencies } from "core/data/feeds/frequencies";
import { FeedNames } from "core/data/feeds/feed_names";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";
import FormatDate from "core/helpers/datetime/FormatDate";
import GetContractSupportIcon from "core/helpers/GetContractSupportIcon";

import { ILatestCampaignsContext, LatestCampaignsContext } from "../contexts/LatestCampaignsContextProvider";
import { ICampaign } from "../types/ICampaign";
import CampaignsListItem from "./CampaignsListItem";
import { UserContext } from "core/data/auth/UserContextProvider";
import { Permission } from "core/enums/Permissions";
import IssuesSubTitle from "core/components/status-table/IssuesSubTitle";


export const getCampaignsIssue = (item: ICampaign) => {
    try {
        return IssueEnum.OK;
        // // If we've not received it yet, lets assume it's just pending
        // if (item.last_date === 0) return IssueEnum.OK;

        // const freq = Frequencies[item.campaign_name] ?? DAILY_FREQ;

        // const now = Date.now();
        // const date = item.last_date; // YYYY-MM-DDTHH:MM:SS.SSSZ

        // const issue = date < now - freq.alert;
        // const warning = date < now - freq.warning;

        // return issue ? IssueEnum.ERROR : (warning ? IssueEnum.WARNING : IssueEnum.OK);
    } catch (e) {
        console.error(e, item);
        return IssueEnum.ERROR;
    }
};
export const transformCampaignsData = (latestCampaigns: ICampaign[] | null) => {
    if (!latestCampaigns) return null;

    return latestCampaigns
        ?.filter(x => !!x.campaign_timestamp && !!x.campaign_name)
        ?.map(x => ({
            ...x,
            id: x.campaign_id,
            name: x.campaign_name,
            last_date: ParseDBDate(x.campaign_timestamp) ?? 0,
            next_date: 0
        }))
        ?.map(x => ({
            ...x,
            issue: getCampaignsIssue(x),
            open_rate: x.sent === 0 ? 0 : Math.round((x.opens / x.sent) * 100),
            click_rate: x.opens === 0 ? 0 : Math.round((x.clicks / x.opens) * 100)
        }))
        ?.sort();
}

const CampaignsListTable = ({collapsible = false}: {collapsible?: boolean}) => {
    const { items, error, setVisible } = useContext(LatestCampaignsContext);
    const {permissions} = useContext(UserContext);

    useEffect(() => {
        setVisible(true);

        return () => {
            setVisible(false);
        };
    }, [setVisible]);

    const GetIssue = useCallback((item: ICampaign) => getCampaignsIssue(item), []);

    const data = useMemo(() => transformCampaignsData(items), [items]);


    const getContractFilter = useCallback(() => {
        return -1;
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ value }: CellProps<ICampaign>) => (
                    <b style={{ fontSize: ".9rem" }}>{FeedNames.get(value) ?? value}</b>
                ),
                style: { 
                    width: "50%",
                }
            },
            {
                Header: 'Date',
                accessor: 'last_date',
                Cell: ({ value }: CellProps<ICampaign>) => FormatDate(value),
                isSorted: true,
                isSortedDesc: true,
                style: { textAlign: 'left' },
            },
            {
                Header: 'Sent',
                accessor: 'sent',
                style: { textAlign: 'right' },
            },
            // {
            //     Header: 'Opens',
            //     accessor: 'opens',
            //     style: { textAlign: 'right' },
            // },
            // {
            //     Header: 'Clicks',
            //     accessor: 'clicks',
            //     style: { textAlign: 'right' },
            // },
            // {
            //     Header: 'Open Rate (%)',
            //     accessor: 'open_rate',
            //     style: { textAlign: 'right' },
            // },
            // {
            //     Header: 'Click Rate (%)',
            //     accessor: 'click_rate',
            //     style: { textAlign: 'right' },
            // },
            {
                Header: 'Supported',
                accessor: 'in_contract',
                Cell: ({ value }: CellProps<ICampaign>) => GetContractSupportIcon(value),
                style: { textAlign: 'center' },
                Filter: ToggleFilter,
                canFilter: true,
                filter: 'toggle',
                getFilterValue: getContractFilter
            }
        ], []
    );

    if (!permissions.includes(Permission.ViewCampaigns)) {
        return null;
    }

    return (
        <>
            {items === null && <LoadingSpinner />}

            {items &&
                <DbStatusTable<ICampaign>
                    unique={true}
                    title={'Campaigns'}
                    items={data}
                    error={error}
                    RenderRow={(item) => <CampaignsListItem key={item.id} item={item} />}
                    GetIssue={GetIssue}
                    columns={columns}
                    collapsible={collapsible}
                />
            }
        </>
    );
};

export default CampaignsListTable;