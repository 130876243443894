import React, { ReactNode, createContext, useCallback, useEffect, useRef, useState } from "react";
import { Auth } from 'aws-amplify';

import Log from "core/helpers/Log";
import Debug from "core/helpers/Debug";
import Parsed from "core/helpers/Parsed";

// Define default value to avoid destructuring errors
export const AWSAuthContext = createContext<IAWSAuthContext>({
    user: null,
    session: null,
    credentials: null,
    logIn: () => Promise.resolve(),
    logOut: () => Promise.resolve(),
    refreshSession: () => Promise.resolve()
});

export interface IAWSAuthContext {
    user: any;
    session: any;
    credentials: any;
    logIn: () => Promise<void>;
    logOut: () => Promise<void>;
    refreshSession: () => Promise<void>;
}


interface AWSAuthContextProviderProps {
    children: ReactNode;
}
const AWSAuthContextProvider = (props: AWSAuthContextProviderProps) => {
    const [user, setUser] = useState<string>(null!);
    const [session, setSession] = useState<string>(null!);
    const [credentials, setCredentials] = useState<string>(null!);
    const [loading, setLoading] = useState(true);

    const updateUser = (u: object) => setUser(JSON.stringify(u));
    const clearUser = useCallback(() => updateUser({}), []);
    const updateSession = (s: object) => setSession(JSON.stringify(s));
    const clearSession = useCallback(() => updateSession({}), []);
    const updateCredentials = (c: object) => setCredentials(JSON.stringify(c));
    const clearCredentials = useCallback(() => updateCredentials({}), []);

    const isRetrying = useRef(false);
    
    // Core method that handles authentication
    const getAWSAuth = useCallback(async () => {
        try {
            const [auth_user, s, c] = await Promise.all([
                Auth.currentAuthenticatedUser(),
                Auth.currentSession(),
                Auth.currentCredentials()
            ]);

            Log('getAWSAuth', auth_user, s, c);
    
            if (auth_user) {
                updateUser(auth_user);
            } else {
                clearUser();
            }
    
            if (s) {
                updateSession(s);
            } else {
                clearSession();
            }
    
            // Amplify seems to return an exception as the credentials if the user is not authorized
            if (c && !c.toString().startsWith('NotAuthorizedException:')) {
                updateCredentials(c);
            } else {
                clearCredentials();
            }
        } catch (error) {
            console.error(error);
            clearUser();
            clearSession();
            clearCredentials();
        }
    }, [clearUser, clearSession, clearCredentials]);

    const logIn = async () => {
        await getAWSAuth();
    };

    const logOut = async () => {
        try {
            await Auth.signOut();
            clearUser();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };

    const refreshSession = useCallback(async () => {
        if (isRetrying.current) return;
        isRetrying.current = true;
        Debug('Refreshing session');
        
        await getAWSAuth();

        Debug('Refreshed session');
        isRetrying.current = false;
    }, [getAWSAuth]);

    // Check credentials on load
    useEffect(() => {
        getAWSAuth();
    }, [getAWSAuth]);

    // As pretty much all of the app depends on the user being logged in
    // wait until we have all the data before rendering the app
    useEffect(() => {
        if (user !== null && session !== null && credentials !== null) {
            setLoading(false);
        }
    }, [user, session, credentials]);

    const value: IAWSAuthContext = {
        user: Parsed(user),
        session: Parsed(session),
        credentials: Parsed(credentials),
        logIn,
        logOut,
        refreshSession
    };

    return (
        <AWSAuthContext.Provider value={value}>
            {loading && <div>Loading...</div>}
            {!loading && props.children}
        </AWSAuthContext.Provider>
    )
};

export default AWSAuthContextProvider;