import React, { FormEvent, useState } from "react";

import { Fade } from "react-awesome-reveal";

const BUTTON_STATE_DEFAULT = '';
const BUTTON_STATE_LOADING = 'loading';
const BUTTON_STATE_SENT = 'sent';

interface IOTPFormProps {
    doOTPCheck: (otpCode: string) => Promise<void>;
}

const OTPForm = ({ doOTPCheck }: IOTPFormProps) => {
    const [btnState, setBtnState] = useState(BUTTON_STATE_DEFAULT);
    const [otpCode, setOTPCode] = useState('');

    const handleOTPCheck = async (e: FormEvent) => {
        e.preventDefault();
        setBtnState(BUTTON_STATE_LOADING);

        try {
            await doOTPCheck(otpCode);
            // Don't set the button state here as the form has been replaced
        } catch (err) {
            setBtnState(BUTTON_STATE_DEFAULT);
        }
    }

    return (
        <form className="login--hero__form" onSubmit={handleOTPCheck} autoComplete="off">
            <Fade duration={1500} delay={50}>
                <>
                    <h3>Enter the code from your MFA app</h3>
                </>
            </Fade>
            <Fade duration={1500} delay={100}>
                <>
                    <label htmlFor="code">Code</label>
                    <input
                        type="text"
                        placeholder="Code"
                        name="code"
                        value={otpCode}
                        onChange={(e) => setOTPCode(e.target.value)}
                        required
                        autoFocus
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={150}>
                <button
                    disabled={btnState === BUTTON_STATE_LOADING || btnState === BUTTON_STATE_SENT}
                    className={`login--button otp ${btnState}`}
                ></button>
            </Fade>
        </form>
    );
}

export default OTPForm;