export enum Feeds {
    AccountCounter = 'AccountCounter',
    AccountCounterRecord = 'ACCOUNTCOUNTERRECORD',
    AccountHistory = 'AccountHistory',
    ActivationTermination = 'ACTIVATION_TERMINATION',
    Adjustment = 'ADJUSTMENT',
    AdjustmentRecord = 'ADJUSTMENTRECORD',
    CallRecord = 'CallRecord',
    CallsInbound = 'Calls Inbound',
    CallsOutbound = 'Calls Outbound',
    CustomerHistory = 'CustomerHistory',
    DataSessions = 'Data Sessions',
    DataSessionsTBUsed = 'Data Sessions - TB Used',
    DataSessionsUserCount = 'Data Sessions - User Count',
    Inventory = 'Inventory',
    PadsRecord = 'PadsRecord',
    PhoneNumberHistory = 'PhoneNumberHistory',
    SIMHistory = 'SIMHistory',
    SMSOutbound = 'SMS Outbound',
    SMSRecord = 'SMSRecord',
    SubscriptionHistory = 'SUBSCRIPTIONHISTORY',
    Topup = 'TOPUP',
    ANIInventory = 'ANI_Inventory',
    PDACustomerDeactivation = 'PDA_CUSTDEACT', // PDA = Prepaid Data Aggregator???
    PDAIMEIRef = 'PDA_IMEI_REF',
    PDAInterconnectVoice = 'PDA_INTERCONNECT_VOICE',
    PDAMemberExpiry = 'PDA_MEMBER_EXPIRY',
    PDAMOPrepaidMMS = 'PDA_MO_PREPAID_MMS', // MO = Mobile Originator
    PDAMOPrepaidSMS = 'PDA_MO_PREPAID_SMS', // MT = Mobile Terminator
    PDAMOPrepaidVoice = 'PDA_MO_PREPAID_VOICE',
    PDAMOWholesaleVoice = 'PDA_MO_WHOLESALE_VOICE',
    PDAMTPrepaidSMS = 'PDA_MT_PREPAID_SMS',
    PDAMTPrepaidVoice = 'PDA_MT_PREPAID_VOICE',
    PDAMTWholesaleVoice = 'PDA_MT_WHOLESALE_VOICE',
    PDAPortIn = 'PDA_PORT_IN',
    PDAPortOut = 'PDA_PORT_OUT',
    PDAPrepaidGPRS = 'PDA_PREPAID_GPRS',
    PDAPrepaidTopups = 'PDA_PREPAID_TOPUPS',
    PDATimeRecTopups = 'PDA_TIME_REC_TOPUPS',
    PDATRTRecurringMem = 'PDA_TRT_RECURRING_MEM',
    SHIPNOT = 'SHIPNOT', // Shipping Notifications -// Sim dispatches
    MessageHistoryReport = 'message_history_report',
    PDAAccountBalance = 'pda_account_balance',
    PDAAccountLifecycle = 'pda_account_lifecycle',
    PDAAccountStatus = 'pda_account_status',
    PDABalanceManagement = 'pda_balance_management',
    PDAFreeUsage = 'pda_free_usage',
    PDAPortOutBalance = 'pda_port_out_balance',
    PDASocialMedia = 'pda_social_media',
    PDASubscrAdj = 'pda_subscr_adj', // Credit Applications
    PDARoamingSummary = 'pda_roaming_summary',
    PDAEEARoamingSummary = 'pda_eea_roaming_sum',
    PDAIntraEEASummary = 'pda_intra_eea_sum',
    PDASNSMessage = 'pda_sns_message',
    PDAVoucherInit = "PDA_VOUCHER_INIT",
    PDAVoucherRtd = "PDA_VOUCHER_RTD",
    PDAVoucherUsed = "PDA_VOUCHER_USED",
    PDAWhatsApp = "PDA_WhatsApp",
    PDACreditCard = "pda_credit_card",
    FreemiumParqueReport = 'Freemium_Parque_Report',
    ReportTableroDiario = 'Report_Tablero_Diario',
    CalidadDeAltasWeekly = 'Calidad_de_Altas_Weekly',
    CalidadDeAltasReport = 'Calidad_de_Altas_Report',
    MatrizA = 'Matriz_A',
    ReporteVentas = 'Reporte_Ventas',
    WeeklyParqueReport = 'Weekly_Parque_Report',
    MonthlyParqueReport = 'Monthly_Parque_Report',
    ComboReport = 'Combo_Report',
    ConcentracionMercado = 'Concentracion_Mercado',
    CostoGigabyte = 'Costo_Gigabyte',
    MOUItx = 'MOU_itx',
    MOU = 'MOU',
    SaldosAcumulados = 'Saldos_Acumulados',
    ActivatedCustomers = 'ActivatedCustomers',
    ActivationRate = 'ActivationRate',
    BaseManagementThreeReport = 'BaseManagement Three Report',
    BaseManagement = 'Base Management',
    BaseManagementMonthly = 'Base Management Monthly',
    BaseManagement1 = 'BaseManagement1',
    BaseManagement2 = 'BaseManagment2',
    BundlesPerDaySummary = 'BundlesPerDaySummary',
    CallTypeSummary = 'CallTypeSummary',
    CampaignPIR = 'Campaign PIR',
    ChurnFunnel = 'Churn Funnel',
    CreditApplication = 'CreditApplication',
    CreditApplicationReport = 'Credit Application Report',
    DailyFlash = 'Daily Flash',
    Daily = 'Daily',
    DecayRate = 'Decay Rate',
    Donations = 'Donations',
    FailedPayments = 'FailedPayments',
    FlexiData = 'FlexiData',
    FreeUsageTest = 'FreeUsage Test',
    FreeUsage = 'FreeUsage',
    FUPDailyReportDistinctDial = 'FUP DailyReport DistinctDial',
    KickbackPortalOrders = 'Kickback Portal Orders',
    KickbackPortalOrdersCSV = 'Kickback_PortalOrders CSV',
    KickbackPortalOrdersExcel = 'Kickback_PortalOrders Excel',
    Migration = 'Migration',
    PortInOutSummary = 'Port In Out Summary',
    PortInOutSummaryCSV = 'Port_In_Out_Summary CSV',
    PortInOutSummaryExcel = 'Port_In_Out_Summary Excel',
    RecruiteesReport = 'Recruitees Report',
    SMSAndVoiceAbuseWeeklyReport = 'SMS and Voice Abuse Weekly Report',
    TheSafe = 'TheSafe',
    WeeklyTradingReport = 'Weekly Trading Report',
    Tester48 = '48 Tester',
    TotalNetworkVoiceMonthly = 'TotalNetwork_Voice_Monthly',
    TotalNetworkSMSMonthly = 'TotalNetwork_SMS_Monthly',
    DataUsageTotalNetworkMonthly = 'DataUsage_TotalNetwork_Monthly',
    ComReg = 'ComReg',
    BaseManagementThreeReportMonthly = 'BaseManagement_ThreeReport_Monthly',
    PortingAndRoamingQuarterly = 'Porting and Roaming Quarterly',
    Segmentation = 'segmentation',
    Budget48 = '48_Budget',
    ActiveSIM = 'ACTIVE_SIM',
    CH48Campus = 'CH48_CAMPUS',
    CreditCardTransaction = "CREDIT_CARD_TRANSACTION",
    CustomerContactsHistory = "customer_contacts_history",
    CustomerSIM = "CUSTOMER_SIM",
    FJSRet = "FJS_RET",
    NotContactEmail = "NOT_CONTACT_EMAIL",
    NotContactSMS = "NOT_CONTACT_SMS",
    O2MTSMS = "O2_MT_SMS",
    Order = "ORDER",
    OrderItem = "ORDER_ITEM",
    VoucherCredits = "voucher_credits",
    PortOut = "port_out",
}