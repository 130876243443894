export enum Permission {
    Default = "Default",
    ListTickets = "ListTickets",
    SeeAllTickets = "SeeAllTickets",
    CreateTicket = "CreateTicket",
    EditTicket = "EditTicket",
    DeleteTicket = "DeleteTicket",
    AddComment = "AddComment",
    ScopeTickets = "ScopeTickets",
    AcceptTickets = "AcceptTickets",
    RejectTickets = "RejectTickets",
    PrioritiseTickets = "PrioritiseTickets",
    ScheduleTickets = "ScheduleTickets",
    AllocateTickets = "AllocateTickets",
    StartWork = "StartWork",
    PauseWork = "PauseWork",
    ReviewWork = "ReviewWork",
    FeedbackOnWork = "FeedbackOnWork",
    CompleteWork = "CompleteWork",
    ViewHomePage = "ViewHomePage",
    ViewFeeds = "ViewFeeds",
    ViewFeedDetails = "ViewFeedDetails",
    ViewReports = "ViewReports",
    ViewReportDetails = "ViewReportDetails",
    ViewMetrics = "ViewMetrics",
    ViewMetricDetails = "ViewMetricDetails",
    ViewCampaigns = "ViewCampaigns",
    ViewCampaignDetails = "ViewCampaignDetails",
    OverrideUsers = "OverrideUsers",
    Dummy = "Dummy",
};