import React, { ReactNode } from "react";

import Tile from './Tile';

interface IListTileProps {
    children: ReactNode;
}

const ListTile = ({ children }: IListTileProps) => <Tile type={'wide list'}>{children}</Tile>;

export default ListTile;