export enum SubPages {
    // TODO: Be more specific as to which pages these are for in order to apply permissions
    DB = "DB",
    Status = "Status",
    History = "History",
    Graphs = "Graphs",
    // Service Desk
    NewTicket = "New",
    ListTickets = "List",
    PrioritiseTickets = "Prioritise",
    SprintPlan = "Plan",
    AllocatedWork = "Allocated",
    ServiceDeskDashboard = "Dashboard",
}