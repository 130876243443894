import React from "react";

import { ReportFrequency } from "core/enums/ReportFrequency";


interface IDaysOfWeekProps {
    days: string;
    freq?: ReportFrequency;
}
const DaysOfWeek = ({ days, freq = ReportFrequency.Quarterly }: IDaysOfWeekProps) => {
    const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    let daysOfWeek = days?.split(",")?.map((x: string) => x.trim()) ?? [];
    if (freq === ReportFrequency.Daily) daysOfWeek = allDays;

    return (
        <>
            <div className="days-of-week">
                {allDays.map((day, index) => {
                    return (
                        <p style={{ fontSize: "3rem" }} key={index} className={daysOfWeek.includes(day) ? "active" : "inactive"}>
                            {day[0]}
                        </p>
                    );
                })}
            </div>
        </>
    );
};

export default DaysOfWeek;