
interface Parameter {
    Type: string;
    Default?: string;
}
interface TriggerRules {
    Name: string;
    Value: string;
}
interface Resource {
    Type: string;
    Properties: any;
    DependsOn?: string[];
}
interface Output {
    Value: any;
}
interface ICFYaml {
    AWSTemplateFormatVersion: string;
    Parameters: { [key: string]: Parameter };
    Resources: { [key: string]: Resource };
    Outputs: { [key: string]: Output };
}

interface IAssumeRolePolicyDocument {
    Version: string;
    Statement: IStatement[];
}
interface IStatement {
    Effect: string;
    Principal: IAssumeRolePolicyDocumentPrincipal;
    Action: string;
}
interface IAssumeRolePolicyDocumentPrincipal {
    Service: string;
}
interface IPolicy {
    PolicyName: string;
    PolicyDocument: IPolicyDocument;
}
interface IPolicyDocument {
    Version: string;
    Statement: IPolicyDocumentStatement[];
}
interface IPolicyDocumentStatement {
    Effect: string;
    Action: string | string[];
    Resource: string | string[];
}

export class AssumeRolePolicyDocument {
    document: IAssumeRolePolicyDocument;

    constructor() {
        this.document = {
            Version: "2012-10-17",
            Statement: []
        };
    }

    addStatement(effect: string, service: string, action: string) {
        let s: IStatement = {
            Effect: effect,
            Principal: {
                Service: service
            },
            Action: action
        };

        this.document.Statement.push(s);
    }
}

export class Policy {
    PolicyName: string;
    PolicyDocument: IPolicyDocument;

    constructor(name: string) {
        this.PolicyName = name;
        this.PolicyDocument = {
            Version: "2012-10-17",
            Statement: []
        };
    }

    addStatement(effect: string, action: string | string[], resource: string | string[]) {
        let s: IPolicyDocumentStatement = {
            Effect: effect,
            Action: action,
            Resource: resource
        };

        this.PolicyDocument.Statement.push(s);
    }
}

export class CFYaml {
    yaml: ICFYaml;
    permissions: string[];

    constructor() {
        this.yaml = {
            AWSTemplateFormatVersion: "2010-09-09",
            Parameters: {},
            Resources: {},
            Outputs: {}
        };
        this.permissions = [];
    }

    addBucket(resourceName: string, bucketName: string) {
        this.addOutputByRef("BucketName", resourceName);
        this.addOutputByGetAtt("BucketArn", resourceName);
    
        let p: Resource = {
            Type: "AWS::S3::Bucket",
            DependsOn: this.permissions,
            Properties: {
                BucketName: {
                    Ref: bucketName
                }
            }
        };
    
        this.yaml.Resources[resourceName] = p;
    }

    addPermission(name: string, lambda: string, bucket: string) {
        let p = {
            Type: "AWS::Lambda::Permission",
            Properties: {
                Action: "lambda:InvokeFunction",
                FunctionName: {
                    Ref: lambda
                },
                Principal: "s3.amazonaws.com",
                SourceAccount: {
                    Ref: "AWS::AccountId"
                },
                SourceArn: {
                    "Fn::Join": [
                        "",
                        [
                            "arn:aws:s3:::",
                            {
                                Ref: bucket
                            }
                        ]
                    ]
                }
            }
        };
    
        this.yaml.Resources[name] = p;
    
        this.permissions.push(name);
    }

    addRole(resourceName: string, roleName: string, assumeRolePolicyDocument: IAssumeRolePolicyDocument, managedPolicyArns: string[], policies: IPolicy[]) {
        let p = {
            Type: "AWS::IAM::Role",
            Properties: {
                RoleName: roleName,
                AssumeRolePolicyDocument: assumeRolePolicyDocument,
                ManagedPolicyArns: managedPolicyArns,
                Policies: policies
            }
        };
    
        this.yaml.Resources[resourceName] = p;
    }

    addLambda(resourceName: string, lambdaName: string, role: string, code: string, runtime: string = "nodejs18.x", timeout: number = 300) { //}, permission_resource: string, bucket_param: string) {
        this.addOutputByGetAtt("LambdaArn", resourceName);
    
        // this.addPermission(permission_resource + resourceName, resourceName, bucket_param);
    
        let p = {
            Type: "AWS::Lambda::Function",
            Properties: {
                FunctionName: lambdaName,
                Handler: "index.handler",
                Role: {
                    "Fn::GetAtt": [
                        role,
                        "Arn"
                    ]
                },
                Code: {
                    ZipFile: code
                },
                Runtime: runtime,
                MemorySize: 1024,
                Timeout: timeout
            }
        };
    
        this.yaml.Resources[resourceName] = p;
        
    }

    addGlueJob(resourceName: string, jobName: string, role: string, code: string, bucket_param: string, timeout: number = 15) {
        this.addOutputByGetAtt("GlueJobArn", resourceName);

        let p = {
            Type: "AWS::Glue::Job",
            Properties: {
                Command: {
                    Name: "glueetl",
                    ScriptLocation: {
                        "Fn::Join": [
                            "",
                            [
                                "s3://",
                                {
                                    Ref: bucket_param
                                },
                                "/scripts/",
                                code
                            ]
                        ]
                    }
                },
                Name: jobName,
                Role: {
                    "Fn::GetAtt": [
                        role,
                        "Arn"
                    ]
                },
                MaxRetries: 0,
                Timeout: timeout,
                GlueVersion: "3.0",
                MaxCapacity: 5,
                ExecutionProperty: {
                    MaxConcurrentRuns: 40
                },
                DefaultArguments: {
                    "--TempDir": {
                        "Fn::Join": [
                            "",
                            [
                                "s3://",
                                {
                                    Ref: bucket_param
                                },
                                "/temporary/"
                            ]
                        ]
                    },
                    "--class": "GlueApp",
                    "--enable-auto-scaling": "true",
                    "--endable-continuous-cloudwatch-log": "true",
                    "--enable-glue-datacatalog": "true",
                    "--enable-job-insights": "true",
                    "--enable-metrics": "true",
                    "--enable-spark-ui": "true",
                    "--job-bookmark-option": "job-bookmark-disable",
                    "--job-language": "python-3",
                    "--spark-event-logs-path": {
                        "Fn::Join": [
                            "",
                            [
                                "s3://",
                                {
                                    Ref: bucket_param
                                },
                                "/sparkHistoryLogs/"
                            ]
                        ]
                    }
                },
            }
        };

        this.yaml.Resources[resourceName] = p;
    }

    addTestEventSchema(resourceName: string, schemaName: string, schema: string) {
        let p = {
            Type: "AWS::EventSchemas::Schema",
            Properties: {
                RegistryName: "lambda-testevent-schemas",
                SchemaName: {
                    "Fn::Join": [
                        "",
                        [
                            "_",
                            schemaName,
                            "-schema"
                        ]
                    ]
                },
                Type: "OpenApi3",
                Content: schema
            }
        };

        this.yaml.Resources[resourceName] = p;
    }

    addStepFn(resourceName: string, stateMachineName: string, roleName: string, definition: string) {
        let p = {
            Type: "AWS::StepFunctions::StateMachine",
            Properties: {
                StateMachineName: stateMachineName,
                RoleArn: {
                    "Fn::GetAtt": [
                        roleName,
                        "Arn"
                    ]
                },
                DefinitionString: definition
            }
        };

        this.yaml.Resources[resourceName] = p;
    }

    addTriggerRule(resourceName: string, ruleName: string, expression: string, target: string) {
        let p = {
            Type: "AWS::Events::Rule",
            Properties: {
                Name: ruleName,
                ScheduleExpression: expression,
                Targets: [
                    {
                        Arn: {
                            "Fn::GetAtt": [
                                target,
                                "Arn"
                            ]
                        },
                        Id: target
                    }
                ]
            }
        };

        this.yaml.Resources[resourceName] = p;
    }

    addParameter(param: string, defaultParam?: string) {
        let p: Parameter = {
            Type: "String"
        };
        if (defaultParam) {
            p.Default = defaultParam;
        }
    
        this.yaml.Parameters[param] = p;
    }

    addOutputByRef(name: string, ref: string) {
        this.yaml.Outputs[name] = {
            Value: {
                Ref: ref
            }
        };
    }
    addOutputByGetAtt(name: string, att: string) {
        this.yaml.Outputs[name] = {
            Value: {
                "Fn::GetAtt": [
                    att,
                    "Arn"
                ]
            }
        };
    }
}