import React, { ReactNode, createContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";

import { IFeed } from "../types/IFeed";
import { FETCH_LATEST_FEEDS_DB } from "config";

export const LatestFeedsContext = createContext<ILatestFeedsContext>(null!);
export interface ILatestFeedsContext extends IDataContext<IFeed> { }


interface ILatestFeedsContextProvider {
    children: ReactNode;
}
const LatestFeedsContextProvider = ({ children }: ILatestFeedsContextProvider) => {
    return (
        <DataContextProvider<IFeed>
            context={LatestFeedsContext}
            endpoint={FETCH_LATEST_FEEDS_DB}
        >
            {children}
        </DataContextProvider>
    );
};

export default LatestFeedsContextProvider;