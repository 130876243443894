import { Feeds } from "./feeds"

export const FeedNames = new Map<string, string>([
    [Feeds.AccountCounter, "Account Counter"],
    [Feeds.AccountCounterRecord, "Account Counter Record"],
    [Feeds.AccountHistory, "Account History"],
    [Feeds.ActivationTermination, "Activation Termination"],
    [Feeds.Adjustment, "Adjustment"],
    [Feeds.AdjustmentRecord, "Adjustment Record"],
    [Feeds.CallRecord, "Call Record"],
    [Feeds.CallsInbound, "Calls Inbound"],
    [Feeds.CallsOutbound, "Calls Outbound"],
    [Feeds.CustomerHistory, "Customer History"],
    [Feeds.DataSessions, "Data Sessions"],
    [Feeds.DataSessionsTBUsed, "Data Sessions - TB Used"],
    [Feeds.DataSessionsUserCount, "Data Sessions - User Count"],
    [Feeds.Inventory, "Inventory"],
    [Feeds.PadsRecord, "Pads Record"],
    [Feeds.PhoneNumberHistory, "Phone Number History"],
    [Feeds.SIMHistory, "SIM History"],
    [Feeds.SMSOutbound, "SMS Outbound"],
    [Feeds.SMSRecord, "SMS Record"],
    [Feeds.SubscriptionHistory, "Subscription History"],
    [Feeds.Topup, "Topup"],
    [Feeds.ANIInventory, "ANI Inventory"],
    [Feeds.PDACustomerDeactivation, "PDA Customer Deactivation"],
    [Feeds.PDAIMEIRef, "PDA IMEI Ref"],
    [Feeds.PDAInterconnectVoice, "PDA Interconnect Voice"],
    [Feeds.PDAMemberExpiry, "PDA Member Expiry"],
    [Feeds.PDAMOPrepaidMMS, "PDA MO Prepaid MMS"],
    [Feeds.PDAMOPrepaidSMS, "PDA MO Prepaid SMS"],
    [Feeds.PDAMOPrepaidVoice, "PDA MO Prepaid Voice"],
    [Feeds.PDAMOWholesaleVoice, "PDA MO Wholesale Voice"],
    [Feeds.PDAMTPrepaidSMS, "PDA MT Prepaid SMS"],
    [Feeds.PDAMTPrepaidVoice, "PDA MT Prepaid Voice"],
    [Feeds.PDAMTWholesaleVoice, "PDA MT Wholesale Voice"],
    [Feeds.PDAPortIn, "PDA Port In"],
    [Feeds.PDAPortOut, "PDA Port Out"],
    [Feeds.PDAPrepaidGPRS, "PDA Prepaid GPRS"],
    [Feeds.PDAPrepaidTopups, "PDA Prepaid Topups"],
    [Feeds.PDATimeRecTopups, "PDA Time Rec Topups"],
    [Feeds.PDATRTRecurringMem, "PDA TRT Recurring Mem"],
    [Feeds.SHIPNOT, "Shipment Notifications"],
    [Feeds.MessageHistoryReport, "Message History Report"],
    [Feeds.PDAAccountBalance, "PDA Account Balance"],
    [Feeds.PDAAccountLifecycle, "PDA Account Lifecycle"],
    [Feeds.PDAAccountStatus, "PDA Account Status"],
    [Feeds.PDABalanceManagement, "PDA Balance Management"],
    [Feeds.PDAFreeUsage, "PDA Free Usage"],
    [Feeds.PDAPortOutBalance, "PDA Port Out Balance"],
    [Feeds.PDASocialMedia, "PDA Social Media"],
    [Feeds.PDASubscrAdj, "PDA Subscr Adj"],
    [Feeds.PDARoamingSummary, "PDA Roaming Summary"],
    [Feeds.PDAEEARoamingSummary, "PDA EEA Roaming Summary"],
    [Feeds.PDAIntraEEASummary, "PDA Intra EEA Summary"],
    [Feeds.PDASNSMessage, "PDA SNS Message"],
    [Feeds.PDAVoucherInit, "PDA Voucher Init"],
    [Feeds.PDAVoucherRtd, "PDA Voucher RTD"],
    [Feeds.PDAVoucherUsed, "PDA Voucher Used"],
    [Feeds.PDAWhatsApp, "PDA WhatsApp"],
    [Feeds.PDACreditCard, "PDA Credit Card"],
    [Feeds.FreemiumParqueReport, "Freemium Parque Report"],
    [Feeds.ReportTableroDiario, "Report Tablero Diario"],
    [Feeds.CalidadDeAltasWeekly, "Calidad de Altas Weekly"],
    [Feeds.CalidadDeAltasReport, "Calidad de Altas Report"],
    [Feeds.MatrizA, "Matriz A"],
    [Feeds.ReporteVentas, "Reporte Ventas"],
    [Feeds.WeeklyParqueReport, "Weekly Parque Report"],
    [Feeds.MonthlyParqueReport, "Monthly Parque Report"],
    [Feeds.ComboReport, "Combo Report"],
    [Feeds.ConcentracionMercado, "Concentracion Mercado"],
    [Feeds.CostoGigabyte, "Costo Gigabyte"],
    [Feeds.MOUItx, "MOU itx"],
    [Feeds.MOU, "MOU"],
    [Feeds.SaldosAcumulados, "Saldos Acumulados"],
    [Feeds.ActivatedCustomers, 'Activated Customers'],
    [Feeds.ActivationRate, 'Activation Rate'],
    [Feeds.BaseManagementThreeReport, 'Base Management - Three Report'],
    [Feeds.BaseManagement, 'Base Management'],
    [Feeds.BaseManagementMonthly, 'Base Management Monthly'],
    [Feeds.BaseManagement1, 'Base Management 1'],
    [Feeds.BaseManagement2, 'Base Management 2'],
    [Feeds.BundlesPerDaySummary, 'Bundles Per Day Summary'],
    [Feeds.CallTypeSummary, 'Call Types Summary'],
    [Feeds.CampaignPIR, 'Campaign PIR'],
    [Feeds.ChurnFunnel, 'Churn Funnel'],
    [Feeds.CreditApplication, 'Credit Application'],
    [Feeds.CreditApplicationReport, 'Credit Application Report'],
    [Feeds.DailyFlash, 'Daily Flash'],
    [Feeds.Daily, 'Daily'],
    [Feeds.DecayRate, 'Decay Rate'],
    [Feeds.Donations, 'Donations'],
    [Feeds.FailedPayments, 'Failed Payments'],
    [Feeds.FlexiData, 'Flexi Data'],
    [Feeds.FreeUsageTest, 'Free Usage Test'],
    [Feeds.FreeUsage, 'Free Usage'],
    [Feeds.FUPDailyReportDistinctDial, 'FUP Daily Report Distinct Dial'],
    [Feeds.KickbackPortalOrders, 'Kickback Portal Orders'],
    [Feeds.KickbackPortalOrdersCSV, 'Kickback Portal Orders CSV'],
    [Feeds.KickbackPortalOrdersExcel, 'Kickback Portal Orders Excel'],
    [Feeds.Migration, 'Migration'],
    [Feeds.PortInOutSummary, 'Port In Out Summary'],
    [Feeds.PortInOutSummaryCSV, 'Port In-Out Summary CSV'],
    [Feeds.PortInOutSummaryExcel, 'Port In-Out Summary Excel'],
    [Feeds.RecruiteesReport, 'Recruitees Report'],
    [Feeds.SMSAndVoiceAbuseWeeklyReport, 'SMS and Voice Abuse Weekly Report'],
    [Feeds.TheSafe, 'The Safe'],
    [Feeds.WeeklyTradingReport, 'Weekly Trading Report'],
    [Feeds.Tester48, '48 Tester'],
    [Feeds.TotalNetworkVoiceMonthly, 'Total Network Voice Monthly'],
    [Feeds.TotalNetworkSMSMonthly, 'Total Network SMS Monthly'],
    [Feeds.DataUsageTotalNetworkMonthly, 'Data Usage Total Network Monthly'],
    [Feeds.ComReg, 'Com Reg'],
    [Feeds.BaseManagementThreeReportMonthly, 'Base Management Three Report Monthly'],
    [Feeds.PortingAndRoamingQuarterly, 'Porting and Roaming Quarterly'],
    [Feeds.Segmentation, 'Segmentation'],
    [Feeds.Budget48, '48 Budget'],
    [Feeds.ActiveSIM, 'Active SIM'],
    [Feeds.CH48Campus, 'CH48 Campus'],
    [Feeds.CreditCardTransaction, 'Credit Card Transaction'],
    [Feeds.CustomerContactsHistory, 'Customer Contacts History'],
    [Feeds.CustomerSIM, 'Customer SIM'],
    [Feeds.FJSRet, 'FJS Ret'],
    [Feeds.NotContactEmail, 'Not Contact Email'],
    [Feeds.NotContactSMS, 'Not Contact SMS'],
    [Feeds.O2MTSMS, 'O2 MT SMS'],
    [Feeds.Order, 'Order'],
    [Feeds.OrderItem, 'Order Item'],
    [Feeds.VoucherCredits, 'Voucher Credits'],
    [Feeds.PortOut, 'Port Out'],
]) 