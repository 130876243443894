import React from "react";
import { Route, Routes } from "react-router-dom";

import { Permission } from "core/enums/Permissions";
import PermissionsCheck from "core/helpers/auth/PermissionsCheck";

import FeedsListTable from "./components/FeedsListTable";
import FeedPage from "./components/FeedPage";


import "./_feeds.scss";


const FeedsPage = () => {
  return (
    <section className="feeds-db">
      <Routes>
        <Route path="/" element={
          <PermissionsCheck permission={Permission.ViewFeeds}>
            <FeedsListTable />
          </PermissionsCheck>
        } />
        <Route path="/:client/:feed" element={
          <PermissionsCheck permission={Permission.ViewFeedDetails}>
            <FeedPage />
          </PermissionsCheck>
        } />
      </Routes>
    </section>
  );
};


export default FeedsPage;
