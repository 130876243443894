import React, { FormEvent, useState } from "react";

import { Fade } from "react-awesome-reveal";

const BUTTON_STATE_DEFAULT = '';
const BUTTON_STATE_LOADING = 'loading';
const BUTTON_STATE_SENT = 'sent';

interface ILoginFormProps {
    doLogin: (email: string, password: string) => Promise<void>;
    setupForPasswordReset: (email: string) => Promise<void>;
}

const LoginForm = ({ doLogin, setupForPasswordReset }: ILoginFormProps) => {
    const [btnState, setBtnState] = useState(BUTTON_STATE_DEFAULT);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e: FormEvent) => {
        e.preventDefault();
        setBtnState(BUTTON_STATE_LOADING);

        await doLogin(email, password);
        setBtnState(BUTTON_STATE_DEFAULT);
    };

    const handlePasswordReset = async (e: FormEvent) => {
        e.preventDefault();
        setBtnState(BUTTON_STATE_LOADING);

        try {
            await setupForPasswordReset(email);
            // Don't set the button state here as the form has been replaced
        } catch (err) {
            setBtnState(BUTTON_STATE_DEFAULT);
        }
    };

    return (
        <form className="login--hero__form" onSubmit={handleLogin}>
            <Fade duration={1500} delay={50}>
                <>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoFocus
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={100}>
                <>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={150}>
                <>
                    <button
                        disabled={btnState === BUTTON_STATE_LOADING || btnState === BUTTON_STATE_SENT}
                        className={`login--button ${btnState}`}
                    ></button>
                    <button
                        disabled={btnState === BUTTON_STATE_LOADING || btnState === BUTTON_STATE_SENT}
                        className={`login--button forgot ${btnState}`}
                        onClick={handlePasswordReset}
                    ></button>
                </>
            </Fade>
        </form>
    );
};

export default LoginForm;
