import React, { ReactNode } from "react";

import Tile from './Tile';

interface ISquareTileProps {
    children: ReactNode;
}

const SquareTile = ({ children }: ISquareTileProps) => <Tile type={'square'}>{children}</Tile>;

export default SquareTile;