import React, { ReactNode } from "react";

interface ITileProps {
    children: ReactNode;
    type?: string;
}

const Tile = ({ children, type = '' }: ITileProps) => {
    return (
        <div className={`tile ${type}`}>
            {children}
        </div>
    )
};
export default Tile;