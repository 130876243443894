import React, { useCallback, useContext, useState } from "react";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { LambdaContext } from "core/data/contexts/LambdaContextProvider";
import { FeedNames } from "core/data/feeds/feed_names";
import SendToLambda from "core/data/aws/lambda/SendToLambda";

import FormatDate from 'core/helpers/datetime/FormatDate';
import Log from "core/helpers/Log";

import { IIssue } from "../IssuesList";

import { RESOLVE_ISSUE_LAMBDA } from 'config';

const BUTTON_STATE_DEFAULT = '';
const BUTTON_STATE_LOADING = 'resolving';
const BUTTON_STATE_SENT = 'resolved';

interface IIssueProps {
  item: IIssue;
  action?: (item: IIssue) => void;
}
const Issue = ({ item, action }: IIssueProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [btnState, setBtnState] = useState(BUTTON_STATE_DEFAULT);
  const [resolved, setResolved] = useState(item.status === "RESOLVED");

  const { lambda } = useContext(LambdaContext);

  const ActionCB = useCallback((item: IIssue) => (
    action
      ? action(item)
      : () => { }
  ), [action]);

  const toggleShowDetails = () => setShowDetails(!showDetails);

  const resolveIssue = async () => {
    if (!lambda) return;

    setBtnState(BUTTON_STATE_LOADING);

    try {
      const [err, data] = await SendToLambda(lambda, RESOLVE_ISSUE_LAMBDA, JSON.stringify({ key: item.Key }));
      Log(RESOLVE_ISSUE_LAMBDA, err, data);

      if (err) {
        setBtnState(BUTTON_STATE_DEFAULT);
        setResolved(false);
      } else {
        setBtnState(BUTTON_STATE_SENT);
        setResolved(true);
        ActionCB({
          ...item,
          status: 'RESOLVED',
        });
      }
    } catch (err) {
      console.error(err);
      setBtnState(BUTTON_STATE_DEFAULT);
      setResolved(false);
    }
  };

  return (
    <div className={`listitem issue-${!resolved ? 'ERROR' : 'OK'}`}>
      <div className={`listitem__header ${showDetails ? 'expanded' : ''}`} onClick={toggleShowDetails}>
        <div className={'listitem__toggle-details'}>
          <div className={'listitem__toggle-details__container'}>
            {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </div>
        <div className={'listitem__title'}>
          {FeedNames.get(item.feed_name) ?? item.feed_name}
        </div>
        <div className={'listitem__subtitle'}>
        </div>
        <div className={`listitem__value issue-${!resolved ? 'ERROR' : 'OK'}`}>
          {item.error} {!resolved ? <ReportProblemIcon /> : <CheckCircleOutlineIcon />}
        </div>
        <div className={'listitem__separator'}></div>
      </div>
      <div className={`listitem__body ${showDetails ? '' : 'hidden'}`}>
        <div className={`listitem__data ${showDetails ? '' : 'hidden'}`}>
          <div className={'listitem__subtitle'}>
            <b>File:</b> {item.Key.split("/").slice(3).join("/")}
          </div>
        </div>
        <div className={`listitem__data ${showDetails ? '' : 'hidden'}`}>
          <div className={'listitem__data-item'}>
            <div>time_raised</div>
            <div>{FormatDate(item.time_raised)}</div>
          </div>
          <div className={'listitem__data-item'}>
            <div>assignee</div>
            <div>{!!(item.assignee) ? item.assignee : "Unassigned"}</div>
          </div>
          <div className={'listitem__data-item wd-4'}>
            <div>notes</div>
            <div>{!!(item.notes) ? item.notes : "No notes"}</div>
          </div>
          <div className={'listitem__data-item'}>
            <div></div>
            <div>
              <button disabled={btnState === BUTTON_STATE_LOADING}
                className={`btn btn--red resolve ${btnState}`}
                onClick={resolveIssue}
              >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Issue;