import React, { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";

import { AWSAuthContext } from 'core/data/aws/AWSAuthContextProvider';

import IsEmptyObj from '../IsEmptyObj';

interface IPrivateRouteProps {
    children: ReactNode;
}

const PrivateRoute = ({ children }: IPrivateRouteProps): JSX.Element => {
    const { user } = useContext(AWSAuthContext);

    if (IsEmptyObj(user)) return <Navigate to="/login" />;

    return (
        <>
            {children}
        </>
    );
};

export default PrivateRoute;