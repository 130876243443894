import React from "react";
import { Link } from "react-router-dom";

import logo from "core/images/dk_logo_primary.svg";

import "./_error-404.scss";

const Error404 = () => {
  return (
    <section className="error-404">

      <Link to="/">
        <img src={logo} alt="dk_logo" className="mb-4" />
      </Link>
      <h1 className="p-3">Page not found</h1>
      <Link to="/" className="btn btn--red">
        Back to home
      </Link>
    </section>
  );
};

export default Error404;
