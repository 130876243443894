import React from "react";
import { useLocation } from "react-router-dom";

import { SubPages } from "../enums/SubPages";
import NavLink from "./NavLink";


interface ISubNavProps {
  pages: SubPages[];
  prefix: string;
}

const SubNav = ({ pages, prefix }: ISubNavProps) => {
  const location = useLocation();

  const currentPath = location.pathname.split("/")[2];
  const page = pages.filter(p => p.toLowerCase() === currentPath)[0];
  
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg">
        <div className="justify-content-start">
          <ul className="pt-4">
            {pages.map((p) => (
              <NavLink 
                key={p}
                to={"/" + prefix + "/" + p.toLowerCase()}
                active={page === p}
                label={p}
              />
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default SubNav;