import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { ClientIds } from "core/data/clients/client_ids";
import { ClientNames } from "core/data/clients/client_names";
import { FeedNames } from "core/data/feeds/feed_names";

import { LatestMetricsContext } from "../contexts/LatestMetricsContextProvider";

import "../_metrics.scss";

const MetricTitle = ({ client, metric }: { client: number; metric: number }) => {
    const { items: latestMetrics, setVisible: setLatestVisible } = useContext(LatestMetricsContext);

    useEffect(() => {
        setLatestVisible(true);

        return () => {
            setLatestVisible(false);
        };
    }, [setLatestVisible]);

    const latestMetric = latestMetrics?.find((x) => x.client_id === client && x.metric_id === metric);

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%" }}>
            <Button style={{ marginRight: "auto", alignSelf: "center" }} color="secondary" onClick={goBack}>
                Back
            </Button>
            <h1 style={{ marginRight: "2rem", marginBottom: "0" }}>{metric ? FeedNames.get(latestMetric?.metric_name ?? "") ?? latestMetric?.metric_name ?? "" : ""}</h1>
            <h3 style={{ marginRight: "auto", marginBottom: ".2rem" }}>{ClientNames.get(ClientIds.get(client) ?? "0") ?? ""}</h3>
        </div>
    );
};

export default MetricTitle;