
import { InvokeCommandInput, Lambda } from '@aws-sdk/client-lambda';

import IsEmptyObj from 'core/helpers/IsEmptyObj';
import Debug from 'core/helpers/Log';

const LambdaRequest = async <T>(lambda: Lambda, functionName: string, payload: string): Promise<[e: string, d: T]> => {
    if (!lambda) return Promise.reject(['No lambda', []]);

    try {
        var enc = new TextEncoder();

        // Setup the request parameters
        let params: InvokeCommandInput = {
            FunctionName: functionName
        };
        if (payload) {
            params.Payload = enc.encode(payload);
        }

        // Invoke the Lambda function
        const data = await lambda.invoke(params);
        if (!('Payload' in data)) {
            console.log(data);
            return Promise.reject(['Unknown response', []]);
        }


        let response: T = {} as T;

        // Parse the response
        Debug(functionName, data);
        var dec = new TextDecoder("utf-8");
        const resPayload = JSON.parse(dec.decode(data.Payload as Uint8Array));
        Debug(functionName, resPayload);

        if (!('body' in resPayload)) {
            if ('errorMessage' in resPayload) {
                return Promise.reject([resPayload.errorMessage, []]);
            } else {
                throw new Error('No body in payload');
            }
        }

        // Get the response body
        resPayload.body = JSON.parse(resPayload.body);
        Debug(functionName, resPayload.body);

        if (!IsEmptyObj(resPayload.body)) {
            response = resPayload.body;
        }
        return Promise.resolve(['', response]);
    } catch (err: any) {
        console.error(err);
        return Promise.reject([err.message, []]);
    }
}

export default LambdaRequest;