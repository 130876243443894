const FormatCounter = (time: number, format: string = 'short') => {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (format === 'full') {
        let str = '';
        if (days > 0) str += `${days}d `;
        if (hours > 0) str += `${hours % 24}h `;
        if (minutes > 0) str += `${minutes % 60}m `;
        if (seconds > 0) str += `${seconds % 60}s`;
        return str;
    } else if (format === 'short') {
        if (days > 0) return `${days}d `;
        if (hours > 0) return `${hours % 24}h `;
        if (minutes > 0) return `${minutes % 60}m `;
        if (seconds > 0) return `${seconds % 60}s`;
        return '0s';
    } else if (format === 'hours') {
        return `${hours}h `;
    }
};
export default FormatCounter;