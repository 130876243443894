import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { CellProps, Column } from "react-table";

import Box from "@mui/material/Box";

import CustomTable from "core/components/status-table/CustomTable";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import { ClientNames } from "core/data/clients/client_names";
import { UserContext } from "core/data/auth/UserContextProvider";
import { Clients } from "core/data/clients/clients";
import { ClientIds } from "core/data/clients/client_ids";
import GetStatusIcon from "core/helpers/GetStatusIcon";

import { transformFeedsData } from "features/dashboards/feeds/components/FeedsListTable";
import { transformReportsData } from "features/dashboards/reports/components/ReportsListTable";
import { transformMetricsData } from "features/dashboards/metrics/components/MetricsListTable";
import { transformCampaignsData } from "features/dashboards/campaigns/components/CampaignsListTable";

import { LatestFeedsContext } from "../../feeds/contexts/LatestFeedsContextProvider";
import { LatestReportsContext } from "../../reports/contexts/LatestReportsContextProvider";
import { LatestMetricsContext } from "../../metrics/contexts/LatestMetricsContextProvider";
import { LatestCampaignsContext } from "../../campaigns/contexts/LatestCampaignsContextProvider";
import ListTile from "core/components/tiles/ListTile";
import { Permission } from "core/enums/Permissions";


interface IStatusList {
    client: string;
    feeds: IssueEnum;
    reports: IssueEnum;
    metrics: IssueEnum;
    campaigns: IssueEnum;
}
interface ITableCols<T extends object> {
    Header: string;
    accessor: string;
    Cell?: ({ value }: CellProps<T>) => JSX.Element;
}


const StatusSummary = () => {
    const { clients, permissions } = useContext(UserContext);
    
    const { items: latestFeeds, setVisible: setFeedsVisible } = useContext(LatestFeedsContext);
    const { items: latestReports, setVisible: setReportsVisible } = useContext(LatestReportsContext);
    const { items: latestMetrics, setVisible: setMetricsVisible } = useContext(LatestMetricsContext);
    const { items: latestCampaigns, setVisible: setCampaignsVisible } = useContext(LatestCampaignsContext);

    
    useEffect(() => {
        setFeedsVisible(true);
        setReportsVisible(true);
        setMetricsVisible(true);
        setCampaignsVisible(true);

        return () => {
            setFeedsVisible(false);
            setReportsVisible(false);
            setMetricsVisible(false);
            setCampaignsVisible(false);
        };
    }, [setFeedsVisible, setReportsVisible, setMetricsVisible, setCampaignsVisible]);

    const feedsData = useMemo(() => transformFeedsData(latestFeeds), [latestFeeds]);
    const reportsData = useMemo(() => transformReportsData(latestReports), [latestReports]);
    const metricsData = useMemo(() => transformMetricsData(latestMetrics), [latestMetrics]);
    const campaignsData = useMemo(() => transformCampaignsData(latestCampaigns), [latestCampaigns]);

    const GetStatusForClient = useCallback((data: any[], client: string) => {
        if (!data) return IssueEnum.OK;

        const clientFeeds = data.filter(x => x.client_name === client);

        const issues = clientFeeds.map(x => x.issue);

        // TODO: Improve by sorting, then popping the first issue
        if (issues.includes(IssueEnum.ERROR)) return IssueEnum.ERROR;
        if (issues.includes(IssueEnum.WARNING)) return IssueEnum.WARNING;
        return IssueEnum.OK;
    }, []);


    const data = useMemo(() => {
        if (!feedsData || !reportsData || !metricsData || !campaignsData) return null;

        if (clients.some(x => ClientIds.get(x) === Clients.DataKraken)) {
            return Object.values(Clients)
                .filter(x => x === Clients.FortyEight || x === Clients.TuentiArgentina || x === Clients.TuentiEcuador)
                .map(c => ({
                    client: ClientNames.get(c) ?? c,
                    feeds: GetStatusForClient(feedsData, c),
                    reports: GetStatusForClient(reportsData, c),
                    metrics: GetStatusForClient(metricsData, c),
                    campaigns: GetStatusForClient(campaignsData, c),
                }));
        } else {
            const c = ClientNames.get(Clients.FortyEight) ?? "48"; 
            return [{
                client: c,
                feeds: GetStatusForClient(feedsData, c),
                reports: GetStatusForClient(reportsData, c),
                metrics: GetStatusForClient(metricsData, c),
                campaigns: GetStatusForClient(campaignsData, c),
            }];
        }
    }, [GetStatusForClient, feedsData, reportsData, metricsData, campaignsData, clients]);


    const columns = useMemo(
        () => {
            let cols: any[] = [];
            
            if (permissions.includes(Permission.ViewFeeds)) {
                cols.push(
                    {
                        Header: 'Feeds',
                        accessor: 'feeds',
                        Cell: ({ value }: { value: IssueEnum }) => GetStatusIcon(value),
                        style: { 
                            textAlign: 'center',
                            width: '25%'
                        },
                    }
                );
            }
            if (permissions.includes(Permission.ViewReports)) {
                cols.push(
                    {
                        Header: 'Reports',
                        accessor: 'reports',
                        Cell: ({ value }: { value: IssueEnum }) => GetStatusIcon(value),
                        style: { 
                            textAlign: 'center',
                            width: '25%'
                        },
                    }
                );
            }
            if (permissions.includes(Permission.ViewMetrics)) {
                cols.push(
                    {
                        Header: 'Metrics',
                        accessor: 'metrics',
                        Cell: ({ value }: { value: IssueEnum }) => GetStatusIcon(value),
                        style: { 
                            textAlign: 'center',
                            width: '25%'
                        },
                    }
                );
            }
            if (permissions.includes(Permission.ViewCampaigns)) {
                cols.push(
                    {
                        Header: 'Campaigns',
                        accessor: 'campaigns',
                        Cell: ({ value }: { value: IssueEnum }) => GetStatusIcon(value),
                        style: { 
                            textAlign: 'center',
                            width: '25%'
                        },
                    }
                );
            }

            if (clients.some(x => ClientIds.get(x) === Clients.DataKraken)) {
                cols.unshift(
                    {
                        Header: 'Client',
                        accessor: 'client',
                        style: { textAlign: 'left' },
                    }
                );
            }
            return cols;
        }, [clients, GetStatusIcon]
    );
    
    return (
            <ListTile>
                <h1 className={`list__title`}>
                    Status
                </h1>

                {data && (
                    <Box className={'tabs-container'}>
                        <CustomTable columns={columns} data={data} />
                    </Box>
                )}
                {!data && <LoadingSpinner />}
            </ListTile>
    );
}

export default StatusSummary;