import IsDateObj from "./IsDateObj";

const ParseDBDate = (dateStr: string | Date) => {
    if (!dateStr) return 0;
    
    const date = IsDateObj(dateStr) ? dateStr.toString() : dateStr as string;

    return new Date(date.replace("Z", "+0100")).getTime();
}

export default ParseDBDate;