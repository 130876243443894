import React, { FormEvent, useState } from "react";

import { Fade } from "react-awesome-reveal";

const BUTTON_STATE_DEFAULT = '';
const BUTTON_STATE_LOADING = 'loading';
const BUTTON_STATE_SENT = 'sent';

interface INewPasswordFormProps {
    user: object;
    doNewPassword: (user: object, newPassword: string, name: string) => Promise<void>;
}

const NewPasswordForm = ({ user, doNewPassword }: INewPasswordFormProps) => {
    const [btnState, setBtnState] = useState(BUTTON_STATE_DEFAULT);
    const [name, setName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleNewPassword = async (e: FormEvent) => {
        e.preventDefault();
        setBtnState(BUTTON_STATE_LOADING);

        try {
            await doNewPassword(user, newPassword, name);
        } catch (err) {
            setBtnState(BUTTON_STATE_DEFAULT);
        }
    }

    return (
        <form className="login--hero__form" onSubmit={handleNewPassword} autoComplete="off">
            <Fade duration={1500} delay={50}>
                <>
                    <h2>Welcome to Iris!</h2>
                    <h3>Please update your account details</h3>
                </>
            </Fade>
            <Fade duration={1500} delay={100}>
                <>
                    <label htmlFor="password">New Password</label>
                    <input
                        type="password"
                        placeholder="New Password"
                        name="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        autoFocus
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={150}>
                <>
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={200}>
                <>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    ></input>
                </>
            </Fade>
            <Fade duration={1500} delay={150}>
                <button
                    disabled={btnState === BUTTON_STATE_LOADING || btnState === BUTTON_STATE_SENT || newPassword !== confirmPassword}
                    className={`login--button update ${btnState}`}
                ></button>
            </Fade>
        </form>
    );
}

export default NewPasswordForm;