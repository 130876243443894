import React from "react";
import ReactEcharts from "echarts-for-react";

import ListTile from "core/components/tiles/ListTile";

const reports = require("./reports.json");
const sms_data = require("./data/SMSRECORD_Aggregate_Report2_Outbound_per_date_json.json");


const bw_colours = [
    "#909090",
    "#808080",
    "#707070",
    "#606060",
    "#505050",
    "#404040",
    "#303030",
    "#202020",
    "#101010",
    "#000000"
];
const red_colours = [
    "#ff9090",
    "#ff8080",
    "#ff7070",
    "#ff6060",
    "#ff5050",
    "#ff4040",
    "#ff3030",
    "#ff2020",
    "#ff1010",
    "#ff0000"
];


const sms_cost = 0.014;
const tariff_cost = 7.11;

const ToPounds = (v: number) => Math.round(v * 100) / 100;


export const DataPage = () => {
    return (
        <section className="data">
        <ListTile>
            <SMSCostByUserHist />
            <SMSCostByUser />
            <SMSCostByDate />
            {/* <SMSCostByDist /> */}
            {/* <CallsInboundChartDist />
            <CallsOutboundChartDist />
            <PadsReceivedChartDist />
            <PadsSentChartDist /> */}
            {/* <SMSChart_Dist /> */}
            {/* <SMSScatter /> */}
            {/* <Tile type={"wide list"}>
                <SMSMap />
            </Tile> */}
        </ListTile>
    </section>
    );
};

export default DataPage;


interface SMSAccountData {
    Date: string;
    SMS_Outbound: string;
    accountnum: string;
}

export const SMSCostByDate = () => {
    const sms_data_by_date = (sms_data as SMSAccountData[]).reduce((acc: CountDict, curr: SMSAccountData) => {
        const date = curr.Date;
        const count = parseInt(curr.SMS_Outbound);
        if (!acc[date]) {
            acc[date] = 0;
        }
        acc[date] += count;
        return acc;
    }, {} as CountDict);
    console.log('sms_data_by_date', sms_data_by_date);

    interface IData {
        value: [string, number]
    }
    const data: IData[] = Object.entries(sms_data_by_date).map((x) =>  ({
        value: [x[0], x[1]],
        itemStyle: {
            color: red_colours[8]
        }
    }));

    
    const max_val = Math.max(...data.map(x => x.value[1]));
    const rounded_max_val = Math.ceil(max_val / 1000) * 1000;
    console.log('rounded_max_val', rounded_max_val);
    
    const option = {
        title: {
            text: "SMS Sent by Date",
        },
        grid: {
            top: 150
        },
        tooltip: {
            trigger: 'item',
            formatter: (p: any) => `${p.data.value[1]} SMSes - £${ToPounds(sms_cost * p.data.value[1])}`
        },
        xAxis: {
            type: 'category',
        },
        yAxis: [
            {
                name: "SMSes",
                type: 'value',
            },
            {
                name: "Cost",
                type: 'value',
                alignTicks: true,
                axisLabel: {
                    formatter: (v: any) => `£${ToPounds(v)}`
                },
                min: 0,
                max: sms_cost * max_val,
            }
        ],
        series: [
            {
                type: "bar",
                data: data
            },
        ],
    };

    return (
        <ReactEcharts
            option={option}
            style={{height: '500px', width: '100%'}}
            className='react_for_echarts'
        />
    )
}

export const SMSCostByUser = () => {
    const sms_data_by_date = (sms_data as SMSAccountData[]).reduce((acc: CountDict, curr: SMSAccountData) => {
        const date = curr.Date;
        const count = parseInt(curr.SMS_Outbound);
        if (!acc[date]) {
            acc[date] = 0;
        }
        acc[date] += count;
        return acc;
    }, {} as CountDict);
    console.log('sms_data_by_date', sms_data_by_date);

    const sms_data_by_user = (sms_data as SMSAccountData[]).reduce((acc: CountDict, curr: SMSAccountData) => {
        const accountnum = curr.accountnum;
        const count = parseInt(curr.SMS_Outbound);
        if (!acc[accountnum]) {
            acc[accountnum] = 0;
        }
        acc[accountnum] += count;
        return acc;
    }, {} as CountDict);
    console.log('sms_data_by_user', sms_data_by_user);

    console.log(Object.values(sms_data_by_user));


    const days = Object.values(sms_data_by_date).filter((x: number) => x > 50000).length;
    const tariff_cost_of_range = tariff_cost * days / 30;
    const profitable_threshold = tariff_cost_of_range / sms_cost;
    console.log(days, tariff_cost_of_range, profitable_threshold);


    interface IData {
        value: [string, number]
    }
    const data: IData[] = Object.entries(sms_data_by_user).map((x) =>  ({
        value: [x[0], x[1]],
        itemStyle: {
            color: x[1] > profitable_threshold ? red_colours[8] : red_colours[0]
        }
    }));
    data.sort((a, b) => b.value[1] - a.value[1]);
    console.log('data', data);


    const user_count = Object.keys(sms_data_by_user).length;
    const over_threshold = data.filter((x: IData) => x.value[1] > profitable_threshold).length;
    const under_threshold = user_count - over_threshold;
    const loss_perc = 100 * Math.round(10000 * over_threshold / user_count) / 10000;
    const loss_value = ToPounds(data.reduce((a: number, c) => a + (c.value[1] > profitable_threshold ? (c.value[1] - profitable_threshold) * sms_cost : 0), 0));
    const revenue = ToPounds(user_count * tariff_cost_of_range);

    console.log(user_count, profitable_threshold, over_threshold, under_threshold, loss_perc, loss_value);

    const loss_statement = `${over_threshold} users (${loss_perc}%) causing a net loss of £${loss_value}`
    const value_statement = `${user_count} users earning a revenue of £${revenue}`;
    const tariff_statement = `Showing SMSes sent over ${days} days - pro-rated tariff cost of £${ToPounds(tariff_cost_of_range)}.`

    
    const max_val = data[0].value[1];
    const rounded_max_val = Math.ceil(max_val / 1000) * 1000;
    console.log('rounded_max_val', rounded_max_val);
    
    const option = {
        title: {
            text: "SMS Profitability",
            subtext: tariff_statement + "\n\n" + value_statement + "\n\n" + loss_statement,
        },
        grid: {
            top: 150
        },
        tooltip: {
            trigger: 'item',
            formatter: (p: any) => `${p.data.value[1]} SMSes - £${ToPounds(sms_cost * p.data.value[1])}`
        },
        xAxis: {
            type: 'category',
        },
        yAxis: [
            {
                name: "SMSes",
                type: 'value',
            },
            {
                name: "Cost",
                type: 'value',
                alignTicks: true,
                axisLabel: {
                    formatter: (v: any) => `£${ToPounds(v)}`
                },
                min: 0,
                max: sms_cost * max_val,
            }
        ],
        series: [
            {
                type: "bar",
                data: data
            },
            // {
            //     type: "line",
            //     data: data.map((x: any) => 5000),
            //     yAxisIndex: 1,
            // }
        ],
        dataZoom: [
            {
                type: 'slider',
                show: true,
                xAxisIndex: [0],
                start: 0,
                end: 5 * loss_perc,
            },
        ]
    };

    return (
        <ReactEcharts
            option={option}
            style={{height: '500px', width: '100%'}}
            className='react_for_echarts'
        />
    )
}

export const SMSCostByUserHist = () => {
    const sms_data_by_user = (sms_data as SMSAccountData[]).reduce((acc: CountDict, curr: SMSAccountData) => {
        const accountnum = curr.accountnum;
        const count = parseInt(curr.SMS_Outbound);
        if (!acc[accountnum]) {
            acc[accountnum] = 0;
        }
        acc[accountnum] += count;
        return acc;
    }, {} as CountDict);
    // console.log('sms_data_by_user', sms_data_by_user);

    const number_of_users_per_sms_count = Object.values(sms_data_by_user).reduce((acc: CountDict, curr: number) => {
        if (!acc[curr]) {
            acc[curr] = 0;
        }
        acc[curr] += 1;
        return acc;
    }, {} as CountDict);
    console.log('number_of_users_per_sms_count', number_of_users_per_sms_count);


    const bins = [1, 3, 10, 30, 100, 300, 1000];
    const data = bins.map((bin, ii) => {
        const bin_count = Object.entries(number_of_users_per_sms_count)
            .reduce((a: number, c) => {
                let sms_count = parseInt(c[0]);
                // Include anything above the last bin, otherwise cap at the next bin
                let is_sms_count_in_this_bin = sms_count >= bin && (ii === bins.length - 1 || sms_count < bins[ii+1]);
                return a + (is_sms_count_in_this_bin ? c[1] : 0)
            }, 0);
        
        return {
            value: [bin, bin_count],
            itemStyle: {
                color: red_colours[Math.round(1.5 * ii)]
            }
        }
    });

    data.sort((a, b) => a.value[0] - b.value[0]);
    console.log('data', data);

    
    const max_val = data[0].value[1];
    const rounded_max_val = Math.ceil(max_val / 1000) * 1000;
    console.log('rounded_max_val', rounded_max_val);
    
    const option = {
        title: {
            text: "Users who sent at least {x} SMSes",
        },
        tooltip: {
            trigger: 'item',
        },
        xAxis: {
            type: 'category',
            nameLocation: 'middle',
            name: "SMSes",
        },
        yAxis: [
            {
                name: "Users",
                type: 'log',
            }
        ],
        series: [
            {
                type: "bar",
                data: data
            }
        ],
    };

    return (
        <ReactEcharts
            option={option}
            style={{height: '500px', width: '100%'}}
            className='react_for_echarts'
        />
    )
}

interface DistDict {
    [key: string]: number[];
}
interface CountDict {
    [key: string]: number;
}
interface PadsReceivedData extends DecilesData {
    DataVolumeReceived: string;
}
interface PadsSentData extends DecilesData {
    DataVolumeSent: string;
}
interface CallsOutboundData extends DecilesData {
    Calls_Outbound: string;
}
interface CallsInboundData extends DecilesData {
    Calls_Inbound: string;
}
interface SMSData extends DecilesData {
    SMS_Outbound: string;
}
interface DecilesData {
    Date: string;
    index: string;
}
export const SMSChart_Dist = () => {
    // Group data by date
    const sms_data_by_date = (reports.SMS as SMSData[]).reduce((acc: DistDict, curr: SMSData) => {
        const date = curr.Date;
        const count = parseInt(curr.SMS_Outbound);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(count);
        return acc;
    }, {} as DistDict);
    console.log('sms_data_by_date', sms_data_by_date);
    
    const segments = Object.values(sms_data_by_date).reduce((acc: number, curr: number[]) => curr.length > acc ? curr.length : acc, 0);

    const candlestickMainIdxOffset = 3;
    const data = Object.values(sms_data_by_date).map((x: any) => [
        x.slice(0, candlestickMainIdxOffset).reduce((a:number, c: number) => a + c, 0),
        x.slice(0, segments-candlestickMainIdxOffset).reduce((a:number, c: number) => a + c, 0),
        0,
        x.reduce((a:number, c: number) => a + c, 0)
    ]);
    console.log('data', data);

    const sms_cost = 0.05; //5p per sms

    const option = {
        title: {
            text: "Distribution of SMSes sent",
        },
        tooltip: {
            trigger: 'item',
            // formatter: (p: any) => {
            //     // console.log(p);
            //     return `${p.data[0]} SMSes, ${p.data[1]} International SMSes`
            // },
        },
        xAxis: {
            data: Object.keys(sms_data_by_date)
        },
        yAxis: [
            {
                name: "SMSes",
                type: 'value',
            },
            {
                name: "Cost",
                type: 'value',
                alignTicks: true,
                axisLabel: {
                    formatter: (v: any) => `£${v}`
                },
                min: 0,
                max: sms_cost * Math.max(...data.map((x: any) => x[3])),
            }
        ],
        series: [
            {
                type: "candlestick",
                data: data
            },
            {
                type: "line",
                data: data.map((x: any) => 5000),
                yAxisIndex: 1,
            }
        ]
    };

    return (
            <ReactEcharts
                option={option}
                style={{height: '500px', width: '100%'}}
                className='react_for_echarts'
            />
    );
};
export const SMSCostByDist = () => {
    // Group data by date
    const sms_data_by_date = (reports.SMS as SMSData[]).reduce((acc: DistDict, curr: SMSData) => {
        const date = curr.Date;
        const count = parseInt(curr.SMS_Outbound);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(count);
        return acc;
    }, {} as DistDict);
    console.log('sms_data', sms_data_by_date);

    const segments = Object.values(sms_data_by_date).reduce((acc: number, curr: number[]) => curr.length > acc ? curr.length : acc, 0);

    const sms_cost = 0.05; //5p per sms

    const data = [];
    for (let i = 0; i < segments; i++) {
        data[i] = Object.values(sms_data_by_date).map((x: number[]) => sms_cost * x[i]);
    }
    console.log('data 2', data);

    interface ISeries {
        type: string;
        stack?: string;
        id?: number;
        name?: string;
        data: number[];
        emphasis?: {
            focus: string;
        };
        colorBy?: string;
        itemStyle?: {
            color: string;
        };
        lineStyle?: {
            color: string;
        };
        areaStyle?: {
            color: string;
        };
        yAxisIndex?: number;
    }

    const series: ISeries[] = Object.values(data).map((x, ii) => ({
            type: "line",
            stack: 'Total',
            id: ii,
            name: "p"+((ii+1)*10).toString(),
            data: x.map(y => Math.floor(y)),
            emphasis: {
            focus: 'series'
            },
            colorBy: 'series',
            itemStyle: {
                color: bw_colours[9 - ii],
                // borderColor: colours[ii],
            },
            lineStyle: {
                color: bw_colours[9 - ii],
            },
            areaStyle: {
                color: red_colours[9 - ii]
            }
        }
    ));

    console.log('series', series);
    


    const option = {
        title: {
            text: "SMS Cost by Percentile",
        },
        tooltip: {
            trigger: 'axis',
            // formatter: (p: any) => {
            //     console.log(p);
            //     return "<b>" + p[0].name + "</b><br/>" + p.map((x: any) => `p${(x.componentIndex + 1) * 10}: ${Math.floor(x.data)}`).reverse().join('<br />');
            //     // return `p${p.seriesIndex*10} - p${(p.seriesIndex + 1)*10}, £${Math.floor(p.data)} of SMS`
            // }
        },
        xAxis: {
            type: 'category',
            data: Object.keys(sms_data_by_date),
            boundaryGap: false,
        },
        yAxis: [
            {
                name: "Cost",
                type: 'value',
            },
            {
                name: "Profitable",
                type: 'value',
                alignTicks: true,
                axisLabel: {
                    formatter: (v: any) => `${v}`
                },
                min: 0,
                max: 10000
            }
        ],
        legend: {
          data:  Object.keys(data).map((x, ii) => "p"+((ii+1)*10).toString())
        },
        series: series.concat([
            {
                type: "line",
                data: data.map((x: any) => 5000),
                yAxisIndex: 1,
            }
        ]),
    };

    return (
            <ReactEcharts
                option={option}
                style={{height: '500px', width: '100%'}}
                className='react_for_echarts'
            />
    );
};
export const CallsInboundChartDist = () => {
    // Group data by date
    const data_by_date = (reports.CALLS_INBOUND as CallsInboundData[]).reduce((acc: DistDict, curr: CallsInboundData) => {
        const date = curr.Date;
        const count = parseFloat(curr.Calls_Inbound);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(count);
        return acc;
    }, {} as DistDict);
    console.log('calls_inbound_data', data_by_date);

    const segments = Object.values(data_by_date).reduce((acc: number, curr: number[]) => curr.length > acc ? curr.length : acc, 0);

    const cost = 1; //5p per sms

    const data = [];
    for (let i = 0; i < segments; i++) {
        data[i] = Object.values(data_by_date).map((x: number[]) => cost * x[i]);
    }
    console.log('data 2', data);

    const series = Object.values(data).map((x, ii) => ({
            type: "line",
            stack: 'Total',
            id: ii,
            name: "p"+((ii+1)*10).toString(),
            data: x.map(y => Math.floor(y)),
            emphasis: {
            focus: 'series'
            },
            colorBy: 'series',
            itemStyle: {
                color: bw_colours[9 - ii],
                // borderColor: colours[ii],
            },
            lineStyle: {
                color: bw_colours[9 - ii],
            },
            areaStyle: {
                color: red_colours[9 - ii]
            }
        }
    ));

    console.log('series', series);
    


    const option = {
        title: {
            text: "Calls Inbound by Percentile",
        },
        tooltip: {
            trigger: 'axis',
            // formatter: (p: any) => {
            //     console.log(p);
            //     return "<b>" + p[0].name + "</b><br/>" + p.map((x: any) => `p${(x.componentIndex + 1) * 10}: ${Math.floor(x.data)}`).reverse().join('<br />');
            //     // return `p${p.seriesIndex*10} - p${(p.seriesIndex + 1)*10}, £${Math.floor(p.data)} of SMS`
            // }
        },
        xAxis: {
            type: 'category',
            data: Object.keys(data_by_date),
            boundaryGap: false,
        },
        yAxis: {},
        legend: {
          data:  Object.keys(data).map((x, ii) => "p"+((ii+1)*10).toString())
        },
        series: series,
    };

    return (
            <ReactEcharts
                option={option}
                style={{height: '500px', width: '100%'}}
                className='react_for_echarts'
            />
    );
};
export const CallsOutboundChartDist = () => {
    // Group data by date
    const data_by_date = (reports.CALLS_OUTBOUND as CallsOutboundData[]).reduce((acc: DistDict, curr: CallsOutboundData) => {
        const date = curr.Date;
        const count = parseFloat(curr.Calls_Outbound);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(count);
        return acc;
    }, {} as DistDict);
    console.log('calls_outbound_data', data_by_date);

    const segments = Object.values(data_by_date).reduce((acc: number, curr: number[]) => curr.length > acc ? curr.length : acc, 0);

    const cost = 1; //5p per sms

    const data = [];
    for (let i = 0; i < segments; i++) {
        data[i] = Object.values(data_by_date).map((x: number[]) => cost * x[i]);
    }
    console.log('data 2', data);

    const series = Object.values(data).map((x, ii) => ({
            type: "line",
            stack: 'Total',
            id: ii,
            name: "p"+((ii+1)*10).toString(),
            data: x.map(y => Math.floor(y)),
            emphasis: {
            focus: 'series'
            },
            colorBy: 'series',
            itemStyle: {
                color: bw_colours[9 - ii],
                // borderColor: colours[ii],
            },
            lineStyle: {
                color: bw_colours[9 - ii],
            },
            areaStyle: {
                color: red_colours[9 - ii]
            }
        }
    ));

    console.log('series', series);
    


    const option = {
        title: {
            text: "Calls Outbound by Percentile",
        },
        tooltip: {
            trigger: 'axis',
            // formatter: (p: any) => {
            //     console.log(p);
            //     return "<b>" + p[0].name + "</b><br/>" + p.map((x: any) => `p${(x.componentIndex + 1) * 10}: ${Math.floor(x.data)}`).reverse().join('<br />');
            //     // return `p${p.seriesIndex*10} - p${(p.seriesIndex + 1)*10}, £${Math.floor(p.data)} of SMS`
            // }
        },
        xAxis: {
            type: 'category',
            data: Object.keys(data_by_date),
            boundaryGap: false,
        },
        yAxis: {},
        legend: {
          data:  Object.keys(data).map((x, ii) => "p"+((ii+1)*10).toString())
        },
        series: series,
    };

    return (
            <ReactEcharts
                option={option}
                style={{height: '500px', width: '100%'}}
                className='react_for_echarts'
            />
    );
};
export const PadsReceivedChartDist = () => {
    // Group data by date
    const data_by_date = (reports.DATA_RECEIVED as PadsReceivedData[]).reduce((acc: DistDict, curr: PadsReceivedData) => {
        const date = curr.Date;
        const count = parseFloat(curr.DataVolumeReceived);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(count);
        return acc;
    }, {} as DistDict);
    console.log('pads_received_data', data_by_date);

    const segments = Object.values(data_by_date).reduce((acc: number, curr: number[]) => curr.length > acc ? curr.length : acc, 0);

    const cost = 1; //5p per sms

    const data = [];
    for (let i = 0; i < segments; i++) {
        data[i] = Object.values(data_by_date).map((x: number[]) => cost * x[i]);
    }
    console.log('data 2', data);

    const series = Object.values(data).map((x, ii) => ({
            type: "line",
            stack: 'Total',
            id: ii,
            name: "p"+((ii+1)*10).toString(),
            data: x.map(y => Math.floor(y)),
            emphasis: {
            focus: 'series'
            },
            colorBy: 'series',
            itemStyle: {
                color: bw_colours[9 - ii],
                // borderColor: colours[ii],
            },
            lineStyle: {
                color: bw_colours[9 - ii],
            },
            areaStyle: {
                color: red_colours[9 - ii]
            }
        }
    ));

    console.log('series', series);
    


    const option = {
        title: {
            text: "Data Downloaded by Percentile",
        },
        tooltip: {
            trigger: 'axis',
            // formatter: (p: any) => {
            //     console.log(p);
            //     return "<b>" + p[0].name + "</b><br/>" + p.map((x: any) => `p${(x.componentIndex + 1) * 10}: ${Math.floor(x.data)}`).reverse().join('<br />');
            //     // return `p${p.seriesIndex*10} - p${(p.seriesIndex + 1)*10}, £${Math.floor(p.data)} of SMS`
            // }
        },
        xAxis: {
            type: 'category',
            data: Object.keys(data_by_date),
            boundaryGap: false,
        },
        yAxis: {},
        legend: {
          data:  Object.keys(data).map((x, ii) => "p"+((ii+1)*10).toString())
        },
        series: series,
    };

    return (
            <ReactEcharts
                option={option}
                style={{height: '500px', width: '100%'}}
                className='react_for_echarts'
            />
    );
};
export const PadsSentChartDist = () => {
    // Group data by date
    const data_by_date = (reports.DATA_SENT as PadsSentData[]).reduce((acc: DistDict, curr: PadsSentData) => {
        const date = curr.Date;
        const count = parseFloat(curr.DataVolumeSent);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(count);
        return acc;
    }, {} as DistDict);
    console.log('pads_sent_data', data_by_date);

    const segments = Object.values(data_by_date).reduce((acc: number, curr: number[]) => curr.length > acc ? curr.length : acc, 0);

    const cost = 1; //5p per sms

    const data = [];
    for (let i = 0; i < segments; i++) {
        data[i] = Object.values(data_by_date).map((x: number[]) => cost * x[i]);
    }
    console.log('data 2', data);

    const series = Object.values(data).map((x, ii) => ({
            type: "line",
            stack: 'Total',
            id: ii,
            name: "p"+((ii+1)*10).toString(),
            data: x.map(y => Math.floor(y)),
            emphasis: {
            focus: 'series'
            },
            colorBy: 'series',
            itemStyle: {
                color: bw_colours[9 - ii],
                // borderColor: colours[ii],
            },
            lineStyle: {
                color: bw_colours[9 - ii],
            },
            areaStyle: {
                color: red_colours[9 - ii]
            }
        }
    ));

    console.log('series', series);
    


    const option = {
        title: {
            text: "Data Uploaded by Percentile",
        },
        tooltip: {
            trigger: 'axis',
            // formatter: (p: any) => {
            //     console.log(p);
            //     return "<b>" + p[0].name + "</b><br/>" + p.map((x: any) => `p${(x.componentIndex + 1) * 10}: ${Math.floor(x.data)}`).reverse().join('<br />');
            //     // return `p${p.seriesIndex*10} - p${(p.seriesIndex + 1)*10}, £${Math.floor(p.data)} of SMS`
            // }
        },
        xAxis: {
            type: 'category',
            data: Object.keys(data_by_date),
            boundaryGap: false,
        },
        yAxis: {},
        legend: {
          data:  Object.keys(data).map((x, ii) => "p"+((ii+1)*10).toString())
        },
        series: series,
    };

    return (
            <ReactEcharts
                option={option}
                style={{height: '500px', width: '100%'}}
                className='react_for_echarts'
            />
    );
};
export const SMSScatter = () => {
    // Group data by date
    // This is using the wrong data for now...
    const sms_data_by_date = (reports.SMS as SMSData[]).reduce((acc: DistDict, curr: SMSData) => {
        const date = curr.Date;
        const count = parseInt(curr.SMS_Outbound);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(count);
        return acc;
    }, {} as DistDict);
    console.log('sms_data_by_date 3', sms_data_by_date);
    
    const data = [
        Object.values(sms_data_by_date).map((x: number[]) => x.slice(0, 7).reduce((a: number, c: number) => a + c, 0)),
        Object.values(sms_data_by_date).map((x: number[]) => x.slice(7, 10).reduce((a: number, c: number) => a + (10 * c), 0)),
    ];
    console.log('data 3', data);

    const series = Object.values(data).map((x, ii) => ({
            type: "scatter",
            // coordinateSystem: 'singleAxis',
            // id: ii,
            // name: ii == 0 ? "Under 10 sms" : "Over 10 sms",
            data: x,
            emphasis: {
            focus: 'series'
            },
            colorBy: 'series',
            itemStyle: {
                color: ii === 0 ? bw_colours[9] : red_colours[9],
            },
            symbolSize: function (dataItem: any) {
                // console.log('dataItem', dataItem)
              return Math.sqrt(dataItem) / 25;
            }
        }
    ));

    console.log('series 3', series);


    const option = {
        title: {
            text: "SMSes sent by users (under / over 10 per day)",
        },
        tooltip: {
            trigger: 'item',
            // formatter: (p: any) => {
            //     // console.log(p);
            //     return `${p.data[0]} SMSes, ${p.data[1]} International SMSes`
            // },
        },
        xAxis: {
            data: Object.keys(sms_data_by_date)
        },
        yAxis: {},
        series: series,
    };

    return (
        <ReactEcharts
            option={option}
            style={{height: '500px', width: '100%'}}
            className='react_for_echarts'
        />
    );
};