import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Row } from "react-table";

import Box from "@mui/material/Box";

import Loading from "core/components/loading/Loading";

import Tabs from "core/components/tabs/Tabs";
import { ClientNames } from "core/data/clients/client_names";
import { IssueEnum } from "../tabs/enums/IssueEnum";
import { ITabStatuses } from "../tabs/types/ITabStatuses";
import { ITabDataDict } from "../tabs/types/ITabDataDict";
import ListTile from "../tiles/ListTile";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export interface IStatusItemBaseDb extends Object {
    client_id: number;
    client_name: string;
    name: string;
    id: number;
    last_date: number;
    next_date: number;
    issue: IssueEnum;
}

interface IStatusTableProps<T extends object> {
    title: string;
    unique?: boolean;
    clientKeyIndex?: number;
    RenderRow: (item: T) => JSX.Element;
    GetIssue: (item: T) => IssueEnum;
    items?: T[] | null;
    error: string;
    columns?: any[];
    subtitle?: ReactNode;
    collapsible?: boolean;
    getRowProps?: (row: Row<T>) => any;
}

const DbStatusTable = <T extends IStatusItemBaseDb>({ title, unique = true, clientKeyIndex = 0, RenderRow, GetIssue, items = null, error = '', columns, subtitle, collapsible=false, getRowProps = () => {} }: IStatusTableProps<T>) => {
    const [clients, setClients] = useState<string[]>([]);
    const [clientIssues, setClientIssues] = useState<ITabStatuses>({});
    const [itemsByClient, setItemsByClient] = useState<ITabDataDict<T>>({});
    const [loading, setLoading] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const GetIssueCB = useCallback(GetIssue, [GetIssue]);
    const SortByIssueAndName = useCallback((a: T, b: T) => {
        return Object.keys(IssueEnum).indexOf(b.issue) - Object.keys(IssueEnum).indexOf(a.issue)
            || (a.next_date === 0 && b.next_date === 0 ? 0 : (a.next_date === 0 ? 1 : (b.next_date === 0 ? -1 : (a.next_date - b.next_date))))
            || (a.last_date === 0 && b.last_date === 0 ? 0 : (a.last_date === 0 ? 1 : (b.last_date === 0 ? -1 : (a.last_date - b.last_date))))
            || a.name.localeCompare(b.name);
    }, []);

    useEffect(() => {
        if (items === null) return;

        // Create a list of clients from the items returned by the api (de-duped)
        const c: string[] = items.map(o => o.client_name).filter((v, i, a) => a.indexOf(v) === i).map(c => ClientNames.get(c) ?? c);
        const ci: ITabStatuses = c.reduce((acc, client) => ({ ...acc, [client]: IssueEnum.OK }), {});
        // Group the items by client - optionally de-dupe the items
        const i = items.reduce((acc, obj) => {
            const client = ClientNames.get(obj.client_name) ?? obj.client_name;

            // const issue = GetIssueCB(obj);
            // obj.issue = issue;
            if (ci[client] !== IssueEnum.ERROR && obj.issue === IssueEnum.ERROR) {
                ci[client] = IssueEnum.ERROR;
            } else if (ci[client] === IssueEnum.OK && obj.issue === IssueEnum.WARNING) {
                ci[client] = IssueEnum.WARNING;
            }

            if (!acc[client]) {
                acc[client] = [];
            }
            // Append and then potentially de-dupe the feeds
            if (unique) {
                acc[client] = [obj, ...acc[client]]
                    .filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
            } else {
                acc[client].push(obj);
            }

            // Show the errors at the top
            acc[client].sort(SortByIssueAndName);
            return acc;
        }, {} as ITabDataDict<T>)

        c.sort((a, b) => Object.values(IssueEnum).indexOf(ci[b]) - Object.values(IssueEnum).indexOf(ci[a]) || a.localeCompare(b));

        setClients(c);
        setClientIssues(ci);
        setItemsByClient(i);
        setLoading(false);
    }, [items, GetIssueCB, unique, SortByIssueAndName, clientKeyIndex]);

    if (loading || items === null) {
        return <Loading />;
    }

    // Display the items by client
    return (
        <ListTile>
            <div 
                className={`list__title__container ${collapsible ? 'collapsible': ''} ${(!collapsible || !isCollapsed) ? 'expanded': ''}`}  
                onClick={() => collapsible && setIsCollapsed(!isCollapsed)}
            >
                {title && (
                    <div className={`list__title`}>
                        {collapsible && isCollapsed && 
                            <KeyboardArrowDownIcon />
                        }
                        {collapsible && !isCollapsed && 
                            <KeyboardArrowUpIcon />
                        }
                        <h2>{title}</h2>
                    </div>
                )}
                {subtitle && (
                    <div className={`list__subtitle`}>
                        {subtitle}
                    </div>
                )}
            </div>

            <Box className={'tabs-container'}>
                {error &&
                    <p>{error}</p>
                }
                {(!collapsible || !isCollapsed) && items.length === 0 &&
                    <p>No items found</p>
                }
                {(!collapsible || !isCollapsed) && items.length > 0 &&
                    <>
                        <Tabs<T>
                            tabs={clients}
                            tabStatuses={clientIssues}
                            tabData={itemsByClient}
                            renderItem={RenderRow}
                            columns={columns}
                            getRowProps={getRowProps}
                        />
                    </>
                }
            </Box>
        </ListTile>
    );
};

export default DbStatusTable;