import React from "react";
import { Route, Routes } from "react-router-dom";

import Box from "@mui/material/Box";

import HalfWidthTileContainer from "core/components/tiles/HalfWidthTileContainer";
import { Permission } from "core/enums/Permissions";
import PermissionsCheckComponent from "core/helpers/auth/PermissionsCheckComponent";

import FeedsListTable from "../feeds/components/FeedsListTable";
import ReportsListTable from "../reports/components/ReportsListTable";
import CampaignsListTable from "../campaigns/components/CampaignsListTable";
import MetricsListTable from "../metrics/components/MetricsListTable";

import ReportsCalendar from "./components/ReportsCalendar";

import "./_deliverables.scss";


const DeliverablesPage = () => {
  return (
    <section className="feeds-db">
      <Routes>
        <Route path="/" element={
          <>
            <HalfWidthTileContainer grow={true}>
              <PermissionsCheckComponent permission={Permission.ViewFeeds}>
                <FeedsListTable collapsible={true} />
              </PermissionsCheckComponent>
              <PermissionsCheckComponent permission={Permission.ViewReports}>
                <ReportsListTable collapsible={true} />
              </PermissionsCheckComponent>
              <PermissionsCheckComponent permission={Permission.ViewMetrics}>
                <MetricsListTable collapsible={true} />
              </PermissionsCheckComponent>
              <PermissionsCheckComponent permission={Permission.ViewCampaigns}>
                <CampaignsListTable collapsible={true} />
              </PermissionsCheckComponent>
            </HalfWidthTileContainer>

            <HalfWidthTileContainer grow={true}>
              <div className={`tile wide list calendar`}>
                <div className={`list__title__container expanded`}>
                  <div className={`list__title`}>
                    <h2>Reports Calendar</h2>
                  </div>
                </div>

                <Box className={'tabs-container'}>
                  <Box className={'tab-panel'}
                        role="tabpanel"
                    >
                    <ReportsCalendar />
                  </Box>
                </Box>
              </div>
            </HalfWidthTileContainer>
          </>
        } />
      </Routes>
    </section>
  );
};

export default DeliverablesPage;