export interface IPopulationData {
    [key: string]: IPopulationDataYear[];
}
export interface IPopulationDataYear {
    name: string;
    value: number;
}
export const populationDataFemale: IPopulationData = {
    "2001": [
        { "name": "Ang Mo Kio", "value": 88810 },
        { "name": "Bedok", "value": 143730 },
        { "name": "Bishan", "value": 45230 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 63010 },
        { "name": "Bukit Merah", "value": 72670 },
        { "name": "Bukit Panjang", "value": 53170 },
        { "name": "Bukit Timah", "value": 34640 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 530 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 72300 },
        { "name": "Clementi", "value": 44430 },
        { "name": "Downtown Core", "value": 1780 },
        { "name": "Geylang", "value": 57980 },
        { "name": "Hougang", "value": 103070 },
        { "name": "Jurong East", "value": 43560 },
        { "name": "Jurong West", "value": 106340 },
        { "name": "Kallang", "value": 43780 },
        { "name": "Lim Chu Kang", "value": 50 },
        { "name": "Mandai", "value": 470 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 24090 },
        { "name": "Museum", "value": 30 },
        { "name": "Newton", "value": 3310 },
        { "name": "North-Eastern Islands", "value": 50 },
        { "name": "Novena", "value": 21670 },
        { "name": "Orchard", "value": 340 },
        { "name": "Outram", "value": 9310 },
        { "name": "Pasir Ris", "value": 60130 },
        { "name": "Paya Lebar", "value": 60 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 340 },
        { "name": "Queenstown", "value": 49100 },
        { "name": "River Valley", "value": 5010 },
        { "name": "Rochor", "value": 8230 },
        { "name": "Seletar", "value": 220 },
        { "name": "Sembawang", "value": 22390 },
        { "name": "Sengkang", "value": 43620 },
        { "name": "Serangoon", "value": 61650 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 520 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 440 },
        { "name": "Tampines", "value": 126940 },
        { "name": "Tanglin", "value": 7820 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 60810 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 240 },
        { "name": "Woodlands", "value": 98460 },
        { "name": "Yishun", "value": 86920 }
    ],
    "2002": [
        { "name": "Ang Mo Kio", "value": 87710 },
        { "name": "Bedok", "value": 144020 },
        { "name": "Bishan", "value": 44960 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 64340 },
        { "name": "Bukit Merah", "value": 72650 },
        { "name": "Bukit Panjang", "value": 55750 },
        { "name": "Bukit Timah", "value": 34930 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 520 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 75530 },
        { "name": "Clementi", "value": 44760 },
        { "name": "Downtown Core", "value": 1770 },
        { "name": "Geylang", "value": 57500 },
        { "name": "Hougang", "value": 103510 },
        { "name": "Jurong East", "value": 43930 },
        { "name": "Jurong West", "value": 110110 },
        { "name": "Kallang", "value": 43280 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 630 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 23840 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3470 },
        { "name": "North-Eastern Islands", "value": 40 },
        { "name": "Novena", "value": 21490 },
        { "name": "Orchard", "value": 350 },
        { "name": "Outram", "value": 8290 },
        { "name": "Pasir Ris", "value": 61050 },
        { "name": "Paya Lebar", "value": 40 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 6490 },
        { "name": "Queenstown", "value": 48830 },
        { "name": "River Valley", "value": 5050 },
        { "name": "Rochor", "value": 8050 },
        { "name": "Seletar", "value": 220 },
        { "name": "Sembawang", "value": 25320 },
        { "name": "Sengkang", "value": 51330 },
        { "name": "Serangoon", "value": 61580 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 510 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 450 },
        { "name": "Tampines", "value": 127340 },
        { "name": "Tanglin", "value": 8150 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 60210 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 280 },
        { "name": "Woodlands", "value": 102960 },
        { "name": "Yishun", "value": 87310 }
    ],
    "2003": [
        { "name": "Ang Mo Kio", "value": 86230 },
        { "name": "Bedok", "value": 140950 },
        { "name": "Bishan", "value": 43680 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 64950 },
        { "name": "Bukit Merah", "value": 70880 },
        { "name": "Bukit Panjang", "value": 56710 },
        { "name": "Bukit Timah", "value": 33680 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 640 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 77230 },
        { "name": "Clementi", "value": 43850 },
        { "name": "Downtown Core", "value": 1680 },
        { "name": "Geylang", "value": 56240 },
        { "name": "Hougang", "value": 102730 },
        { "name": "Jurong East", "value": 42850 },
        { "name": "Jurong West", "value": 111210 },
        { "name": "Kallang", "value": 42470 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 720 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22750 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3250 },
        { "name": "North-Eastern Islands", "value": 40 },
        { "name": "Novena", "value": 20840 },
        { "name": "Orchard", "value": 310 },
        { "name": "Outram", "value": 7840 },
        { "name": "Pasir Ris", "value": 60780 },
        { "name": "Paya Lebar", "value": 40 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 12340 },
        { "name": "Queenstown", "value": 47650 },
        { "name": "River Valley", "value": 4760 },
        { "name": "Rochor", "value": 7280 },
        { "name": "Seletar", "value": 180 },
        { "name": "Sembawang", "value": 27170 },
        { "name": "Sengkang", "value": 56780 },
        { "name": "Serangoon", "value": 60050 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 470 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 410 },
        { "name": "Tampines", "value": 125840 },
        { "name": "Tanglin", "value": 7750 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 58560 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 290 },
        { "name": "Woodlands", "value": 105050 },
        { "name": "Yishun", "value": 86220 }
    ],
    "2004": [
        { "name": "Ang Mo Kio", "value": 85770 },
        { "name": "Bedok", "value": 141200 },
        { "name": "Bishan", "value": 44140 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 66280 },
        { "name": "Bukit Merah", "value": 71230 },
        { "name": "Bukit Panjang", "value": 58520 },
        { "name": "Bukit Timah", "value": 34450 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 660 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 78490 },
        { "name": "Clementi", "value": 43870 },
        { "name": "Downtown Core", "value": 1630 },
        { "name": "Geylang", "value": 56260 },
        { "name": "Hougang", "value": 103090 },
        { "name": "Jurong East", "value": 42700 },
        { "name": "Jurong West", "value": 112980 },
        { "name": "Kallang", "value": 42510 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 740 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 22720 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3400 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 21080 },
        { "name": "Orchard", "value": 330 },
        { "name": "Outram", "value": 7650 },
        { "name": "Pasir Ris", "value": 61030 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 18300 },
        { "name": "Queenstown", "value": 47880 },
        { "name": "River Valley", "value": 4870 },
        { "name": "Rochor", "value": 7200 },
        { "name": "Seletar", "value": 210 },
        { "name": "Sembawang", "value": 28970 },
        { "name": "Sengkang", "value": 61430 },
        { "name": "Serangoon", "value": 60390 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 520 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 390 },
        { "name": "Tampines", "value": 126000 },
        { "name": "Tanglin", "value": 8060 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 58760 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 310 },
        { "name": "Woodlands", "value": 107550 },
        { "name": "Yishun", "value": 86530 }
    ],
    "2005": [
        { "name": "Ang Mo Kio", "value": 85940 },
        { "name": "Bedok", "value": 141830 },
        { "name": "Bishan", "value": 44700 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 67810 },
        { "name": "Bukit Merah", "value": 72060 },
        { "name": "Bukit Panjang", "value": 59610 },
        { "name": "Bukit Timah", "value": 35450 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 670 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 80190 },
        { "name": "Clementi", "value": 43870 },
        { "name": "Downtown Core", "value": 1630 },
        { "name": "Geylang", "value": 56860 },
        { "name": "Hougang", "value": 103790 },
        { "name": "Jurong East", "value": 42740 },
        { "name": "Jurong West", "value": 114900 },
        { "name": "Kallang", "value": 43270 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 760 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 23110 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3580 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 22230 },
        { "name": "Orchard", "value": 330 },
        { "name": "Outram", "value": 7570 },
        { "name": "Pasir Ris", "value": 61640 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 21460 },
        { "name": "Queenstown", "value": 48080 },
        { "name": "River Valley", "value": 4950 },
        { "name": "Rochor", "value": 7310 },
        { "name": "Seletar", "value": 220 },
        { "name": "Sembawang", "value": 30400 },
        { "name": "Sengkang", "value": 65600 },
        { "name": "Serangoon", "value": 61430 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 610 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 390 },
        { "name": "Tampines", "value": 126860 },
        { "name": "Tanglin", "value": 8510 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 58820 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 350 },
        { "name": "Woodlands", "value": 109730 },
        { "name": "Yishun", "value": 87270 }
    ],
    "2006": [
        { "name": "Ang Mo Kio", "value": 87200 },
        { "name": "Bedok", "value": 142610 },
        { "name": "Bishan", "value": 45250 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 69250 },
        { "name": "Bukit Merah", "value": 74300 },
        { "name": "Bukit Panjang", "value": 60210 },
        { "name": "Bukit Timah", "value": 36580 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 690 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 81470 },
        { "name": "Clementi", "value": 44360 },
        { "name": "Downtown Core", "value": 1680 },
        { "name": "Geylang", "value": 57700 },
        { "name": "Hougang", "value": 104780 },
        { "name": "Jurong East", "value": 42860 },
        { "name": "Jurong West", "value": 117340 },
        { "name": "Kallang", "value": 44370 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 780 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 23440 },
        { "name": "Museum", "value": 0 },
        { "name": "Newton", "value": 3690 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23070 },
        { "name": "Orchard", "value": 360 },
        { "name": "Outram", "value": 7670 },
        { "name": "Pasir Ris", "value": 62760 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 23720 },
        { "name": "Queenstown", "value": 48670 },
        { "name": "River Valley", "value": 5100 },
        { "name": "Rochor", "value": 7470 },
        { "name": "Seletar", "value": 240 },
        { "name": "Sembawang", "value": 31680 },
        { "name": "Sengkang", "value": 69710 },
        { "name": "Serangoon", "value": 62030 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 620 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 410 },
        { "name": "Tampines", "value": 127750 },
        { "name": "Tanglin", "value": 8860 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 59320 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 370 },
        { "name": "Woodlands", "value": 111570 },
        { "name": "Yishun", "value": 87590 }
    ],
    "2007": [
        { "name": "Ang Mo Kio", "value": 88180 },
        { "name": "Bedok", "value": 144130 },
        { "name": "Bishan", "value": 45690 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70550 },
        { "name": "Bukit Merah", "value": 76370 },
        { "name": "Bukit Panjang", "value": 61140 },
        { "name": "Bukit Timah", "value": 37490 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 730 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 82680 },
        { "name": "Clementi", "value": 44590 },
        { "name": "Downtown Core", "value": 1670 },
        { "name": "Geylang", "value": 58900 },
        { "name": "Hougang", "value": 105830 },
        { "name": "Jurong East", "value": 42960 },
        { "name": "Jurong West", "value": 119670 },
        { "name": "Kallang", "value": 45640 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 720 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 23780 },
        { "name": "Museum", "value": 90 },
        { "name": "Newton", "value": 3530 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23390 },
        { "name": "Orchard", "value": 360 },
        { "name": "Outram", "value": 8160 },
        { "name": "Pasir Ris", "value": 64140 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 25760 },
        { "name": "Queenstown", "value": 49430 },
        { "name": "River Valley", "value": 4750 },
        { "name": "Rochor", "value": 7590 },
        { "name": "Seletar", "value": 250 },
        { "name": "Sembawang", "value": 32670 },
        { "name": "Sengkang", "value": 72930 },
        { "name": "Serangoon", "value": 62420 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 680 },
        { "name": "Southern Islands", "value": 0 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 420 },
        { "name": "Tampines", "value": 128640 },
        { "name": "Tanglin", "value": 8970 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 60030 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 390 },
        { "name": "Woodlands", "value": 113230 },
        { "name": "Yishun", "value": 88880 }
    ],
    "2008": [
        { "name": "Ang Mo Kio", "value": 89540 },
        { "name": "Bedok", "value": 145310 },
        { "name": "Bishan", "value": 46120 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 71230 },
        { "name": "Bukit Merah", "value": 78020 },
        { "name": "Bukit Panjang", "value": 62250 },
        { "name": "Bukit Timah", "value": 36840 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 800 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 83820 },
        { "name": "Clementi", "value": 45140 },
        { "name": "Downtown Core", "value": 1730 },
        { "name": "Geylang", "value": 59530 },
        { "name": "Hougang", "value": 107620 },
        { "name": "Jurong East", "value": 43090 },
        { "name": "Jurong West", "value": 124330 },
        { "name": "Kallang", "value": 46850 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 760 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 23800 },
        { "name": "Museum", "value": 120 },
        { "name": "Newton", "value": 3180 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23350 },
        { "name": "Orchard", "value": 370 },
        { "name": "Outram", "value": 8170 },
        { "name": "Pasir Ris", "value": 65250 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 27620 },
        { "name": "Queenstown", "value": 50210 },
        { "name": "River Valley", "value": 4520 },
        { "name": "Rochor", "value": 7660 },
        { "name": "Seletar", "value": 270 },
        { "name": "Sembawang", "value": 33890 },
        { "name": "Sengkang", "value": 77190 },
        { "name": "Serangoon", "value": 62810 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 680 },
        { "name": "Southern Islands", "value": 110 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 410 },
        { "name": "Tampines", "value": 130140 },
        { "name": "Tanglin", "value": 8710 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 61550 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 450 },
        { "name": "Woodlands", "value": 115860 },
        { "name": "Yishun", "value": 90270 }
    ],
    "2009": [
        { "name": "Ang Mo Kio", "value": 91950 },
        { "name": "Bedok", "value": 148590 },
        { "name": "Bishan", "value": 46650 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 72780 },
        { "name": "Bukit Merah", "value": 80030 },
        { "name": "Bukit Panjang", "value": 63810 },
        { "name": "Bukit Timah", "value": 36710 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 900 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 85600 },
        { "name": "Clementi", "value": 46790 },
        { "name": "Downtown Core", "value": 1860 },
        { "name": "Geylang", "value": 60390 },
        { "name": "Hougang", "value": 109410 },
        { "name": "Jurong East", "value": 44020 },
        { "name": "Jurong West", "value": 129670 },
        { "name": "Kallang", "value": 48770 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 810 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 24600 },
        { "name": "Museum", "value": 160 },
        { "name": "Newton", "value": 3230 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 23960 },
        { "name": "Orchard", "value": 400 },
        { "name": "Outram", "value": 8320 },
        { "name": "Pasir Ris", "value": 66360 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 30 },
        { "name": "Punggol", "value": 28820 },
        { "name": "Queenstown", "value": 51590 },
        { "name": "River Valley", "value": 4360 },
        { "name": "Rochor", "value": 7840 },
        { "name": "Seletar", "value": 160 },
        { "name": "Sembawang", "value": 35160 },
        { "name": "Sengkang", "value": 81270 },
        { "name": "Serangoon", "value": 63840 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 850 },
        { "name": "Southern Islands", "value": 190 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 132650 },
        { "name": "Tanglin", "value": 8920 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 63820 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 540 },
        { "name": "Woodlands", "value": 120170 },
        { "name": "Yishun", "value": 92570 }
    ],
    "2010": [
        { "name": "Ang Mo Kio", "value": 92180 },
        { "name": "Bedok", "value": 150120 },
        { "name": "Bishan", "value": 46770 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 72840 },
        { "name": "Bukit Merah", "value": 80370 },
        { "name": "Bukit Panjang", "value": 64510 },
        { "name": "Bukit Timah", "value": 37340 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1030 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 86410 },
        { "name": "Clementi", "value": 47410 },
        { "name": "Downtown Core", "value": 1870 },
        { "name": "Geylang", "value": 60760 },
        { "name": "Hougang", "value": 109510 },
        { "name": "Jurong East", "value": 43900 },
        { "name": "Jurong West", "value": 131580 },
        { "name": "Kallang", "value": 49580 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 980 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25110 },
        { "name": "Museum", "value": 180 },
        { "name": "Newton", "value": 3380 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 24240 },
        { "name": "Orchard", "value": 430 },
        { "name": "Outram", "value": 9720 },
        { "name": "Pasir Ris", "value": 67370 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 30120 },
        { "name": "Queenstown", "value": 51690 },
        { "name": "River Valley", "value": 4480 },
        { "name": "Rochor", "value": 7750 },
        { "name": "Seletar", "value": 180 },
        { "name": "Sembawang", "value": 36530 },
        { "name": "Sengkang", "value": 84350 },
        { "name": "Serangoon", "value": 64070 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1030 },
        { "name": "Southern Islands", "value": 330 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 440 },
        { "name": "Tampines", "value": 132880 },
        { "name": "Tanglin", "value": 9260 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 64730 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 560 },
        { "name": "Woodlands", "value": 121450 },
        { "name": "Yishun", "value": 93050 }
    ],
    "2011": [
        { "name": "Ang Mo Kio", "value": 92100 },
        { "name": "Bedok", "value": 150660 },
        { "name": "Bishan", "value": 46640 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 72540 },
        { "name": "Bukit Merah", "value": 80160 },
        { "name": "Bukit Panjang", "value": 64860 },
        { "name": "Bukit Timah", "value": 37400 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1170 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 86770 },
        { "name": "Clementi", "value": 47500 },
        { "name": "Downtown Core", "value": 1840 },
        { "name": "Geylang", "value": 60340 },
        { "name": "Hougang", "value": 109850 },
        { "name": "Jurong East", "value": 43370 },
        { "name": "Jurong West", "value": 132960 },
        { "name": "Kallang", "value": 50980 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1060 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25680 },
        { "name": "Museum", "value": 170 },
        { "name": "Newton", "value": 3470 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 24300 },
        { "name": "Orchard", "value": 430 },
        { "name": "Outram", "value": 10640 },
        { "name": "Pasir Ris", "value": 67430 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 32750 },
        { "name": "Queenstown", "value": 51340 },
        { "name": "River Valley", "value": 4590 },
        { "name": "Rochor", "value": 7630 },
        { "name": "Seletar", "value": 180 },
        { "name": "Sembawang", "value": 36710 },
        { "name": "Sengkang", "value": 86530 },
        { "name": "Serangoon", "value": 63510 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1100 },
        { "name": "Southern Islands", "value": 460 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 440 },
        { "name": "Tampines", "value": 132450 },
        { "name": "Tanglin", "value": 9430 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 65130 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 540 },
        { "name": "Woodlands", "value": 122600 },
        { "name": "Yishun", "value": 93210 }
    ],
    "2012": [
        { "name": "Ang Mo Kio", "value": 92290 },
        { "name": "Bedok", "value": 150800 },
        { "name": "Bishan", "value": 47600 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 72290 },
        { "name": "Bukit Merah", "value": 80610 },
        { "name": "Bukit Panjang", "value": 65680 },
        { "name": "Bukit Timah", "value": 38350 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1190 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 87150 },
        { "name": "Clementi", "value": 47380 },
        { "name": "Downtown Core", "value": 1850 },
        { "name": "Geylang", "value": 60030 },
        { "name": "Hougang", "value": 110200 },
        { "name": "Jurong East", "value": 43250 },
        { "name": "Jurong West", "value": 133960 },
        { "name": "Kallang", "value": 51530 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1090 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25710 },
        { "name": "Museum", "value": 180 },
        { "name": "Newton", "value": 3510 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 24420 },
        { "name": "Orchard", "value": 470 },
        { "name": "Outram", "value": 10890 },
        { "name": "Pasir Ris", "value": 68490 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 37900 },
        { "name": "Queenstown", "value": 51470 },
        { "name": "River Valley", "value": 4700 },
        { "name": "Rochor", "value": 7550 },
        { "name": "Seletar", "value": 160 },
        { "name": "Sembawang", "value": 36830 },
        { "name": "Sengkang", "value": 90000 },
        { "name": "Serangoon", "value": 63450 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1200 },
        { "name": "Southern Islands", "value": 560 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 450 },
        { "name": "Tampines", "value": 132180 },
        { "name": "Tanglin", "value": 9570 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 65530 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 590 },
        { "name": "Woodlands", "value": 122860 },
        { "name": "Yishun", "value": 94140 }
    ],
    "2013": [
        { "name": "Ang Mo Kio", "value": 92220 },
        { "name": "Bedok", "value": 150410 },
        { "name": "Bishan", "value": 47580 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 72070 },
        { "name": "Bukit Merah", "value": 80280 },
        { "name": "Bukit Panjang", "value": 65990 },
        { "name": "Bukit Timah", "value": 39070 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1230 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 87620 },
        { "name": "Clementi", "value": 47500 },
        { "name": "Downtown Core", "value": 1870 },
        { "name": "Geylang", "value": 60120 },
        { "name": "Hougang", "value": 110420 },
        { "name": "Jurong East", "value": 43510 },
        { "name": "Jurong West", "value": 133950 },
        { "name": "Kallang", "value": 51540 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1120 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25870 },
        { "name": "Museum", "value": 170 },
        { "name": "Newton", "value": 3570 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 24730 },
        { "name": "Orchard", "value": 490 },
        { "name": "Outram", "value": 11030 },
        { "name": "Pasir Ris", "value": 68960 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 44670 },
        { "name": "Queenstown", "value": 52250 },
        { "name": "River Valley", "value": 4850 },
        { "name": "Rochor", "value": 7420 },
        { "name": "Seletar", "value": 150 },
        { "name": "Sembawang", "value": 36880 },
        { "name": "Sengkang", "value": 92740 },
        { "name": "Serangoon", "value": 63230 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1310 },
        { "name": "Southern Islands", "value": 670 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 440 },
        { "name": "Tampines", "value": 131940 },
        { "name": "Tanglin", "value": 9770 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 66270 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 590 },
        { "name": "Woodlands", "value": 123930 },
        { "name": "Yishun", "value": 94710 }
    ],
    "2014": [
        { "name": "Ang Mo Kio", "value": 92180 },
        { "name": "Bedok", "value": 149990 },
        { "name": "Bishan", "value": 47200 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 71800 },
        { "name": "Bukit Merah", "value": 80800 },
        { "name": "Bukit Panjang", "value": 66780 },
        { "name": "Bukit Timah", "value": 39480 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1220 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 87880 },
        { "name": "Clementi", "value": 47460 },
        { "name": "Downtown Core", "value": 1840 },
        { "name": "Geylang", "value": 59990 },
        { "name": "Hougang", "value": 111250 },
        { "name": "Jurong East", "value": 43360 },
        { "name": "Jurong West", "value": 134530 },
        { "name": "Kallang", "value": 51210 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1110 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25890 },
        { "name": "Museum", "value": 180 },
        { "name": "Newton", "value": 3560 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 24690 },
        { "name": "Orchard", "value": 470 },
        { "name": "Outram", "value": 11050 },
        { "name": "Pasir Ris", "value": 69450 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 49760 },
        { "name": "Queenstown", "value": 52210 },
        { "name": "River Valley", "value": 4900 },
        { "name": "Rochor", "value": 7270 },
        { "name": "Seletar", "value": 160 },
        { "name": "Sembawang", "value": 38120 },
        { "name": "Sengkang", "value": 97310 },
        { "name": "Serangoon", "value": 62960 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1340 },
        { "name": "Southern Islands", "value": 720 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 360 },
        { "name": "Tampines", "value": 131810 },
        { "name": "Tanglin", "value": 9910 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 65950 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 550 },
        { "name": "Woodlands", "value": 124260 },
        { "name": "Yishun", "value": 97270 }
    ],
    "2015": [
        { "name": "Ang Mo Kio", "value": 90550 },
        { "name": "Bedok", "value": 148530 },
        { "name": "Bishan", "value": 46590 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70610 },
        { "name": "Bukit Merah", "value": 80390 },
        { "name": "Bukit Panjang", "value": 69870 },
        { "name": "Bukit Timah", "value": 39810 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1230 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 87340 },
        { "name": "Clementi", "value": 47760 },
        { "name": "Downtown Core", "value": 1860 },
        { "name": "Geylang", "value": 58870 },
        { "name": "Hougang", "value": 112750 },
        { "name": "Jurong East", "value": 42710 },
        { "name": "Jurong West", "value": 135110 },
        { "name": "Kallang", "value": 50800 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1120 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25770 },
        { "name": "Museum", "value": 190 },
        { "name": "Newton", "value": 3680 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 24830 },
        { "name": "Orchard", "value": 500 },
        { "name": "Outram", "value": 10980 },
        { "name": "Pasir Ris", "value": 70740 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 55650 },
        { "name": "Queenstown", "value": 51820 },
        { "name": "River Valley", "value": 4930 },
        { "name": "Rochor", "value": 7180 },
        { "name": "Seletar", "value": 140 },
        { "name": "Sembawang", "value": 38620 },
        { "name": "Sengkang", "value": 104890 },
        { "name": "Serangoon", "value": 62290 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1410 },
        { "name": "Southern Islands", "value": 760 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 420 },
        { "name": "Tampines", "value": 133030 },
        { "name": "Tanglin", "value": 10230 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 65100 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 500 },
        { "name": "Woodlands", "value": 124380 },
        { "name": "Yishun", "value": 101980 }
    ],
    "2016": [
        { "name": "Ang Mo Kio", "value": 88800 },
        { "name": "Bedok", "value": 147440 },
        { "name": "Bishan", "value": 46320 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70080 },
        { "name": "Bukit Merah", "value": 79850 },
        { "name": "Bukit Panjang", "value": 71220 },
        { "name": "Bukit Timah", "value": 40500 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1190 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 89820 },
        { "name": "Clementi", "value": 47930 },
        { "name": "Downtown Core", "value": 1860 },
        { "name": "Geylang", "value": 58440 },
        { "name": "Hougang", "value": 113340 },
        { "name": "Jurong East", "value": 42580 },
        { "name": "Jurong West", "value": 134180 },
        { "name": "Kallang", "value": 50400 },
        { "name": "Lim Chu Kang", "value": 0 },
        { "name": "Mandai", "value": 1140 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25590 },
        { "name": "Museum", "value": 210 },
        { "name": "Newton", "value": 3810 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 24600 },
        { "name": "Orchard", "value": 470 },
        { "name": "Outram", "value": 10670 },
        { "name": "Pasir Ris", "value": 72320 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 65960 },
        { "name": "Queenstown", "value": 52410 },
        { "name": "River Valley", "value": 5000 },
        { "name": "Rochor", "value": 7070 },
        { "name": "Seletar", "value": 130 },
        { "name": "Sembawang", "value": 39030 },
        { "name": "Sengkang", "value": 111470 },
        { "name": "Serangoon", "value": 62030 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1490 },
        { "name": "Southern Islands", "value": 820 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 430 },
        { "name": "Tampines", "value": 131990 },
        { "name": "Tanglin", "value": 10560 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 64090 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 440 },
        { "name": "Woodlands", "value": 124270 },
        { "name": "Yishun", "value": 103980 }
    ],
    "2017": [
        { "name": "Ang Mo Kio", "value": 86780 },
        { "name": "Bedok", "value": 146470 },
        { "name": "Bishan", "value": 46440 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 70350 },
        { "name": "Bukit Merah", "value": 79170 },
        { "name": "Bukit Panjang", "value": 71590 },
        { "name": "Bukit Timah", "value": 40900 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1080 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 93520 },
        { "name": "Clementi", "value": 48470 },
        { "name": "Downtown Core", "value": 1210 },
        { "name": "Geylang", "value": 57250 },
        { "name": "Hougang", "value": 114150 },
        { "name": "Jurong East", "value": 42060 },
        { "name": "Jurong West", "value": 133050 },
        { "name": "Kallang", "value": 51290 },
        { "name": "Lim Chu Kang", "value": 50 },
        { "name": "Mandai", "value": 1130 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25390 },
        { "name": "Museum", "value": 200 },
        { "name": "Newton", "value": 3860 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 25080 },
        { "name": "Orchard", "value": 510 },
        { "name": "Outram", "value": 10360 },
        { "name": "Pasir Ris", "value": 73360 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 74200 },
        { "name": "Queenstown", "value": 51560 },
        { "name": "River Valley", "value": 5230 },
        { "name": "Rochor", "value": 6920 },
        { "name": "Seletar", "value": 110 },
        { "name": "Sembawang", "value": 40250 },
        { "name": "Sengkang", "value": 117910 },
        { "name": "Serangoon", "value": 61550 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1520 },
        { "name": "Southern Islands", "value": 880 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 370 },
        { "name": "Tampines", "value": 131920 },
        { "name": "Tanglin", "value": 10910 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 63650 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 370 },
        { "name": "Woodlands", "value": 124610 },
        { "name": "Yishun", "value": 106440 }
    ],
    "2018": [
        { "name": "Ang Mo Kio", "value": 86350 },
        { "name": "Bedok", "value": 144780 },
        { "name": "Bishan", "value": 45590 },
        { "name": "Boon Lay", "value": 0 },
        { "name": "Bukit Batok", "value": 73550 },
        { "name": "Bukit Merah", "value": 78750 },
        { "name": "Bukit Panjang", "value": 71290 },
        { "name": "Bukit Timah", "value": 41450 },
        { "name": "Central Water Catchment", "value": 0 },
        { "name": "Changi", "value": 1030 },
        { "name": "Changi Bay", "value": 0 },
        { "name": "Choa Chu Kang", "value": 94070 },
        { "name": "Clementi", "value": 48700 },
        { "name": "Downtown Core", "value": 1250 },
        { "name": "Geylang", "value": 56360 },
        { "name": "Hougang", "value": 113470 },
        { "name": "Jurong East", "value": 40910 },
        { "name": "Jurong West", "value": 132640 },
        { "name": "Kallang", "value": 51180 },
        { "name": "Lim Chu Kang", "value": 50 },
        { "name": "Mandai", "value": 1090 },
        { "name": "Marina East", "value": 0 },
        { "name": "Marina South", "value": 0 },
        { "name": "Marine Parade", "value": 25050 },
        { "name": "Museum", "value": 210 },
        { "name": "Newton", "value": 4030 },
        { "name": "North-Eastern Islands", "value": 0 },
        { "name": "Novena", "value": 25260 },
        { "name": "Orchard", "value": 530 },
        { "name": "Outram", "value": 9960 },
        { "name": "Pasir Ris", "value": 74440 },
        { "name": "Paya Lebar", "value": 0 },
        { "name": "Pioneer", "value": 0 },
        { "name": "Punggol", "value": 81790 },
        { "name": "Queenstown", "value": 51820 },
        { "name": "River Valley", "value": 5330 },
        { "name": "Rochor", "value": 6760 },
        { "name": "Seletar", "value": 140 },
        { "name": "Sembawang", "value": 44140 },
        { "name": "Sengkang", "value": 122280 },
        { "name": "Serangoon", "value": 60600 },
        { "name": "Simpang", "value": 0 },
        { "name": "Singapore River", "value": 1560 },
        { "name": "Southern Islands", "value": 960 },
        { "name": "Straits View", "value": 0 },
        { "name": "Sungei Kadut", "value": 380 },
        { "name": "Tampines", "value": 131330 },
        { "name": "Tanglin", "value": 11270 },
        { "name": "Tengah", "value": 0 },
        { "name": "Toa Payoh", "value": 63070 },
        { "name": "Tuas", "value": 0 },
        { "name": "Western Islands", "value": 0 },
        { "name": "Western Water Catchment", "value": 380 },
        { "name": "Woodlands", "value": 125810 },
        { "name": "Yishun", "value": 108770 }
    ]
};