import React from "react";

import IssuesContextProvider from "features/dashboards/issues/IssuesContextProvider";

import LatestReportsContextProvider from "features/dashboards/reports/contexts/LatestReportsContextProvider";
import ReportsHistoryContextProvider from "features/dashboards/reports/contexts/ReportsHistoryContextProvider";
import ReportsConfigContextProvider from "features/dashboards/reports/contexts/ReportsConfigContextProvider";
import ReportsExpectedContextProvider from "features/dashboards/reports/contexts/ReportsExpectedContextProvider";
import MetricsConfigContextProvider from "features/dashboards/metrics/contexts/MetricsConfigContextProvider";
import MetricsHistoryContextProvider from "features/dashboards/metrics/contexts/MetricsHistoryContextProvider";
import LatestMetricsContextProvider from "features/dashboards/metrics/contexts/LatestMetricsContextProvider";
import LatestFeedsContextProvider from "features/dashboards/feeds/contexts/LatestFeedsContextProvider";
import FeedsConfigContextProvider from "features/dashboards/feeds/contexts/FeedsConfigContextProvider";
import ServiceDeskContextProvider from "features/service-desk/contexts/ServiceDeskContextProvider";
import FeedsHistoryContextProviders from "features/dashboards/feeds/contexts/FeedsHistoryContextProviders";
import CampaignsConfigContextProvider from "features/dashboards/campaigns/contexts/CampaignsConfigContextProvider";
import CampaignsHistoryContextProvider from "features/dashboards/campaigns/contexts/CampaignsHistoryContextProvider";
import LatestCampaignsContextProvider from "features/dashboards/campaigns/contexts/LatestCampaignsContextProvider";


interface IDataContextProvidersProps {
    children: React.ReactNode;
}

const DataContextProviders = ({ children }: IDataContextProvidersProps) => {
    return (
        <IssuesContextProvider>

            <LatestReportsContextProvider>
                <ReportsHistoryContextProvider>
                    <ReportsConfigContextProvider>
                        <ReportsExpectedContextProvider>

                            <MetricsConfigContextProvider>
                                <MetricsHistoryContextProvider>
                                    <LatestMetricsContextProvider>

                                        <LatestFeedsContextProvider>
                                            <FeedsHistoryContextProviders>
                                                <FeedsConfigContextProvider>

                                                    <CampaignsConfigContextProvider>
                                                        <CampaignsHistoryContextProvider>
                                                            <LatestCampaignsContextProvider>

                                                                <ServiceDeskContextProvider>
                                                                    {children}
                                                                </ServiceDeskContextProvider>

                                                            </LatestCampaignsContextProvider>
                                                        </CampaignsHistoryContextProvider>
                                                    </CampaignsConfigContextProvider>

                                                </FeedsConfigContextProvider>
                                            </FeedsHistoryContextProviders>
                                        </LatestFeedsContextProvider>

                                    </LatestMetricsContextProvider>
                                </MetricsHistoryContextProvider>
                            </MetricsConfigContextProvider>

                        </ReportsExpectedContextProvider>
                    </ReportsConfigContextProvider>
                </ReportsHistoryContextProvider>
            </LatestReportsContextProvider>

        </IssuesContextProvider>
    )
};

export default DataContextProviders;

