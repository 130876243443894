import React from 'react';
import { Link } from 'react-router-dom';

import { FeedNames } from 'core/data/feeds/feed_names';
import FormatDay from 'core/helpers/datetime/FormatDay';
import GetStatusIcon from 'core/helpers/GetStatusIcon';

import { ICampaign } from '../types/ICampaign';

interface ICampaignsListItemProps {
    item: ICampaign
}

const CampaignsListItem = ({ item }: ICampaignsListItemProps) => {
    return (
        <div className={`listitem issue-${item.issue}`}>
            <Link to={`/campaigns/${item.client_name}/${item.campaign_name}`}>

                <div className={'listitem__header'}>
                    <div className={'listitem__toggle-details'}>
                        <div className={'listitem__toggle-details__container'}>
                            {GetStatusIcon(item.issue)}
                        </div>
                    </div>

                    <div className={'listitem__title'}>
                        {FeedNames.get(item.campaign_name) ?? item.campaign_name}
                    </div>

                    <div className={`listitem__value`}>
                        Received: {item.last_date === 0 ? 'Never' : FormatDay(item.last_date)}
                    </div>

                    <div className={`listitem__value issue-${item.issue}`}>
                        Sent: {item.sent}
                    </div>

                    <div className={`listitem__value issue-${item.issue}`}>
                        Opens: {item.opens}
                    </div>

                    <div className={`listitem__value issue-${item.issue}`}>
                        Clicks: {item.clicks}
                    </div>
                    <div className={'listitem__icon'}>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default CampaignsListItem;