import React from 'react';
import WideTile from 'core/components/tiles/WideTile';
import SquareTile from 'core/components/tiles/SquareTile';
import HalfWidthTileContainer from 'core/components/tiles/HalfWidthTileContainer';

import AnimatedBarChart from './examples/AnimatedBarChart';
import Area from './examples/Area';
import Wine from './examples/Wine';
import Bar from './examples/Bar';
import Radar from './examples/Radar';
import Pie from './examples/Pie';
import Projects from './examples/Projects';
import LineAnnotated from './examples/LineAnnotated';
import GradientBarChart from './examples/GradientBarChart';
import Line from './examples/Line';
import Force from './examples/Force';

const TiledDashboard = () => {
    return (
        <>
            <WideTile>
                <Area />
            </WideTile>
            <SquareTile>
                <Wine />
            </SquareTile>
            <HalfWidthTileContainer>
                <SquareTile>
                    <Bar />
                </SquareTile>
                <SquareTile>
                    <Radar />
                </SquareTile>
                <SquareTile>
                    <Pie />
                </SquareTile>
                <SquareTile>
                    <Projects />
                </SquareTile>
            </HalfWidthTileContainer>
            <WideTile>
                <LineAnnotated />
            </WideTile>
            <HalfWidthTileContainer>
                <WideTile>
                    <GradientBarChart />
                </WideTile>
                <WideTile>
                    <Line />
                </WideTile>
            </HalfWidthTileContainer>
            <SquareTile>
                <Force />
            </SquareTile>
            <WideTile>
                <AnimatedBarChart />
            </WideTile>
        </>
    )
};

export default TiledDashboard;