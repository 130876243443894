export enum TicketStatus {
    Draft = "Draft",
    Submitted = "Submitted",
    Scoped = "Scoped",
    Accepted = "Accepted",
    Scheduled = "Scheduled",
    InDevelopment = "In Development",
    Paused = "Paused",
    InfoRequested = "Info Requested",
    ReviewRequested = "Review Requested",
    FeedbackRequested = "Feedback Requested",
    Complete = "Complete",
    Cancelled = "Cancelled",
    Deleted = "Deleted"
}

export const PossibleNextStatuses: { [key in TicketStatus]: TicketStatus[] } = {
    [TicketStatus.Draft]: [TicketStatus.Submitted, TicketStatus.Cancelled],
    [TicketStatus.Submitted]: [TicketStatus.Scoped, TicketStatus.Draft],
    [TicketStatus.Scoped]: [TicketStatus.Accepted, TicketStatus.Cancelled],
    [TicketStatus.Accepted]: [TicketStatus.Scheduled, TicketStatus.Cancelled],
    [TicketStatus.Cancelled]: [TicketStatus.Draft],
    [TicketStatus.Scheduled]: [TicketStatus.InDevelopment, TicketStatus.Accepted, TicketStatus.Cancelled],
    [TicketStatus.InDevelopment]: [TicketStatus.Paused, TicketStatus.InfoRequested, TicketStatus.FeedbackRequested, TicketStatus.ReviewRequested, TicketStatus.Complete, TicketStatus.Accepted, TicketStatus.Cancelled],
    [TicketStatus.Paused]: [TicketStatus.InDevelopment, TicketStatus.Accepted, TicketStatus.Cancelled],
    [TicketStatus.InfoRequested]: [TicketStatus.InDevelopment, TicketStatus.Accepted, TicketStatus.Cancelled],
    [TicketStatus.ReviewRequested]: [TicketStatus.InDevelopment, TicketStatus.Complete, TicketStatus.Accepted, TicketStatus.Cancelled],
    [TicketStatus.FeedbackRequested]: [TicketStatus.InDevelopment, TicketStatus.Complete, TicketStatus.Accepted, TicketStatus.Cancelled],
    [TicketStatus.Complete]: [TicketStatus.Draft],
    [TicketStatus.Deleted]: [TicketStatus.Draft]
}