import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import FlagIcon from '@mui/icons-material/Flag';

import CustomTable from "core/components/status-table/CustomTable";
import { UsersContext } from "core/data/auth/UsersContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";
import { Permission } from "core/enums/Permissions";
import FormatDate from "core/helpers/datetime/FormatDate";

import { Ticket } from "../types/Ticket";
import { ServiceDeskContext } from "../contexts/ServiceDeskContextProvider";
import { Priority } from "../types/Priority";
import { TicketStatus } from "../types/TicketStatus";
import { ContractPriority } from "../types/ContractPriorities";
import ListTile from "core/components/tiles/ListTile";

const ServiceDeskAllocatedWork = () => {
    const { tickets, fetchSprintName, currentSprint } = useContext(ServiceDeskContext);
    const { user, permissions } = useContext(UserContext);
    const { fetchUserName } = useContext(UsersContext);

    let userTickets = [];
    if (permissions.includes(Permission.AllocateTickets)) {
        userTickets = tickets.filter(x =>
            x.sprint === currentSprint.id
        );
    } else {
        userTickets = tickets.filter(x =>
            x.assignee_id === user.id &&
            x.status !== TicketStatus.Complete &&
            x.status !== TicketStatus.Deleted &&
            x.status !== TicketStatus.Cancelled
        );
    }

    userTickets.sort((a, b) =>
        (!!b.sprint && b.sprint === currentSprint.id ? 1 : 0) - (!!a.sprint && a.sprint === currentSprint.id ? 1 : 0) ||
        (!!a.sprint ? a.sprint : Infinity) - (!!b.sprint ? b.sprint : Infinity) ||
        Object.keys(ContractPriority).indexOf(a.contract_priority) - Object.keys(ContractPriority).indexOf(b.contract_priority) ||
        Object.keys(Priority).indexOf(a.priority) - Object.keys(Priority).indexOf(b.priority) ||
        a.id - b.id
    );

    const GetSprint = useMemo(() => {
        return (id: number) => ({ value: id, label: (id === undefined || id === null || id === -1) ? "No Sprint" : fetchSprintName(id) })
    }, [fetchSprintName]);

    const GetAssignee = useMemo(() => {
        return (id: number) => ({ value: id, label: id === -1 ? "Unassigned" : fetchUserName(id) });
    }, [fetchUserName]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }: CellProps<Ticket>) => {
                    return (
                        <Link to={`/service-desk/${row.original.id}`}>
                            {row.original.name}
                        </Link>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Priority',
                accessor: 'priority',
            },
            {
                Header: 'Deadline',
                accessor: 'deadline',
                Cell: ({ value }: { value: Date }) => FormatDate(value),
            },
            {
                Header: 'Flagged',
                accessor: 'flagged',
                Cell: ({ row, value }: CellProps<Ticket>) => {
                    return (
                        <FlagIcon
                            className={`flagged ${value ? 'active' : 'inactive'}`}
                        />
                    )
                },
            },
            {
                Header: 'Scope',
                accessor: 'scope',
                Cell: ({ row, value }: CellProps<Ticket>) => (
                    <input type={'number'}
                        defaultValue={value ?? -1}
                        min={0}
                        max={1000}
                        readOnly={true}
                    />
                )
            },
            {
                Header: 'Sprint',
                accessor: 'sprint',
                Cell: ({ row, value }: CellProps<Ticket>) => {
                    return (
                        <>
                            {GetSprint(value).label}
                        </>
                    );
                }
            },
            {
                Header: 'Assignee',
                accessor: 'assignee_id',
                Cell: ({ row, value }: CellProps<Ticket>) => {
                    return (
                        <>
                            {GetAssignee(value).label}
                        </>
                    );
                }
            }
        ], [GetSprint]
    )


    return (
        <div className="service-desk__tickets-list">
            <ListTile>
                <h1 className={`list__title`}>
                    Allocated Work
                </h1>
                <CustomTable columns={columns} data={userTickets} />
            </ListTile>
        </div >
    );
};
export default ServiceDeskAllocatedWork;