import React, { useContext, useCallback, useEffect, useMemo } from "react";
import { CellProps, Row } from "react-table";

import { ToggleFilter } from "core/components/status-table/CustomTable";
import DbStatusTable from "core/components/status-table/DbStatusTable";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import LoadingSpinner from "core/components/loading/Loading";
import IssuesSubTitle from "core/components/status-table/IssuesSubTitle";
import { UserContext } from "core/data/auth/UserContextProvider";
import { Frequencies, TEN_MIN_FREQ } from "core/data/feeds/frequencies";
import { FeedNames } from "core/data/feeds/feed_names";
import { Permission } from "core/enums/Permissions";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";
import FormatDate from "core/helpers/datetime/FormatDate";
import GetContractSupportIcon from "core/helpers/GetContractSupportIcon";
import AdminLink from "core/helpers/auth/AdminLink";
import GetStatusIcon from "core/helpers/GetStatusIcon";

import { LatestReportsContext } from "../contexts/LatestReportsContextProvider";
import { IReport } from "../types/IReport";
import ReportsListItem from "./ReportsListItem";


export const getReportsIssue = (item: IReport) => {
    try {
        // If we dont have an expected date, lets assume it's just pending
        if (item.next_date === 0) return IssueEnum.OK;

        const freq = Frequencies[item.report_name] ?? TEN_MIN_FREQ;

        // Expected items can take a little while to update, so show OK if we've recently received one
        if (item.next_date < item.last_date) return IssueEnum.OK;

        const now = Date.now();
        const date = item.next_date; // YYYY-MM-DDTHH:MM:SS.SSSZ

        if (now < date) return IssueEnum.OK;
        if (now < date + freq.alert) return IssueEnum.WARNING;
        return IssueEnum.ERROR;
    } catch (e) {
        console.error(e, item);
        return IssueEnum.ERROR;
    }
};

export const transformReportsData = (latestReports: IReport[] | null) => {
    if (!latestReports) return null;

    return latestReports
    ?.filter(x => !!x.last_report_datetime)
    ?.map(x => ({
        ...x,
        id: x.report_id,
        name: x.report_name,
        last_date: ParseDBDate(x.last_report_datetime),
        next_date: ParseDBDate(x.next_report_datetime) || 1e15
    }))
    ?.map(x => ({
        ...x,
        issue: getReportsIssue(x)
    }));
};

const ReportsListTable = ({collapsible = false}: {collapsible?: boolean}) => {
    const { items, error, setVisible } = useContext(LatestReportsContext);
    const {permissions} = useContext(UserContext);

    useEffect(() => {
        setVisible(true);

        return () => {
            setVisible(false);
        };
    }, [setVisible]);


    const GetIssue = useCallback((item: IReport) => getReportsIssue(item), []);

    const data = useMemo(() => transformReportsData(items), [items]);

    const getContractFilter = useCallback(() => {
        return -1;
    }, []);
    

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ value, row }: CellProps<IReport>) => (
                    <AdminLink 
                        permission={Permission.ViewReportDetails}
                        to={`/reports/${row.original.client_id}/${row.original.id}`}
                    >
                        <b>{FeedNames.get(value) ?? value}</b>
                    </AdminLink>
                ),
                headerStyle: { width: "35%" }
            },
            {
                Header: 'Received',
                accessor: 'last_date',
                Cell: ({ value }: CellProps<IReport>) => FormatDate(value),
            },
            {
                Header: 'Expected',
                accessor: 'next_date',
                Cell: ({ value }: CellProps<IReport>) => value === 1e15 ? "" : FormatDate(value),
                isSorted: true,
                isSortedDesc: false,
            },
            {
                Header: 'Supported',
                accessor: 'in_contract',
                Cell: ({ value }: CellProps<IReport>) => GetContractSupportIcon(value),
                style: { textAlign: 'center' },
                Filter: ToggleFilter,
                canFilter: true,
                filter: 'toggle',
                getFilterValue: getContractFilter
            },
            {
                Header: 'Status',
                accessor: 'issue',
                Cell: ({ value }: CellProps<IReport>) => GetStatusIcon(value),
                style: { textAlign: 'center' },
            },
        ], []
    );

    const issues = data?.filter(x => x.issue !== IssueEnum.OK).length ?? 0;

    if (!permissions.includes(Permission.ViewReports)) {
        return null;
    }

    return (
        <>
            {items === null && <LoadingSpinner />}

            {items && (
                <DbStatusTable<IReport>
                    unique={true}
                    title={'Reports'}
                    subtitle={IssuesSubTitle(issues)}
                    items={data}
                    error={error}
                    RenderRow={(item) => <ReportsListItem key={item.id} item={item} />}
                    GetIssue={GetIssue}
                    columns={columns}
                    collapsible={collapsible}
                    getRowProps={(row: Row<IReport>) => ({
                            style: {
                                backgroundColor: row.original.issue === IssueEnum.ERROR 
                                    ? '#ffcccc' : 
                                    (row.original.issue === IssueEnum.WARNING ? '#ffffcc' : 'white')
                            }
                        })
                    }
                />
            )}
        </>
    );
};

export default ReportsListTable;