import React, { CSSProperties, useEffect, useRef, useState } from "react";

import { DAILY_FREQ, IFrequency, MONTHLY_FREQ, QUARTERLY_FREQ, WEEKLY_FREQ } from "core/data/feeds/frequencies";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import { ReportFrequency } from "core/enums/ReportFrequency";


import FormatCounter from "./FormatCounter";

interface ICountdownProps {
    time: number;
    freq?: IFrequency;
    reportFreq?: ReportFrequency;
    style?: CSSProperties;
}
const Countdown = ({ time, freq, reportFreq, style }: ICountdownProps) => {
    // https://codepen.io/thebabydino/pen/RwPNaEd

    if (freq === undefined && reportFreq === undefined) {
        throw new Error("Countdown must be provided a frequency or report frequency");
    }

    if (!!reportFreq) {
        switch (reportFreq) {
            case ReportFrequency.Daily:
            default:
                freq = DAILY_FREQ;
                break;
            case ReportFrequency.Weekly:
                freq = WEEKLY_FREQ;
                break;
            case ReportFrequency.Monthly:
                freq = MONTHLY_FREQ;
                break;
            case ReportFrequency.Quarterly:
                freq = QUARTERLY_FREQ;
                break;
        }
    }

    if (!freq) {
        freq = DAILY_FREQ;
    }

    const ref = useRef<HTMLDivElement>(null);
    const [currentTime, setCurrentTime] = useState(time);

    useEffect(() => {
        const i = setInterval(() => {
            setCurrentTime(ct => ct - 1000);
        }, 1000);

        return () => {
            clearInterval(i);
        }
    }, []);

    const diam = 100;
    const offset = -0.5 * diam;
    const strokeWidth = 0.1 * diam;
    const rad = 0.5 * (diam - strokeWidth);
    const strokeLen = 2 * Math.PI * rad;

    let strokeDashoffset = strokeLen;
    let issue = IssueEnum.OK;
    let displayTime = currentTime;

    // Shape of the stroke depends on the time remaining/exceeded
    if (currentTime > 0) {
        strokeDashoffset = strokeLen - (strokeLen * currentTime / freq.expected);
        issue = IssueEnum.OK;
    } else if (currentTime > (freq.expected - freq.warning)) {
        strokeDashoffset = (strokeLen * currentTime / (freq.expected - freq.warning)) - strokeLen;
        issue = IssueEnum.WARNING;
        displayTime = -1 * currentTime;
    } else {
        strokeDashoffset = (strokeLen * currentTime / (freq.expected - freq.alert)) - strokeLen;
        issue = IssueEnum.ERROR;
        displayTime = -1 * currentTime;
    }
    
    return (
        <div style={style} className={`countdown issue-${issue} exp-${freq.expected/1000}`} ref={ref}>
            <svg viewBox={`${offset} ${offset} ${diam} ${diam}`} strokeWidth={strokeWidth}>
                <circle r={rad}></circle>
                <circle r={rad}
                    strokeDasharray={strokeLen}
                    strokeDashoffset={strokeDashoffset.toString() + "px"}
                ></circle>
            </svg>
            <div className={"label"}>{FormatCounter(displayTime)}</div>
        </div>
    )
};

export default Countdown;