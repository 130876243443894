import React, { ReactNode, createContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";

import { IMetricConfig } from "../types/IMetricConfig";
import { FETCH_METRICS_CONFIG_DB } from "config";


export const MetricsConfigContext = createContext<IMetricsConfigContext>(null!);
export interface IMetricsConfigContext extends IDataContext<IMetricConfig> { }


interface IMetricsConfigContextProvider {
    children: ReactNode;
}
const MetricsConfigContextProvider = ({ children }: IMetricsConfigContextProvider) => {

    return (
        <DataContextProvider<IMetricConfig>
            context={MetricsConfigContext}
            endpoint={FETCH_METRICS_CONFIG_DB}
        >
            {children}
        </DataContextProvider>
    );
};

export default MetricsConfigContextProvider;