import React, { useContext } from "react";

import HalfWidthTileContainer from "core/components/tiles/HalfWidthTileContainer";
import WideTile from "core/components/tiles/WideTile";
import SimpleTable from "core/components/status-table/SimpleTable";
import SimpleListItem from "core/components/status-table/SimpleListItem";
import LoadingSpinner from "core/components/loading/Loading";
import { IssueEnum } from "core/components/tabs/enums/IssueEnum";

import FormatTime from "core/helpers/datetime/FormatTime";
import FormatDay from "core/helpers/datetime/FormatDay";
import Log from "core/helpers/Log";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";

import { ReportsHistoryContext } from "../contexts/ReportsHistoryContextProvider";

import { IReportHistory } from "../types/IReportHistory";

const ReportsHistoryTable = ({ client, report }: { client: number; report: number }) => {
    const { items: reportsHistory, error: reportsHistoryError } = useContext(ReportsHistoryContext);

    let reportHistory = reportsHistory
        ?.filter((x) => x.client_id === client && x.report_id === report && x.report_timestamp !== null)
        ?.map((x) => ({
            ...x,
            name: x.report_name,
            last_date: ParseDBDate(x.report_timestamp),
            next_date: 0,
            issue: IssueEnum.OK,
        }))
        ?.sort((a, b) => b.last_date - a.last_date)
        ?.slice(0, 10);
    Log("reportHistory", reportHistory);

    return (
        <HalfWidthTileContainer grow={true}>
            {reportHistory && (
                <SimpleTable<IReportHistory>
                    title={"History"}
                    items={reportHistory.slice(0, 7)}
                    error={reportsHistoryError}
                    RenderRow={(item) => <SimpleListItem key={item.name} title={FormatDay(item.last_date)} text={FormatTime(item.last_date)} />} 
                />
            )}
            {reportHistory === null && <LoadingSpinner />}
            {reportHistory && reportHistory.length === 0 && (
                <WideTile>
                    <h3>History</h3>
                    <p>No history found</p>
                </WideTile>
            )}
        </HalfWidthTileContainer>
    );
};

export default ReportsHistoryTable;