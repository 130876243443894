import React, { createContext, useCallback, useContext, useEffect, useState, useRef } from "react";

import { UserContext } from "core/data/auth/UserContextProvider";
import FetchFromLambda from "core/data/aws/lambda/FetchFromLambda";
import { LambdaContext } from "core/data/contexts/LambdaContextProvider";
import Log from "core/helpers/Log";

import { Ticket } from "../types/Ticket";
import { TicketType } from "../types/TicketType";
import { ItemType } from "../types/ItemType";
import { ContractItem } from "../types/ContractItems";
import { ContractPriority, IContractPriority } from "../types/ContractPriorities";
import { IContract } from "../types/Contract";
import { TicketStatus } from "../types/TicketStatus";
import { Priority } from "../types/Priority";
import { ISprint, SprintStatus } from "../types/Sprint";
import { ICalendar } from "../types/Calendar";
import { Permission } from "core/enums/Permissions";
import { ISLA, SLA } from "../types/SLAs";

import { FETCH_CALENDARS_LAMBDA } from "config";
import { UsersContext } from "core/data/auth/UsersContextProvider";

// Define default value to avoid destructuring errors
export const ServiceDeskContext = createContext<IServiceDeskContext>({
    tickets: [],
    items: [],
    quotas: [],
    contract: {} as IContract,
    sprints: [],
    currentSprint: {} as ISprint,
    calendars: [],
    newTicket: (ticket: Ticket) => { },
    updateTicket: (ticket: Ticket) => { },
    fetchSprint: (id: number) => ({} as ISprint),
    fetchSprintName: (id: number) => '',
    fetchItem: (id: number) => ({} as ContractItem),
    fetchItemName: (id: number) => '',
    slaForTicketInState: (ticket: Ticket) => 0,
});

const Contracts: IContract[] = [
    {
        id: 1,
        contract_name: 'Data Kraken - 48 - Contract 2023',
        client_id: 2,
        contract_location: 'file:///',
        contract_start_date: '2023-01-01',
        contract_end_date: '2023-12-31'
    }
];

export interface IContractQuota {
    id: number;
    contract_id: number;
    item_type: ItemType;
    quota: number;
    cost: number;
}

const ContractQuotas: IContractQuota[] = [
    {
        id: 1,
        contract_id: 1,
        item_type: ItemType.Feed,
        quota: 100,
        cost: 10
    },
    {
        id: 2,
        contract_id: 1,
        item_type: ItemType.Report,
        quota: 10,
        cost: 100
    },
    {
        id: 3,
        contract_id: 1,
        item_type: ItemType.Campaign,
        quota: 50,
        cost: 50
    },
    {
        id: 4,
        contract_id: 1,
        item_type: ItemType.Analysis,
        quota: 25,
        cost: 250
    }
];


const SLAs = new Map<SLA, ISLA>([
    [SLA.Default, {
        id: 1,
        sla_name: 'Default',
        sla_description: 'Default',
        time_in_hours: 5 * 24
    }],
    [SLA.Emergency, {
        id: 2,
        sla_name: 'Emergency',
        sla_description: 'Emergency',
        time_in_hours: 8
    }]
]);

const ContractPriorities = new Map<ContractPriority, IContractPriority>([
    [ContractPriority.HighReporting, {
        id: 1,
        contract_id: 1,
        priority_name: 'High - Reporting',
        priority_value: 1,
        priority_description: 'High - Reporting',
        response_time_in_hours: 8,
        resolution_time_in_hours: 8
    }],
    [ContractPriority.HighDataLoads, {
        id: 2,
        contract_id: 1,
        priority_name: 'High - Data Loads',
        priority_value: 2,
        priority_description: 'High - Data Loads',
        response_time_in_hours: 8,
        resolution_time_in_hours: 16
    }],
    [ContractPriority.Medium, {
        id: 3,
        contract_id: 1,
        priority_name: 'Medium',
        priority_value: 3,
        priority_description: 'Medium',
        response_time_in_hours: 8,
        resolution_time_in_hours: 24
    }],
    [ContractPriority.Low, {
        id: 4,
        contract_id: 1,
        priority_name: 'Low',
        priority_value: 4,
        priority_description: 'Low',
        response_time_in_hours: 16,
        resolution_time_in_hours: 40
    }]
]);

const ContractItems: ContractItem[] = [
    {
        id: 1,
        contract_id: 1,
        contract_page: 1,
        report_id: 1,
        report_name: 'Report 1',
        item_type: ItemType.Report
    },
    {
        id: 2,
        contract_id: 1,
        contract_page: 2,
        report_id: 2,
        report_name: 'Report 2',
        item_type: ItemType.Report
    },
    {
        id: 3,
        contract_id: 1,
        contract_page: 3,
        report_id: 3,
        report_name: 'Report 3',
        item_type: ItemType.Report
    },
    {
        id: 4,
        contract_id: 1,
        contract_page: 1,
        feed_id: 1,
        feed_name: 'Feed 1',
        item_type: ItemType.Feed
    },
    {
        id: 5,
        contract_id: 1,
        contract_page: 2,
        feed_id: 2,
        feed_name: 'Feed 2',
        item_type: ItemType.Feed
    },
    {
        id: 6,
        contract_id: 1,
        contract_page: 3,
        feed_id: 3,
        feed_name: 'Feed 3',
        item_type: ItemType.Feed
    }
];

const Sprints: ISprint[] = [
    {
        id: 1,
        name: 'Sprint 1',
        start_date: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000),
        end_date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
        status: SprintStatus.Complete,
    },
    {
        id: 2,
        name: 'Sprint 2',
        start_date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
        end_date: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000),
        status: SprintStatus.InProgress,
    },
    {
        id: 3,
        name: 'Sprint 3',
        start_date: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000),
        end_date: new Date(Date.now() + 12 * 24 * 60 * 60 * 1000),
        status: SprintStatus.NotStarted,
    }
];

const Tickets = [
    {
        id: 0,
        client: 2,
        creator_id: 0,
        assignee_id: 3,
        name: "Ticket 1",
        description: "This is a description of ticket 1",
        status: TicketStatus.Draft,
        priority: Priority.High,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        item_ids: [1],
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 1,
        client: 0,
        creator_id: 1,
        assignee_id: 1,
        name: "Ticket 2",
        description: "This is a description of ticket 2",
        status: TicketStatus.Submitted,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.Issue,
        ItemType: ItemType.Analysis,
        item_ids: [2],
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 2,
        client: 0,
        creator_id: 0,
        assignee_id: 3,
        name: "Ticket 3",
        description: "This is a description of ticket 3",
        status: TicketStatus.Scoped,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.ChangeRequest,
        item_ids: [4],
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 3,
        client: 2,
        creator_id: 1,
        assignee_id: 1,
        name: "Ticket 4",
        description: "This is a description of ticket 4",
        status: TicketStatus.Accepted,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.Issue,
        item_ids: [1],
        scope: 5,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 4,
        client: 2,
        creator_id: 1,
        assignee_id: 3,
        name: "Ticket 5",
        description: "This is a description of ticket 5",
        status: TicketStatus.Cancelled,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.ChangeRequest,
        item_ids: [2],
        scope: 8,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 5,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 6",
        description: "This is a description of ticket 6",
        status: TicketStatus.Scheduled,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        scope: 13,
        sprint: Sprints[1].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 6,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 7",
        description: "This is a description of ticket 7",
        status: TicketStatus.InDevelopment,
        priority: Priority.High,
        created: new Date(),
        updated: new Date(),
        type: TicketType.Issue,
        item_ids: [1],
        scope: 11,
        sprint: Sprints[1].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 7,
        client: 2,
        creator_id: 1,
        assignee_id: 1,
        name: "Ticket 8",
        description: "This is a description of ticket 8",
        status: TicketStatus.Paused,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.ChangeRequest,
        item_ids: [2],
        scope: 5,
        sprint: Sprints[1].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 8,
        client: 2,
        creator_id: 1,
        assignee_id: 3,
        name: "Ticket 9",
        description: "This is a description of ticket 9",
        status: TicketStatus.InfoRequested,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        scope: 21,
        sprint: Sprints[1].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 9,
        client: 2,
        creator_id: 1,
        assignee_id: 1,
        name: "Ticket 10",
        description: "This is a description of ticket 10",
        status: TicketStatus.ReviewRequested,
        priority: Priority.High,
        created: new Date(),
        updated: new Date(),
        type: TicketType.Issue,
        item_ids: [1],
        scope: 34,
        sprint: Sprints[1].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 10,
        client: 2,
        creator_id: 1,
        assignee_id: 3,
        name: "Ticket 11",
        description: "This is a description of ticket 11",
        status: TicketStatus.FeedbackRequested,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.ChangeRequest,
        item_ids: [2],
        scope: 16,
        sprint: Sprints[1].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 11,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 12",
        description: "This is a description of ticket 12",
        status: TicketStatus.Complete,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        scope: 8,
        sprint: Sprints[1].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 12,
        client: 2,
        creator_id: 1,
        assignee_id: 1,
        name: "Ticket 13",
        description: "This is a description of ticket 13",
        status: TicketStatus.Deleted,
        priority: Priority.High,
        created: new Date(),
        updated: new Date(),
        type: TicketType.Issue,
        item_ids: [1],
        scope: 5,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 13,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 14",
        description: "This is a description of ticket 14",
        status: TicketStatus.Accepted,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.ChangeRequest,
        item_ids: [2],
        scope: 3,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 14,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 15",
        description: "This is a description of ticket 15",
        status: TicketStatus.Accepted,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        scope: 2,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 15,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 16",
        description: "This is a description of ticket 16",
        status: TicketStatus.Accepted,
        priority: Priority.High,
        created: new Date(),
        updated: new Date(),
        type: TicketType.Issue,
        item_ids: [1],
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 16,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 17",
        description: "This is a description of ticket 17",
        status: TicketStatus.Scheduled,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.ChangeRequest,
        item_ids: [2],
        scope: 1,
        sprint: Sprints[2].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 17,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 18",
        description: "This is a description of ticket 18",
        status: TicketStatus.Scheduled,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        scope: 1,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 18,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 19",
        description: "This is a description of ticket 19",
        status: TicketStatus.Complete,
        priority: Priority.High,
        created: new Date(),
        updated: new Date(),
        type: TicketType.Issue,
        item_ids: [1],
        scope: 8,
        sprint: Sprints[0].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 19,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 20",
        description: "This is a description of ticket 20",
        status: TicketStatus.Complete,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.ChangeRequest,
        item_ids: [2],
        scope: 5,
        sprint: Sprints[0].id,
        contract_priority: ContractPriority.Low,
        internal: false
    },
    {
        id: 20,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 21",
        description: "This is a description of ticket 21",
        status: TicketStatus.InfoRequested,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        scope: 3,
        sprint: Sprints[0].id,
        contract_priority: ContractPriority.Low,
        internal: false,
        linked_tickets: [21]
    },
    {
        id: 21,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 22",
        description: "This is a description of ticket 22",
        status: TicketStatus.InfoRequested,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        scope: 3,
        sprint: Sprints[0].id,
        contract_priority: ContractPriority.Low,
        internal: false,
        linked_tickets: [20]
    },
    {
        id: 22,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 22 - Report 1",
        description: "This is a description of ticket 22",
        status: TicketStatus.InfoRequested,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        item_ids: [1],
        contract_priority: ContractPriority.Low,
        internal: true,
        parent_id: 21,
        linked_tickets: [19]
    },
    {
        id: 23,
        client: 2,
        creator_id: 1,
        assignee_id: 2,
        name: "Ticket 22 - Report 2",
        description: "This is a description of ticket 22",
        status: TicketStatus.InfoRequested,
        priority: Priority.Medium,
        created: new Date(),
        updated: new Date(),
        type: TicketType.NewFeature,
        item_ids: [2],
        contract_priority: ContractPriority.Low,
        internal: true,
        parent_id: 21
    },
    {
        id: 24,
        client: 3,
        creator_id: 4,
        assignee_id: 2,
        name: "Ticket 24",
        description: "This is a description of ticket 24",
        status: TicketStatus.Accepted,
        priority: Priority.High,
        created: new Date(),
        updated: new Date(),
        type: TicketType.Issue,
        item_id: 1,
        scope: 2,
        sprint: Sprints[0].id,
        contract_priority: ContractPriority.Low,
        internal: false,
    },
    {
        id: 25,
        client: 3,
        creator_id: 4,
        assignee_id: 2,
        name: "Ticket 25",
        description: "This is a description of ticket 25",
        status: TicketStatus.Accepted,
        priority: Priority.Low,
        created: new Date(),
        updated: new Date(),
        type: TicketType.ChangeRequest,
        item_id: 2,
        contract_priority: ContractPriority.Low,
        internal: false,
    },
];

interface props {
    children: React.ReactNode;
}

export interface IServiceDeskContext {
    tickets: Ticket[];
    items: ContractItem[];
    quotas: IContractQuota[];
    contract: IContract;
    sprints: ISprint[];
    currentSprint: ISprint;
    calendars: ICalendar[];
    newTicket: (ticket: Ticket) => void;
    updateTicket: (ticket: Ticket) => void;
    fetchSprint: (id: number) => ISprint;
    fetchSprintName: (id: number) => string;
    fetchItem: (id: number) => ContractItem;
    fetchItemName: (id: number) => string;
    slaForTicketInState: (ticket: Ticket) => number | null;
}

export interface ICalendarAPIResponse {
    data: {
        [key: string]: {
            start: string;
            end: string;
            duration: number;
        }[]
    }
}

const ServiceDeskContextProvider = (props: props) => {
    const { clients, permissions } = useContext(UserContext);
    const { fetchUserIdByEmail } = useContext(UsersContext);
    const { lambda } = useContext(LambdaContext);

    const [tickets, setTickets] = useState<Ticket[]>(
        permissions.some(x => x === Permission.SeeAllTickets)
            ? Tickets
            : Tickets.filter(x => clients.includes(x.client) && !x.internal)
    );
    const [allTickets, setAllTickets] = useState<Ticket[]>(Tickets);
    const nextTicketId = useRef(Tickets.length + 1);
    const [items] = useState<ContractItem[]>(ContractItems);
    const [quotas] = useState<IContractQuota[]>(ContractQuotas);
    const [sprints] = useState<ISprint[]>(Sprints);
    const [calendars, setCalendars] = useState<ICalendar[]>([]);

    const [contract] = useState<IContract>(
        Contracts.find(x =>
            clients.includes(x.client_id) &&
            new Date(x.contract_start_date).getTime() < Date.now() &&
            (!!x.contract_end_date ? new Date(x.contract_end_date).getTime() > Date.now() : true)
        ) ?? {} as IContract
    );
    const [currentSprint] = useState<ISprint>(
        Sprints.find(x =>
            x.start_date.getTime() < Date.now() &&
            x.end_date.getTime() > Date.now()
        ) ?? {} as ISprint
    );
    const [loading] = useState(false);

    useEffect(() => {
        const fetchCalendar = async () => {
            if (!lambda) return;

            try {
                const [error, data] = await FetchFromLambda<ICalendarAPIResponse>(lambda, FETCH_CALENDARS_LAMBDA);
                Log(FETCH_CALENDARS_LAMBDA, error, data);

                if (error) {
                    console.error(error);
                    setCalendars([]);
                } else {
                    if (data) {
                        let c: ICalendar[] = [];
                        Object.entries(data.data).forEach(([key, values]) => {
                            let id = fetchUserIdByEmail(key);
                            if (id === -1) return;

                            c = c.concat(
                                values.map((v, ii) => ({
                                    id: c.length + ii,
                                    user_id: id,
                                    start: new Date(v.start),
                                    end: new Date(v.end),
                                }))
                            );
                        });

                        setCalendars(c);
                    } else {
                        setCalendars([]);
                    }
                }

            } catch (error) {
                console.error(error);
                setCalendars([]);
            }
        };

        fetchCalendar();
    }, [lambda, fetchUserIdByEmail]);

    const updateUserTickets = useCallback(() => {
        setTickets(t => (
            permissions.some(x => x === Permission.SeeAllTickets)
                ? allTickets
                : allTickets.filter(x => clients.includes(x.client) && !x.internal)
        ));
    }, [clients, permissions, allTickets]);

    useEffect(() => updateUserTickets(), [updateUserTickets]);


    const newTicket = useCallback((ticket: Ticket) => {
        console.log("Submit new ticket", ticket);
        ticket.id = nextTicketId.current;
        nextTicketId.current += 1;
        setAllTickets(t => [...t, ticket]);
        updateUserTickets();
    }, [updateUserTickets, nextTicketId]);

    const updateTicket = useCallback((ticket: Ticket) => {
        console.log('updateTicket', ticket.id, ticket.status);
        const index = tickets.findIndex((p) => p.id === ticket.id);
        if (index === -1) return;

        const updatedDate = new Date();
        ticket.updated = updatedDate;

        setAllTickets(t => [...([...t].filter(x => x.id !== ticket.id)), ticket]);
        updateUserTickets();
    }, [tickets, updateUserTickets]);


    const fetchSprint = useCallback((id: number) => {
        return sprints.find(x => x.id === id) ?? {} as ISprint;
    }, [sprints]);

    const fetchSprintName = useCallback((id: number) => {
        return fetchSprint(id)?.name ?? "No Sprint";
    }, [fetchSprint]);

    const fetchItem = useCallback((id: number) => {
        return items.find(x => x.id === id) ?? {} as ContractItem;
    }, [items]);

    const fetchItemName = useCallback((id: number) => {
        const item = fetchItem(id);
        if (!item) return "No item selected";
        if ('feed_name' in item) return item.feed_name;
        return item.report_name;
    }, [fetchItem]);

    const nextBusinessDate = useCallback((date: number) => {
        const businessHours = [9, 10, 11, 12, 13, 14, 15, 16];
        const businessDays = [1, 2, 3, 4, 5];

        const d = new Date(date);
        if (d.getHours() < Math.min(...businessHours)) d.setHours(Math.min(...businessHours), 0, 0, 0);
        if (d.getHours() > Math.max(...businessHours)) {
            d.setDate(d.getDate() + 1);
            d.setHours(Math.min(...businessHours), 0, 0, 0);
        }

        while (!businessDays.includes(d.getDay())) {
            d.setDate(d.getDate() + 1);
        }
        return d;
    }, []);
    const remainingOnDate = useCallback((d: Date) => {
        const businessDays = [1, 2, 3, 4, 5];
        if (!businessDays.includes(d.getDay())) return 0;

        if (d.getHours() < 9) return 8 * 60 * 60 * 1000;
        if (d.getHours() > 16) return 0;
        return new Date(d).setHours(17, 0, 0, 0) - d.getTime();
    }, []);
    const findResponseTimeDuringBusinessHours = useCallback((ms: number) => {
        const businessDays = [1, 2, 3, 4, 5];
        const responseDate = nextBusinessDate(Date.now());
        while (ms > 0) {
            // If it fits in the current day, return
            const remaining = remainingOnDate(responseDate);
            if (ms < remaining) {
                return responseDate.getTime() + ms;
            }
            // Otherwise, subtract the remaining time and move to the next day
            ms -= remaining;
            responseDate.setDate(responseDate.getDate() + 1);
            responseDate.setHours(9, 0, 0, 0);

            // If it's a weekend, move to the next business day
            while (!businessDays.includes(responseDate.getDay())) {
                responseDate.setDate(responseDate.getDate() + 1);
            }
        }
        return responseDate.getTime();
    }, [nextBusinessDate, remainingOnDate]);

    const slaForTicketInState = useCallback((ticket: Ticket) => {
        const contractPriority = ContractPriorities.get(ticket.contract_priority);
        if (!contractPriority) return null;

        const slaRelevantStatuses = [
            TicketStatus.Submitted,
            TicketStatus.Scoped,
            TicketStatus.InfoRequested,
            TicketStatus.ReviewRequested,
            TicketStatus.FeedbackRequested,
            TicketStatus.Paused
        ];
        if (slaRelevantStatuses.includes(ticket.status)) {
            const diff = Date.now() - ticket.updated.getTime();
            const remaining = contractPriority.response_time_in_hours * 60 * 60 * 1000 - diff;
            return findResponseTimeDuringBusinessHours(remaining);
        }
        return null;
    }, [findResponseTimeDuringBusinessHours]);

    const value: IServiceDeskContext = {
        tickets,
        items,
        quotas,
        contract,
        sprints,
        currentSprint,
        calendars,
        newTicket,
        updateTicket,
        fetchSprint,
        fetchSprintName,
        fetchItem,
        fetchItemName,
        slaForTicketInState
    };

    return (
        <ServiceDeskContext.Provider value={value}>
            {loading && <div>Loading...</div>}
            {!loading && props.children}
        </ServiceDeskContext.Provider>
    )
};

export default ServiceDeskContextProvider;