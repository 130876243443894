import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select, { MultiValue, SingleValue } from "react-select";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";

import { UserContext } from "core/data/auth/UserContextProvider";
import Log from "core/helpers/Log";
import FormatDate from "core/helpers/datetime/FormatDate";
import FormatCounter from "core/helpers/FormatCounter";

import { ServiceDeskContext } from "../contexts/ServiceDeskContextProvider";

import { TicketType } from "../types/TicketType";
import { PossibleNextStatuses, TicketStatus } from "../types/TicketStatus";
import { Priority } from "../types/Priority";
import { ItemType } from "../types/ItemType";
import { EmailType, MetricType, TargetingType } from "../types/ReportTypes";
import { Ticket } from "../types/Ticket";
import { Permission } from "core/enums/Permissions";


import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "../plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "../plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "../plugins/AutoLinkPlugin";
import TreeViewPlugin from "../plugins/TreeViewPlugin";
import ToolbarPlugin from "../plugins/ToolbarPlugin";
import ExampleTheme from "../themes/ExampleTheme";

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $getSelection } from 'lexical';
import { EditorState, LexicalEditor } from "lexical";


import "../_service-desk.scss";
import CustomTable from "core/components/status-table/CustomTable";
import { CellProps } from "react-table";
import { UsersContext } from "core/data/auth/UsersContextProvider";
import ListTile from "core/components/tiles/ListTile";



// When the editor changes, you can get notified via the
// LexicalOnChangePlugin!
function onChange(editorState: EditorState, editor: LexicalEditor, tags: Set<string>) {
    editorState.read(() => {
        // Read the contents of the EditorState here.
        const root = $getRoot();
        const selection = $getSelection();

        console.log(root, selection);
    });
}// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function MyCustomAutoFocusPlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // Focus the editor when the effect fires!
        editor.focus();
    }, [editor]);

    return null;
}
// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error, editor: LexicalEditor) {
    console.error(error);
}



const ServiceDeskTicketPage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const { tickets, updateTicket, items, sprints, slaForTicketInState, fetchItemName, fetchSprintName } = useContext(ServiceDeskContext);
    const { user, clients, permissions } = useContext(UserContext);
    const { users, fetchUserName } = useContext(UsersContext);

    let ticket = useMemo(() => ({} as Ticket), []);

    const ticketid = !!id ? parseInt(id) : 0;

    const [draft, setDraft] = useState(tickets.find((p) => p.id === ticketid) ?? ticket);
    const [editOverride, setEditOverride] = useState(false);
    const [actionError, setActionError] = useState<string>("");

    const editableStatuses = useMemo(() => [
        TicketStatus.Draft
    ], []);

    const editable = useMemo(() => (
        (
            permissions.includes(Permission.EditTicket) &&
            editableStatuses.includes(draft.status)
        ) || editOverride
    ), [permissions, editableStatuses, draft.status, editOverride]);

    const assigneeEditable = useMemo(() => (
        permissions.includes(Permission.AllocateTickets) &&
        (
            draft.status === TicketStatus.Accepted ||
            draft.status === TicketStatus.Scheduled ||
            draft.status === TicketStatus.InDevelopment
        )
    ), [permissions, draft.status]);

    const scopeEditable = useMemo(() => (
        permissions.includes(Permission.ScopeTickets) &&
        (
            draft.status === TicketStatus.Submitted ||
            draft.status === TicketStatus.Accepted ||
            draft.status === TicketStatus.Scheduled
        )
    ), [permissions, draft.status]);

    const viewAdminFields = useMemo(() => (
        permissions.includes(Permission.AllocateTickets)
    ), [permissions]);

    const assigneeOptions = useMemo(() => {
        let opts = users.map((x: any) => ({ value: x.id, label: x.first_name }));
        opts.push({ value: -1, label: "Unassigned" });
        return opts;
    }, [users]);

    const GetAssignee = useMemo(() => {
        return (id: number) => ({ value: id, label: id === -1 ? "Unassigned" : fetchUserName(id) });
    }, [fetchUserName]);

    const GetPossibleStatuses = useCallback(() => {
        if (!draft) return [];

        return [draft.status].concat(PossibleNextStatuses[draft.status] || []);
    }, [draft]);

    const item = items.find(x => draft.item_ids?.includes(x.id) ?? false);

    let parentTicket: Ticket | undefined;
    let childTickets: Ticket[] = tickets.filter(x => x.parent_id === draft.id);
    let siblingTickets: Ticket[] = [];
    let linkedTickets: Ticket[] = [];

    if (draft.linked_tickets) {
        linkedTickets = tickets.filter(x =>
            draft.linked_tickets?.includes(x.id) ?? false
        );
    }
    if (draft.parent_id) {
        parentTicket = tickets.find(x => x.id === draft.parent_id);
        siblingTickets = tickets.filter(x => x.parent_id === draft.parent_id && x.id !== draft.id);
        linkedTickets = linkedTickets.concat(
            tickets.filter(x =>
                x.linked_tickets?.includes(draft.parent_id!) ?? false
            )
        );
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }: CellProps<Ticket>) => {
                    return (
                        <Link to={`/service-desk/${row.original.id}`}>
                            {row.original.name}
                        </Link>
                    )
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                filter: 'fuzzyText'
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Priority',
                accessor: 'priority',
            },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: ({ value }: { value: Date }) => FormatDate(value),
            },

            {
                Header: 'Updated',
                accessor: 'updated',
                Cell: ({ value }: { value: Date }) => FormatDate(value),
            },
            {
                Header: 'Type',
                accessor: 'type',
            }
        ], []
    )

    const sla = slaForTicketInState(draft);

    const ticketTypeOptions = Object.values(TicketType).map((x) => ({ value: x, label: x }));
    const priorityOptions = Object.values(Priority).map((x) => ({ value: x, label: x }));
    const statusOptions = GetPossibleStatuses().map((x) => ({ value: x, label: x }));
    const itemOptions = items.map((x) => ({ value: x.id, label: fetchItemName(x.id) }));
    const sprintOptions = sprints.map((x) => ({ value: x.id, label: fetchSprintName(x.id) }));

    const emailTypeOptions = Object.values(EmailType).map((x) => ({ value: x, label: x }));
    const metricTypeOptions = Object.values(MetricType).map((x) => ({ value: x, label: x }));
    const targetingTypeOptions = Object.values(TargetingType).map((x) => ({ value: x, label: x }));

    const updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!draft) return;

        setDraft({
            ...draft,
            name: e.target.value,
        });
    };

    const updateDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!draft) return;

        setDraft({
            ...draft,
            description: e.target.value,
        });
    };

    const updateType = (e: SingleValue<{ value: TicketType; label: TicketType }>) => {
        if (!draft) return;

        let newType = e?.value as TicketType;
        if (!newType) return;

        setDraft({
            ...draft,
            type: newType,
        });
    };

    const updatePriority = (e: SingleValue<{ value: Priority; label: Priority }>) => {
        if (!draft) return;

        let newPriority = e?.value as Priority;
        if (!newPriority) return;

        setDraft({
            ...draft,
            priority: newPriority,
        });
    };

    const updateStatus = (e: SingleValue<{ value: TicketStatus; label: TicketStatus }>) => {
        if (!draft) return;

        let newStatus = e?.value as TicketStatus;
        if (!newStatus) return;

        setDraft({
            ...draft,
            status: newStatus,
        });
    };

    const updateSprint = (e: SingleValue<{ value: number; label: string }>) => {
        if (!draft) return;

        let newSprint = e?.value;
        if (!newSprint) return;

        setDraft({
            ...draft,
            sprint: newSprint,
        });
    };

    const updateScope = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!draft) return;

        let newScope = parseInt(e.target.value);
        if (!newScope) return;

        console.log(draft.scope, newScope);
        setDraft({
            ...draft,
            scope: newScope,
        });
    };

    const updateItem = (e: SingleValue<{ value: number; label: string }>) => {
        if (!draft) return;

        let newItem = e?.value;
        if (!newItem) return;

        setDraft({
            ...draft,
            item_ids: [newItem],
        });
    };

    const updateEmailType = (e: SingleValue<{ value: EmailType; label: EmailType }>) => {
        if (!draft) return;

        let newEmailType = e?.value as EmailType;
        if (!newEmailType) return;

        setDraft({
            ...draft,
            email_type: newEmailType,
        });
    };

    const updateMetricType = (e: SingleValue<{ value: MetricType; label: MetricType }>) => {
        if (!draft) return;

        let newMetricType = e?.value as MetricType;
        if (!newMetricType) return;

        setDraft({
            ...draft,
            metric_type: newMetricType,
        });
    };

    const updateTargetingTypes = (e: MultiValue<{ value: TargetingType; label: TargetingType }>) => {
        if (!draft) return;

        let new_targeting_types = e.map((x) => x.value as TargetingType);
        if (!new_targeting_types) return;

        setDraft({
            ...draft,
            targeting_types: new_targeting_types,
        });
    };

    const updateStartDate = (date: Date) => {
        if (!draft) return;

        setDraft({
            ...draft,
            start_date: date,
        });
    };

    const updateEndDate = (date: Date) => {
        if (!draft) return;

        setDraft({
            ...draft,
            end_date: date,
        });
    };

    const updateDeadline = (date: Date) => {
        if (!draft) return;

        if (date < new Date()) {
            alert("Deadline cannot be in the past.");
            return;
        }

        setDraft({
            ...draft,
            deadline: date,
        });
    };

    const updateAssignee = (id: number) => {
        if (!draft) return;

        setDraft({
            ...draft,
            assignee_id: id,
        });
    };

    const actionHandler = (action: TicketAction) => {
        if (!draft) return;

        action(draft, permissions, updateTicket)
            .then(({ success }: ActionResult) => {
                if (!success) {
                    console.error("Failed to perform action");
                } else {
                    setActionError("");
                    setEditOverride(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setActionError(error);
            });
    };

    interface TicketAction {
        (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void): Promise<ActionResult>;
    }

    const onSave = () => actionHandler(SaveAction);
    const onSubmit = () => actionHandler(SubmitAction);
    const onDelete = () => actionHandler(DeleteAction);
    const onScope = () => actionHandler(ScopeAction);
    const onAccept = () => actionHandler(AcceptAction);
    const onReject = () => actionHandler(RejectAction);
    const onReOpen = () => actionHandler(ReOpenAction);
    const onCancel = () => actionHandler(CancelAction);
    const onSchedule = () => actionHandler(ScheduleAction);
    const onAllocate = () => actionHandler(AllocateAction);
    const onStart = () => actionHandler(StartAction);
    const onUnschedule = () => actionHandler(UnscheduleAction);
    const onPause = () => actionHandler(PauseAction);
    const onRequestInfo = () => actionHandler(RequestInfoAction);
    const onReply = () => actionHandler(ReplyAction);
    const onRequestReview = () => actionHandler(RequestReviewAction);
    const onRequestFeedback = () => actionHandler(RequestFeedbackAction);
    const onResume = () => actionHandler(ResumeAction);
    const onReviewChanges = () => actionHandler(ReviewChangesAction);
    const onReviewOk = () => actionHandler(ReviewOkAction);
    const onFeedbackChanges = () => actionHandler(FeedbackChangesAction);
    const onComplete = () => actionHandler(CompleteAction);


    const onDiscard = () => {
        if (editOverride) {
            setEditOverride(false);
            return;
        } else {
            navigate(-1);
        }
    };

    const onEdit = () => {
        setEditOverride(true);
    };


    useEffect(() => {
        if (!clients.includes(draft.client) && user.client !== 0) {
            navigate(-1);
            return;
        }
    }, [draft, user, clients, navigate]);

    const editorConfig = {
        namespace: 'MyEditor',
        // The editor theme
        theme: ExampleTheme,
        // Handling of errors during update
        onError,
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };


    function Placeholder() {
        return <div className="editor-placeholder">Enter some rich text...</div>;
    }

    return (
        <>
            {!draft && <h1>Ticket not found</h1>}
            {draft && (
                <>
                    <div className="service-desk__ticket">
                        <Button onClick={() => navigate(-1)}>Back</Button>

                        <ListTile>
                            <div>
                                <label htmlFor="title">Title</label>
                                <input id="title" value={draft.name} onChange={updateName} readOnly={!editable} />
                            </div>

                            <div>
                                <label htmlFor="type">Type</label>
                                {!editable && <input id="type" value={draft.type} readOnly={true} />}
                                {editable && (
                                    <Select
                                        options={ticketTypeOptions}
                                        defaultValue={{ value: draft.type, label: draft.type }}
                                        onChange={updateType}
                                    />
                                )}
                            </div>

                            <>
                                {sla && sla > 24 * 60 * 60 * 1000 && (
                                    <Alert severity="info">SLA: Response needed by {FormatDate(sla)} ({FormatCounter(sla - Date.now(), 'full')})</Alert>
                                )}
                                {sla && sla > 0 && sla < 24 * 60 * 60 * 1000 && (
                                    <Alert severity="warning">SLA: Response needed by {FormatDate(sla)} ({FormatCounter(sla - Date.now(), 'full')})</Alert>
                                )}
                                {sla && sla < 0 && (
                                    <Alert severity="error">SLA: Response time missed - {FormatDate(sla)} ({FormatCounter(sla - Date.now(), 'full')})</Alert>
                                )}
                            </>

                            <div>
                                <p>Created: {draft.created?.toLocaleString()}</p>
                            </div>

                            <div>
                                <p>Updated: {draft.updated?.toLocaleString()}</p>
                            </div>
                        </ListTile>

                        <div className="tile list">
                            <div>
                                <label htmlFor="description">Description</label>
                                <textarea
                                    id="description"
                                    value={draft.description}
                                    onChange={updateDescription}
                                    readOnly={!editable}
                                />
                            </div>
                            {/* <LexicalComposer initialConfig={editorConfig}>
                                <div className="editor-container">
                                    <ToolbarPlugin />
                                    <div className="editor-inner">
                                        <RichTextPlugin
                                            contentEditable={<ContentEditable className="editor-input" />}
                                            placeholder={<Placeholder />}
                                            ErrorBoundary={LexicalErrorBoundary}
                                        />
                                        <HistoryPlugin />
                                        <TreeViewPlugin />
                                        <AutoFocusPlugin />
                                        <CodeHighlightPlugin />
                                        <ListPlugin />
                                        <LinkPlugin />
                                        <AutoLinkPlugin />
                                        <ListMaxIndentLevelPlugin maxDepth={7} />
                                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                                    </div>
                                </div>
                            </LexicalComposer> */}

                            {item && (
                                <div>
                                    <label htmlFor="item">Item</label>
                                    {!editable && <input id="item" value={fetchItemName(item!.id)} readOnly={true} />}
                                    {editable && (
                                        <Select
                                            options={itemOptions}
                                            defaultValue={{
                                                value: item!.id,
                                                label: fetchItemName(item!.id),
                                            }}
                                            onChange={updateItem}
                                        />
                                    )}
                                </div>
                            )}

                            <div>
                                <label htmlFor="deadline">Deadline</label>
                                <DatePicker selected={draft.deadline} onChange={updateDeadline} readOnly={!editable} />
                            </div>

                            {draft.type === TicketType.NewFeature && item && item.item_type === ItemType.Report && (
                                <>
                                    <div>
                                        <label htmlFor="emailType">Email Type</label>
                                        {!editable && <input id="emailType" value={draft.email_type} readOnly={true} />}
                                        {editable && (
                                            <Select
                                                options={emailTypeOptions}
                                                defaultValue={{
                                                    value: draft.email_type ?? EmailType.None,
                                                    label: draft.email_type ?? EmailType.None,
                                                }}
                                                onChange={updateEmailType}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        <label htmlFor="metricType">Metric Type</label>
                                        {!editable && <input id="metricType" value={draft.metric_type} readOnly={true} />}
                                        {editable && (
                                            <Select
                                                options={metricTypeOptions}
                                                defaultValue={{
                                                    value: draft.metric_type ?? MetricType.None,
                                                    label: draft.metric_type ?? MetricType.None,
                                                }}
                                                onChange={updateMetricType}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        <label htmlFor="targetingTypes">Targeting Types</label>
                                        {!editable && (
                                            <input
                                                id="targetingTypes"
                                                value={draft.targeting_types?.join(", ")}
                                                readOnly={true}
                                            />
                                        )}
                                        {editable && (
                                            <Select
                                                options={targetingTypeOptions}
                                                defaultValue={draft.targeting_types?.map((x) => ({ value: x, label: x }))}
                                                onChange={updateTargetingTypes}
                                                isMulti={true}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        <label htmlFor="startDate">Start Date</label>
                                        <DatePicker selected={draft.start_date} onChange={updateStartDate} readOnly={!editable} />
                                    </div>

                                    <div>
                                        <label htmlFor="endDate">End Date</label>
                                        <DatePicker selected={draft.end_date} onChange={updateEndDate} readOnly={!editable} />
                                    </div>
                                </>
                            )}
                        </div>

                        <div className={`tile-container grow`}>
                            <RenderActions ticket={draft} readonly={!editable} actionError={actionError} actions={{
                                "Save": onSave,
                                "Discard": onDiscard,
                                "Submit": onSubmit,
                                "Delete": onDelete,
                                "Scope": onScope,
                                "Accept": onAccept,
                                "Reject": onReject,
                                "Edit": onEdit,
                                "ReOpen": onReOpen,
                                "Cancel": onCancel,
                                "Schedule": onSchedule,
                                "Allocate": onAllocate,
                                "Start": onStart,
                                "Unschedule": onUnschedule,
                                "Pause": onPause,
                                "RequestInfo": onRequestInfo,
                                "Reply": onReply,
                                "RequestReview": onRequestReview,
                                "RequestFeedback": onRequestFeedback,
                                "Resume": onResume,
                                "ReviewChanges": onReviewChanges,
                                "ReviewOk": onReviewOk,
                                "FeedbackChanges": onFeedbackChanges,
                                "Complete": onComplete,
                            }} />

                            <div className={`tile list`}>
                                <label htmlFor="status">Status</label>
                                {!editable && <input id="status" value={draft.status} readOnly={true} />}
                                {editable && (
                                    <Select
                                        options={statusOptions}
                                        defaultValue={{ value: draft.status, label: draft.status }}
                                        onChange={updateStatus}
                                    />
                                )}
                            </div>

                            <div className={`tile list`}>
                                <label htmlFor="priority">Priority</label>
                                {!editable && <input id="priority" value={draft.priority} readOnly={true} />}
                                {editable && (
                                    <Select
                                        options={priorityOptions}
                                        defaultValue={{ value: draft.priority, label: draft.priority }}
                                        onChange={updatePriority}
                                    />
                                )}
                            </div>

                            {viewAdminFields && (
                                <>
                                    <div className={`tile list`}>
                                        <label htmlFor="assignee">Assignee</label>
                                        {!assigneeEditable && (
                                            <input id="assignee" value={GetAssignee(draft.assignee_id ?? -1).label} readOnly={true} />
                                        )}
                                        {assigneeEditable && (
                                            <Select
                                                options={assigneeOptions}
                                                defaultValue={GetAssignee(draft.assignee_id ?? -1)}
                                                onChange={(x) => updateAssignee(x?.value ?? -1)}
                                            />
                                        )}
                                    </div>
                                </>
                            )}

                            {((draft.scope && draft.scope > 0) || draft.status === TicketStatus.Submitted) && (
                                <div className={`tile list`}>
                                    <label htmlFor="scope">Scope</label>
                                    <input
                                        type="number"
                                        id="scope"
                                        value={draft.scope ?? 0}
                                        min={0}
                                        max={1000}
                                        onChange={updateScope}
                                        readOnly={!scopeEditable}
                                    />
                                </div>
                            )}

                            {draft.sprint && (
                                <>
                                    <div className={`tile list`}>
                                        <label htmlFor="sprint">Sprint</label>
                                        {!editable && <input id="sprint" value={fetchSprintName(draft.sprint)} readOnly={true} />}
                                        {editable && (
                                            <Select
                                                options={sprintOptions}
                                                defaultValue={{ value: draft.sprint, label: fetchSprintName(draft.sprint) }}
                                                onChange={updateSprint}
                                            />
                                        )}
                                    </div>
                                </>
                            )}

                            {parentTicket && (
                                <ListTile>
                                    <h1 className={`list__title`}>
                                        Parent Ticket
                                    </h1>

                                    <Box className={'tabs-container'}>
                                        <CustomTable columns={columns} data={[parentTicket]} />
                                    </Box>
                                </ListTile>
                            )}
                            {siblingTickets && siblingTickets.length > 0 && (
                                <ListTile>
                                    <h1 className={`list__title`}>
                                        Sibling Tickets
                                    </h1>

                                    <Box className={'tabs-container'}>
                                        <CustomTable columns={columns} data={siblingTickets} />
                                    </Box>
                                </ListTile>
                            )}
                            {childTickets && childTickets.length > 0 && (
                                <ListTile>
                                    <h1 className={`list__title`}>
                                        Child Tickets
                                    </h1>

                                    <Box className={'tabs-container'}>
                                        <CustomTable columns={columns} data={childTickets} />
                                    </Box>
                                </ListTile>
                            )}
                            {linkedTickets && linkedTickets.length > 0 && (
                                <ListTile>
                                    <h1 className={`list__title`}>
                                        Linked Tickets
                                    </h1>

                                    <Box className={'tabs-container'}>
                                        <CustomTable columns={columns} data={linkedTickets} />
                                    </Box>
                                </ListTile>
                            )}
                        </div>
                    </div>
                </>
            )
            }
        </>
    );
};
export default ServiceDeskTicketPage;

interface IRenderActionsProps {
    ticket: Ticket;
    readonly: boolean;
    actions: {
        [key: string]: () => void;
    };
    actionError?: string;
}
const RenderActions = ({ ticket, readonly, actions, actionError }: IRenderActionsProps) => {
    return (
        <>
            {ticket.status === TicketStatus.Draft && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.CreateTicket} buttonText={"Save"} onClick={actions["Save"]} />
                    <ActionButton permission={Permission.Default} buttonText={"Discard"} onClick={actions["Discard"]} />
                    <ActionButton permission={Permission.CreateTicket} buttonText={"Submit"} onClick={actions["Submit"]} />
                    <ActionButton permission={Permission.DeleteTicket} buttonText={"Delete"} onClick={actions["Delete"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.Submitted && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.CreateTicket} buttonText={"Save"} onClick={actions["Save"]} />
                    <ActionButton permission={Permission.Default} buttonText={"Discard"} onClick={actions["Discard"]} />
                    <ActionButton permission={Permission.ScopeTickets} buttonText={"Mark as Scoped"} onClick={actions["Scope"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.Scoped && readonly && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.AcceptTickets} buttonText={"Accept"} onClick={actions["Accept"]} />
                    <ActionButton permission={Permission.RejectTickets} buttonText={"Reject"} onClick={actions["Reject"]} />
                    <ActionButton permission={Permission.EditTicket} buttonText={"Edit"} onClick={actions["Edit"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.Scoped && !readonly && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.CreateTicket} buttonText={"Save"} onClick={actions["Save"]} />
                    <ActionButton permission={Permission.Default} buttonText={"Discard"} onClick={actions["Discard"]} />
                    <ActionButton permission={Permission.CreateTicket} buttonText={"Submit"} onClick={actions["Submit"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.Cancelled && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.CreateTicket} buttonText={"ReOpen"} onClick={actions["ReOpen"]} />
                    <ActionButton permission={Permission.DeleteTicket} buttonText={"Delete"} onClick={actions["Delete"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.Accepted && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.RejectTickets} buttonText={"Cancel"} onClick={actions["Cancel"]} />
                    {readonly && <>
                        <ActionButton permission={Permission.ScheduleTickets} buttonText={"Schedule"} onClick={actions["Schedule"]} />
                        <ActionButton permission={Permission.AllocateTickets} buttonText={"Allocate"} onClick={actions["Allocate"]} />
                        <ActionButton permission={Permission.EditTicket} buttonText={"Edit"} onClick={actions["Edit"]} />
                    </>}
                    {!readonly && <>
                        <ActionButton permission={Permission.CreateTicket} buttonText={"Save"} onClick={actions["Save"]} />
                        <ActionButton permission={Permission.Default} buttonText={"Discard"} onClick={actions["Discard"]} />
                    </>}
                </ListTile>
            )}
            {ticket.status === TicketStatus.Scheduled && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.RejectTickets} buttonText={"Cancel"} onClick={actions["Cancel"]} />
                    <ActionButton permission={Permission.AllocateTickets} buttonText={"Allocate"} onClick={actions["Allocate"]} />
                    <ActionButton permission={Permission.StartWork} buttonText={"Start Work"} onClick={actions["Start"]} />
                    <ActionButton permission={Permission.ScheduleTickets} buttonText={"Remove from Sprint"} onClick={actions["Unschedule"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.InDevelopment && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.RejectTickets} buttonText={"Cancel"} onClick={actions["Cancel"]} />
                    <ActionButton permission={Permission.PauseWork} buttonText={"Pause Work"} onClick={actions["Pause"]} />
                    <ActionButton permission={Permission.AllocateTickets} buttonText={"Allocate"} onClick={actions["Allocate"]} />
                    <ActionButton permission={Permission.StartWork} buttonText={"Request Review"} onClick={actions["RequestReview"]} />
                    <ActionButton permission={Permission.StartWork} buttonText={"Request Feedback"} onClick={actions["RequestFeedback"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.Paused && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.RejectTickets} buttonText={"Cancel"} onClick={actions["Cancel"]} />
                    <ActionButton permission={Permission.PauseWork} buttonText={"Resume Work"} onClick={actions["Resume"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.InfoRequested && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.Default} buttonText={"Discard"} onClick={actions["Discard"]} />
                    <ActionButton permission={Permission.FeedbackOnWork} buttonText={"Submit Reply"} onClick={actions["Reply"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.ReviewRequested && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.ReviewWork} buttonText={"Request Changes"} onClick={actions["ReviewChanges"]} />
                    <ActionButton permission={Permission.ReviewWork} buttonText={"Mark as Complete"} onClick={actions["ReviewOk"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.FeedbackRequested && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.FeedbackOnWork} buttonText={"Request Changes"} onClick={actions["FeedbackChanges"]} />
                    <ActionButton permission={Permission.FeedbackOnWork} buttonText={"Mark as Complete"} onClick={actions["Complete"]} />
                </ListTile>
            )}
            {ticket.status === TicketStatus.Deleted && (
                <ListTile>
                    {actionError && <Alert severity="error">{actionError}</Alert>}
                    <ActionButton permission={Permission.CreateTicket} buttonText={"ReOpen"} onClick={actions["ReOpen"]} />
                </ListTile>
            )}
        </>
    );
}

interface ActionResult {
    success: boolean;
    error?: string;
}

interface IActionButtonProps {
    permission: Permission;
    buttonText: string;
    onClick: () => void;
}
const ActionButton = ({ permission, buttonText, onClick }: IActionButtonProps) => {
    const { permissions } = useContext(UserContext);

    Log("PermissionsCheck", permission, permissions);
    const valid = permissions.includes(permission);

    return (
        <>
            {valid && <Button onClick={onClick}>{buttonText}</Button>}
            {!valid && <Button disabled={true}>{buttonText}</Button>}
        </>
    );
};


// TODO: As I'm passing `draft` into these actions, they'll not just update the ticket but save any other changes too
const SaveAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.EditTicket;

        Log("saveAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow saving tickets");

        // TODO: This might not always be the case
        ticket.status = TicketStatus.Draft;

        // Save
        updateTicket(ticket);
        resolve({ success: true });
    });
};

const SubmitAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.CreateTicket;

        Log("submitAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow submitting tickets");

        // Submit
        ticket.status = TicketStatus.Submitted;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const DeleteAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.DeleteTicket;

        Log("deleteAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow deleting tickets");

        // Delete
        ticket.status = TicketStatus.Deleted;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const ScopeAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.ScopeTickets;

        Log("scopeAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow scoping tickets");

        if (!ticket.scope || ticket.scope <= 0) return reject("Scope must be greater than 0");

        // Scope
        ticket.status = TicketStatus.Scoped;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const AcceptAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.AcceptTickets;

        Log("acceptAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow accepting tickets");

        // Accept
        ticket.status = TicketStatus.Accepted;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const RejectAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.RejectTickets;

        Log("rejectAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow rejecting tickets");

        // Reject
        ticket.status = TicketStatus.Cancelled;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const ReOpenAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.CreateTicket;

        Log("editAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow reopening tickets");


        // ReOpen
        ticket.status = TicketStatus.Draft;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const CancelAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.RejectTickets;

        Log("cancelAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow cancelling tickets");

        // Cancel
        ticket.status = TicketStatus.Cancelled;
        updateTicket(ticket);
        resolve({ success: true, message: "Ticket cancelled" });
    });
}

const ScheduleAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.ScheduleTickets;

        Log("scheduleAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow scheduling tickets");

        // Schedule
        ticket.status = TicketStatus.Scheduled;
        updateTicket(ticket);
        resolve({ success: true, message: "Ticket scheduled" });
    });
}

const AllocateAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.AllocateTickets;

        Log("allocateAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow allocating tickets");

        // Allocate
        updateTicket(ticket);
        resolve({ success: true, message: "Ticket allocated" });
    });
}

const StartAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.StartWork;

        Log("startAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow starting tickets");

        // Start
        ticket.status = TicketStatus.InDevelopment;
        updateTicket(ticket);
        resolve({ success: true, message: "Ticket started" });
    });
}

const UnscheduleAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.ScheduleTickets;

        Log("unscheduleAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow unscheduling tickets");

        // Unschedule
        ticket.status = TicketStatus.Accepted;
        ticket.sprint = -1;
        updateTicket(ticket);
        resolve({ success: true, message: "Ticket unscheduled" });
    });
}

const PauseAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.PauseWork;

        Log("pauseAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow pausing tickets");

        // Pause
        ticket.status = TicketStatus.Paused;
        updateTicket(ticket);
        resolve({ success: true, message: "Ticket paused" });
    });
}

const RequestInfoAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.StartWork;

        Log("requestInfoAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow requesting info");

        // Request Info
        ticket.status = TicketStatus.InfoRequested;
        updateTicket(ticket);
        resolve({ success: true, message: "Info requested" });
    });
}

const ReplyAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.FeedbackOnWork;

        Log("replyAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow replying");

        // Reply
        ticket.status = TicketStatus.InDevelopment;
        updateTicket(ticket);
        resolve({ success: true, message: "Replied" });
    });
}

const RequestReviewAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.StartWork;

        Log("requestReviewAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow requesting review");

        // Request Review
        ticket.status = TicketStatus.ReviewRequested;
        updateTicket(ticket);
        resolve({ success: true, message: "Review requested" });
    });
}

const RequestFeedbackAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.StartWork;

        Log("requestFeedbackAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow requesting feedback");

        // Request Feedback
        ticket.status = TicketStatus.FeedbackRequested;
        updateTicket(ticket);
        resolve({ success: true, message: "Feedback requested" });
    });
}

const ResumeAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.PauseWork;

        Log("resumeAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow resuming tickets");

        // Resume
        ticket.status = TicketStatus.InDevelopment;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const ReviewChangesAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.ReviewWork;

        Log("reviewChangesAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow reviewing changes");

        // Review Changes
        ticket.status = TicketStatus.InDevelopment;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const ReviewOkAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.ReviewWork;

        Log("reviewOkAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow reviewing changes");

        // Review Ok
        ticket.status = TicketStatus.InDevelopment;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const FeedbackChangesAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<ActionResult>((resolve, reject) => {
        const permission = Permission.FeedbackOnWork;

        Log("feedbackChangesAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow reviewing changes");

        // Feedback Changes
        ticket.status = TicketStatus.InDevelopment;
        updateTicket(ticket);
        resolve({ success: true });
    });
}

const CompleteAction = (ticket: Ticket, permissions: Permission[], updateTicket: (ticket: Ticket) => void) => {
    return new Promise<{ success: boolean, message: string }>((resolve, reject) => {
        const permission = Permission.CompleteWork;

        Log("completeAction", permission, permissions);

        if (!permissions.includes(permission)) return reject("No user groups allow completing tickets");

        // Complete
        ticket.status = TicketStatus.Complete;
        updateTicket(ticket);
        resolve({ success: true, message: "Ticket completed" });
    });
}