import React from 'react';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { IssueEnum } from "../components/tabs/enums/IssueEnum";

const GetStatusIcon = (tabStatus: IssueEnum) => tabStatus === IssueEnum.ERROR
    ? <ReportProblemIcon className={'issue'} />
    : <CheckCircleOutlineIcon className={`checkmark issue-${tabStatus}`} />;

export default GetStatusIcon;