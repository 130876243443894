import React, { useContext } from "react";

import { IssueEnum } from "core/components/tabs/enums/IssueEnum";
import HalfWidthTileContainer from "core/components/tiles/HalfWidthTileContainer";
import WideTile from "core/components/tiles/WideTile";
import SimpleTable from "core/components/status-table/SimpleTable";
import SimpleListItem from "core/components/status-table/SimpleListItem";

import FormatDay from "core/helpers/datetime/FormatDay";
import Debug from "core/helpers/Debug";
import ParseDBDate from "core/helpers/datetime/ParseDBDate";

import { CampaignsHistoryContext, ICampaignsHistoryContext } from "../contexts/CampaignsHistoryContextProvider";

import { ICampaignHistory } from "../types/ICampaignHistory";



interface ICampaignHistoryTableProps {
    client: string;
    campaign: string;
}
const CampaignHistoryTable = ({ client, campaign }: ICampaignHistoryTableProps) => {
    const { items: campaignsHistory, error: campaignsHistoryError } = useContext(CampaignsHistoryContext);

    const campaignHistory = campaignsHistory
        ?.filter(x => x.client_name === client && x.campaign_name === campaign && x.campaign_date !== null)
        ?.map(x => ({
            ...x,
            name: x.campaign_name,
            last_date: ParseDBDate(x.campaign_date),
            next_date: 0,
            issue: IssueEnum.OK
        }))
        ?.sort((a, b) => b.last_date - a.last_date);
    Debug('History', campaignHistory);

    return (
        <HalfWidthTileContainer grow={true}>
            {campaignHistory ? (
                <SimpleTable<ICampaignHistory>
                    title={'History'}
                    items={campaignHistory.slice(0, 7)}
                    error={campaignsHistoryError}
                    RenderRow={(item) => <SimpleListItem key={item.name} title={FormatDay(item.last_date)} text={item.campaign_value.toString()} />}
                />
            ) : (
                <WideTile>
                    <h3>History</h3>
                    <p>No history found</p>
                </WideTile>
            )}
        </HalfWidthTileContainer>
    )
}

export default CampaignHistoryTable;