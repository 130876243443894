import React, { useCallback, useMemo } from "react";

import Button from "@mui/material/Button";

import GetStartOfWeek from "core/helpers/datetime/GetStartOfWeek";
import GetStartOfDay from "core/helpers/datetime/GetStartOfDay";

interface ToolbarProps {
    onNavigate: (action: string) => void;
    days: number;
    setDays: (days: number) => void;
    setNav: (action: string) => void;
    currentDate: Date;
}
const Toolbar = ({ onNavigate, days, setDays, setNav, currentDate }: ToolbarProps) => {
    // Use 'Today' and 'This Week' buttons to set the calendar to the current date
    // As well as toggling between the number of days shown
    let nowDate = GetStartOfDay().getTime();
    if (days === 7) {
        nowDate = GetStartOfWeek().getTime();
    }
    let minDate = nowDate - (days - 1) * 24 * 60 * 60 * 1000;
    let maxDate = nowDate + days * 24 * 60 * 60 * 1000;

    const showingToday = useMemo(() =>
        currentDate.getTime() >= minDate && currentDate.getTime() < maxDate,
        [currentDate, minDate, maxDate]
    );

    const onToday = useCallback(() => {
        setDays(1);
        onNavigate('TODAY');
    }, [onNavigate]);

    const onThisWeek = useCallback(() => {
        setDays(7);
        onNavigate('TODAY');
    }, [onNavigate]);

    const onPrev = useCallback(() => {
        setNav("PREV");
        onNavigate('PREV');
        setNav("");
    }, [onNavigate]);

    const onNext = useCallback(() => {
        setNav("NEXT");
        onNavigate('NEXT');
        setNav("");
    }, [onNavigate]);

    return (
        <div className="rbc-toolbar">
            <span className="rbc-btn-group">
                <Button
                    type="button"
                    disabled={days === 1 && showingToday}
                    onClick={onToday}
                >
                    {'Today'}
                </Button>
                <Button
                    type="button"
                    disabled={days === 7 && showingToday}
                    onClick={onThisWeek}
                >
                    {'This Week'}
                </Button>
            </span>
            <span className="rbc-btn-group">
                <Button
                    type="button"
                    onClick={onPrev}
                >
                    {'Back'}
                </Button>
                <Button
                    type="button"
                    onClick={onNext}
                >
                    {'Next'}
                </Button>
            </span>
        </div>
    )
}
export default Toolbar;

