import React, { createContext, useContext } from "react";

import DataContextProvider, { IDataContext } from "core/data/contexts/DataContextProvider";
import { UserContext } from "core/data/auth/UserContextProvider";

import { ICampaignHistory } from "../types/ICampaignHistory";

import { FETCH_METRICS_HISTORY_DB } from "config";


export const CampaignsHistoryContext = createContext<ICampaignsHistoryContext>(null!);
export interface ICampaignsHistoryContext extends IDataContext<ICampaignHistory> { }


interface ICampaignsHistoryContextProvider {
    children: React.ReactNode;
}
const CampaignsHistoryContextProvider = ({ children }: ICampaignsHistoryContextProvider) => {
    const { clients } = useContext(UserContext);

    let params = { 
        clients: clients
    };

    return (
        <DataContextProvider<ICampaignHistory>
            context={CampaignsHistoryContext}
            endpoint={FETCH_METRICS_HISTORY_DB}
            params={params}
        >
            {children}
        </DataContextProvider>
    );
};

export default CampaignsHistoryContextProvider;