import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import Button from "@mui/material/Button";
import { dump } from 'js-yaml';

import ListTile from "core/components/tiles/ListTile";
import { AssumeRolePolicyDocument, CFYaml, Policy } from "../graphs/CFYaml";

import "./_pipelines.scss";

const graph_data = require("./graphData.json");


export const PipelinesPage = () => {
    return (
        <section className="data">
        <ListTile>
            <ModifiableGraph />
            <DependenciesChart />
        </ListTile>
    </section>
    );
};

export default PipelinesPage;

export const ModifiableGraph = () => {
    const menu = useRef<HTMLDivElement>(null);
    const echartsRef = useRef<any>(null);

    const dragIndex = useRef<number>(-1);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [yaml, setYaml] = useState<string>("");

    const nodes = useRef([
        {name: 'ExtractZipJob', layer: 1, src: "DK_Glue_Extract_Zip.py"},
        {name: 'StoreSMSRecordJob', layer: 2, src: "DK_Glue_SMSRecord_Store.py"},
        {name: 'AggregateSMSRecordJob', layer: 3, src: "DK_Glue_SMSRecord_Aggregate.py"},
        {name: 'ReportSMSRecordJob', layer: 4, src: "DK_Glue_SMSRecord_Report.py"},
        {name: 'StoreCallRecordJob', layer: 2, src: "DK_Glue_CallRecord_Store.py"},
        {name: 'AggregateCallRecordInboundJob', layer: 3, src: "DK_Glue_CallRecord_Inbound_Aggregate.py"},
        {name: 'AggregateCallRecordOutboundJob', layer: 3, src: "DK_Glue_CallRecord_Outbound_Aggregate.py"},
        {name: 'ReportCallsInboundRecordJob', layer: 4, src: "DK_Glue_CallRecord_Inbound_Report.py"},
        {name: 'ReportCallsOutboundRecordJob', layer: 4, src: "DK_Glue_CallRecord_Outbound_Report.py"},
        {name: 'StorePadsRecordJob', layer: 2, src: "DK_Glue_PadsRecord_Store.py"},
        {name: 'AggregatePadsRecordJob', layer: 3, src: "DK_Glue_PadsRecord_Aggregate.py"},
        {name: 'ReportPadsSentRecordJob', layer: 4, src: "DK_Glue_PadsRecord_Sent_Report.py"},
        {name: 'ReportPadsReceivedRecordJob', layer: 4, src: "DK_Glue_PadsRecord_Received_Report.py"},
    ]);
    const edges = useRef([
        {source: 'ExtractZipJob', target: 'StoreSMSRecordJob'},
        {source: 'ExtractZipJob', target: 'StoreCallRecordJob'},
        {source: 'ExtractZipJob', target: 'StorePadsRecordJob'},
        {source: 'StoreSMSRecordJob', target: 'AggregateSMSRecordJob'},
        {source: 'AggregateSMSRecordJob', target: 'ReportSMSRecordJob'},
        {source: 'StoreCallRecordJob', target: 'AggregateCallRecordInboundJob'},
        {source: 'StoreCallRecordJob', target: 'AggregateCallRecordOutboundJob'},
        {source: 'AggregateCallRecordInboundJob', target: 'ReportCallsInboundRecordJob'},
        {source: 'AggregateCallRecordOutboundJob', target: 'ReportCallsOutboundRecordJob'},
        {source: 'StorePadsRecordJob', target: 'AggregatePadsRecordJob'},
        {source: 'AggregatePadsRecordJob', target: 'ReportPadsSentRecordJob'},
        {source: 'AggregatePadsRecordJob', target: 'ReportPadsReceivedRecordJob'},
    ]);

    const layers = useMemo(() => {
        return Array.from(new Set(nodes.current.map((x: any) => x.layer)));
    }, [nodes]);

    const calculatePositions = useCallback(() => {
        // Check there are no missing layers
        // const layers = Array.from(new Set(nodes.current.map((x: any) => x.layer)));

        const horizontalSpacingBasedOnLayerCount = 250 / layers.length;

        // Calculate vertical positions for nodes
        const nodesPerLayer = nodes.current.reduce((acc: any, x: any) => {
            if (!acc[x.layer]) {
                acc[x.layer] = [];
            }
            acc[x.layer].push(x);
            return acc;
        }, {} as {[key: number]: any[]});
        const verticalSpacingPerLayerBasedOnNodeCount = Object.keys(nodesPerLayer).reduce((acc: any, x: any) => {
            acc[x] = 100 / nodesPerLayer[x].length;
            return acc;
        }, {} as {[key: number]: number});

        const perLayerIndex = {} as {[key: number]: number};
        const positions = nodes.current.map((x: any) => {
            if (!perLayerIndex[x.layer]) {
                perLayerIndex[x.layer] = 0;
            }
            return {
                x: x.layer * horizontalSpacingBasedOnLayerCount,
                y: (verticalSpacingPerLayerBasedOnNodeCount[x.layer] * perLayerIndex[x.layer]++) + verticalSpacingPerLayerBasedOnNodeCount[x.layer] * 0.5,
            }
        });

        return positions;
    }, [nodes]);
    
    const positions = useMemo(() => {
        return calculatePositions();
    }, [nodes, calculatePositions]);

    const option = {
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                type: "graph",
                layout: 'none',
                data: nodes.current.map((x: any, ii: number) => {
                    return {
                        ...x,
                        ...positions[ii]
                    }
                }),
                links: edges.current,
                itemStyle: {
                  color: '#1f77b4',
                  borderColor: '#1f77b4'
                },
                lineStyle: {
                    color: 'source',
                    curveness: 0
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{b}'
                },
                // draggable: true
            }
        ]
    };

    const onClickNode = (params: any)=> {
        console.log('onClickNode', params);
        if (menu.current) {
            menu.current.style.display = "none";
        }

        if (params.dataType == 'node') {
            setSelectedIndex(params.dataIndex);
        } else {
            setSelectedIndex(-1);
        }
    };

    const onClickBackground = (params: any)=> {
        console.log('onClickBackground', params);
        if (menu.current) {
            menu.current.style.display = "none";
        }
    };
    
    const onContextMenu = (params: any)=>{
        // alert('contextmenu', params);
        console.log('contextmenu', menu.current, params);
        if (menu.current) {
            menu.current.style.display = menu.current.style.display == "block" ? "none": "block";
            menu.current.style.top = params.event.clientY.toString() + "px";
            menu.current.style.left = params.event.clientX.toString() + "px";
        }
        params.event.preventDefault();
    };

    useEffect(() => {
        echartsRef.current?.getEchartsInstance().on('click', onClickNode);
        echartsRef.current?.getEchartsInstance().getZr().on('click', onClickBackground);
        echartsRef.current?.getEchartsInstance().on('mousedown', (p: any) => {
            console.log('mousedown', p);
            dragIndex.current = p.dataIndex;
        });
        echartsRef.current?.getEchartsInstance().on('mouseup', (p: any) => {
            console.log('mouseup', p);
            if (dragIndex.current >= 0 && dragIndex.current != p.dataIndex) {
                addEdge(dragIndex.current, p.dataIndex);
            }
            dragIndex.current = -1;
        });
        // echartsRef.current?.getEchartsInstance().getZr().on('drag', (p: any) => {
        //     console.log('drag', p.event.offsetX, p.event.offsetY, dragIndex.current);
        // });
        echartsRef.current?.getEchartsInstance().getZr().on('contextmenu', onContextMenu);
    }, []);

    const addNodeTo = useCallback((layer: number) => {
        nodes.current = [...nodes.current, {name: `node${nodes.current.length + 1}`, layer, src: ""}];
        
        const positions = calculatePositions();

        echartsRef.current?.getEchartsInstance().setOption({
            series: [
                {
                    data: nodes.current.map((x: any, ii: number) => {
                        return {
                            ...x,
                            ...positions[ii]
                        }
                    }),
                }
            ]
        });
        
        if (menu.current) {
            menu.current.style.display = "none";
        }
    }, [nodes, echartsRef, calculatePositions]);

    const addEdge = useCallback((source: number, target: number) => {
        if (edges.current.find((x: any) => x.source == nodes.current[source].name && x.target == nodes.current[target].name)) {
            console.log('edge already exists')
            return;
        }

        edges.current = [...edges.current, {
            source: nodes.current[source].name,
            target: nodes.current[target].name
        }];
        echartsRef.current?.getEchartsInstance().setOption({
            series: [
                {
                    links: edges.current,
                }
            ]
        });
    }, [nodes, edges, echartsRef]);

    const generateCF = useCallback(() => {
        let cfyaml = new CFYaml();
        
        const BUCKET_PARAM = "BucketName";
        const BUCKET_VALUE = "mp-test-aws-glue";
        cfyaml.addParameter(BUCKET_PARAM, BUCKET_VALUE);
        

        const glueRoleResourceName = "AWSGlueServiceRole";
        const glueRoleName = "AWSGlueServiceRole-dk-glue";

        const glueRoleAssumedPolicy = new AssumeRolePolicyDocument()
        glueRoleAssumedPolicy.addStatement("Allow", "glue.amazonaws.com", "sts:AssumeRole");
        
        const gluePassRolePolicy = new Policy("passRole-glue");
        gluePassRolePolicy.addStatement("Allow", 'iam:PassRole', "*");

        const glueStartStepFnPolicy = new Policy("start-stepfn-glue");
        glueStartStepFnPolicy.addStatement("Allow", 'states:StartExecution', "arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_Workflow");

        const glueManagedPolicyArns = [    
            'arn:aws:iam::aws:policy/AmazonS3FullAccess',
            'arn:aws:iam::aws:policy/service-role/AWSGlueServiceRole'
        ];
        cfyaml.addRole(glueRoleResourceName, glueRoleName, glueRoleAssumedPolicy.document, glueManagedPolicyArns, [gluePassRolePolicy, glueStartStepFnPolicy]);

        
        const stepFnResourceName = "AWSStepFunctionRole";
        const stepFnRoleName = "AWSStepFunctionRole-dk-glue";

        const stepFnRoleAssumedPolicy = new AssumeRolePolicyDocument()
        stepFnRoleAssumedPolicy.addStatement("Allow", "states.amazonaws.com", "sts:AssumeRole");
        
        const stepFnPassRolePolicy = new Policy("passRole-glue");
        stepFnPassRolePolicy.addStatement("Allow", 'iam:PassRole', "*");
        
        const stepFnExecuteGluePolicy = new Policy("step-fn-start-execution-glue");
        stepFnExecuteGluePolicy.addStatement("Allow", 'states:StartExecution', [
            "arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_SMS_Workflow",
            "arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_Calls_Workflow",
            "arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_Data_Workflow"
        ]);
        stepFnExecuteGluePolicy.addStatement("Allow", [
            'states:DescribeExecution',
            'states:StopExecution'
        ], "arn:aws:states:eu-west-2:537416613611:stateMachine:*");
        stepFnExecuteGluePolicy.addStatement("Allow", [
            "events:PutTargets",
            "events:PutRule",
            "events:DescribeRule"
        ], "arn:aws:events:eu-west-2:537416613611:rule/StepFunctionsGetEventsForStepFunctionsExecutionRule");

        const stepFnManagedPolicyArns = [    
            'arn:aws:iam::aws:policy/CloudWatchLogsFullAccess',
            'arn:aws:iam::aws:policy/service-role/AWSGlueServiceRole'
        ];
        cfyaml.addRole(stepFnResourceName, stepFnRoleName, stepFnRoleAssumedPolicy.document, stepFnManagedPolicyArns, [stepFnPassRolePolicy, stepFnExecuteGluePolicy]);

        
        const lambdaRoleResourceName = "AWSLambdaTriggerRole";
        const lambdaRoleName = "DK_Glue_Trigger_Role";

        const lambdaRoleAssumedPolicy = new AssumeRolePolicyDocument()
        lambdaRoleAssumedPolicy.addStatement("Allow", "lambda.amazonaws.com", "sts:AssumeRole");
        
        const lambdaExecuteGluePolicy = new Policy("DK_Glue_Trigger-lambda-execution-role");
        lambdaExecuteGluePolicy.addStatement("Allow", 'logs:CreateLogGroup', "arn:aws:logs:eu-west-2:537416613611:*");
        lambdaExecuteGluePolicy.addStatement("Allow", [
            'logs:CreateLogStream',
            "logs:PutLogEvents"
        ], "arn:aws:logs:eu-west-2:537416613611:log-group:/aws/lambda/DK_Glue_Trigger:*");

        const lambdaStartJobPolicy = new Policy("DK_Glue_Trigger-glue-start-job-role");
        lambdaStartJobPolicy.addStatement("Allow", 'glue:StartJobRun', "*");

        const lambdaTriggerS3Policy = new Policy("DK_Glue_Trigger-s3-role");
        lambdaTriggerS3Policy.addStatement("Allow", [
            "s3:*",
            "s3-object-lambda:*"
        ], [
            "arn:aws:s3:::"+BUCKET_VALUE,
            "arn:aws:s3:::"+BUCKET_VALUE+"/*",
        ]);

        const lambdaStartStepFnPolicy = new Policy("start-stepfn-glue");
        lambdaStartStepFnPolicy.addStatement("Allow", 'states:StartExecution', "arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_Workflow");

        const lambdaManagedPolicyArns: string[] = [];
        cfyaml.addRole(
            lambdaRoleResourceName,
            lambdaRoleName,
            lambdaRoleAssumedPolicy.document,
            lambdaManagedPolicyArns,
            [lambdaExecuteGluePolicy, lambdaStartJobPolicy, lambdaTriggerS3Policy, lambdaStartStepFnPolicy]
        );


        nodes.current.forEach((x: any) => {
            cfyaml.addGlueJob(x.name, x.src.split('.')[0], glueRoleResourceName, x.src, BUCKET_PARAM, x.timeout ? x.timeout : 15);
        });

        cfyaml.addStepFn("OverviewStateMachine", "DK_Glue_Workflow", stepFnResourceName, `{
            "Comment": "A description of my state machine",
            "StartAt": "Parallel",
            "States": {
              "Parallel": {
                "Type": "Parallel",
                "Branches": [
                  {
                    "StartAt": "Run SMS Step Fn",
                    "States": {
                      "Run SMS Step Fn": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::states:startExecution.sync:2",
                        "Parameters": {
                          "StateMachineArn": "arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_SMS_Workflow",
                          "Input": {}
                        },
                        "Next": "SMS Success",
                        "Catch": [
                          {
                            "ErrorEquals": [
                              "States.ALL"
                            ],
                            "Next": "SMS Fail"
                          }
                        ]
                      },
                      "SMS Success": {
                        "Type": "Succeed"
                      },
                      "SMS Fail": {
                        "Type": "Succeed"
                      }
                    }
                  },
                  {
                    "StartAt": "Run Calls Step Fn",
                    "States": {
                      "Run Calls Step Fn": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::states:startExecution.sync:2",
                        "Parameters": {
                          "StateMachineArn": "arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_Calls_Workflow",
                          "Input": {}
                        },
                        "Next": "Calls Success",
                        "Catch": [
                          {
                            "ErrorEquals": [
                              "States.ALL"
                            ],
                            "Next": "Calls Fail"
                          }
                        ]
                      },
                      "Calls Success": {
                        "Type": "Succeed"
                      },
                      "Calls Fail": {
                        "Type": "Succeed"
                      }
                    }
                  },
                  {
                    "StartAt": "Run Data Step Fn",
                    "States": {
                      "Run Data Step Fn": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::states:startExecution.sync:2",
                        "Parameters": {
                          "StateMachineArn": "arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_Data_Workflow",
                          "Input": {}
                        },
                        "Next": "Data Success",
                        "Catch": [
                          {
                            "ErrorEquals": [
                              "States.ALL"
                            ],
                            "Next": "Data Fail"
                          }
                        ]
                      },
                      "Data Success": {
                        "Type": "Succeed"
                      },
                      "Data Fail": {
                        "Type": "Succeed"
                      }
                    }
                  }
                ],
                "End": true
              }
            }
          }`
        );

        cfyaml.addStepFn("SMSStateMachine", "DK_Glue_SMS_Workflow", stepFnResourceName, `{
            "Comment": "A description of my state machine",
            "StartAt": "Store SMS",
            "States": {
              "Store SMS": {
                "Type": "Task",
                "Resource": "arn:aws:states:::glue:startJobRun.sync",
                "Parameters": {
                  "JobName": "DK_Glue_SMSRecord_Store"
                },
                "Next": "Aggregate SMS"
              },
              "Aggregate SMS": {
                "Type": "Task",
                "Resource": "arn:aws:states:::glue:startJobRun.sync",
                "Parameters": {
                  "JobName": "DK_Glue_SMSRecord_Aggregate"
                },
                "Next": "Report SMS"
              },
              "Report SMS": {
                "Type": "Task",
                "Resource": "arn:aws:states:::glue:startJobRun",
                "Parameters": {
                  "JobName": "DK_Glue_SMSRecord_Report"
                },
                "End": true
              }
            }
        }`);

        cfyaml.addStepFn("CallsStateMachine", "DK_Glue_Calls_Workflow", stepFnResourceName, `{
            "Comment": "A description of my state machine",
            "StartAt": "Store Calls",
            "States": {
              "Store Calls": {
                "Type": "Task",
                "Resource": "arn:aws:states:::glue:startJobRun.sync",
                "Parameters": {
                  "JobName": "DK_Glue_CallRecord_Store"
                },
                "Next": "Aggregate Calls"
              },
              "Aggregate Calls": {
                "Type": "Parallel",
                "Branches": [
                  {
                    "StartAt": "Aggregate Inbound Calls",
                    "States": {
                      "Aggregate Inbound Calls": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::glue:startJobRun.sync",
                        "Parameters": {
                          "JobName": "DK_Glue_CallRecord_Inbound_Aggregate"
                        },
                        "Next": "Report Inbound Calls"
                      },
                      "Report Inbound Calls": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::glue:startJobRun",
                        "Parameters": {
                          "JobName": "DK_Glue_CallRecord_Inbound_Report"
                        },
                        "End": true
                      }
                    }
                  },
                  {
                    "StartAt": "Aggregate Outbound Calls",
                    "States": {
                      "Aggregate Outbound Calls": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::glue:startJobRun.sync",
                        "Parameters": {
                          "JobName": "DK_Glue_CallRecord_Outbound_Aggregate"
                        },
                        "Next": "Report Outbound Calls"
                      },
                      "Report Outbound Calls": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::glue:startJobRun",
                        "Parameters": {
                          "JobName": "DK_Glue_CallRecord_Outbound_Report"
                        },
                        "End": true
                      }
                    }
                  }
                ],
                "End": true
              }
            }
        }`);

        cfyaml.addStepFn("DataStateMachine", "DK_Glue_Data_Workflow", stepFnResourceName, `{
            "Comment": "A description of my state machine",
            "StartAt": "Store Data",
            "States": {
              "Store Data": {
                "Type": "Task",
                "Resource": "arn:aws:states:::glue:startJobRun.sync",
                "Parameters": {
                  "JobName": "DK_Glue_PadsRecord_Store"
                },
                "Next": "Aggregate Data"
              },
              "Aggregate Data": {
                "Type": "Task",
                "Resource": "arn:aws:states:::glue:startJobRun.sync",
                "Parameters": {
                  "JobName": "DK_Glue_PadsRecord_Aggregate"
                },
                "Next": "Report Data"
              },
              "Report Data": {
                "Type": "Parallel",
                "Branches": [
                  {
                    "StartAt": "Report Sent Data",
                    "States": {
                      "Report Sent Data": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::glue:startJobRun",
                        "Parameters": {
                          "JobName": "DK_Glue_PadsRecord_Sent_Report"
                        },
                        "End": true
                      }
                    }
                  },
                  {
                    "StartAt": "Report Received Data",
                    "States": {
                      "Report Received Data": {
                        "Type": "Task",
                        "Resource": "arn:aws:states:::glue:startJobRun",
                        "Parameters": {
                          "JobName": "DK_Glue_PadsRecord_Received_Report"
                        },
                        "End": true
                      }
                    }
                  }
                ],
                "End": true
              }
            }
        }`);

        cfyaml.addTestEventSchema("GlueTriggerLambdaEventSchema", "GlueTriggerLambda", `{
            "openapi": "3.0.0",
            "info": {
              "version": "1.0.0",
              "title": "Event"
            },
            "paths": {},
            "components": {
              "schemas": {
                "Event": {
                  "type": "object",
                  "required": [
                    "Records",
                    "override"
                  ],
                  "properties": {
                    "Records": {
                      "type": "array",
                      "items": {
                        "type": "object"
                      }
                    },
                    "override": {
                      "type": "boolean"
                    }
                  }
                }
              },
              "examples": {
                "test-records": {
                  "value": {
                    "Records": [
                      {
                        "s3": {
                          "object": {
                            "key": "argentina_raw/filename.zip"
                          }
                        }
                      }
                    ],
                    "override": true
                  }
                }
              }
            }
          }`);


        const glueTriggerLambdaCode = `
import json
import os
import io
import urllib.parse
import logging
logger = logging.getLogger()
logger.setLevel(logging.INFO)

# Import Boto 3 for AWS Glue
import boto3
client = boto3.client('glue')
s3 = boto3.client("s3")

# Variables for the job: 
glueJobName = "DK_Glue_Extract_Zip"
bucket = "mp-test-aws-glue"
trigger_file = "trigger.csv"

# Define Lambda function
def handler(event, context):
    to_process = []
    
    if "Records" in event:
        logger.info('## INITIATED BY EVENT: ')
        logger.info(event['Records'])
        
        to_process = [urllib.parse.unquote_plus(record['s3']['object']['key'], encoding='utf-8') for record in event['Records']]
    else:
        objects = s3.list_objects(
            Bucket=bucket,
            Prefix="argentina_raw/"
        )["Contents"]
        to_process = [ o["Key"] for o in objects if o["Key"].endswith(".zip") ] 
        
    processed_files_list = s3.get_object(
        Bucket=bucket,
        Key=trigger_file
    )
    objbuffer = processed_files_list["Body"].read().decode('utf-8')
    processed_files = objbuffer.split("\r\n")
    
    print("processed_files:", len(processed_files))
    print("to process:", len(to_process))
    
    if "override" in event and event["override"]:
        unprocessed = [x for x in to_process]
    else:
        unprocessed = [x for x in to_process if x not in processed_files]
    print("unprocessed:", len(unprocessed))
    
    responses = []
    for key in (unprocessed[:30]):
        processed_files.append(key)
        
        response = client.start_job_run(JobName = glueJobName, Arguments={'--FILE_NAME': key})
        logger.info('## STARTED GLUE JOB: ' + glueJobName)
        logger.info('## GLUE JOB RUN ID: ' + response['JobRunId'])
        
        print("processed:", key)
        
        responses.append(response['JobRunId'])
    
    s3.put_object(Body=b'\r\n'.join([x.encode("utf-8") for x in processed_files]), Bucket=bucket, Key=trigger_file)
    
    return ", ".join(responses)
        `;
        cfyaml.addLambda("GlueTriggerLambda", "DK_Glue_Extract_Trigger", lambdaRoleResourceName, glueTriggerLambdaCode, "python3.10", 30) 
        
        
        cfyaml.addTriggerRule("StepFnTriggerRule", "DK-Glue-Step-Fn-Trigger-3-hour", "rate(3 hours)", "StepFnTriggerLambda");

        const stepFnTriggerLambdaCode = `
import json
import os
import io
import urllib.parse
import logging
logger = logging.getLogger()
logger.setLevel(logging.INFO)

# Import Boto 3 for AWS Glue
import boto3
step_fn = boto3.client("stepfunctions")

# Define Lambda function
def handler(event, context):
    logger.info("Triggering step fn")
    response = step_fn.start_execution(
        stateMachineArn='arn:aws:states:eu-west-2:537416613611:stateMachine:DK_Glue_Workflow'
    )
    print(response)
        `;
        cfyaml.addLambda("StepFnTriggerLambda", "DK_Glue_Step_Fn_Trigger", lambdaRoleResourceName, stepFnTriggerLambdaCode, "python3.10", 30) 
        

        let x = dump(cfyaml.yaml, {
            indent: 2,
            noRefs: true,
            noCompatMode: true,
            lineWidth: -1,
            quotingType: '"',
        });
        console.log(x)
        setYaml(x);
    }, [nodes, edges]);


    return (
        <>
            <div id="context-menu" ref={menu}>
                {layers.map((x: any, ii: number) => (
                        <div 
                            className="item"
                            onClick={() => addNodeTo(x)}
                            key={ii}
                        >
                            New node in layer {x}
                        </div>
                    )
                )}
            </div>
            <ReactEcharts
                ref={echartsRef}
                option={option}
                style={{height: '500px', width: '100%'}}
                className='react_for_echarts'
            />
            {selectedIndex >= 0 && (
                <div className="selected-node">
                    <div className="title">Selected node</div>
                    <div className="content">
                        <div className="item">
                            <div className="label">Name</div>
                            <input type={'text'} readOnly className="value" value={nodes.current[selectedIndex].name} />
                        </div>
                        <div className="item">
                            <div className="label">Layer</div>
                            <input className="value" readOnly value={nodes.current[selectedIndex].layer} />
                        </div>
                        <div className="item">
                            <div className="label">Source</div>
                            <input className="value" readOnly value={nodes.current[selectedIndex].src} />
                        </div>
                    </div>
                </div>
            )}
            <Button onClick={() => generateCF()}>Generate CF</Button>
            {yaml && (
                <div className="yaml">
                    <pre>{yaml}</pre>
                </div>
            )}
        </>
    );
}

export const DependenciesChart = () => {
    const option = {
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                type: "sankey",
                layout: 'none',
                emphasis: {
                  focus: 'adjacency'
                },
                nodeAlign: 'left',
                data: graph_data.nodes,
                links: graph_data.edges,
                itemStyle: {
                  color: '#1f77b4',
                  borderColor: '#1f77b4'
                },
                lineStyle: {
                    color: 'source',
                    curveness: 0.5
                },
                label: {
                    show: true,
                    position: 'right',
                    formatter: '{b}'
                },
            }
        ]
    };

    return (
            <ReactEcharts
                option={option}
                style={{height: '500px', width: '100%'}}
                className='react_for_echarts'
            />
    );
};